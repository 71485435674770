import React, { Component } from 'react';
import { connect } from 'react-redux';
import withBackend from '../../backend/withBackend';
import { compose } from 'redux';
import { setUser } from '../../actions';
import Profile from '../../components/Profile';

class AppContainer extends Component {
  render() {
    const { setUser, firebase } = this.props;
    return <Profile firebase={firebase} setUser={setUser} />;
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(AppContainer);