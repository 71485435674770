import { SET_USER, REMOVE_USER, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from '../constants/actionTypes';
import Firebase from '../services/firebase';

const setUser = payload => ({
  type: SET_USER,
  payload,
});

const removeUser = () => ({
  type: REMOVE_USER,
});

// const fetchUser = (userId, payload) => ({
//   type: FETCH_USER_REQUEST,
//   payload,
// });

const fetchUserSuccess = () => ({
  type: FETCH_USER_SUCCESS,
})

const fetchUserError = () => ({
  type: FETCH_USER_ERROR,
})

const fetchUser = (userId) => {
  return dispatch => {
    Firebase.getUser(userId)
      .then(querySnapshot => {
        if (querySnapshot) {
          const userData = querySnapshot.data();
          dispatch(setUser(userData));
          dispatch(fetchUserSuccess(userData));
        } else {
          dispatch(fetchUserError('User not found'));
        }
      })
      .catch(error => {
        dispatch(fetchUserError(error.message));
      });
  };
};

export { setUser, removeUser, fetchUser, fetchUserSuccess, fetchUserError };
