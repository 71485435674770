import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

function QuickSignUp({ handleSubmit, handleSubmitWithGoogle, error }) {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="x-sign_in-wrapper">
      <div className="login-background-wrapper">
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407972786317/showcase5.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={321}
            height={321}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407972786317/showcase5.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197514854410/showcase14.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={699}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197514854410/showcase14.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1151896408299929670/showcase6.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={719}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896408299929670/showcase6.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195652587570/showcase10.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={531}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195652587570/showcase10.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152075176628985956/showcase16.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={719}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152075176628985956/showcase16.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407549149265/showcase4.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={699}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151896407549149265/showcase4.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195895865414/showcase11.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={719}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074195895865414/showcase11.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1151892585107968122/showcase1.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={531}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151892585107968122/showcase1.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1151892584671739984/showcase2.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={699}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1151892584671739984/showcase2.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197808459817/showcase15.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={719}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197808459817/showcase15.webp"
          />
        </picture>
        <picture className="login-background-item">
          <source
            srcSet="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197246431334/showcase13.webp"
            type="image/webp"
          />
          <img
            className="login-background-item"
            alt="noithatai"
            width={521}
            height={531}
            src="https://cdn.discordapp.com/attachments/1105800665105711166/1152074197246431334/showcase13.webp"
          />
        </picture>
      </div>
      <div className="sign_in-container">
        <div className="x-login-placeholder">
          <div className="login-form-container">
            <div>
              <h1 className="x-auth-panel-title">{t('signup.title')}</h1>
              <a href="#" className="link-block-6 w-inline-block" onClick={(e) => handleSubmitWithGoogle(e, t)}>
                <div>
                  <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" />
                </div>
                <p className="body-1-semi-bold ml-12">{t('button.googleSignup')}</p>
              </a>
            </div>
            <div class="g-recaptcha flex justify-content-center mt-2" data-sitekey={RECAPTCHA}/>
            <div className="x-agree-service">
              {t('tos.agree')} 
              <Link
                className="agree-service"
                to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
              >
                {t('footer.terms')}
              </Link>
              {' '}&{' '}
            <Link
              className="agree-service"
              to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
            >
              {t('footer.privacy')}
            </Link>
            </div>
            <div className="w-form">
              <form id="email-form" name="email-form">
                <div className="_2nd-form">
                  {error && <p style={{ marginTop: '15px', color: 'red' }}>{error.message}</p>}
                    {/* <Link
                    to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                    className="submit-button-4 w-button text-align-center"
                    >
                    Go with Email
                  </Link>
                  <p className="agree-service">
                    {t('tos.agree')} 
                    <Link
                      className="agree-service"
                      to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                    >
                      {t('footer.terms')}
                    </Link>
                    {' '}&{' '}
                  <Link
                    className="agree-service"
                    to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                  >
                    {t('footer.privacy')}
                  </Link>
                  </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}

QuickSignUp.propTypes = {
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default QuickSignUp;
