import React from 'react';

const Loading = ({title}) => {

  return (
    <div className="showbox">
      <div className="loader-wrapper">
        <p/><p/><p/><p/>
      </div>
      <div className="searching-title">{title}</div>
    </div>
  );
};

export default Loading;
