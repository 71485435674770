const en = {
    Basic: "Basic",
    Pro: "Pro",
    month: "month",
    year: "year",
    button: {
        getStarted: 'Get Started',
        try: 'Generate Headshots',
        viewall: 'View All',
        basic: 'Basic',
        pro: 'Pro',
        generate: 'Generate Design',
        backHome: 'Back to Home',
        googleSignin: 'Google',
        googleSignup: 'Google',
        emailSignin: 'Sign In with Email',
        emailSignup: 'Sign Up with Email',
        submit: 'Submit',
        choose: 'Choose',
        download: 'Download This Template',
        upgrade: 'Buy more credits',
        subscribe: 'Buy it',
        upgradePro: 'Upgrade',
        confirmPayment: 'Confirm Payment',
        tryThisFeature: 'Try This Now',
        removeBG: 'Start',
        professional: 'Start',
        upscale: 'Start',
        ok: 'Ok',
        cancel: 'Cancel',
        select: 'Select',
        selectToStart: 'Select To Start',
        uploadPose: 'Upload pose',
        uploadingPose: 'Uploading your pose',
        upgradeToUpload: 'Upgrade to upload',
        unlock: "Upgrade",
        englishOnly: "English version",
        contactUs: "Contact Us",
        forEnterprise: "For Enterprise Solutions",
        tryThis: "Try"
    },
    header: {
        nav: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            signOut: 'Sign Out',
            supportTitle: 'Support',
            supportContact: 'Contact Us',
            myFlowers: 'My Headshots'
        }
    },
    landing: {
        title: 'AI headshot generator 📸',
        subTitle: "Instantly generate polished business headshots with our AI-photographer in seconds not hours. Save time and money effortlessly.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '125,620+ AI headshots already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'No FaceID or multiple photos needed. Simply use a single selfie, just like your usual Facebook or Instagram posts.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How it works',
        howItWorksSub: 'Snap or upload -> Pick style and background -> Pick or upload your pose -> Receive your headshots!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'What our customers say',
        hotFeatureSub: 'These are AI-generated headshots examples, shared by their permission.',
        pricingTitle: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
        step1: 'Upload only 1 selfie',
        step1Sub: 'Select your style, background and pose.',
        step2: 'Our AI photographer gets to work in seconds',
        step2Sub: 'Our cutting-edge technology quickly creates your headshots.',
        step3: 'Receive your headshots',
        step3Sub: 'Download your headshots.',
        trialTitle: "FREE Trial",
        trialSubTitle: "#1 AI Headshot Generator 📸",
        headshotsMenu: "Headshots"
    },
    businessHeadshots: {
        title: 'Businesses Headshots',
        subTitle: "Unlock the Ultimate Team Branding Experience with Businesses Headshot!",
        menuTitle: "Businesses",
    },
    datingPhotos: {
        title: 'Dating Photos with AI',
        subTitle: "Skyrocket Your Dating Success: Unleash the Power of AI to Transform Your Profile!",
        menuTitle: "Dating"
    },
    studentHeadshots: {
        title: 'Student Headshots',
        subTitle: "Get more jobs with our professional AI headshot in seconds",
        menuTitle: "Student Headshots"
    },
    teacherHeadshots: {
        title: 'Teacher Headshots',
        subTitle: "Unlock the Ultimate Professional Edge: Teachers, Transform Your Image in Minutes with the Revolutionary AI Headshot Generator!",
        menuTitle: "Teacher Headshots"
    },
    docterHeadshots: {
        title: 'Doctor Headshots',
        subTitle: "Unlock the Future of Personal Branding with AI-enhanced Headshots for Healthcare Professionals!",
        menuTitle: "Doctor Headshots"
    },
    lawyerHeadshots: {
        title: 'Lawyer Headshots',
        subTitle: "Unlock the Power of First Impressions: Revolutionary AI Headshots Tailored for Lawyers!",
        menuTitle: "Lawyer Headshots"
    },
    realeastateHeadshots: {
        title: 'Real Estate Headshots',
        subTitle: "Boost Your Real Estate Success Elevate Your Marketing with Striking AI-Generated Headshots!",
        menuTitle: "Real Estate Headshots"
    },
    salespersonHeadshots: {
        title: 'Salesperson Headshots',
        subTitle: "Unlock the Secret to Skyrocketing Your Sales Transform Your First Impression with Our Revolutionary AI Headshots!",
        menuTitle: "Salesperson Headshots"
    },
    yearbook: {
        title: 'AI Yearbook photoshoot 📸',
        subTitle: "Not using many Selfie images. Get your 90s Yearbook photos with a single Selfie with our AI-photographer in seconds. Save 10x your time.",
        input: 'Tell me the type of flower you want to create?',
        inside: 'Indoor',
        outside: 'Outdoor',
        loveBy: '125,620+ AI headshots already created',
        respectTitle: 'We respect your privacy',
        respectSub1: 'We do not use FaceID or require a lot of photos to perform. Just use a Selfie, similar to what you often post on Facebook and Instagram.',
        respectSub2: 'Your data is completely safe and secure on Google platform very high security.',
        howItWorks: 'How AI Yearbook Works',
        howItWorksSub: 'You take a selfie or use a clear ID photo, select your style & pose do headshots in seconds not hours!',
        unBlurryTitle: 'Photo upscaler',
        unBlurrySub: 'Remove blurs, enhance, and improve photo colors before posting on social media platforms like Facebook, Instagram, Tiktok.',
        useThisFeature: 'Create yours now',
        anotherFeatureTitle: 'Outstanding Features',
        anotherFeatureSub: 'Free, fast, and accurate. Try these features below.',
        feature1: 'Remove backgrounds',
        feature1Sub: 'Accurately remove any background.',
        feature2: 'Interior Design',
        feature2Sub: 'Interior design, exterior design, and sketching with genhomeai.com',
        feature3: 'Change Backgrounds (Coming soon)',
        feature3Sub: 'Change backgrounds for any object with REOK’s AI',
        hotFeatureTitle: 'Our AI Yearbook Photos',
        hotFeatureSub: 'These are AI-generated headshots.',
        pricingTitle: 'Pricing',
        pricingSub: '1 professional photo = 10 credits or less',
    },
    showcase: {
        title: 'Sample Flowers',
        status: {
            pending: 'processing',
            upscaling: 'upscaling',
            choosing: 'choosing',
            finished: 'finished'
        },
        mode: {
            pro: 'Pro',
            basic: 'Basic'
        }
    },
    home: {
        title: 'Your Designs',
        hotColletion: "Hot Collections",
        credits: 'Designs left',
        uploadTitle: 'Upload your selfie',
        uploadPlaceHolder: 'Drag and drop, tap to select, or take a photo (max 10MB)',
        stylePlaceHolder: 'Select',
        uploadPosePlaceHolder: 'Select',
        uploadBGPlaceHolder: 'Select',
        roomTitle: 'Choose room type (29)',
        themeTitle: 'Choose room style (34)',
        pro: {
            title: 'Creativity for All Types of Flowers',
            input: 'Describe your flower...',
        },
        basic: {
            title: 'Unlimited Creativity with Pro',
            chooseFlowerTitle: 'Flower',
            flowers: 'Living Room',
            styles: 'Style',
            decoration: 'Decoration',
            events: 'Events',
            backgrounds: 'Backgrounds'
        },
    },
    profile: {
        title: 'My Headshots',
        before: 'Before -',
        after: 'After -'
    },
    terms: {
        title: 'Terms and Conditions',
        content: ''
    },
    privacy: {
        title: 'Privacy Policy',
        one: 'Your privacy is very important to us. The privacy policy of reok.pro respects your privacy regarding any information we may collect from you on our website, reok.pro, and other websites we own and operate.',
        two: 'We only request personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.',
        three: 'We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.',
        four: 'We do not share any personally identifying information publicly or with third-parties, except when required to by law.',
        five: 'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.',
        six: 'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        seven: 'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.',
        eight: 'This policy is effective as of September 15, 2023.'
    },
    refund: {
        moneyBack: 'Money-back Guarantee',
        title: 'Refund Policy',
        one: 'Our goal is to provide high-quality service and outcomes to all our clients. We understand that there can be instances where the output does not meet your expectations',
        two: 'We guarantee your satisfaction. If you are unhappy with the photos generated using Reok.pro, we are more than happy to provide you with a full refund. Alternatively, in some cases, we may offer to redo your photos to better meet your expectations.',
        three: 'We will review and respond to refund requests, based on this policy, within 1-3 business days. If your refund is approved, the funds should be back on your card within 5-10 business days. Refunds will be processed back to the original payment method used during purchase; we cannot refund to a different account.',
        four: 'We can only process refund requests submitted within 30 days of purchase and use UNDER 20 credits.',
        five: 'To apply for a refund, kindly email us at hello@reok.pro or use our chat feature available on our website. When contacting us about a refund, please include the email address you used when placing your order.',
        six: 'Thank you for choosing Reok.pro for your headshots.',
    },
    footer: {
        title: 'REOK Studio: AI Headshots for LinkedIn, Social Media, Resume, Acting. Results in seconds from a single selfie.',
        terms: 'Terms',
        privacy: 'Privacy Policy',
        app: 'App',
        signin: 'Sign In',
        signup: 'Sign Up',
        email: 'Email',
        chatNow: 'Chat Now',
        partner: 'Partner',
        reportBug: 'Report a Bug',
        require: 'Request Features',
        page: 'Page',
        tos: 'Legal',
        contact: 'Contact',
        other: 'Other',
        affiliate: 'Affiliates',
        yearbook: 'AI Yearbook',
        blog: 'Blog',
        pricing: 'Pricing',
        howItWorks: 'How it works',
    },
    tos: {
        agree: 'New accounts are subject to our ',
        one: '1. Introduction',
        oneDetail: 'By using reok.pro, you agree to and are bound by these terms and conditions.',
        two: '2. Agreement to Terms',
        twoDetail: 'This Agreement is effective from the date you first use the reok.pro app.',
        three: '3. Features',
        threeDetail: 'You will be able to use all features after subscribing to a specific paid plan. Details of available features can be found on the pricing page.',
        four: '4. Refund Policy',
        fourDetail: 'We allow refunds for failed transactions within 14 days of purchase. Contact support to request a refund.',
        five: '5. Use of Product',
        fiveDetail: 'By using reok.pro, you agree to receive important product updates from reok.pro via the email associated with your Google account or the email you used to register your account. You can opt out of receiving these product updates at any time by clicking the "Unsubscribe" link at the bottom of each email. We only send important product updates.',
        six: '6. Disclaimer',
        sixDetail: 'We do not guarantee that reok.pro will meet your requirements or that its operation will be uninterrupted or error-free. All warranties or conditions not expressly set out in this Agreement (including, without limitation, loss of profit, loss or corruption of data, business interruption, or any other pecuniary loss, damage, cost, or expense of any nature whatsoever) are, to the maximum extent permitted by law, excluded and disclaimed. This Agreement does not affect any statutory rights you may have as a consumer.',
        seven: '7. Warranty and Legal Limitations',
        sevenDetail: 'reok.pro does not make any warranties, guarantees, or other representations about the quality, fitness for a particular purpose, or other character or performance of the software. reok.pro will not be liable to you for any lost profits or any consequential, special, incidental, or indirect damages arising out of or relating to this Agreement or your use of reok.pro (however arising, including negligence) except where liability cannot be excluded under law.',
        eight: '8. General Terms and Law',
        eightDetail: 'You acknowledge that no joint venture, partnership, employment, or agency relationship exists between you and reok.pro as a result of using these services. You agree not to hold yourself out as a representative, agent, or employee of reok.pro. You agree that reok.pro will not be liable to you for any action that we take to prevent or address misuse of our platform. You acknowledge that, in providing you with the use of reok.pro, we have relied upon your agreement to be bound by the terms of this Agreement.',
    },
    faq: {
        title: 'FAQ',
        q1: 'Why should I use REOK for taking professional photos?',
        answer1: 'To save your time and money. We have created an online studio using our powerful AI technology. No need to wait, do makeup, or pose for photos. It helps you quickly create beautiful, professional, and stylish photos for work or to post on social media platforms like Facebook, Instagram, Tiktok.',
        q2: 'How does REOK work?',
        answer2: 'Just take a selfie or select a photo from your gallery with clearly your face, then choose your gender and the number of photos you want to create. REOK\'s AI will process your request quickly and accurately.',
        q3: 'Is my personal information secure?',
        answer3: 'Absolutely, we work directly with Google`s platform. Protecting your privacy is extremely important to us. REOK commits to taking necessary measures to secure your personal information and design data. We have strong security protocols in place to ensure that your data is kept secure and used only for the purpose of providing our services. For detailed information, please refer to our privacy policy on our website.',
        q4: 'How long will My Headshots be stored?',
        answer4: 'Your designs will be stored permanently on the cloud as long as our service is still operational.',
        q5: 'How much does it cost to take a professional photo for work or business?',
        answer5: 'It costs only 5 credits for a headshot photo. This is 10 times cheaper than going to a studio for a photo shoot.',
        q6: 'How many credits does it cost to remove the background?',
        answer6: 'It costs only 2 credits to remove the background once.',
        q7: 'How many credits does it cost to unblur an image?',
        answer7: 'It costs only 2 credits to unblur an image once.',
        q8: 'Is there a fee for designing homes, interiors, and exteriors?',
        answer8: 'Yes. genhomeai.com is one of our applications and you will be charged for each design session.',
        q9: 'Can I get a refund if I am not satisfied?',
        answer9: 'We regret to inform you that, at present, we are unable to facilitate refunds for high-cost AI-related services. We recommend considering the purchase of credits after utilizing the complimentary credits provided by our platform.'
    },
    signin: {
        title: 'Sign In',
        another: 'Or with Email',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        noAccount: 'Don’t have an account?'
    },
    signup: {
        title: 'Sign Up',
        another: 'Or with Email',
        firstNamePlaceHolder: 'First Name',
        lastNamePlaceHolder: 'Last Name',
        emailPlaceHolder: 'Email Address',
        passwordPlaceHolder: 'Password',
        rememberTitle: 'Remember for Next Time',
        forgetPassword: 'Forgot Password?',
        gotAccount: 'Already have an account?'
    },
    forgetPassword: {
        title: 'Retrieve Password',
        content: 'We will send a password retrieval email to you',
        emailPlaceHolder: 'Please enter your Email',
        sendBtn: 'Send',
        backSignin: 'Back to Sign in'
    },
    pricing: {
        yearlySubscription: '1000 designs',
        monthlySubscription: '100 designs',
        saveDesk: 'Save 10%',
        saveMobile: 'Save 10% when purchasing 1000 designs',
        popular: "Best value",
        free: {
            title: 'Free Credits',
            price: '0$',
            trialTitle: 'Registration Code: Please Sign In',
            joinTitle: 'Paste your code here',
            one: 'Get 20 Free Credits',
            two: 'Included All 100 Credits Features',
            affiliateTitle: 'Affiliate program',
            shareTitle: 'Share with friends👇',
            loginToShare: 'Please sign in to share'
        },
        basic: {
            title: '100 Credits',
            price: '7$',
            duration: '/lifetime',
            recommend: 'For Individuals',
            one: '20 Professional Photos',
            two: 'Remove Background',
            three: 'Upscale to 4K',
            four: 'Download All Photos',
            five: 'Unlimited Cloud Storage',
            six: 'Commercial Usage Of Photos',
            seven: 'Access to All New Features',
            subscribed: 'Purchased'
        },
        pro: {
            title: '1000 Credits',
            subTitle: '(Save 30%)',
            price: '$49',
            duration: '/lifetime',
            recommend: 'Influencers, Businesses, Photo Studios',
            one: '200 Professional Photos',
            two: 'Included All 100 Credits Features',
            three: 'Save Your Money',
            subscribed: 'Purchased'
        },
        credit: {
            title: '1 Headshot = 5 Credits. One-time payment no subscription.'
        },
        first: {
            title: 'Starter - 200 credits',
            price: '$19',
            priceDetail: '$ 0.47 / headshot',
            duration: '/lifetime',
            recommend: '1 Headshot = 5 Credits',
            one: '40 Rendered headshots',
            two: 'Remove background',
            three: 'Upscale to 4K',
            four: 'Download all photos',
            five: 'Unlimited cloud storage',
            six: 'Commercial usage of photos',
            seven: 'Take headshots in seconds',
            eight: 'Any style & background',
            nine: 'Upload your pose',
            ten: 'No training time'
        },
        second: {
            title: 'Pro - 1250 credits',
            subTitle: '(Save 40%)',
            price: '$69',
            priceDetail: '$ 0.26 / headshot',
            duration: '/lifetime',
            recommend: 'Influencers, Businesses, Photo Studios',
            five: 'Everything in $19 plan and save 40%',
            two: '250 Rendered headshots',
            three: 'Take headshots without limited AI',
            one: 'Buy once use forever no subscription',
            six: 'Without going to the Studio',
            four: 'Render for another upto 250 people',
            subscribed: 'Purchased'
        },
        third: {
            title: 'Pro photographer',
            subTitle: '(Best value)',
            price: '$49',
            priceDetail: '$ 4.9 / headshot',
            duration: '/lifetime',
            recommend: 'Influencers, Businesses, Photo Studios',
            one: 'Request any style',
            two: '10 premium headshots',
            three: 'By professional photographers',
            four: 'Delivery headshots in a day',
            five: 'High-quality headshots',
            subscribed: 'Purchased'
        },
        four: {
            title: 'Enterprise Solutions',
            subTitle: '(Contact Us)',
            price: '$0',
            priceDetail: '$ 0 / headshot',
            duration: '/lifetime',
            recommend: 'Influencers, Businesses, Photo Studios',
            one: 'High-quality headshots',
            two: 'Companies or remote teams',
            three: 'Save time and money',
            four: 'Take headshots in seconds',
            five: 'Without traditional photo shoots',
            subscribed: 'Purchased',
        },
        currency: 'đ',
        perMonth: '/month',
        safePayment: 'Secure Payment',
        qr: 'Or Use QR Code'
    },
    milestoneMessage: {
        first: 'Happier customers with',
        second: 'designs created'
    },
    message: {
        success: {
            redering: 'Your professional photos are rendering, please wait a moment',
            rederingDone: 'AI has finished creating the design',
            activated: 'Activation successful! Enjoy creating beautiful and satisfying designs!',
            removingBG: 'Removing your background',
            upscale: 'Upscaling resolution',
            resetPassword: 'We just sent an email to reset your password. Please check it!',
            signIn: 'We just sent an email to activate your account. Please check it!',
            reviewing: 'We have received your request. It will be reviewed in a day',
            remindReviewing: 'This photo has been reviewing to clam new credits',
            removed: 'Removed the photo successfully',
            useTheTemplate: "Using the template, please just upload your selfie"
        },
        error: {
            selectPhotographer: 'Please select your AI photographer',
            uploadPhoto: 'Please upload your selfie',
            chooseRoom: 'Please select the room type',
            chooseTheme: 'Please select the room style',
            fileOverBasic: 'Please upload a file smaller than 5MB, or upgrade to Pro to upload larger files',
            fileOverPro: 'Please upload a file smaller than 10MB',
            wrongFile: 'Invalid file, please try another file',
            wrongFace: 'Please do not take your selfie too close, please try another!',
            busy: 'Connection interrupted, please try again',
            gender: 'Please select your gender',
            age: 'Please select your age',
            style: 'Please select your style',
            numberOfResult: 'Please select number of headshots',
            outOfCredits: 'You are out of Credits, please buy more credits!',
            userNotFound: 'This account is not found. Please input correctly your account!',
            wrongPassword: 'Wrong password! If you don`t remember your password please use Forget Password.',
            accountExist: 'Account already exists. Try to login from this account instead',
            tiktok: 'For Google User Security reasons, please use the Chrome or Safari browser. Visit reok.pro to access.',
            reviewing: 'You can not remove this photo because it has been reviewing',
            background: 'Please select your background',
            pose: 'Please select your pose',
            signUpReCaptcha: 'Please complete the reCAPTCHA before sign up.',
            emptyBG: 'Your background is empty, please input it!',
            tooShortBG: 'Your background should be longer than 2 words.',
            tooLongBG: 'Your background too long.',
            badWordsBG: 'Your background contains inappropriate words. Please try another',
            emptyStyle: 'Your style is empty, please input it!',
            tooShortStyle: 'Your style should be longer than 2 words.',
            tooLongStyle: 'Your style too long.',
            badWordsStyle: 'Your style contains inappropriate words. Please try another',
        },
        warning: {
            runOutBasicCredits: 'You have used all the credits in the Basic plan, please purchase more credits',
            runOutProCredits: 'Please buy more credits to use ^^',
            selectPose: 'Please select your Gender before Pose'
        },
        prompt: {
            professional: 'Professional photo',
            removeBG: 'Remove background',
            upscale: 'Upscaled resolution',
            rendering: 'AI rendering',
            faceNotFound: 'Your face was too close, please try another selfie!',
            inReview: 'In review',
            styles: {
                business: 'Professional business photo',
                realEstate: 'Professional realtor photo',
                carShowroom: 'Professional car salesperson photo',
                doctor: 'Professional doctor photo',
                yearbook: 'Professional 90s Yearbook photo',
                wedding: 'Professional wedding photo',
                halloween: 'Halloween photo'
            },
        },
        maintain: {
            backsoon: 'We are maintaining this feature, and it will be back soon. Please use other features in the meantime. Thank you! 💗💗💗'
        }
    },    
    app : {
        menu: {
            creditTitle: 'Your credits',
            affiliateTitle: 'Affiliate program',
            affiliateSub: 'Receive a 25% commission on payments for all customers you refer to us!',
            professionalMode: 'My Studio',
            removeBGMode: 'Remove BG',
            unBluryMode: 'Upscale',
            myPhotos: 'My Headshots',
        },
        tryThese: 'No image? Try these:',
        goodSelfie: 'Good selfie',
        badSelfie: 'Bad selfie',
        trick: 'Use Upscale to get better quality',
        removeBGSub: 'Removing backgrounds becomes quick, simple, and accurate with AI.',
        professionalMode: {
            title: 'Create any headshot with AI',
            refundTitle: 'Our AI has been learning for great results 💓',
            creditTitle: '1 photo = 10 credits',
            genderTitle: 'Select gender',
            ageTitle: 'Select your age',
            styleTitle: 'Select/custom style',
            quantityTitle: 'Number of results',
            poseTitle: 'Select/upload pose',
            backgroundTitle: 'Select/custom background',
            skipPose: 'Skip to keep your original pose',
            defaultPose: 'Default',
            uploadedPose: 'Uploaded',
            defaultBackground: 'Backgrounds',
            defaultStyle: 'Styles',
            headshotsTab: 'Headshots',
            selectPhotographer: 'Select your ai photographer',
            selectPhotographerSub: 'Please choose a photographer you like',
            requirements: 'Follow his requirements',
            guideSub: 'Please do it step by step 👇'
        },
        upscaleMode: {
            title: 'Quickly and accurately remove blurriness and enhance your photos with AI.',
        }
    },
    myDesign: {
        title: 'My Headshots',
        scrollTitle: 'Scroll to see all your designs.',
    },
    imageComponent: {
        compare: 'Compare',
        download: 'Download',
        removeBG: 'Remove Background',
        upscale: 'Upscale',
        errorPhoto: 'Renew Bad Photo'
    },
    results: {
        one: '1 headshot',
        four: '4 headshots',
    },
    genders: {
        man: 'Man',
        woman: 'Woman'
    },
    styles: {
        hoco_black: 'Hoco Black',
        date: 'Date',
        hoco_pink: 'Hoco Pink',
        sweet: 'Sweet',
        elite: 'Elite',
        luxury: 'Luxury',
        rich: 'Rich',
        jean: 'Jeans',
        tank_and_jeans: 'Tank & Jeans',
        athleisure: 'Athleisure',
        bordo: 'Bordo',
        cinematic: 'Cinematic',
        disney: 'Disney',
        digital_art: 'Digital Art',
        fantasy_art: 'Fantasy Art',
        neonpunk: 'Neonpunk',
        comic_book: 'Comic Book',
        lowpoly: 'Lowpoly',
        line_art: 'Line Art',
        business: 'Business',
        realEstate: 'Realtor',
        carShowroom: 'Car Salesperson',
        doctor: 'Doctor',
        yearbook: '90s Yearbook',
        wedding: 'Wedding',
        halloween: "Halloween",
        gray_suit: "Gray Suit",
        white_suit: "White Suit",
        black_suit: "Black Suit",
        pink_suit: "Pink Suit",
        blue_suit: "Blue Suit",
        white_shirt: "White Shirt",
        black_shirt: "Black Shirt",
        gray_shirt: "Gray Shirt",
        blue_shirt: "Blue Shirt",
        pink_shirt: "Pink Shirt",
        dating: "Dating",
        other: "Other",
        custom: {
            title: 'Styles & Clothes',
            btn: 'Start custom',
            dialogTitle: 'Your style and clothes',
            dialogSubTitle: 'Tell us your style, clothes, age, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a classic suit without tie, 26 years old depends on what you want...',
        }
    },
    backgrounds: {
        coffeeShop: "Coffee Shop",
        office: "Office",
        cityStreet: "City Street",
        factory: "Factories",
        garden: "Garden",
        carShowroom: "Car Showroom",
        bookShelf: "Bookshelf",
        hotel: "Hotel",
        goldenGate: "Golden Gate",
        snow: "Snow",
        realtor: "Realtor",
        brick: "Brick",
        custom: {
            title: 'Backgrounds',
            btn: 'Start custom',
            dialogTitle: 'Your backgrounds',
            dialogSubTitle: 'Tell us your background, our AI photographer will try to help you appear with it!',
            upgrade: 'Upgrade this feature',
            example: 'a coffee shop, depends on where you want...',
        },
    },
    removeTitle: 'Removing this photo',
    removeSub: 'Are you sure you want to remove this photo? This photo will be permanently removed. This action cannot be undone.',
    renewTitle: 'Renew this photo',
    renewSub: 'We will review your request. If this photo has problem, it will be permanently removed. You will get 10 new credits.',
    reasonTitle: 'Tell us why would you renew it?',
    reasonEx: 'It was unformal...',
    photographers: {
        sam: {
            name: "Sam, 👋 Hi customer",
            title: "I'm good at Styles",
            intro: "What I can do?",
            intro1: "Any Style, Background and Mood in seconds",
            intro2: "Avatar(Linkedin, 🔥Tinder, Instagram, Facebook, X, Tiktok, Youtube,...)",
            intro3: "Business photos",
            intro4: "Dating photos",
            intro5: "Art & Funny photos"
        },
        david: {
            name: "David, 👋 Hi customer",
            title: "I'm good at Poses",
            intro: "What I can do?",
            intro1: "Any Pose and Style based on your background in seconds",
            intro2: "Business photos",
            intro3: "Dating photos",
            intro4: "Wedding photos",
            intro5: "Full body photos"
        }
    }
}

export default en;
