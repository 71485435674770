const id = {
    Basic: "Dasar",
    Pro: "Pro",
    month: "bulan",
    year: "tahun",
    button: {
        getStarted: 'Mulai',
        try: 'Mulai Percobaan GRATIS Anda',
        viewall: 'Lihat Semua',
        basic: 'Dasar',
        pro: 'Pro',
        generate: 'Buat Desain',
        backHome: 'Kembali ke Beranda',
        googleSignin: 'Google',
        googleSignup: 'Google',
        emailSignin: 'Masuk dengan Email',
        emailSignup: 'Daftar dengan Email',
        submit: 'Kirim',
        choose: 'Pilih',
        download: 'Unduh Template Ini',
        upgrade: 'Beli lebih banyak kredit',
        subscribe: 'Beli it',
        upgradePro: 'Upgrade',
        confirmPayment: 'Konfirmasi Pembayaran',
        tryThisFeature: 'Coba Sekarang',
        removeBG: 'Mulai',
        professional: 'Mulai',
        upscale: 'Mulai',
        ok: 'Ok',
        cancel: 'Batal',
        select: 'Pilih',
        selectToStart: 'Pilih untuk Memulai',
        uploadPose: 'Unggah pose',
        uploadingPose: 'Mengunggah pose Anda',
        upgradeToUpload: 'Upgrade untuk mengunggah',
        unlock: "Upgrade",
        englishOnly: "Versi Bahasa Inggris",
        contactUs: "Hubungi kami di sini",
        forEnterprise: "Untuk Perusahaan",
        tryThis: "Coba"
    },
    header: {
        nav: {
            signIn: 'Masuk',
            signUp: 'Daftar',
            signOut: 'Keluar',
            supportTitle: 'Dukungan',
            supportContact: 'Hubungi Kami',
            myFlowers: 'Headshots Saya'
        }
    },
    landing: {
        title: 'Headshots profesional 📸',
        subTitle: "Buat headshots bisnis yang sempurna dengan fotografer AI kami dalam hitungan detik bukan jam. Hemat waktu dan uang dengan mudah.",
        input: 'Beritahu saya jenis bunga yang ingin Anda buat?',
        inside: 'Dalam ruangan',
        outside: 'Luar ruangan',
        loveBy: 'Lebih dari 125.620 headshots AI sudah dibuat',
        respectTitle: 'Kami menghormati privasi Anda',
        respectSub1: 'Tidak memerlukan FaceID atau beberapa foto. Cukup gunakan selfie tunggal, seperti postingan Facebook atau Instagram Anda biasanya.',
        respectSub2: 'Data Anda sepenuhnya aman dan aman di platform Google dengan keamanan yang sangat tinggi.',
        howItWorks: 'Bagaimana cara kerjanya',
        howItWorksSub: 'Ambil foto atau unggah -> Pilih gaya dan latar belakang -> Pilih atau unggah pose Anda -> Terima headshots Anda!',
        unBlurryTitle: 'Peningkatan resolusi foto',
        unBlurrySub: 'Hapus kabur, tingkatkan, dan perbaiki warna foto sebelum memposting di platform media sosial seperti Facebook, Instagram, Tiktok.',
        useThisFeature: 'Buat milikmu sekarang',
        anotherFeatureTitle: 'Fitur Unggulan',
        anotherFeatureSub: 'Gratis, cepat, dan akurat. Coba fitur di bawah ini.',
        feature1: 'Hilangkan latar belakang',
        feature1Sub: 'Hapus latar belakang dengan akurat.',
        feature2: 'Desain Interior',
        feature2Sub: 'Desain interior, desain eksterior, dan sketsa dengan genhomeai.com',
        feature3: 'Ganti Latar Belakang (Segera Hadir)',
        feature3Sub: 'Ganti latar belakang untuk objek apa pun dengan AI REOK.',
        hotFeatureTitle: 'Apa kata pelanggan kami',
        hotFeatureSub: 'Ini adalah contoh headshots yang dihasilkan oleh AI, dibagikan dengan izin mereka.',
        pricingTitle: 'Harga',
        pricingSub: '1 foto profesional = 10 kredit atau kurang',
        step1: 'Unggah hanya 1 selfie',
        step1Sub: 'Pilih gaya, latar belakang, dan pose Anda.',
        step2: 'AI kami bekerja dalam hitungan detik',
        step2Sub: 'Teknologi mutakhir kami dengan cepat membuat headshots Anda.',
        step3: 'Terima headshots Anda',
        step3Sub: 'Unduh headshots Anda.',
        trialTitle: "UJI COBA GRATIS",
        trialSubTitle: "#1 Pembuat Foto Profil AI 📸",
        headshotsMenu: "Foto Kepala"
    },
    businessHeadshots: {
        title: 'Foto Kepala Bisnis',
        subTitle: "Buka Pengalaman Merek Tim Terbaik dengan Foto Kepala Bisnis!",
        menuTitle: "Bisnis",
    },
    datingPhotos: {
        title: 'Foto Kencan dengan AI',
        subTitle: "Loncatkan Kesuksesan Kencan Anda: Lepaskan Kekuatan AI untuk Mengubah Profil Anda!",
        menuTitle: "Kencan"
    },
    studentHeadshots: {
        title: 'Foto Kepala Mahasiswa',
        subTitle: "Dapatkan lebih banyak pekerjaan dengan foto kepala AI profesional kami dalam hitungan detik",
        menuTitle: "Foto Kepala Mahasiswa"
    },
    teacherHeadshots: {
        title: 'Foto Kepala Guru',
        subTitle: "Buka Keunggulan Profesional Utama: Guru, Ubah Citra Anda dalam Hitungan Menit dengan Pembuat Foto Kepala AI Revolusioner!",
        menuTitle: "Foto Kepala Guru"
    },
    docterHeadshots: {
        title: 'Foto Kepala Dokter',
        subTitle: "Buka Masa Depan Personal Branding dengan Foto Kepala yang Ditingkatkan AI untuk Profesional Kesehatan!",
        menuTitle: "Foto Kepala Dokter"
    },
    lawyerHeadshots: {
        title: 'Foto Kepala Pengacara',
        subTitle: "Buka Kekuatan Kesan Pertama: Foto Kepala AI Revolusioner yang Disesuaikan untuk Pengacara!",
        menuTitle: "Foto Kepala Pengacara"
    },
    realeastateHeadshots: {
        title: 'Foto Kepala Properti',
        subTitle: "Tingkatkan Kesuksesan Properti Anda Tingkatkan Pemasaran Anda dengan Foto Kepala yang Menarik yang Dihasilkan AI!",
        menuTitle: "Foto Kepala Properti"
    },
    salespersonHeadshots: {
        title: 'Foto Kepala Tenaga Penjualan',
        subTitle: "Buka Rahasia Meningkatkan Penjualan Anda Transformasikan Kesan Pertama Anda dengan Foto Kepala AI Revolusioner Kami!",
        menuTitle: "Foto Kepala Tenaga Penjualan"
    },
    yearbook: {
        title: 'Sesi foto tahunan AI 📸',
        subTitle: "Tidak menggunakan banyak gambar selfie. Dapatkan foto tahun 90-an Anda dengan satu selfie menggunakan fotografer AI kami dalam hitungan detik. Hemat 10 kali lipat waktu Anda.",
        input: 'Beritahu saya jenis bunga yang ingin Anda buat?',
        inside: 'Dalam ruangan',
        outside: 'Luar ruangan',
        loveBy: 'Lebih dari 125.620 headshots AI sudah dibuat',
        respectTitle: 'Kami menghormati privasi Anda',
        respectSub1: 'Kami tidak menggunakan FaceID atau memerlukan banyak foto untuk melakukan. Cukup gunakan Selfie, seperti yang sering Anda posting di Facebook dan Instagram.',
        respectSub2: 'Data Anda sepenuhnya aman dan aman di platform Google dengan keamanan yang sangat tinggi.',
        howItWorks: 'Cara Kerja AI Yearbook',
        howItWorksSub: 'Anda mengambil selfie atau menggunakan foto ID yang jelas, pilih gaya & pose, lakukan headshots dalam hitungan detik bukan jam!',
        unBlurryTitle: 'Peningkatan resolusi foto',
        unBlurrySub: 'Hapus kabur, tingkatkan, dan perbaiki warna foto sebelum memposting di platform media sosial seperti Facebook, Instagram, Tiktok.',
        useThisFeature: 'Buat milikmu sekarang',
        anotherFeatureTitle: 'Fitur Unggulan',
        anotherFeatureSub: 'Gratis, cepat, dan akurat. Coba fitur di bawah ini.',
        feature1: 'Hilangkan latar belakang',
        feature1Sub: 'Hapus latar belakang dengan akurat.',
        feature2: 'Desain Interior',
        feature2Sub: 'Desain interior, eksterior, dan sketsa dengan genhomeai.com',
        feature3: 'Ganti Latar Belakang (Segera Hadir)',
        feature3Sub: 'Ganti latar belakang untuk objek apa pun dengan AI REOK.',
        hotFeatureTitle: 'Foto Yearbook AI Kami',
        hotFeatureSub: 'Ini adalah contoh headshots yang dihasilkan oleh AI.',
        pricingTitle: 'Harga',
        pricingSub: '1 foto profesional = 10 kredit atau kurang',
        step1: 'Unggah hanya 1 selfie',
        step1Sub: 'Pilih gaya, latar belakang, dan pose Anda.',
        step2: 'AI kami bekerja dalam hitungan detik',
        step2Sub: 'Teknologi mutakhir kami dengan cepat membuat headshots Anda.',
        step3: 'Terima headshots Anda',
        step3Sub: 'Unduh headshots Anda.',
    },
    showcase: {
        title: 'Contoh Bunga',
        status: {
            pending: 'sedang diproses',
            upscaling: 'sedang meningkatkan resolusi',
            choosing: 'sedang memilih',
            finished: 'selesai'
        },
        mode: {
            pro: 'Pro',
            basic: 'Dasar'
        }
    },
    home: {
        title: 'Desain Anda',
        hotColletion: "Koleksi Terpopuler",
        credits: 'Desain tersisa',
        uploadTitle: 'Unggah selfie Anda',
        uploadPlaceHolder: 'Seret dan lepas, ketuk untuk memilih, atau ambil foto (maks. 10MB)',
        stylePlaceHolder: 'Pilih',
        uploadPosePlaceHolder: 'Pilih',
        uploadBGPlaceHolder: 'Pilih',
        roomTitle: 'Pilih jenis ruangan (29)',
        themeTitle: 'Pilih gaya ruangan (34)',
        pro: {
            title: 'Kreativitas untuk Semua Jenis Bunga',
            input: 'Deskripsikan bunga Anda...',
        },
        basic: {
            title: 'Kreativitas Tanpa Batas dengan Pro',
            chooseFlowerTitle: 'Bunga',
            flowers: 'Ruang Tamu',
            styles: 'Gaya',
            decoration: 'Dekorasi',
            events: 'Acara',
            backgrounds: 'Latar Belakang'
        },
    },
    profile: {
        title: 'Foto Profil Saya',
        before: 'Sebelum -',
        after: 'Sesudah -'
    },
    terms: {
        title: 'Syarat dan Ketentuan',
        content: ''
    },
    privacy: {
        title: 'Kebijakan Privasi',
        one: 'Privasi Anda sangat penting bagi kami. Kebijakan privasi reok.pro menghormati privasi Anda terkait informasi apa pun yang mungkin kami kumpulkan dari Anda di situs web kami, reok.pro, dan situs web lain yang kami miliki dan operasikan.',
        two: 'Kami hanya meminta informasi pribadi ketika kami benar-benar membutuhkannya untuk menyediakan layanan kepada Anda. Kami mengumpulkannya dengan cara yang adil dan sah, dengan pengetahuan dan persetujuan Anda. Kami juga memberi tahu Anda mengapa kami mengumpulkannya dan bagaimana penggunaannya.',
        three: 'Kami hanya menyimpan informasi yang dikumpulkan selama diperlukan untuk menyediakan layanan yang diminta. Data yang kami simpan akan kami lindungi dengan cara yang dapat diterima secara komersial untuk mencegah kehilangan dan pencurian, serta akses, pengungkapan, penyalinan, penggunaan, atau modifikasi tanpa izin.',
        four: 'Kami tidak membagikan informasi identifikasi pribadi apa pun secara publik atau kepada pihak ketiga, kecuali jika diwajibkan oleh hukum.',
        five: 'Situs web kami mungkin terhubung ke situs eksternal yang tidak dioperasikan oleh kami. Harap ketahui bahwa kami tidak memiliki kontrol atas konten dan praktik situs ini dan tidak dapat menerima tanggung jawab atau kewajiban atas kebijakan privasi masing-masing.',
        six: 'Anda bebas menolak permintaan kami untuk informasi pribadi Anda, dengan pemahaman bahwa kami mungkin tidak dapat menyediakan beberapa layanan yang Anda inginkan.',
        seven: 'Penggunaan terus-menerus situs web kami akan dianggap sebagai penerimaan praktik kami seputar privasi dan informasi pribadi. Jika Anda memiliki pertanyaan tentang bagaimana kami menangani data pengguna dan informasi pribadi, jangan ragu untuk menghubungi kami.',
        eight: 'Kebijakan ini berlaku efektif mulai 15 September 2023.'
    },
    footer: {
        title: 'REOK Studio: Headshot AI untuk LinkedIn, Media Sosial, Resume, Aktor. Hasil dalam hitungan detik dari satu selfie.',
        terms: 'Syarat',
        privacy: 'Kebijakan Privasi',
        app: 'Aplikasi',
        signin: 'Masuk',
        signup: 'Daftar',
        email: 'Email',
        chatNow: 'Chat Sekarang',
        partner: 'Mitra',
        reportBug: 'Laporkan Bug',
        require: 'Kirim Permintaan',
        page: 'Halaman',
        tos: 'Hukum',
        contact: 'Hubungi',
        other: 'Lainnya',
        affiliate: 'Afiliasi',
        yearbook: 'AI Yearbook',
        blog: 'Blog',
        pricing: 'Harga',
        howItWorks: 'Cara Kerja',
        businesses: "Perusahaan",
        dating: "Kencan"
    },
    signin: {
        title: 'Masuk',
        another: 'Atau dengan Email',
        emailPlaceHolder: 'Alamat Email',
        passwordPlaceHolder: 'Kata Sandi',
        rememberTitle: 'Ingat untuk Kali Berikutnya',
        forgetPassword: 'Lupa Kata Sandi?',
        noAccount: 'Belum punya akun?'
    },
    signup: {
        title: 'Daftar',
        another: 'Atau dengan Email',
        firstNamePlaceHolder: 'Nama Depan',
        lastNamePlaceHolder: 'Nama Belakang',
        emailPlaceHolder: 'Alamat Email',
        passwordPlaceHolder: 'Kata Sandi',
        rememberTitle: 'Ingat untuk Kali Berikutnya',
        forgetPassword: 'Lupa Kata Sandi?',
        gotAccount: 'Sudah punya akun?'
    },
    forgetPassword: {
        title: 'Pemulihan Kata Sandi',
        content: 'Kami akan mengirim email pemulihan kata sandi kepada Anda',
        emailPlaceHolder: 'Silakan masukkan Email Anda',
        sendBtn: 'Kirim',
        backSignin: 'Kembali ke Masuk'
    },
    pricing: {
        yearlySubscription: '1000 desain',
        monthlySubscription: '100 desain',
        saveDesk: 'Simpan 10%',
        saveMobile: 'Simpan 10% saat membeli 1000 desain',
        popular: '82% memilih paket ini',
        free: {
            title: 'Kredit Gratis',
            price: '0$',
            trialTitle: 'Kode Pendaftaran: Silakan Masuk',
            joinTitle: 'Tempelkan kode Anda di sini',
            one: 'Dapatkan 20 Kredit Gratis',
            two: 'Termasuk Semua Fitur 100 Kredit',
            affiliateTitle: 'Program Afiliasi',
            shareTitle: 'Bagikan dengan teman-teman👇',
            loginToShare: 'Silakan masuk untuk berbagi'
        },
        basic: {
            title: '100 Kredit',
            price: '7$',
            duration: '/seumur hidup',
            recommend: 'Untuk Individu',
            one: '20 Foto Profesional',
            two: 'Hilangkan Latar Belakang',
            three: 'Upscale ke 4K',
            four: 'Unduh Semua Foto',
            five: 'Penyimpanan Cloud Tanpa Batas',
            six: 'Penggunaan Komersial Foto',
            seven: 'Akses ke Semua Fitur Baru',
            subscribed: 'Dibeli'
        },
        pro: {
            title: '1000 Kredit',
            subTitle: '(Simpan 30%)',
            price: '$49',
            duration: '/seumur hidup',
            recommend: 'Pengaruh, Bisnis, Studio Foto',
            one: '200 Foto Profesional',
            two: 'Termasuk Semua Fitur 100 Kredit',
            three: 'Menghemat Uang Anda',
            subscribed: 'Dibeli'
        },
        credit: {
            title: '1 Headshot = 5 Kredit. Pembayaran satu kali.'
        },
        first: {
            title: 'Pemula - 200 kredit',
            price: '$19',
            priceDetail: '$ 0.47 / foto profil',
            duration: '/seumur hidup',
            recommend: '1 Foto Profil = 5 Kredit',
            one: '40 Foto profil yang dihasilkan',
            two: 'Hapus latar belakang',
            three: 'Meningkatkan resolusi menjadi 4K',
            four: 'Unduh semua foto',
            five: 'Penyimpanan cloud tanpa batas',
            six: 'Penggunaan komersial foto',
            seven: 'Mengambil foto profil dalam hitungan detik',
            eight: 'Gaya & latar belakang apa pun',
            nine: 'Unggah pose Anda',
            ten: 'Tidak ada waktu pelatihan'
        },
        second: {
            title: 'Pro - 2500 kredit',
            subTitle: '(Simpan 60%)',
            price: '$99',
            priceDetail: '$ 0.19 / foto profil',
            duration: '/seumur hidup',
            recommend: 'Pengaruh, Bisnis, Studio Foto',
            five: 'Semua fitur dalam paket $19 dan hemat 60%',
            two: '500 Foto profil yang dihasilkan',
            three: 'Foto profil yang indah tanpa batasan',
            one: 'Beli sekali gunakan selamanya, bukan langganan',
            six: 'Tanpa harus ke Studio',
            four: 'Menghasilkan untuk orang lain hingga 500 orang',
            subscribed: 'Dibeli'
        },
        third: {
            title: 'Fotografer Pro',
            subTitle: '(Nilai Terbaik)',
            price: '$49',
            priceDetail: '$ 4.9 / foto profil',
            duration: '/seumur hidup',
            recommend: 'Pengaruh, Bisnis, Studio Foto',
            one: 'Minta gaya apa pun',
            two: '10 foto profil premium',
            three: 'Oleh fotografer profesional',
            four: 'Pengiriman foto profil dalam sehari',
            five: 'Foto profil berkualitas tinggi',
            subscribed: 'Dibeli'
        },
        four: {
            title: 'Solusi Perusahaan',
            subTitle: '(Hubungi Kami)',
            price: '$0',
            priceDetail: '$ 0 / foto profil',
            duration: '/seumur hidup',
            recommend: 'Pengaruh, Bisnis, Studio Foto',
            one: 'Foto profil berkualitas tinggi',
            two: 'Perusahaan atau tim jarak jauh',
            three: 'Menghemat waktu dan uang',
            four: 'Mengambil foto profil dalam hitungan detik',
            five: 'Tanpa pemotretan tradisional',
            subscribed: 'Dibeli',
        },
        currency: 'đ',
        perMonth: '/bulan',
        safePayment: 'Pembayaran Aman',
        qr: 'Atau Gunakan Kode QR'
    },
    milestoneMessage: {
        first: 'Pelanggan lebih bahagia dengan',
        second: 'desain yang dibuat'
    },   
    tos: {
        agree: 'Akun baru tunduk pada ',
        one: '1. Pendahuluan',
        oneDetail: 'Dengan menggunakan reok.pro, Anda menyetujui dan terikat oleh syarat dan ketentuan ini.',
        two: '2. Persetujuan terhadap Syarat dan Ketentuan',
        twoDetail: 'Perjanjian ini efektif sejak tanggal Anda pertama kali menggunakan aplikasi reok.pro.',
        three: '3. Fitur',
        threeDetail: 'Anda akan dapat menggunakan semua fitur setelah berlangganan rencana berbayar tertentu. Rincian fitur yang tersedia dapat ditemukan di halaman harga.',
        four: '4. Kebijakan Pengembalian Dana',
        fourDetail: 'Kami memungkinkan pengembalian dana untuk transaksi yang gagal dalam waktu 14 hari sejak pembelian. Hubungi dukungan untuk meminta pengembalian dana.',
        five: '5. Penggunaan Produk',
        fiveDetail: 'Dengan menggunakan reok.pro, Anda menyetujui untuk menerima pembaruan produk penting dari reok.pro melalui email yang terkait dengan akun Google Anda atau email yang Anda gunakan untuk mendaftar akun Anda. Anda dapat berhenti menerima pembaruan produk ini kapan saja dengan mengklik tautan "Berhenti berlangganan" di bagian bawah setiap email. Kami hanya mengirimkan pembaruan produk penting.',
        six: '6. Penafian',
        sixDetail: 'Kami tidak menjamin bahwa reok.pro akan memenuhi persyaratan Anda atau bahwa operasinya akan berjalan tanpa gangguan atau bebas dari kesalahan. Semua jaminan atau kondisi yang tidak secara tegas diatur dalam Perjanjian ini (termasuk, tanpa batasan, kerugian keuntungan, kerugian atau kerusakan data, gangguan bisnis, atau kerugian, kerusakan, biaya, atau pengeluaran pecuniary lainnya, kerugian, kerusakan, biaya, atau pengeluaran dari jenis apa pun juga) dikecualikan dan dikesampingkan, sejauh yang diizinkan oleh hukum. Perjanjian ini tidak memengaruhi hak-hak yuridis yang mungkin Anda miliki sebagai konsumen.',
        seven: '7. Garansi dan Pembatasan Hukum',
        sevenDetail: 'reok.pro tidak memberikan jaminan, garansi, atau representasi lain tentang kualitas, kecocokan untuk tujuan tertentu, atau karakter atau kinerja perangkat lunak. reok.pro tidak akan bertanggung jawab kepada Anda atas keuntungan yang hilang atau kerugian lainnya atau kerusakan tidak langsung, khusus, insidental, atau tidak langsung yang timbul dari atau terkait dengan Perjanjian ini atau penggunaan Anda atas reok.pro (bagaimanapun timbulnya, termasuk kelalaian) kecuali jika tanggung jawab tidak dapat dikecualikan berdasarkan hukum.',
        eight: '8. Ketentuan Umum dan Hukum',
        eightDetail: 'Anda mengakui bahwa tidak ada persekutuan bersama, kemitraan, pekerjaan, atau hubungan agen yang ada antara Anda dan reok.pro sebagai hasil dari menggunakan layanan ini. Anda setuju untuk tidak menganggap diri Anda sebagai perwakilan, agen, atau karyawan reok.pro. Anda setuju bahwa reok.pro tidak akan bertanggung jawab kepada Anda atas tindakan apa pun yang kami lakukan untuk mencegah atau mengatasi penyalahgunaan platform kami. Anda mengakui bahwa, dengan memberikan Anda penggunaan reok.pro, kami telah mengandalkan persetujuan Anda untuk terikat oleh syarat-syarat Perjanjian ini.'
    },
    refund: {
        moneyBack: 'Jaminan Uang Kembali',
        title: 'Kebijakan Pengembalian Dana',
        one: 'Tujuan kami adalah menyediakan layanan dan hasil berkualitas tinggi untuk semua klien kami. Kami memahami bahwa ada situasi di mana hasilnya tidak sesuai dengan harapan Anda',
        two: 'Kami menjamin kepuasan Anda. Jika Anda tidak puas dengan foto yang dihasilkan menggunakan Reok.pro, kami dengan senang hati akan memberikan pengembalian dana penuh kepada Anda. Sebagai alternatif, dalam beberapa kasus, kami mungkin menawarkan untuk mengambil ulang foto Anda untuk lebih memenuhi harapan Anda.',
        three: 'Kami akan meninjau dan menanggapi permintaan pengembalian dana, berdasarkan kebijakan ini, dalam waktu 1-3 hari kerja. Jika pengembalian Anda disetujui, dana tersebut harus kembali ke kartu Anda dalam waktu 5-10 hari kerja. Pengembalian dana akan diproses kembali ke metode pembayaran asli yang digunakan selama pembelian; kami tidak dapat mengembalikan dana ke akun yang berbeda.',
        four: 'Kami hanya dapat memproses permintaan pengembalian dana yang diajukan dalam waktu 30 hari setelah pembelian dan penggunaan DIBAWAH 20 kredit.',
        five: 'Untuk mengajukan pengembalian dana, silakan kirim email kepada kami di hello@reok.pro atau gunakan fitur obrolan yang tersedia di situs web kami. Saat menghubungi kami tentang pengembalian dana, harap sertakan alamat email yang Anda gunakan saat melakukan pemesanan.',
        six: 'Terima kasih telah memilih Reok.pro untuk foto profil Anda.',
    },    
    faq: {
        title: 'Pertanyaan yang Sering Diajukan',
        q1: 'Mengapa saya harus menggunakan REOK untuk mengambil foto profesional?',
        answer1: 'Untuk menghemat waktu dan uang Anda. Kami telah membuat studio online menggunakan teknologi AI yang kuat. Tidak perlu menunggu, berdandan, atau berpose untuk foto. Ini membantu Anda dengan cepat membuat foto yang indah, profesional, dan stylish untuk pekerjaan atau diposting di platform media sosial seperti Facebook, Instagram, Tiktok.',
        q2: 'Bagaimana cara kerja REOK?',
        answer2: 'Cukup ambil selfie atau pilih foto dari galeri Anda dengan jelas wajah Anda, lalu pilih jenis kelamin Anda dan jumlah foto yang ingin Anda buat. AI REOK akan memproses permintaan Anda dengan cepat dan akurat.',
        q3: 'Apakah informasi pribadi saya dan data desain aman?',
        answer3: 'Tentu saja, kami bekerja langsung dengan platform Google. Melindungi privasi Anda sangat penting bagi kami. REOK berkomitmen untuk mengambil tindakan yang diperlukan untuk melindungi informasi pribadi Anda dan data desain. Kami memiliki protokol keamanan yang kuat untuk memastikan bahwa data Anda tetap aman dan digunakan hanya untuk tujuan menyediakan layanan kami. Untuk informasi lebih lanjut, silakan lihat kebijakan privasi kami di situs web kami.',
        q4: 'Berapa lama desain saya akan disimpan?',
        answer4: 'Desain Anda akan disimpan secara permanen di cloud selama layanan kami masih beroperasi.',
        q5: 'Berapa biaya untuk mengambil foto profesional untuk pekerjaan atau bisnis?',
        answer5: 'Hanya 10 kredit untuk satu foto profesional. Ini 10 kali lebih murah daripada pergi ke studio untuk sesi foto.',
        q6: 'Berapa banyak kredit yang diperlukan untuk menghapus latar belakang?',
        answer6: 'Hanya 2 kredit untuk menghapus latar belakang sekali.',
        q7: 'Berapa banyak kredit yang diperlukan untuk menghilangkan kabur dari gambar?',
        answer7: 'Hanya 2 kredit untuk menghilangkan kabur dari gambar sekali.',
        q8: 'Apakah ada biaya untuk merancang rumah, interior, dan eksterior?',
        answer8: 'Ya. genhomeai.com adalah salah satu aplikasi kami dan Anda akan dikenai biaya untuk setiap sesi perancangan.',
        q9: 'Dapatkah saya mendapatkan pengembalian dana jika saya tidak puas?',
        answer9: 'Kami sangat menyesal mengatakan tidak. Untuk hal-hal berbiaya tinggi yang menggunakan teknologi AI, saat ini kami tidak mendukung pengembalian dana. Anda mungkin ingin mempertimbangkan pembelian kredit setelah menggunakan kredit gratis dari kami.',
    },
    message: {
        success: {
            redering: 'Foto profesional Anda sedang dirender, harap tunggu sebentar',
            rederingDone: 'AI telah selesai membuat desain',
            activated: 'Aktivasi berhasil! Nikmati membuat desain yang indah dan memuaskan!',
            removingBG: 'Menghapus latar belakang Anda',
            upscale: 'Meningkatkan resolusi',
            resetPassword: 'Kami baru saja mengirim email untuk mereset kata sandi Anda. Silakan periksa!',
            signIn: 'Kami baru saja mengirim email untuk mengaktifkan akun Anda. Silakan periksa!',
            reviewing: 'Kami telah menerima permintaan Anda. Itu akan direview dalam satu hari',
            remindReviewing: 'Foto ini sedang direview untuk klaim kredit baru',
            removed: 'Berhasil menghapus foto',
            useTheTemplate: "Gunakan template ini, silakan unggah selfie Anda"
        },
        error: {
            selectPhotographer: 'Harap pilih fotografer AI Anda',
            uploadPhoto: 'Harap unggah foto selfie Anda',
            chooseRoom: 'Harap pilih jenis kamar',
            chooseTheme: 'Harap pilih gaya kamar',
            fileOverBasic: 'Harap unggah file yang lebih kecil dari 5MB, atau upgrade ke Pro untuk mengunggah file yang lebih besar',
            fileOverPro: 'Harap unggah file yang lebih kecil dari 10MB',
            wrongFile: 'File tidak valid, harap coba file lain',
            wrongFace: 'Harap jangan mengambil selfie terlalu dekat, harap coba yang lain!',
            busy: 'Koneksi terputus, harap coba lagi',
            gender: 'Harap pilih jenis kelamin Anda',
            age: 'Harap pilih usia Anda',
            style: 'Harap pilih gaya Anda',
            numberOfResult: 'Harap pilih jumlah potret',
            outOfCredits: 'Anda kehabisan Kredit, harap beli lebih banyak kredit!',
            userNotFound: 'Akun ini tidak ditemukan. Harap masukkan akun Anda dengan benar!',
            wrongPassword: 'Kata sandi salah! Jika Anda tidak ingat kata sandi Anda, gunakan Lupa Kata Sandi.',
            accountExist: 'Akun sudah ada. Cobalah login dari akun ini.',
            tiktok: 'Untuk alasan keamanan pengguna Google, harap gunakan browser Chrome atau Safari. Kunjungi reok.pro untuk mengakses.',
            reviewing: 'Anda tidak dapat menghapus foto ini karena sedang direview',
            background: 'Harap pilih latar belakang Anda',
            pose: 'Harap pilih pose Anda',
            signUpReCaptcha: 'Harap lengkapi reCAPTCHA sebelum mendaftar.',
            emptyBG: 'Latar belakang Anda kosong, harap masukkan!',
            tooShortBG: 'Latar belakang Anda harus lebih panjang dari 2 kata.',
            tooLongBG: 'Latar belakang Anda terlalu panjang.',
            badWordsBG: 'Latar belakang Anda mengandung kata-kata yang tidak pantas. Harap coba yang lain',
            emptyStyle: 'Gaya Anda kosong, harap masukkan!',
            tooShortStyle: 'Gaya Anda harus lebih panjang dari 2 kata.',
            tooLongStyle: 'Gaya Anda terlalu panjang.',
            badWordsStyle: 'Gaya Anda mengandung kata-kata yang tidak pantas. Harap coba yang lain',
        },
        warning: {
            runOutBasicCredits: 'Anda telah menggunakan semua kredit dalam paket Basic, harap beli lebih banyak kredit',
            runOutProCredits: 'Harap beli lebih banyak kredit untuk digunakan ^^',
            selectPose: 'Harap pilih Jenis Kelamin Anda sebelum Pose'
        },
        prompt: {
            professional: 'Foto profesional',
            removeBG: 'Hapus latar belakang',
            upscale: 'Meningkatkan resolusi',
            rendering: 'Pengolahan AI',
            faceNotFound: 'Wajah Anda terlalu dekat, harap coba selfie lain!',
            inReview: 'Sedang direview',
            styles: {
                business: 'Foto bisnis profesional',
                realEstate: 'Foto agen real estate profesional',
                carShowroom: 'Foto profesional penjualan mobil',
                doctor: 'Foto dokter profesional',
                yearbook: 'Foto tahun buku 90-an profesional',
                wedding: 'Foto pernikahan profesional',
                halloween: 'Foto Halloween'
            },
        },
        maintain: {
            backsoon: 'Kami sedang memelihara fitur ini, dan akan segera kembali. Silakan gunakan fitur lain sementara waktu. Terima kasih! 💗💗💗'
        }
    },    
    app: {
        menu: {
            creditTitle: 'Kredit Anda',
            affiliateTitle: 'Program Afiliasi',
            affiliateSub: 'Dapatkan komisi 25% pada pembayaran untuk semua pelanggan yang Anda referensikan kepada kami!',
            professionalMode: 'Studio Saya',
            removeBGMode: 'Hapus BG',
            unBluryMode: 'Meningkatkan',
            myPhotos: 'Potret Saya',
        },
        tryThese: 'Tidak ada gambar? Coba ini:',
        goodSelfie: 'Selfie bagus',
        badSelfie: 'Selfie buruk',
        trick: 'Gunakan Meningkatkan untuk kualitas yang lebih baik',
        removeBGSub: 'Menghapus latar belakang menjadi cepat, sederhana, dan akurat dengan AI.',
        professionalMode: {
            title: 'Buat potret profesional Anda dengan AI',
            refundTitle: 'AI kami telah belajar untuk hasil yang bagus 💓',
            creditTitle: '1 foto = 10 kredit',
            genderTitle: 'Pilih jenis kelamin',
            ageTitle: 'Pilih usia Anda',
            styleTitle: 'Pilih/gaya kustom',
            quantityTitle: 'Jumlah hasil',
            poseTitle: 'Pilih/unggah pose',
            backgroundTitle: 'Pilih latar belakang',
            skipPose: 'Lewati untuk mempertahankan pose asli Anda',
            defaultPose: 'Default',
            uploadedPose: 'Diunggah',
            defaultBackground: 'Latar belakang',
            defaultStyle: 'Gaya',
            headshotsTab: 'Potret',
            selectPhotographer: 'Pilih fotografer AI Anda',
            selectPhotographerSub: 'Silakan pilih fotografer yang Anda sukai',
            requirements: 'Ikuti persyaratannya',
            guideSub: 'Silakan lakukan langkah demi langkah 👇'
        },
        upscaleMode: {
            title: 'Menghapus buram dengan cepat dan akurat dan meningkatkan foto Anda dengan AI.',
        }
    },
    myDesign: {
        title: 'Potret Saya',
        scrollTitle: 'Gulir untuk melihat semua desain Anda.',
    },
    imageComponent: {
        compare: 'Bandingkan',
        download: 'Unduh',
        removeBG: 'Hapus Latar Belakang',
        upscale: 'Meningkatkan',
        errorPhoto: 'Perbarui Foto Buruk'
    },
    results: {
        one: '1 potret',
        four: '4 potret',
    },
    genders: {
        man: 'Pria',
        woman: 'Wanita'
    },
    styles: {
        hoco_black: 'Hoco Hitam',
        date: 'Tanggal',
        hoco_pink: 'Hoco Pink',
        sweet: 'Manis',
        elite: 'Elite',
        luxury: 'Mewah',
        rich: 'Kaya',
        jean: 'Jeans',
        tank_and_jeans: 'Tank Top & Jeans',
        athleisure: 'Athleisure',
        bordo: 'Bordo',
        cinematic: 'Cinematik',
        disney: 'Disney',
        digital_art: 'Seni Digital',
        fantasy_art: 'Seni Fantasi',
        neonpunk: 'Neonpunk',
        comic_book: 'Komik',
        lowpoly: 'Lowpoly',
        line_art: 'Seni Garis',
        business: 'Bisnis',
        realEstate: 'Agen Real Estate',
        carShowroom: 'Penjualan Mobil',
        doctor: 'Dokter',
        yearbook: 'Tahun Buku 90-an',
        wedding: 'Pernikahan',
        halloween: "Halloween",
        gray_suit: "Setelan Abu-abu",
        white_suit: "Setelan Putih",
        black_suit: "Setelan Hitam",
        pink_suit: "Setelan Pink",
        blue_suit: "Setelan Biru",
        white_shirt: "Kemeja Putih",
        black_shirt: "Kemeja Hitam",
        gray_shirt: "Kemeja Abu-abu",
        blue_shirt: "Kemeja Biru",
        pink_shirt: "Kemeja Pink",
        dating: "Pertemuan",
        other: "Lainnya",
        custom: {
            title: 'Gaya & Pakaian',
            btn: 'Mulai kustom',
            dialogTitle: 'Gaya dan pakaian Anda',
            dialogSubTitle: 'Beritahu kami gaya dan pakaian Anda, fotografer AI kami akan mencoba membantu Anda tampil dengan itu!',
            upgrade: 'Tingkatkan fitur ini',
            example: 'setelan klasik tanpa dasi, tergantung pada apa yang Anda inginkan...',
        }
    },
    backgrounds: {
        coffeeShop: "Warung Kopi",
        office: "Kantor",
        cityStreet: "Jalan Kota",
        factory: "Pabrik",
        garden: "Taman",
        carShowroom: "Showroom Mobil",
        bookShelf: "Rak Buku",
        hotel: "Hotel",
        goldenGate: "Golden Gate",
        snow: "Salju",
        realtor: "Agen Real Estate",
        brick: "Bata",
        custom: {
            title: 'Latar Belakang',
            btn: 'Mulai kustom',
            dialogTitle: 'Latar belakang Anda',
            dialogSubTitle: 'Beritahu kami latar belakang Anda, fotografer AI kami akan mencoba membantu Anda tampil dengan itu!',
            upgrade: 'Tingkatkan fitur ini',
            example: 'warung kopi, tergantung di mana Anda inginkan...',
        },
    },
    removeTitle: 'Menghapus foto ini',
    removeSub: 'Apakah Anda yakin ingin menghapus foto ini? Foto ini akan dihapus secara permanen. Tindakan ini tidak dapat dibatalkan.',
    renewTitle: 'Perbarui foto ini',
    renewSub: 'Kami akan meninjau permintaan Anda. Jika foto ini bermasalah, itu akan dihapus secara permanen. Anda akan mendapatkan 10 kredit baru.',
    reasonTitle: 'Beritahu kami mengapa Anda ingin memperbarui ini?',
    reasonEx: 'Itu tidak formal...',
    photographers: {
        sam: {
            name: "Sam, 👋 Hi customer",
            title: "Saya ahli dalam Gaya",
            intro: "Apa yang bisa saya lakukan?",
            intro1: "Setiap Gaya dan Mood dalam hitungan detik",
            intro2: "Avatar (Linkedin, 🔥Tinder, Instagram, Facebook, X, Tiktok, Youtube,...)",
            intro3: "Foto bisnis",
            intro4: "Foto kencan",
            intro5: "Foto seni & lucu"
        },
        david: {
            name: "David, 👋 Hi customer",
            title: "Saya ahli dalam Pose",
            intro: "Apa yang bisa saya lakukan?",
            intro1: "Setiap Pose dan Gaya dalam hitungan detik",
            intro2: "Foto bisnis",
            intro3: "Foto kencan",
            intro4: "Foto pernikahan",
            intro5: "Foto tubuh penuh"
        }
    }
};

export default id;  