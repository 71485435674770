import React, { Component } from 'react';
import { compose } from 'redux';

import ForgetPassword from '../../components/ForgetPassword';
import Spinner from '../../components/Spinner';
import { Navigate } from 'react-router-dom';
import * as routes from '../../constants/routes';
import withBackend from '../../backend/withBackend';
import { message } from 'antd';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const LOCATION = process.env.REACT_APP_LOCATION;

class ForgetPasswordContainer extends Component {
  state = {
    email: '',
    error: null,
    sent: 0,
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleResetPassword = (e, t) => {
    e.preventDefault();
    this.setState({ loading: true });

    const { engine } = this.props;
    const { email } = this.state;

    engine
      .resetPassword(email)
      .then(() => {
        this.setState({ loading: false, sent: 1 });
        message.success(t('message.success.resetPassword'));
        // if ((LOCATION === "en")) {
        //   message.success("We just sent an email to reset your password. Please check it!");
        // } else {
        //   message.success("Chúng tôi đã gửi 1 email để cập nhật lại mật khẩu của bạn, vui lòng kiểm tra email!");
        // }
      })
      .catch(error => {
        
        if (error.code === "auth/user-not-found") {
          error.message = t('message.error.userNotFound');
          // error.message = "This account is not found. Please input correctly your account!"
        }

        this.setState({ error, loading: false});
      });
  };

  render() {
    const { email, error, loading, sent } = this.state;

    return (
      <>
        {sent ? <Navigate to={routes.SIGN_IN} replace={true} /> : null}
        <ForgetPassword 
          email={email}
          error={error}
          handleResetPassword={this.handleResetPassword}
          handleChange={this.handleChange}
        />
        {loading && <Spinner />}
      </>
    );
  }
}

export default compose(
  withBackend
)(ForgetPasswordContainer);
