import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUser } from '../../actions';

import SignIn from '../../components/SignIn';
import Spinner from '../../components/Spinner';
import withBackend from '../../backend/withBackend';
import { message } from 'antd';
import {v4} from 'uuid';

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const LOCATION = process.env.REACT_APP_LOCATION;

const RANDOM_CODE = Math.floor(Math.random() * 900000) + 100000;
const RANDOM_TRIAL_CODE = Math.floor(Math.random() * 900000) + 100000;

let initialUserData = {
  uid: null,
  role:"USER",
  email:"",
  joinedDate: new Date().getTime(),
  lastLoginDate:null,
  firstName:"",
  lastName:"",
  phone: null,
  phoneCode:84,
  phoneShort:"VN",
  profilePicUrl:null,
  companyName: null,
  country:null,
  birthDate:null,
  gender:null,
  address:null,
  city:null,
  status:"incomplete",
  isActivated: true,
  hasSubscription:false,
  emailVerified: false,
  proTrial: process.env.REACT_APP_PRO_TRIAL,
  basicTrial: process.env.REACT_APP_BASIC_TRIAL,
  upscaleTrial: process.env.REACT_APP_UPSCALE_TRIAL,
  pro: false,
  basic: true,
  trial: true,
  monthlyBasicCode: `REOK ${RANDOM_CODE}`,
  yearlyBasicCode: `YB ${RANDOM_CODE}`,
  monthlyProCode: `MP ${RANDOM_CODE}`,
  yearlyProCode: `YP ${RANDOM_CODE}`,
  trialCode: `REOK ${RANDOM_TRIAL_CODE}`,
  proCredits: 0,
  basicCredits: 30,
  refCode: ''
}

class SignInContainer extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    error: null,
    refCode: '',
    redirect: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e, t) => {
    e.preventDefault();

    const { engine, setUser } = this.props;
    const { email, password, redirect } = this.state;

    if (redirect === "tiktok") {
      alert(t('message.error.tiktok'));
      return;
    }

    this.setState({ loading: true });

    engine
      .signIn(email, password)
      .then(response => {
        const emailVerified = response.user.emailVerified;
        const uid = response.user.uid;
        if (!emailVerified) {
          engine.sendEmailVerificationLink();

          message.success(t('message.success.signIn'));

          // if ((LOCATION === "en")) {
          //   message.success("We just sent an email to activate your account. Please check it!");
          // } else {
          //   message.success("Chúng tôi đã gửi 1 email để kích hoạt tài khoản của bạn, vui lòng kiểm tra email!");
          // }
        } else {
          engine.updateProfile(uid, {emailVerified: true})
          .then((result) => {
          })
          .catch(error => console.log(error));

          engine.getUser(uid)
          .then(querySnapshot => setUser(querySnapshot.data()));
        }
      })
      .then(() => {this.setState({ loading: false })})
      .catch(error => {
        console.log(error.code);
        if (error.code === "auth/user-not-found") {
          error.message = t('message.error.userNotFound');
          // error.message = "This account is not found. Please input correctly your account!"
        }

        if (error.code === "auth/wrong-password") {
          error.message = t('message.error.wrongPassword');
          // error.message = "Wrong password! If you don't remember your password please use Forget Password."
          this.setState({ error })
        }
        this.setState({ error, loading: false })
      });
  };

  handleSubmitWithGoogle = (e, t) => {
    e.preventDefault();

    const { redirect } = this.state;

    if (redirect === "tiktok") {
      alert(t('message.error.tiktok'));
      return;
    }

    // Perform reCAPTCHA verification here
    // const recaptchaResponse = window.grecaptcha.getResponse();

    // If recaptchaResponse is empty, the user did not complete the reCAPTCHA challenge
    // if (!recaptchaResponse) {
    //     alert(t('message.error.signInReCaptcha'));
    //     return;
    // }

    const { engine, setUser } = this.props;

    engine
      .signInWithGoogle()
      .then(response => {
        engine.getUser(response.user.uid)
        .then (querySnapshot => {
          if (querySnapshot.data()) {
            engine.getUser(response.user.uid)
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });
          } else {
            const { refCode } = this.state;

            initialUserData.uid = response.user.uid;
            initialUserData.email = response.user.email;
            initialUserData.firstName = response.user.displayName;
            initialUserData.emailVerified = true;
            initialUserData.refCode = refCode;
            initialUserData.proCredits = 0;
            initialUserData.pro = false;
            initialUserData.trial = true;

            engine.addUser(response.user.uid, initialUserData)
            .then(() => engine.getUser(engine.auth.currentUser.uid))
            .then(querySnapshot => setUser(querySnapshot.data()))
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                // error.message = ERROR_MSG_ACCOUNT_EXISTS;
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });

            const randomUUID = v4();
            const refLink = `https://reok.pro/vi/?ref=${randomUUID}`;
            const data = { uid: response.user.uid, email: response.user.email, code: randomUUID, refLink: refLink, isActive: true, createdAt: new Date().toISOString()}
            
            engine.addAffMembers(data)
            .then(querySnapshot => {})
            .catch(error => {
              if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                // error.message = ERROR_MSG_ACCOUNT_EXISTS;
                error.message = t('message.error.accountExist');
              }
              this.setState({ error });
            });
          }
        })
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          // error.message = ERROR_MSG_ACCOUNT_EXISTS;
          error.message = t('message.error.accountExist');
        }

        if (error.code === "auth/popup-closed-by-user") {
          error.message = "Popup closed";
        }

        if (error.code === "auth/cancelled-popup-request") {
          error.message = "Cancelled popup request";
        }
        
        this.setState({ error });
      });
  };

  componentDidMount() {
    // Retrieve the stored referralCode from local storage
    const storedReferralCode = localStorage.getItem('refCode');

    if (storedReferralCode) {
      this.setState({ refCode: storedReferralCode });
      // You can perform further actions with the referral code here
    }

    const redirect = localStorage.getItem('redirect');

    if (redirect) {
      this.setState({ redirect: redirect });
      // You can perform further actions with the referral code here
    }

    // Load reCAPTCHA script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  render() {
    const { email, password, error, loading } = this.state;

    return (
      <>
        <SignIn
          email={email}
          password={password}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleSubmitWithGoogle= {this.handleSubmitWithGoogle}
          error={error}
        />
        {loading && <Spinner />}
      </>
    );
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(SignInContainer);
