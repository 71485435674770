const LANDING = '/';
const SIGN_UP = '/signup';
const SIGN_IN = '/signin';
const HOME = '/app';
const FORGET_PASSWORD = '/forget-password';
const SIGN_OUT = 'signout';
const CAMPAIGN_DETAIL = '/campaign';
const MY_DASHBOARD = '/my-dashboard';
const MY_FLOWERS = 'my-designs';
const CREATORS = 'creators';
const LIVE_STREAMERS = 'live-streamers';
const TERMS_CONDITIONS = 'tos';
const PRIVACY = 'privacy';
const REFUND = 'refund';
const APP = '/app';
const AGENCIES= '/agencies'
const AFFILIATE = '/affiliate'
const X = '/x'
const LINKEDIN = '/linkedin'
const YEARBOOK = '/how-to-get-ai-yearbook-for-free'
const AIHEADSHOT = '/free-trial'
const BUSINESSES_HEADSHOT = '/businesses-headshots'
const DATING_PHOTOS= '/dating-photos'
const STUDENT_HEADSHOTS = '/student-headshots'
const TEACHER_HEADSHOTS = '/teacher-headshots'
const DOCTOR_HEADSHOTS = '/doctor-headshots'
const LAWYER_HEADSHOTS = '/lawyer-headshots'
const REAL_ESTATE_HEADSHOTS = '/real-estate-headshots'
const SALESPERSON_HEADSHOTS = '/salesperson-headshots'

export { 
    SALESPERSON_HEADSHOTS,
    REAL_ESTATE_HEADSHOTS,
    LAWYER_HEADSHOTS,
    DOCTOR_HEADSHOTS,
    BUSINESSES_HEADSHOT, 
    DATING_PHOTOS, 
    STUDENT_HEADSHOTS, 
    TEACHER_HEADSHOTS, 
    LANDING, 
    TERMS_CONDITIONS, 
    PRIVACY, 
    SIGN_UP, 
    SIGN_IN, 
    APP, 
    HOME, 
    FORGET_PASSWORD, 
    SIGN_OUT, 
    CAMPAIGN_DETAIL, 
    MY_DASHBOARD, 
    MY_FLOWERS, 
    CREATORS, 
    LIVE_STREAMERS, 
    AGENCIES, 
    AFFILIATE, 
    YEARBOOK, 
    X, 
    LINKEDIN,
    AIHEADSHOT, 
    REFUND 
};
