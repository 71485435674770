import React, { useRef } from 'react';
import i18n from '../../i18n/index';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const faqToggles = useRef([]);

  const {t} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const handleToggleClick = (index) => {
    faqToggles.current[index].parentNode.classList.toggle('active');
  };

  return (
    <div className="faq-wrapper">
      <h2 className="mx-auto relative z-[10] t-50 text-white mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
        {t('faq.title')}
      </h2>
      <div className="faqs-container">
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q1')}
            </h3>
            <p className="faq-text">
            {t('faq.answer1')}
            </p>
            <div className="faq-toggle" onClick={() => handleToggleClick(0)} ref={(element) => (faqToggles.current[0] = element)}>
              <span className="fas fa-chevron-down">
                <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </span>
              <span className="fas fa-times">
              <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
                <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
              </svg>
            </span>
            </div>
          </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q2')}
          </h3>
          <p className="faq-text">
            {t('faq.answer2')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(1)} ref={(element) => (faqToggles.current[1] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q3')}
          </h3>
          <p className="faq-text">
            {t('faq.answer3')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(2)} ref={(element) => (faqToggles.current[2] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q4')}
          </h3>
          <p className="faq-text">
            {t('faq.answer4')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(3)} ref={(element) => (faqToggles.current[3] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q5')}
          </h3>
          <p className="faq-text">
            {t('faq.answer5')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(4)} ref={(element) => (faqToggles.current[4] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q6')}
          </h3>
          <p className="faq-text">
            {t('faq.answer6')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(5)} ref={(element) => (faqToggles.current[5] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q7')}
          </h3>
          <p className="faq-text">
            {t('faq.answer7')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(6)} ref={(element) => (faqToggles.current[6] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q8')}
          </h3>
          <p className="faq-text">
            {t('faq.answer8')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(7)} ref={(element) => (faqToggles.current[7] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
        <div className="faq">
          <h3 className="faq-title">
            {t('faq.q9')}
          </h3>
          <p className="faq-text">
            {t('faq.answer9')}
          </p>
          <div className="faq-toggle" onClick={() => handleToggleClick(8)} ref={(element) => (faqToggles.current[8] = element)}>
            <span className="fas fa-chevron-down">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 20 20" fill="currentColor"  className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </span>
            <span className="fas fa-times">
            <svg fill="currentColor" width="25" viewBox="-6 -6 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-close">
              <path d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"/>
            </svg>
          </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
