import React, { useEffect, useState } from 'react';
import Pricing from '../Pricing';
import { Gradient } from "../AdminHeader/Gradient.js";
import AllShowCase from '../AllShowCase';
import {
  HomeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import YourShowCase from '../YourShowCase';
import Spinner from '../Spinner';

const gradient = new Gradient();

const Profile = ({ firebase, setUser }) => {

  const [jobsList, setJobsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastJobCreatedAt, setLastJobCreatedAt] = useState("");
  const [userData, setUserData] = useState('');
  const [isShowPricing, setIsShowPricing] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setIsSpinning(true);
    gradient.initGradient("#canvas-gradient");

    const unsubscribe = firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        // User is authenticated, fetch the user data
        firebase
          .getUser(user.uid)
          .then((querySnapshot) => {
            setUserData(querySnapshot.data());

            firebase.getFirstJobs(user.uid)
              .then((result) => {
                const jobsArray = result.docs
                  .filter((doc) => doc.data().mode !== "BASIC")
                  .map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                  }));

                if (jobsArray.length < 1) {
                  setJobsList(sampleLivingrooms);
                } else {
                  setJobsList(jobsArray);
                  setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
                }

              })
              .catch((error) => {
                console.log(error);
              });
            setTimeout(() => {
              setIsSpinning(false);
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      else {
        // User is not authenticated, clear the user data
        setUser(null);
        setTimeout(() => {
          setIsSpinning(false);
        }, 1000);
      }
    });

    return () => {
    };
  }, []);

  useEffect(() => {

    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        loadMorePhotos(isLoading, jobsList, lastJobCreatedAt);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, lastJobCreatedAt, jobsList]);

  const loadMorePhotos = async (loading, jobs, lastCreatedAt) => {
    if (!loading && lastCreatedAt !== "") {
      setIsLoading(true);
      firebase.getJobsPagination(firebase.auth.currentUser.uid, lastCreatedAt)
        .then((result) => {

          const jobsArray = result.docs
            .filter((doc) => doc.data().mode !== "BASIC")
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

          if (jobsArray.length > 0) {
            setTimeout(() => {
              const mergedJobsList = [...jobs, ...jobsArray];
              setLastJobCreatedAt(jobsArray[jobsArray.length - 1].createdAt);
              setJobsList(mergedJobsList);
              setIsLoading(false);
            }, 500);
          } else {
            setLastJobCreatedAt("");
            setIsLoading(true);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }

  };

  function onSetJob(job) {
    setJobsList([]);
    const mergedJobsList = [...[job], ...jobsList];
    setJobsList(mergedJobsList);
  }

  function onSetJobsList(jobs) {
    setLastJobCreatedAt(jobs[jobs.length - 1].createdAt);
    setJobsList([]);
    setJobsList(jobs);
  }

  function onShowPricing(value) {
    setIsShowPricing(value);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <main>
        <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-5 mb-sm-4">{t('profile.title')}</h1>
              <Link
                to="/app"
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />{t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      <div className="page-header mb-0 page-header-reset mb-4" />
      <YourShowCase title={t('showcase.title')} t={t} jobsList={jobsList} userData={userData} setUser={setUser} firebase={firebase} onShowPricing={onShowPricing} onSetJobsList={onSetJobsList} onSetJob={onSetJob} />
      {isShowPricing && <Pricing t={t} userData={userData} firebase={firebase} onShowPricing={onShowPricing} onFetchUser={onFetchUser} />}
      {isSpinning && <Spinner />}
    </>
  );
};

const sampleLivingrooms = [
  {
    jobId: "",
    status: "finished",
    prompt: "Thiết kế của Phòng khách Hiện đại",
    uid: "admin",
    upscaleUrl: "https://cdn.discordapp.com/attachments/1105800665105711166/1119892426501394513/after-livingroom.jpeg",
    originalUrl: "https://cdn.discordapp.com/attachments/1105800665105711166/1119891007354445824/before-livingroom.jpeg",
    mode: "PRO"
  },
  {
    jobId: "",
    status: "finished",
    prompt: "Thiết kế của Phòng ngủ Tối giản",
    uid: "admin",
    upscaleUrl: "https://cdn.discordapp.com/attachments/1105800665105711166/1119465508727693332/after-bedroom.jpeg",
    originalUrl: "https://cdn.discordapp.com/attachments/1105800665105711166/1119465503489011803/before-bedroom.png",
    mode: "PRO"
  }
]
export default Profile;
