import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import LoaderLineMask from '../LoaderLineMask';
import CompareImage from '../CompareImage';
import ShopFurniture from '../ShopFurniture';
import axios from 'axios';
import Searching from '../Loading';
import EditImage from '../EditImage';
import ImageResult from '../ImageResult';
import AgencyForm from '../AgencyForm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CollectionCard from '../CollectionCard';
import NewSkeleton from '../NewSkeleton';

const APP_URL = process.env.REACT_APP_APP_URL;

const photographerStyle = "photographer_style";
const photographerPose = "photographer_pose";

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const StyledTabs = styled(({ darkMode, ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ darkMode }) => ({
    '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '80%',
    backgroundColor: darkMode ? 'white' : 'black',
  },
}));

const StyledTab = styled(({ darkMode, ...props }) => (
  <Tab disableRipple {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'inherit',
  letterSpacing: "-0.01em",
  backgroundColor: 'transparent',
  color: '#5d5d5d', // Adjusted color property

  '&.Mui-selected': {
    color: darkMode ? 'white' : 'black', // Adjusted color property
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "-0.01em"
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const AllShowCase = ({ userData, currentJob, t, firebase, onGenFill, onFetchUser, checkCredits, onUpImageResolution, onRemoveBG, progress, handleSelectCollection, darkMode}) => {
  const [jobsList, setJobsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [imageSrcCompare, setImageSrcCompare] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCompareImage, setShowCompareImage] = useState(false);
  const [showShopFurniture, setShowShopFurniture] = useState(false);
  const [imageShop, setImageShop] = useState(null);
  const [imageSrcEdit, setImageSrcEdit] = useState();
  const [showEditImage, setShowEditImage] = useState(false);
  const [searching, setSearching] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isFaceNotFound, setIsFaceNotFound] = useState(false);
  const [value, setValue] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const collectionInfo = [
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F13.webp?alt=media&token=0a46442d-790b-4622-aedf-75cc762c7979",
      photographerUrl: "/images/sam.webp",
      name: "Luxurious suit",
      style: "Wearing a man luxurious suit",
      background: "in luxurious backgrounds",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F14.webp?alt=media&token=dd770fb8-1734-4eaf-bbb9-987bbf62412a",
      photographerUrl: "/images/sam.webp",
      name: "Luxurious suit",
      style: "Wearing a woman luxurious suit",
      background: "in luxurious backgrounds",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F15.webp?alt=media&token=cd56bc86-b72b-4033-8df0-69faa2cf5af2",
      photographerUrl: "/images/sam.webp",
      name: "The Northern Lights",
      style: "Smiling in Reykjavik",
      background: "with the Northern Lights in the background",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F16.webp?alt=media&token=44cc3799-e0d9-42bc-b629-8ec88271ffba",
      photographerUrl: "/images/sam.webp",
      name: "The Northern Lights",
      style: "Smiling in Reykjavik",
      background: "with the Northern Lights in the background",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F10.webp?alt=media&token=6c099d6e-7f31-4826-a36d-a79f10b70c5b",
      photographerUrl: "/images/sam.webp",
      name: "Holographic outfit",
      style: "Wearing holographic outfit and digital shorts ",
      background: "in a neon-lit Blade runner inspired cityscape",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F9.webp?alt=media&token=e1fddc9a-e7bf-45c2-bc57-50d85cd70b9e",
      photographerUrl: "/images/sam.webp",
      name: "Holographic crop top",
      style: "Wearing holographic crop top and digital shorts ",
      background: "in a neon-lit Blade runner inspired cityscape",
      gender: "woman"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F1.webp?alt=media&token=75534d6b-8876-4135-b46c-beb8eedaacae",
      photographerUrl: "/images/david.webp",
      name: " Y2K aesthetic",
      style: "Wearing Y2K aesthetic, 2000s fashion, aughts style, noughties style, grunge or 2000s style, oversized washed out style, instagram photo",
      background: "Y2K Aesthetic background",
      gender: "man"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F2.webp?alt=media&token=303c67a6-1c2a-424f-aec5-48fe707beefa",
      photographerUrl: "/images/david.webp",
      name: " Y2K aesthetic",
      style: "Wearing Y2K aesthetic, 2000s fashion, aughts style, noughties style, grunge or 2000s style, oversized washed out style, instagram photo",
      background: "Y2K Aesthetic background",
      gender: "woman"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F3.webp?alt=media&token=6edca17c-1600-42e4-bbd1-8aab2ef04d1d",
      photographerUrl: "/images/david.webp",
      name: "Luxurious wedding suit",
      style: "Wearing a man luxurious wedding suit",
      background: "in a wedding studio",
      gender: "man"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F4.webp?alt=media&token=ae36712e-d1e6-4da2-b832-dba74a56ea38",
      photographerUrl: "/images/david.webp",
      style: "Wearing a woman luxurious wedding dress",
      background: "in a wedding studio",
      gender: "woman"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F25.webp?alt=media&token=36da38af-d7fc-479d-849e-fed784f562a8",
      photographerUrl: "/images/david.webp",
      name: "Cyberpunk suit",
      style: "Wearing a Cyberpunk suit",
      background: "Cyberpunk background",
      gender: "man"
    },
    {
      photographerCode: photographerPose,
      photographer: "David",
      photographerName: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F26.webp?alt=media&token=44a8d42f-a38a-4016-b895-5a7cde810982",
      photographerUrl: "/images/david.webp",
      name: "Cyberpunk outfit",
      style: "Wearing a Cyberpunk outfit",
      background: "Cyberpunk background",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F7.webp?alt=media&token=b60fcaf8-a13b-4eac-bb9f-446e53c21b86",
      photographerUrl: "/images/sam.webp",
      name: "Colorful murals",
      style: "Wearing a trendy outfit, walking down a street lined",
      background: "With colorful murals",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F8.webp?alt=media&token=c1ea2c32-186e-4211-afaf-e83a305e6c0b",
      photographerUrl: "/images/sam.webp",
      name: "Colorful murals",
      style: "Wearing a trendy outfit, walking down a street lined",
      background: "With colorful murals",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F11.webp?alt=media&token=c415c602-9f31-4f02-b575-5def46e651e2",
      photographerUrl: "/images/sam.webp",
      name: "Conference keynote speaker",
      style: "Wearing outfit for a conference keynote speaker",
      background: "Standing on stage at a conference",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F12.webp?alt=media&token=584423de-1d61-473d-a588-a75171e6e203",
      photographerUrl: "/images/sam.webp",
      name: "Conference keynote speaker",
      style: "Wearing outfit for a conference keynote speaker",
      background: "Standing on stage at a conference",
      gender: "woman"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F6.webp?alt=media&token=ec28909a-a3ba-4cfc-ba4d-e104e1bced31",
      photographerUrl: "/images/sam.webp",
      name: "Black bodycon clothes",
      style: "Wearing black man bodycon clothes, instagram photo, instagram, hourglass figure",
      background: "With luxury villa living room",
      gender: "man"
    },
    {
      photographerCode: photographerStyle,
      photographer: "Sam",
      photographerName: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/collections%2F5.webp?alt=media&token=f127b9ef-715d-43f8-ac1f-754d8a8f19fb",
      photographerUrl: "/images/sam.webp",
      name: "Black bodycon dress",
      style: "Wearing black bodycon dress, instagram photo, instagram, hourglass figure",
      background: "With luxury villa living room",
      gender: "woman"
    },
  ]

  useEffect(() => {
    // Check job status then add to the list
    if (currentJob !== undefined && currentJob !== null) {
      if (currentJob.status === "starting") {
        setIsCreating(true);
        setIsFaceNotFound(false);
        setValue(1);

        // try {
        //   var reader = new FileReader();
        //   reader.onload = function(event) {
        //     var dataURL = event.target.result;
        //     document.getElementById('img_src_loader').src = dataURL;
        //   };
        //   reader.readAsDataURL(currentJob.images.before);
        // } catch (error) {
        //   setIsCreating(false);
        //   console.log(error);
        // }
      }
      else if (currentJob.status === "finished") {
        const mergedJobsList = [...[currentJob], ...jobsList];
        setValue(1);
        setIsCreating(false);
        setJobsList(mergedJobsList);
      }
      else if (currentJob.status === "failed-face") {
        setValue(1);
        setIsFaceNotFound(true);
      }
    }
  }, [currentJob]);

  // useEffect(() => {
  //   setCurrentProgress(progress);
  // }, [progress]);

  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `reokpro-${randomNumber}.${extension}`;
  }

  const onCompareImage = (images) => {
    setImageSrcCompare(images)
    setShowCompareImage(true);
  }

  const searchProduct = (imageUrl) => {
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const requestDataEng = {
      imageUrl: imageUrl,
      packageName: modes.pro
    };

    axios.post(`${APP_URL}/search-product`, requestDataEng,{ headers })
    .then(response => {
      // Handle the response data
      setProductsList(response.data);
      setSearching(false);
      setShowShopFurniture(true);
      onFetchUser();
    })
    .catch(error => {
      message.error(t('message.error.busy'));
    });
  }

  const onFindAgency = (image) => {
    setOpenForm(true);
    setImageShop(image);
    setShowShopFurniture(false);
  }

  const onEditImage = (imageUrl) => {

    if (checkCredits()) {
      setShowEditImage(false);
      return;
    }

    setImageSrcEdit(imageUrl);
    setShowEditImage(true);
  }

  const onCloseImageCompare = () => {
    setShowCompareImage(false);
  }

  const onCloseShopFurniture = () => {
    setShowShopFurniture(false);
  }

  const onCloseImageEdit = () => {
    setShowEditImage(false);
  }

  const onDownloadImage = (image) => {
    setIsDownloading(true);

    try {
      setTimeout(async () => {
      const response = await fetch(image);
      const blob = await response.blob();
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const fileName = generateRandomFileName('jpeg');
      link.download = fileName;
      link.click();
  
      // Clean up the temporary URL
      URL.revokeObjectURL(url);
      setIsDownloading(false);

      }, 2000);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  const onChangeImageSrc = (image) => {
    setImageSrcEdit(image);
  }

  const onCloseAgencyForm = () => {
    setOpenForm(false);
  }

  const onRemoveFromList = (id) => {
    const updatedJobList = jobsList.filter(job => job.id !== id);
    setJobsList(updatedJobList);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function onSelectCollection(e, collection) {
    e.preventDefault();
    handleSelectCollection(collection);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div 
      className={`results-area ${darkMode? 'dark-bg' : ''}`}
    >
      <Box sx={{ borderBottom: 1, borderColor: darkMode ? '#5d5d5d' : '#eee' }}>
        <StyledTabs
          darkMode={darkMode}
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          scrollButtons={false}
          >
          <StyledTab darkMode={darkMode} label={"🔥 " + t('home.hotColletion')} />
          <StyledTab darkMode={darkMode} label={"📸 " + t('home.title')} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="rnftg-h-full rnftg-w-full">
          <div className="rnftg-h-full dark:rnftg-bg-gray-900">
            <div className="rnftg-flex rnftg-flex-col pd-2">
              <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-3 xl:rnftg-grid-cols-3">
                {collectionInfo.map((c, i) => {
                  return (
                    <CollectionCard darkMode={darkMode} key={i} t={t} info={c} onSelectCollection={onSelectCollection}/>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <div className="rnftg-h-full rnftg-w-full" id="my-result">
            <div className="rnftg-h-full dark:rnftg-bg-gray-900">
              <div className="rnftg-flex rnftg-flex-col pd-2">
                <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-1 md:rnftg-grid-cols-2 lg:rnftg-grid-cols-2 xl:rnftg-grid-cols-2">
                  {isCreating &&
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper">
                        {!isFaceNotFound ?
                          (
                          <div className="result">
                            <LoaderLineMask status={progress}/>
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              {/* <img
                                className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl rendering-mask"
                                id="img_src_loader"
                                loading="lazy"
                                src={currentJob.images.before}
                              /> */}
                              {!imageLoaded && (
                                <NewSkeleton/>
                                )
                              }
                              <img
                                className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl ${imageLoaded ? 'rendering-mask' : ''}`}
                                alt="reok"
                                src={currentJob.images.before}
                                onLoad={handleImageLoad}
                              ></img>
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.rendering') + " - " + currentJob.result + " - " + currentJob.prompt}</div>
                            </span>
                          </div>
                          ) : (
                          <div className="result">
                            <div className="line-mask">
                              <div className="circular-progress">
                                <svg width="20px" height="20px" viewBox="0 0 0.5 0.5" xmlns="http://www.w3.org/2000/svg">
                                  <path fill="#d32f2f" d="M0.49 0.397 0.3 0.048C0.288 0.025 0.269 0.013 0.25 0.013c-0.02 0 -0.038 0.013 -0.05 0.036L0.01 0.397c-0.012 0.023 -0.014 0.046 -0.003 0.063 0.01 0.018 0.03 0.028 0.055 0.028h0.376c0.025 0 0.045 -0.01 0.056 -0.028 0.01 -0.018 0.009 -0.04 -0.004 -0.063ZM0.25 0.121c0.01 0 0.018 0.008 0.018 0.018v0.165c0 0.01 -0.008 0.018 -0.018 0.018a0.018 0.018 0 0 1 -0.018 -0.018V0.14c0 -0.01 0.008 -0.018 0.018 -0.018Zm0 0.291c-0.015 0 -0.028 -0.013 -0.028 -0.028 0 -0.016 0.013 -0.029 0.028 -0.029 0.015 0 0.028 0.013 0.028 0.028 0 0.016 -0.013 0.029 -0.028 0.029Z"/>
                                </svg>
                              </div>
                            </div>
                            <span className="rnftg-no-underline rnftg-text-black dark:rnftg-text-gray-200">
                              <img
                                className="rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl rendering-mask"
                                id="img_src_loader"
                                loading="lazy"
                                src={currentJob.images.before}
                              />
                              <div className="img_src_loader"></div>
                              <div className="prompt-label">{t('message.prompt.faceNotFound')}</div>
                            </span>
                          </div>
                          )
                        }
                      </div>
                    </article>
                  }
                  {jobsList.length > 0 &&
                    jobsList.map((job, key) => {
                      job.originalUrl = job.images.before;
                      job.upscaleUrl = job.images.after;

                      return (
                      <ImageResult t={t} firebase={firebase} userData={userData} key={key} job={job} onCompareImage={onCompareImage} onDownloadImage={onDownloadImage} onFindAgency={onFindAgency} onEditImage={onEditImage} onRemoveBG={onRemoveBG} isDownloading={isDownloading} onUpImageResolution={onUpImageResolution} onRemoveFromList={onRemoveFromList} hideRenew={true}/>
                    )}
                  )}
                </div>
              </div>
            </div>
          </div>
      </CustomTabPanel>
      {/* <section className="selection-thanks-customer">
        <span className="container display-4 fw-bold-600">{t('milestoneMessage.first')}<span className="particletext hearts"> 10.000 </span>{t('milestoneMessage.second')}</span>
      </section> */}
      { showCompareImage && <CompareImage images={imageSrcCompare} onClose={onCloseImageCompare}></CompareImage>}
      { showShopFurniture && <ShopFurniture checkCredits={checkCredits} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseShopFurniture}></ShopFurniture>}
      { showEditImage && <EditImage checkCredits={checkCredits} imageUrl={imageSrcEdit} onClose={onCloseImageEdit} firebase={firebase} t={t} onGenFill={onGenFill} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}></EditImage>}
      { searching && <Searching title="Searching products"/> }
      { openForm && <AgencyForm userData={userData} productsList={productsList} firebase={firebase} t={t} image={imageShop} onClose={onCloseAgencyForm}></AgencyForm>}
    </div>
  );
};

export default AllShowCase;
