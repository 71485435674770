import React, { Component } from 'react';
import { connect } from 'react-redux';
import withBackend from '../../backend/withBackend';
import { compose } from 'redux';
import { setUser } from '../../actions';
import AdsHeader from '../../components/AdsHeader';

class AdsHeaderContainer extends Component {
  render() {
    const { userData, setUser, firebase } = this.props;
    return <AdsHeader userData={userData} firebase={firebase} setUser={setUser} />;
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(AdsHeaderContainer);