import React, { useState } from 'react';
import {
  Button
} from '@mui/material';
import NewSkeleton from '../NewSkeleton';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

function CollectionCard({info, t, onSelectCollection, darkMode}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="pose-item">
      <div 
        className="collection-line-border"
        style={{border: darkMode? '1px solid #5d5d5d' : '',}}
      >
        <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800">
          <div className="rnftg-item__img-wrapper">
            <div className="collection-col">
              {!imageLoaded && (
                <NewSkeleton/>
                )
              }
              <img
                className={`new-image-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
                alt="reok"
                src={info.imageUrl}
                onLoad={handleImageLoad}
              ></img>
              <div className="collection-item-label">{info.name}</div>
            </div>
          </div>
        </div>
        <Card
          sx={{
            background: darkMode? '#2a2a2a' : '#fff',
          }}
        >
          <CardHeader
            sx={{
              p:0.6,
              background: darkMode? '#2a2a2a' : '#fff',
              color: darkMode? 'white' : 'black',
              fontFamily: 'inherit',
              "& .MuiTypography-root": {
                color: darkMode? 'white' : 'black',
              },
              fontSize: "14px"
            }}
            avatar={
              <Avatar alt="Photographer avatar" src={info.photographerUrl} sx={{ width: 24, height: 24 }}/>
            }
            action={
              <Button sx={{p:0.8}} onClick={(e) => onSelectCollection(e, info)}>
                <div className="relative items-center justify-center inline-block p-1 px-5 py-0 overflow-hidden font-medium rounded-lg shadow-2xl group">
                <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-red-500 rounded-full blur-md ease"></span>
                  <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                    <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-purple-500 rounded-full blur-md"></span>
                    <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-pink-500 rounded-full blur-md"></span>
                  </span>
                  <span className="relative text-white" style={{textTransform:'none' }}>{t('button.tryThis')}</span>
                </div>
                {/* <span style={{textTransform:'none' }}>{t('button.tryThis')}</span> */}
              </Button>
            }
            subheader={info.photographer}
          />
        </Card>
      </div>
  </div>
  );
}
export default CollectionCard;