const theme = {
    // colors
    crcoLettuce: '#18D96E',
    crcoTechBlue: '#027DF0',
    crcoNeonYellow: '#DDFF00',
    crcoCoral: '#FF7661',
    crcoMidnight: '#080830',
    crcoOffWhite: '#f7f7f7',
    crcoCottonCandyBlue: '#bbdcf7',
    crcoCottonCandyPink: '#ffccd9',

    crcoBoxShadow: '5px 5px 20px -10px rgb(0 0 0 / 20%)',

    // breakpoints
    breakpointMobile: '480px',
    breakpointTablet: '767px',
    breakpointDesktop: '980px',
    breakpointDesktopWide: '1200px',

    scrollbar: `
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f2f2f2;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 5px;
      cursor: pointer;
    }`,
}

export { theme };