import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const MoneyBackGuarantee = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const {t} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };


  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <a href={currentLanguage === 'en' ? '/refund' : `/${currentLanguage}/refund`}>
      <div className="text-gray-500">
        <span style={{marginRight: "2px"}}>
          <svg fill="#6bff7e" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1.5 1.5" enableBackground="new 0 0 52 52" xmlSpace="preserve">
            <g>
              <path d="M0.478 1.063 0.065 0.647c-0.015 -0.015 -0.015 -0.04 0 -0.055l0.055 -0.055c0.015 -0.015 0.04 -0.015 0.055 0L0.485 0.85c0.01 0.01 0.028 0.01 0.038 0L1.13 0.238c0.015 -0.015 0.04 -0.015 0.055 0l0.055 0.055c0.015 0.015 0.015 0.04 0 0.055L0.533 1.063c-0.015 0.017 -0.04 0.017 -0.055 0"/>
            </g>
          </svg>
        </span>
        <span>
          {t('refund.moneyBack')}
        </span>
      </div>
    </a>
  );
};

export default MoneyBackGuarantee;
