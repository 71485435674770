import React, { useState } from 'react';
import ProgressBar from '../ProgressBar';
import confetti from 'canvas-confetti';

const YourShowCase = ({ jobsList, t }) => {

  const [imaginedImage, setImaginedImage] = useState(false);
  const [choosingImage, setChoosingImage] = useState(false);
  const [hideDownloadButton, setHideDownloadButton] = useState(false);
  const [large, setlargeImage] = useState('');
  const [designData, setDesignData] = useState('');

  const onShowImage = (index, designData, isUpscaleUrl) => {
    if (isUpscaleUrl ) {
      setlargeImage(jobsList[index].upscaleUrl);
    }else {
      setlargeImage(jobsList[index].originalUrl);
    }
    setDesignData(designData);
    setChoosingImage(false);
    setHideDownloadButton(false);
    setImaginedImage(true);
  }

  function onCloseImageWindow() {
    setImaginedImage(false);
  }


  function generateRandomFileName(extension) {
    const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
    return `noithatai-${randomNumber}.${extension}`;
  }

  const onDownloadImage = (image) => {
    try {
      setTimeout(async () => {
        setImaginedImage(false);
        try {
          const response = await fetch(image);
          const blob = await response.blob();
      
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;

          const fileName = generateRandomFileName('jpeg');
          link.download = fileName;
          link.click();
      
          // Clean up the temporary URL
          URL.revokeObjectURL(url);

          confetti({
            origin: { y: 0.5 },
            spread: 400,
            particleCount: 100,
            origin: { y: 0.4 },
          });
        } catch (error) {
          console.error('Error downloading image:', error);
        }

      }, 500);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="container break-left-panel-md">
          <div className="row flex-grow-1 flex-nowrap m-0">
            <div className="col panel-main py-3 px-0 py-xl-5">
              <div className="d-block">
                <div className="w-100 mb-6">
                  <div className="page-header mb-0 page-header-reset mb-4">
                  </div>
                  <div className="swiper">
                    <div className="swiper-wrapper-all-flowers">
                      {jobsList.length > 0 && jobsList.map((job, index) => (
                        <>
                          <div className="swiper-slide">
                            <div className="listing-tile-wrapper w-100 h-100">
                              <div className="media-wrap">
                                <a>
                                  <div className="media" onClick={() => onShowImage(index, job, false)} >
                                    <img src={job.originalUrl} loading='lazy' alt="originalUrl" />
                                  </div>
                                </a>
                              </div>
                              <div className="d-flex">
                                <div
                                  className="d-flex h-listing-title"
                                >
                                  <div className="line-clamp-2 text-dark mt-2"><span style={{color: "#55a6c4"}}>{t('profile.before')} </span>Hình gốc</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="listing-tile-wrapper w-100 h-100">
                              <div className="media-wrap">
                                <a>
                                  <div className="media" onClick={() => onShowImage(index, job, true)} >
                                    <img src={job.upscaleUrl} loading='lazy' alt="upscaleUrl" />
                                  </div>
                                </a>
                              </div>
                              <div className="d-flex">
                                <div className="d-flex h-listing-title">
                                  <div className="line-clamp-2 text-dark mt-2"><span style={{color: "#55a6c4"}}>{t('profile.after')} </span>{job.prompt}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imaginedImage && <ProgressBar t={t} designData={designData} large={large} onClose={onCloseImageWindow} choosingImage={choosingImage} hideDownloadButton={hideDownloadButton} onDownloadImage={onDownloadImage} />}
    </>
  );
};

export default YourShowCase;
