import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import FAQ from '../FAQ';
import PricingLandingPage from '../PricingLandingPage';

const upgradeResolution = {
  before: 'https://cdn.discordapp.com/attachments/1105800665105711166/1151826369261608960/blurry.webp',
  after: 'https://cdn.discordapp.com/attachments/1105800665105711166/1151826368808615956/unblurry.webp',
};

const YearbookPage = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('affCode', '');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('redirect', "");
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main style={{ background: '#000' }}>
        <img src="/images/night-background.webp" alt="reok" className="absolute inset-0 object-cover object-bottom w-full h-full" />
        <div className="landing-cover-header" data-controller="gradient-animation">
          <Row gutter={12}>
            <Col md={12} xs={24} className="landing-order-second">
              <div className="banner-block-copy-0-2-82 banner-block-copy-d123-0-2-395">
                <div className="title-landing-wrapper">
                  <h1 className="text-root-0-2-141 text-root-d10-0-2-401 banner-block-copyTitle-0-2-83 banner-block-copyTitle-d124-0-2-396">
                    <span>
                      <h1 className="display-title-landing fw-bold text-white">{t('yearbook.title')}</h1>
                    </span>
                  </h1>
                  <div className="description-root-0-2-148 description-root-d27-0-2-402">
                    <p style={{ color: '#adb5bd' }}>
                      {t('yearbook.subTitle')}
                    </p>
                  </div>
                  <div className="relative mx-auto mt-6 max-w-lg space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="flex -space-x-3 overflow-hidden">
                        <img
                          src="/images/customer-1.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-2.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-3.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-4.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-5.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                        />
                      </div>{' '}
                      <div className="flex" style={{ color: '#facc15' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    </div>{' '}
                    <p className="text-sm text-gray-500">{t('yearbook.loveBy')}</p>
                  </div>
                  <div className="banner-block-ctaHolder-0-2-86 banner-block-ctaHolder-d126-0-2-398">
                    <div className="upgrade-btn-wrapper">
                      <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                        {t('button.try')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="landing-order-first">
              <div className="banner-block-mediaWrapper">
                <div className=" media-root-0-2-161 media-root-d6-0-2-411 banner-block-media-0-2-88 banner-block-media-d128-0-2-400">
                  <div data-testid="image-root" className="base-image-root-0-2-361 base-image-root-d2-0-2-412">
                    <div className="before-after-landing-wrapper">
                      <div className="video-slider">
                        <img
                          className="banner-video-landing"
                          alt="reok"
                          src="https://cdn.discordapp.com/attachments/1105800665105711166/1161197687031463957/landingyearbook.webp"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex items-center justify-center text-center relative">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" alt="reok" src="images/tiktok.webp" />
            <img className="instagram-seen-on" loading="lazy" alt="reok" src="images/instagram.webp" />
          </div>
        </div>
      </main>
      <div className="mb-3" />
      <main>
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10]  t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('yearbook.respectTitle')}
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  {t('yearbook.respectSub1')}
                </p>
                <p>{t('yearbook.respectSub2')}</p>
              </div>
            </div>
            <div className="w-full">
              <h2 className="mx-auto relative z-[10] t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('yearbook.howItWorks')}
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>{t('yearbook.howItWorksSub')}</p>
              </div>
            </div>
            <div className="grid gap-5 sm:grid-cols-2 sm:gap-4 lg:gap-10">
              <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-aliceblue">
                <div className="rnftg-item__img-wrapper_landing">
                  <div>
                    <img
                      className="banner-video"
                      alt="reok"
                      src="https://cdn.discordapp.com/attachments/1105800665105711166/1161165385366773821/yearbookman.webp"
                    ></img>
                  </div>
                </div>
              </article>
              <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-purple">
                <div className="rnftg-item__img-wrapper_landing">
                  <div>
                    <img
                      className="banner-video"
                      alt="reok"
                      src="https://cdn.discordapp.com/attachments/1105800665105711166/1161165385052196874/yearbookwoman.webp"
                    ></img>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
        <section
          id="5section1"
          className="relative overflow-hidden bg-black section-top-none section-top-90"
          data-layouts="simple_cards"
        >
          <div className="container space-y-8 md:space-y-[85px]">
            <div className="w-full">
              <h2 className="mx-auto relative z-[10] t-50 text-white text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                {t('yearbook.hotFeatureTitle')}
              </h2>
              <div
                className="mx-auto font-body relative z-10 text-white t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>{t('yearbook.hotFeatureSub')}</p>
              </div>
            </div>
            <div className="rnftg-h-full rnftg-w-full">
              <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                <div className="rnftg-flex rnftg-flex-col pd-2">
                  <div className="rnftg-grid rnftg-gap-6 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-4 xl:rnftg-grid-cols-5">
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161179386536529982/reok-2394234.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161178787321491466/reok-474147951.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161179385827704853/reok-257672631.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161178787665420399/reok-696915772.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161182410470326282/reok-197802826.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161181159875678309/reok-9394934.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161185115028860978/reok-8384834.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161181159422697472/reok-905769426.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161182411305001040/reok-3483248.webp"
                          ></img>
                        </div>
                      </div>
                    </article>
                    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                      <div className="rnftg-item__img-wrapper_landing">
                        <div className="result">
                          <img
                            className="banner-video"
                            alt="reok"
                            src="https://cdn.discordapp.com/attachments/1105800665105711166/1161185115582513212/reok-938384.webp"
                          ></img>
                        </div>
                      </div>
                    </article>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-neutral-black section-top-180">
          <PricingLandingPage t={t} currentLanguage={currentLanguage}/>
        </section>
        <section>
          <div className="relative bg-neutral-black section-top-180 section-bottom-90">
            <div className="container">
              <FAQ t={t}/>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default YearbookPage;
