import React from 'react';

const Spinning = ({t, text}) => {
  return (
    <div className="showbox">
    <div className="loader-wrapper">
      <p/><p/><p/><p/>
    </div>
    <div className="loader-title">
      <p>{text}</p>
    </div>
  </div>
  );
};

export default Spinning;
