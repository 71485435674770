import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import {
  SALESPERSON_HEADSHOTS, 
  BUSINESSES_HEADSHOT, 
  DATING_PHOTOS, 
  STUDENT_HEADSHOTS, 
  TEACHER_HEADSHOTS, 
  DOCTOR_HEADSHOTS, 
  LAWYER_HEADSHOTS, 
  REAL_ESTATE_HEADSHOTS 
} from '../../constants/routes'

const jobs = [
  {
    label: 'Doctor Headshots',
    code: 'docterHeadshots',
    path: DOCTOR_HEADSHOTS
  },
  {
    label: 'Teacher Headshots',
    code: 'teacherHeadshots',
    path: TEACHER_HEADSHOTS
  },
  {
    label: 'Student Headshots',
    code: 'studentHeadshots',
    path: STUDENT_HEADSHOTS
  },
  {
    label: 'Lawyer Headshots',
    code: 'lawyerHeadshots',
    path: LAWYER_HEADSHOTS
  },
  {
    label: 'Real Estate Headshots',
    code: 'realeastateHeadshots',
    path: REAL_ESTATE_HEADSHOTS
  },
  {
    label: 'Salesperson Headshots',
    code: 'salespersonHeadshots',
    path: SALESPERSON_HEADSHOTS
  }
];

const JobHeader = ({currentLanguage}) => {
  const [selected, setSelected] = useState(0);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    const currentLanguage = i18n.language;
    const index = jobs.findIndex((country) => country.lang === currentLanguage);
    setSelected(index);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [i18n.language]);

  const handleLanguageChange = (index) => {
    setSelected(index);
    dropdownRef.current.classList.remove('show');
    localStorage.setItem('mainLang', jobs[index].lang);
  };

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function handleMouseEnter() {
    if (!dropdownRef.current.classList.contains('show')) {
      dropdownRef.current.classList.toggle('show');
    }
  }

  return (
    <div className="bg-gray-200 flex items-center justify-center pt-5 pb-56">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <div className="w-full">
          <div className="relative pb-5">
            <button
              className="job-btn-wrapper rounded-pill text-nowrap lang-btn"
              type="button"
              onClick={onOpenToggler}
              onMouseEnter={handleMouseEnter}
            >
              <div className='fw-semi-bold'>{t('landing.headshotsMenu')}</div>
              <svg
                width="14px"
                height="14px"
                viewBox="0 0 12 12"
                fill="none"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    strokeWidth={2}
                    d="M9.898 4.157 6 8.055 2.102 4.157"
                    stroke="currentColor"
                  />
                </g>
              </svg>
            </button>
            <div style={{width: "210px", left: 0}} className="lang-drop-down bg-white text-gray-700 shadow-md rounded text-sm absolute mt-12 top-0 right-0 min-w-full" ref={dropdownRef}>
              <div className="bg-white overflow-auto rounded w-full relative z-10">
                <ul className="list-reset">
                  {jobs.map((item, index) => (
                    <li key={index}>
                      <a
                        href={currentLanguage === 'en' ? `${item.path}` : `/${currentLanguage}${item.path}`}
                        className={`px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 ${index === selected ? 'font-bold' : ''}`}
                      >
                        <span className="inline-block">
                          {t(`${item.code}.menuTitle`)}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobHeader;

