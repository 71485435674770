import React from 'react';

const Spinner = () => {
  return (
    <div className="showbox">
    <div className="loader-wrapper">
      <p/><p/><p/><p/>
    </div>
  </div>
  );
};

export default Spinner;
