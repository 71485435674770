import React, { useEffect, useState, useRef } from 'react';
import { Button, message } from 'antd';
import axios from 'axios';
import ComposeSection from '../ComposeSection';
import Pricing from '../Pricing';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import AllShowCase from '../AllShowCase';
import confetti from 'canvas-confetti';
import { MenuItem, FormControl, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import NewAvatarHeader from '../NewAvatarHeader';
import EditImage from '../EditImage';
import MyDesign from '../MyDesign';
import Referal from '../Referal';
import Pose from '../Pose';
import NewPricing from '../NewPricing';
import Background from '../Background';
import Style from '../Style';
import Photographer from '../Photographer';
import HeaderSticky from '../HeaderSticky';
import { styled } from '@mui/material/styles';

const APP_URL_V2 = process.env.REACT_APP_APP_URL_V2;
const APP_URL_V5 = process.env.REACT_APP_APP_URL_V5;
const APP_URL_V6 = process.env.REACT_APP_APP_URL_V6;
const APP_URL_V12 = process.env.REACT_APP_APP_URL_V12;
const APP_URL_V13 = process.env.REACT_APP_APP_URL_V13;

const photographerStyle = "photographer_style";
const photographerPose = "photographer_pose";

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const genders = [
  {
    value: 'man',
    label: 'man'
  },
  {
    value: 'woman',
    label: 'woman'
  }
]

const results = [
  {
    value: 1,
    label: 'one'
  },
  {
    value: 4,
    label: 'four'
  }
]


const ageItems = [];
for (let age = 18; age <= 100; age++) {
  ageItems.push(
    {
      value: age,
      label: age
    }
  );
}

const NewSelect = styled(({ darkMode, ...props }) => (
  <Select {...props} />
))(({ darkMode }) => ({
  textTransform: 'none',
  fontSize: 16,
  fontFamily: 'inherit',
  backgroundColor: 'transparent',
  color: '#5d5d5d',
  background: darkMode ? '#5d5d5d' : '#fff',
}));

const NewHome = ({ firebase, setUser }) => {
  const [userData, setUserData] = useState({});
  const [jobsList, setJobsList] = useState([]);
  const [posesList, setPosesList] = useState([]);
  const [uploadedPosesList, setUploadedPosesList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [mode, setMode] = useState(modes.pro); //Set default is PRO
  const [isShowPricing, setIsShowPricing] = useState(false);
  const [isShowPose, setIsShowPose] = useState(false);
  const [isShowBackground, setIsShowBackground] = useState(false);
  const [isShowStyle, setIsShowStyle] = useState(false);
  const [isShowPhotographer, setIsShowPhotographer] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [poseSrc, setPoseSrc] = useState('');
  const [poseUrl, setPoseUrl] = useState('');
  const [backgroundSrc, setBackgroundSrc] = useState('');
  const [styleSrc, setStyleSrc] = useState('');
  const [photographerSrc, setPhotographerSrc] = useState('');
  const [photographerName, setPhotographerName] = useState('');
  const [photographerCode, setPhotographerCode] = useState('');
  const [file, setFile] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [currentPoses, setCurrentPoses] = useState(null);
  const [backgrounds, setBackgrounds] = useState(null);
  const [styles, setStyles] = useState(null);
  const [backgroundName, setBackgroundName] = useState(null);
  const [backgroundCode, setBackgroundCode] = useState(null);
  const [styleName, setStyleName] = useState(null);
  const [styleCode, setStyleCode] = useState(null);
  const [currentGender, setCurrentGender] = useState('');
  const [currentStyle, setCurrentStyle] = useState('');
  const [currentResult, setCurrentResult] = useState(4);
  const [currentJob, setCurrentJob] = useState(null);
  const [showGenFillEditor, setShowGenFillEditor] = useState(false);
  const [showUpgradeResolution, setShowUpgradeResolution] = useState(false);
  const [showMyDesign, setShowMyDesign] = useState(false);
  const [showProfessional, setShowProfessional] = useState(true);
  const [showRemoveBG, setShowRemoveBG] = useState(false);
  const [imageUrlEditor, setImageUrlEditor] = useState('');
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isActiveDeskMenu, setIsActiveDeskMenu] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showReferal, setShowReferal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [currentAge, setCurrentAge] = useState('');
  const [convertPose, setConvertPose] = useState(false);
  const [promptStyle, setPromptStyle] = useState('');
  const [promptBackground, setPromptBackground] = useState('');
  const [credits, setCredits] = useState(0);
  const [progress, setProgress] = useState(0);
  const navPopupRef = useRef(null);
  const [darkMode, setDarkMode] = useState(true);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const photographerInfo = [
    {
      code: photographerStyle,
      name: t('photographers.sam.name'),
      title: t('photographers.sam.title'),
      imageUrl: "/images/sam.webp",
      introUrl: "/images/sam-intro.webp",
      intro: t('photographers.sam.intro'),
      intro1: t('photographers.sam.intro1'),
      intro2: t('photographers.sam.intro2'),
      intro3: t('photographers.sam.intro3'),
      intro4: t('photographers.sam.intro4'),
      intro5: t('photographers.sam.intro5'),
    },
    {
      code: photographerPose,
      name: t('photographers.david.name'),
      title: t('photographers.david.title'),
      imageUrl: "/images/david.webp",
      introUrl: "/images/david-intro.webp",
      intro: t('photographers.david.intro'),
      intro1: t('photographers.david.intro1'),
      intro2: t('photographers.david.intro2'),
      intro3: t('photographers.david.intro3'),
      intro4: t('photographers.david.intro4'),
      intro5: t('photographers.david.intro5'),
    }
  ]

  useEffect(() => {
    setCurrentLanguage(i18n.language);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // Access specific parameters
    const imageUrl = params.url;

    if (imageUrl !== undefined && imageUrl !== "") {
      sampleUploadFromLink(imageUrl);
    }

  }, [i18n.language]);

  useEffect(() => {
    setIsSpinning(true);
    setCurrentJob(null);
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // User is authenticated, fetch the user data
        firebase
          .getUser(user.uid)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              setUserData(querySnapshot.data());
              setIsSpinning(false);
              // setTimeout(() => {
              //   setIsSpinning(false);
              //   if(photographerSrc === '') {
              //     setIsShowPhotographer(true);
              //   }
              // }, 1000);

              firebase
                .getPoses()
                .then(result => {
                  const posesArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));

                  posesArr.sort((a, b) => a.priority - b.priority);
                  setPosesList(posesArr);
                })
                .catch(error => {
                  console.log(error);
                });

                firebase
                .getUploadedPoses(user.uid)
                .then(result => {
                  const uploadedPosesArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  
                  setUploadedPosesList(uploadedPosesArr);
                })
                .catch(error => {
                  console.log(error);
                });

                firebase
                .getBackgrounds()
                .then(result => {
                  const backgroundArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  
                  backgroundArr.sort((a, b) => a.priority - b.priority);

                  setBackgrounds(backgroundArr);
                })
                .catch(error => {
                  console.log(error);
                });

                firebase
                .getStyles()
                .then(result => {
                  const styleArr = result.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  
                  styleArr.sort((a, b) => a.priority - b.priority);

                  setStyles(styleArr);
                })
                .catch(error => {
                  console.log(error);
                });
            }
          })
          .catch(error => {
            console.log(error);
            setIsSpinning(false);
          });
      } else {
        // User is not authenticated, clear the user data
        setUserData(null);
        setUser(null);
        setIsSpinning(false);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsActiveDeskMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setIsMobile(true);
        setIsActiveDeskMenu(false);
      } else {
        setIsMobile(false);
        setIsActiveDeskMenu(true);
      }
    };
  
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (navPopupRef.current && event.target.contains(navPopupRef.current)) {
        setIsActiveMobileMenu(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [navPopupRef]);

  function checkCredits() {
    if (mode === modes.pro && ((userData.pro && userData.proCredits < 2) || !userData.pro)) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
        setShowGenFillEditor(false);
      }, 1500);
      return true;
    }

    if (mode === modes.basic && ((userData.basic && userData.basicCredits < 2) || !userData.basic)) {
      message.warning(t('message.warning.runOutBasicCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
        setShowGenFillEditor(false);
      }, 1500);
      return true;
    }
    return false;
  }

  function checkCreditsProfessional() {
    if (mode === modes.pro && ((userData.pro && (userData.proCredits < currentResult*5)))) {
      message.warning(t('message.warning.runOutProCredits'));
      setTimeout(() => {
        setIsCreating(false);
        onShowPricing(true);
        setShowGenFillEditor(false);
      }, 1500);
      return true;
    }
    return false;
  }

  const onCloseImageEdit = () => {
    setShowGenFillEditor(false);
  };

  const scrollToMyResult = () => {
    const section = document.querySelector( '#my-result' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  const scrollToTop = () => {
    const section = document.querySelector( '#scroll-top' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  const incrementProgress = () => {
    setProgress((prevProgress) => {

    const newProgress = prevProgress + Math.floor(Math.random() * 5) + 1;
  
      if (newProgress < 90) {
        return newProgress;
      } else {
        return prevProgress; // Return the previous state without updating
      }
    });
  };

  function onDesignProfessional() {
    if (checkCreditsProfessional()) return;

    if (photographerCode === '' || photographerSrc === '') {
      message.error(t('message.error.selectPhotographer'));
      setTimeout(() => {
        setIsShowPhotographer(true);
      }, 2000);
      return;
    }

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      return;
    }

    if (!currentGender) {
      message.error(t('message.error.gender'));
      return;
    }

    // if (!currentAge) {
    //   message.error(t('message.error.age'));
    //   return;
    // }

    if (currentStyle !== '' && promptStyle === '') {
      message.error(t('message.error.style'));
      return;
    }

    if ( photographerCode === photographerPose && (!poseUrl || poseUrl === '')) {
      message.error(t('message.error.pose'));
      return;
    }

    if (!backgroundName || backgroundName === '') {
      message.error(t('message.error.background'));
      return;
    }

    if (!currentResult || currentResult === '') {
      message.error(t('message.error.numberOfResult'));
      return;
    }

    //maintain
    // alert(t('message.maintain.backsoon'));
    // return;

    setIsCreating(true);

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }

    // const job = {
    //   images: {
    //     before: file,
    //     after: '',
    //   },
    //   status: "starting",
    //   progress: 0
    // };

    // setCurrentJob(job);
    // setProgress(0);
    
    // // scrollToMyResult();

    // message.success(t('message.success.redering'));

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;

        if (!imageUrl) return;

        let prompt = '';
        let isCustomedStyle = false;
        let isCustomedBackground = false;
        let style = '';
        let background = '';

        if (promptStyle !== '' && promptBackground === '') { // custom only style
          prompt = promptStyle + " - " + t(`backgrounds.${backgroundCode}`);
          isCustomedStyle = true;
          isCustomedBackground = false;
          style = promptStyle;
          background = backgroundName;
        } 
        else if (promptStyle === '' && promptBackground !== '') { //custom only background
          prompt = t(`styles.${styleCode}`) + " - " + promptBackground;
          isCustomedStyle = false;
          isCustomedBackground = true;
          style = styleName;
          background = promptBackground;
        }
        else if (promptStyle !== '' && promptBackground !== '') { //custom both style and background
          prompt = promptStyle + " - " + promptBackground;
          isCustomedStyle = true;
          isCustomedBackground = true;
          style = promptStyle;
          background = promptBackground;
        }
         else {
          style = styleName;
          background = backgroundName;
          prompt = t(`styles.${styleCode}`) + " - " + t(`backgrounds.${backgroundCode}`)
        }

        const resultItem = results.find(item => item.value === currentResult);
        const job = {
          images: {
            before: imageUrl,
            after: '',
          },
          status: "starting",
          progress: 0,
          prompt: prompt,
          result: t(`results.${resultItem.label}`)
        };
    
        setCurrentJob(job);        
    
        message.success(t('message.success.redering'));

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
        };

        let requestDataEng = {
          imageUrl: imageUrl,
          poseUrl: poseUrl,
          convertPose: convertPose,
          packageName: mode,
          gender: currentGender,
          quantity: currentResult,
          prompt : prompt,
          style: style,
          background: background,
          isCustomedStyle: isCustomedStyle,
          isCustomedBackground: isCustomedBackground,
          photographer: photographerCode
        };

        const result = await axios.post(`${APP_URL_V13}/headshot`, requestDataEng, { headers });

        const jobIds = result.data.ids;

        for (let index = 0; index < jobIds.length; index++) {
          const jobId = jobIds[index];

          while (true) {
            const result = await axios.get(`${APP_URL_V6}/job/${jobId}`, {}, { headers });

            if (result.data.status === 'finished') {
              setProgress(100);

              setTimeout(() => {
                // Result is finished, you can access it using result.data.output or perform further actions
                let finishedImage = result.data.image;

                const newImages = {
                  before: imageUrl,
                  after: finishedImage,
                };

                // Add new job to AllShowCase
                const newJob = {
                  images: newImages,
                  status: 'finished',
                  prompt: prompt,
                  uid: userData.uid,
                  id: result.data.id,
                  isLoved: false
                };
                setCurrentJob(newJob);

                firebase
                  .getUser(firebase.auth.currentUser.uid)
                  .then(querySnapshot => {
                    setUser(querySnapshot.data());
                    setUserData(querySnapshot.data());
                  })
                  .catch(err => {
                    message.error(err.message);
                  });

              }, 1000);

              break;
              
            } else if (result.data.status === 'nsfw') {
              // Result is finished, you can access it using result.data.output or perform further actions
              let finishedImage = result.data.image;

              const newImages = {
                before: imageUrl,
                after: finishedImage,
              };

              const newJob = {
                images: newImages,
                prompt: prompt,
                uid: userData.uid,
                id: result.data.id,
                isLoved: false,
                status: "nsfw"
              };
              setCurrentJob(newJob);

              firebase
                .getUser(firebase.auth.currentUser.uid)
                .then(querySnapshot => {
                  setUser(querySnapshot.data());
                  setUserData(querySnapshot.data());
                })
                .catch(err => {
                  message.error(err.message);
                });
              break;
              
            
            } else if (result.data.status === 'failed-face') {
              // Job failed, handle the failure case
              message.error(t('message.error.wrongFace'));
              const job = {
                status: 'failed-face'
              };
          
              setCurrentJob(job);
              setIsCreating(false);
              return;
            }
            else if (result.data.status === 'failed') {
              // Job failed, handle the failure case
              message.error(t('message.error.wrongFace'));
              setIsCreating(false);
              return;
            } else {
              if (result.data.status > progress) {
                setProgress(result.data.status);
              } else {
                incrementProgress();
              }
            }
            // If the job is not yet finished, wait for some time before making the next request
            await new Promise(resolve => setTimeout(resolve, 3000));
          }
        }

        // confetti({
        //   origin: { y: 0.5 },
        //   spread: 400,
        //   particleCount: 100,
        //   origin: { y: 0.4 },
        // });
        message.success(t('message.success.rederingDone'));
        setIsCreating(false);
        setProgress(0);
      })
      .catch(error => {
        if (error.response.data.error === "not-enough-credits") {
          message.error(t('message.error.outOfCredits'));
          setIsCreating(false);
          return;
        }
        message.error(t('message.error.wrongFace'));
        setIsCreating(false);
        // Handle the error
      });
  }

  function onRemoveBG() {
    if (checkCredits()) return;

    setIsCreating(true);

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      setIsCreating(false);
      return;
    }

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }

    const job = {
      images: {
        before: file,
        after: file,
      },
      status: "starting"
    };

    setCurrentJob(job);
    setProgress(0);
    scrollToMyResult();

    message.success(t('message.success.removingBG'));

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;

        if (!imageUrl) return;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
        };

        const prompt = t('message.prompt.removeBG');

        const requestDataEng = {
          imageUrl: imageUrl,
          packageName: mode,
          prompt : prompt
        };

        const result = await axios.post(`${APP_URL_V2}/remove-bg`, requestDataEng, { headers });
        const jobId = result.data.id;

        while (true) {
          const result = await axios.get(`${APP_URL_V5}/job/${jobId}`, {}, { headers });

          if (result.data.status === 'finished') {
            setProgress(100);

            setTimeout(() => {

              // Result is finished, you can access it using result.data.output or perform further actions
              let finishedImage = result.data.image;

              const newImages = {
                before: imageUrl,
                after: finishedImage,
              };

              const newJob = {
                images: newImages,
                status: 'finished',
                prompt: prompt,
                uid: userData.uid,
                id: result.data.id,
                isLoved: false
              };
              setCurrentJob(newJob);

              message.success(t('message.success.rederingDone'));
              setIsCreating(false);
              confetti({
                origin: { y: 0.5 },
                spread: 400,
                particleCount: 100,
                origin: { y: 0.4 },
              });

              firebase
                .getUser(firebase.auth.currentUser.uid)
                .then(querySnapshot => {
                  setUser(querySnapshot.data());
                  setUserData(querySnapshot.data());
                })
                .catch(err => {
                  message.error(err.message);
                });
            }, 1000);

            return;

          } else if (result.data.status === 'failed') {
            // Job failed, handle the failure case
            message.error(t('message.error.busy'));
            setIsCreating(false);
            return;
          } else {
            if (result.data.status > progress) {
              setProgress(result.data.status);
            } else {
              incrementProgress();
            }
          }          
          // If the job is not yet finished, wait for some time before making the next request
          await new Promise(resolve => setTimeout(resolve, 3000));
        }
      })
      .catch(error => {
        message.error(t('message.error.wrongFile'));
        setIsCreating(false);
        // Handle the error
      });
  }

  function onBeauty() {
    if (checkCredits()) return;

    setIsCreating(true);

    if (!fileInfo) {
      message.error(t('message.error.uploadPhoto'));
      setIsCreating(false);
      return;
    }

    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return;
    }

    const job = {
      images: {
        before: file,
        after: file,
      },
      status: "starting"
    };

    setCurrentJob(job);
    setProgress(0);
    scrollToMyResult();

    message.success(t('message.success.upscale'));

    firebase
      .uploadFile(file)
      .then(async downloadURL => {
        const imageUrl = downloadURL;

        if (!imageUrl) return;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
        };

        const prompt = t('message.prompt.upscale');

        const requestDataEng = {
          imageUrl: imageUrl,
          packageName: mode,
          prompt: prompt
        };

        const result = await axios.post(`${APP_URL_V2}/beauty`, requestDataEng, { headers });
        const jobId = result.data.id;

        while (true) {
          const result = await axios.get(`${APP_URL_V5}/job/${jobId}`, {}, { headers });

          if (result.data.status === 'finished') {
            setProgress(100);

            setTimeout(() => {
              // Result is finished, you can access it using result.data.output or perform further actions
              let finishedImage = result.data.image;

              const newImages = {
                before: imageUrl,
                after: finishedImage,
              };

              const newJob = {
                images: newImages,
                status: 'finished',
                prompt: prompt,
                uid: userData.uid,
                id: result.data.id,
                isLoved: false
              };
              setCurrentJob(newJob);

              message.success(t('message.success.rederingDone'));
              setIsCreating(false);
              confetti({
                origin: { y: 0.5 },
                spread: 400,
                particleCount: 100,
                origin: { y: 0.4 },
              });

              firebase
                .getUser(firebase.auth.currentUser.uid)
                .then(querySnapshot => {
                  setUser(querySnapshot.data());
                  setUserData(querySnapshot.data());
                })
                .catch(err => {
                  message.error(err.message);
                });
              }, 1000);

            return;
          } else if (result.data.status === 'failed') {
            // Job failed, handle the failure case
            message.error(t('message.error.busy'));
            setIsCreating(false);
            return;
          } else {
            if (result.data.status > progress) {
              setProgress(result.data.status);
            } else {
              incrementProgress();
            }
          }  
          // If the job is not yet finished, wait for some time before making the next request
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      })
      .catch(error => {
        message.error(t('message.error.wrongFile'));
        setIsCreating(false);
        // Handle the error
      });
  }

  function onGenFill(newJob) {
    setCurrentJob(newJob);
  }

  function onChangeImageSrc(imageUrl) {
    setImageUrlEditor(imageUrl);
  }

  function handleModeChangeParent(mode) {
    setMode(mode);
  }

  function handleChangeGender(event) {
    setCurrentGender(event.target.value);
    handleCurrentPoses(event.target.value)
  }

  function handleCurrentPoses(gender) {
    const filteredPoses = posesList.filter(pose => pose.gender === gender);
    setCurrentPoses(filteredPoses);
  }

  function handleSelectPose(pose, isConvertPose) {
    // setCurrentPoseId(pose.poseUrl);
    setIsShowPose(false);
    setPoseSrc(pose.imageUrl);
    setConvertPose(isConvertPose);

    if (isConvertPose) {
      setPoseUrl(pose.imageUrl)
    } else {
      setPoseUrl(pose.poseUrl)
    }
  }

  function handleSelectBackground(background) {
    setIsShowBackground(false);
    setBackgroundSrc(background.imageUrl);
    setBackgroundName(background.name);
    setBackgroundCode(background.code)
    setPromptBackground('');
  }

  function handleSelectStyle(style) {
    setIsShowStyle(false);
    setStyleSrc(style.imageUrl);
    setStyleName(style.name);
    setStyleCode(style.code);
    setPromptStyle('');
  }

  function handleSelectPhotographer(photographer) {
    setIsShowPhotographer(false);
    setPhotographerSrc(photographer.imageUrl);
    setPhotographerName(photographer.name + " " + photographer.title);
    setPhotographerCode(photographer.code);
    setCurrentResult(4);
    setCredits(20)
  }

  function handleChangeResult(event) {
    setCurrentResult(event.target.value);
    if (event.target.value === 1) {
      setCredits(5);
    }
    if (event.target.value === 4) {
      setCredits(20);
    }
  }

  function onShowPricing(value) {
    setIsShowPricing(value);
  }

  function onShowPose(value) {
    setIsShowPose(value);
  }

  function onShowBackground(value) {
    setIsShowBackground(value);
  }

  function onShowStyle(value) {
    setIsShowStyle(value);
  }

  function onShowPhotographer(value) {
    setIsShowPhotographer(value);
  }

  function onShowReferal() {
    setShowReferal(true);
    setShowUpgradeResolution(false);
    setShowMyDesign(false);
    setShowProfessional(false);
    setShowRemoveBG(false);

    const activeLink = document.querySelector('.nav-link.active');
    activeLink.classList.remove('active');
    setIsActiveMobileMenu(false);
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
          setUser(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  function onFetchUser() {
    firebase
      .getUser(firebase.auth.currentUser.uid)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          setUserData(querySnapshot.data());
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const checkFileSize = file => {
    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsCreating(false);
      return false;
    }
    return true;
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `reok-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleFileUploadFromLink = async (event, imageUrl) => {

    scrollToTop();
    onActiveUpgradeResolution(event);
    const response = await fetch(imageUrl);
    const file = await response.blob();

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `reok-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const sampleUploadFromLink = async (imageUrl) => {

    const response = await fetch(imageUrl);
    const file = await response.blob();

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `reok-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const handleOpenFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleOpenPoseUpload = () => {
    if (currentGender === ""){
      message.warning(t('message.warning.selectPose'));
      return;
    }
    setIsShowPose(true);
  };

  const handleSetPoseFile = function(file, convertPose) {
    setPoseSrc(file); //Show on UI
    setPoseUrl(file); // Send to backend with converted pose
    setConvertPose(convertPose);

    firebase
    .getUploadedPoses(userData.uid)
    .then(result => {
      const uploadedPosesArr = result.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      setUploadedPosesList(uploadedPosesArr);
    })
    .catch(error => {
      console.log(error);
    });
  }

  const handleBackgroundUpload = () => {
    setIsShowBackground(true);
  };

  const handleStyleUpload = () => {
    setIsShowStyle(true);
  };

  const handlePhotographer = () => {
    setIsShowPhotographer(true);
  }

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];

      if (checkFileSize(file) === false) return;

      setFile(file);
      const reader = new FileReader();

      reader.onload = function () {
        const image = new Image();

        image.onload = function () {
          const canvas = document.createElement('canvas');
          const scaleSize = MAX_WIDTH / image.width;
          // canvas.width = MAX_WIDTH;
          // canvas.height = image.height * scaleSize;

          canvas.width = image.width;
          canvas.height = image.height;

          const ctx = canvas.getContext('2d');

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (file.name === '' || file.name === undefined) {
            const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
            file.name = `reok-up-${randomNumber}.jpg`;
          }

          canvas.toBlob(blob => {
            const canvasImage = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });
            setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
            setFile(canvasImage);

            const reader2 = new FileReader();
            reader2.onloadend = function () {
              const srcEncoded = reader2.result;
              setImageSrc(srcEncoded);
            };

            canvas.remove(); // Remove the canvas element
            reader2.readAsDataURL(blob);
          }, 'image/jpeg');
        };

        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = '1px dashed rgb(64 178 80)';
      uploaderWrapper.style.color = 'rgb(64 178 80)';
    } else if (e.type === 'dragleave') {
      const uploaderWrapper = document.querySelector('.uploader-wrapper');
      uploaderWrapper.style.border = '1px dashed rgb(0 0 0 / 20%)';
      uploaderWrapper.style.color = '#8c8c8c';
    }
  };

  const handleOnRemoveBG = async (event, imageUrl) => {

    scrollToTop();
    onActiveRemoveBG(event);
    const response = await fetch(imageUrl);
    const file = await response.blob();

    if (checkFileSize(file) === false) return;

    setFile(file);
    const reader = new FileReader();

    reader.onload = function () {
      const image = new Image();

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const scaleSize = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleSize;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (file.name === '' || file.name === undefined) {
          const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
          file.name = `reok-up-${randomNumber}.jpg`;
        }

        canvas.toBlob(blob => {
          const canvasImage = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
          setFile(canvasImage);

          const reader2 = new FileReader();
          reader2.onloadend = function () {
            const srcEncoded = reader2.result;
            setImageSrc(srcEncoded);
          };

          canvas.remove(); // Remove the canvas element
          reader2.readAsDataURL(blob);
        }, 'image/jpeg');
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const removeUploadedImage = function (e) {
    e.preventDefault();
    setFileInfo(null);
    setImageSrc('');
  };

  const removeUploadedPose = function (e) {
    e.preventDefault();
    // setFileInfo(null);
    setPoseSrc('');
    setPoseUrl('');
    setConvertPose(true);
  };

  const removeUploadedBackground = function (e) {
    setBackgroundSrc('');
    setBackgroundName('');
  }

  const removeUploadedStyle = function (e) {
    setStyleSrc('');
    setStyleName('');
    setPromptStyle('');
  }

  const removePhotographer = function (e) {
    setPhotographerSrc('');
    setPhotographerName('');
    setPhotographerCode('');
    setCredits(0)
    setCurrentResult(0);
  }

  function onActiveRemoveBG (event) {
    setShowRemoveBG(true);
    setShowProfessional(false);
    setShowUpgradeResolution(false);
    setShowMyDesign(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveProfessional (event) {
    setShowProfessional(true);
    setShowRemoveBG(false);
    setShowUpgradeResolution(false);
    setShowMyDesign(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveUpgradeResolution(event) {
    setShowUpgradeResolution(true);
    setShowRemoveBG(false);
    setShowProfessional(false);
    setShowMyDesign(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  function onActiveMyPhoto(event) {
    setShowMyDesign(true);
    setShowRemoveBG(false);
    setShowProfessional(false);
    setShowUpgradeResolution(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }
    event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  const openMobileMenu = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  function chooseSampleProfessional(event, link, gender) {
    event.preventDefault();

    sampleUploadFromLink(link);
    // setCurrentResult(1);

    // if(gender === 'man') {
    //   setCurrentGender('man');
    //   setCurrentStyle('business');
    // } else if (gender === 'woman') {
    //   setCurrentGender('woman');
    //   setCurrentStyle('business');
    // } else {
    //   setCurrentGender('');
    //   setCurrentStyle('');
    // }
    // setCurrentAge(25);
  }

  function chooseBadSampleProfessional(event, link, gender) {
    event.preventDefault();

    sampleUploadFromLink(link);
    // setCurrentResult('');
    setCurrentGender('');
    setCurrentStyle('');
  }

  const handleChangeAge = (event) => {
    setCurrentAge(event.target.value);
  };

  const submitPromptStyle = (prompt) => {
    setPromptStyle(prompt)
    onShowStyle(false);
  }

  const submitPromptBG = (prompt) => {
    setPromptBackground(prompt)
    onShowBackground(false);
  }

  const hideMenuBar = () => {
    setIsActiveDeskMenu(!isActiveDeskMenu);
  }

  function onActiveOnlyProfessional () {
    setShowProfessional(true);
    setShowRemoveBG(false);
    setShowUpgradeResolution(false);
    setShowMyDesign(false);
    setShowReferal(false);

    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.classList.remove('active');
    }

    const firstNavLink = document.querySelector('.nav-link');
    if (firstNavLink) {
      firstNavLink.classList.add('active');
    }

    // event.currentTarget.classList.add('active');
    setIsActiveMobileMenu(false);
  }

  const handleSelectCollection = (collection) => {
    setPhotographerSrc('');
    setPhotographerName('');
    setPhotographerCode('');
    message.success(t('message.success.useTheTemplate'));

    setTimeout(() => {
      // Set photographer
      onActiveOnlyProfessional();
      setPhotographerCode(collection.photographerCode);
      setPhotographerName(collection.photographerName + ", " + collection.title);
      setPhotographerSrc(collection.photographerUrl);

      // Set gender
      setCurrentGender(collection.gender);

      // Set prompt
      const style = {
        imageUrl: "/images/custom-style.webp",
        name: prompt
      }
      setPromptStyle(collection.style);
      setStyleName(collection.style);
      setStyleSrc("/images/custom-style.webp");

      setPromptBackground(collection.background);
      setBackgroundName(collection.background);
      setBackgroundSrc("/images/custom-style.webp");
      onShowBackground(false);

      // Set Pose
      if (collection.photographerCode === photographerPose) {
        handleCurrentPoses(collection.gender);
        setPoseSrc(collection.imageUrl);
        setPoseUrl(collection.imageUrl);
        setConvertPose(true);
      }

      // Number of results
      setCurrentResult(4);
      setCredits(20);
      scrollToTop();
    }, 500);
  }

  const onSelectDarkMode = () => {
    setDarkMode(!darkMode);
  }

  return (
    <div>
      {userData && (
        <div>
          {!isMobile ?
          (
            <div className="left-area" style={!isActiveDeskMenu? {left: "-350px"}: {left: "0"}} id="scroll-top-nav">
              <div className="logo-header">
                <HeaderSticky currentLanguage={currentLanguage} isMobile={isMobile} isActiveMobileMenu={isActiveMobileMenu} openMobileMenu={openMobileMenu} onActiveProfessional={onActiveProfessional}/>
              </div>
              <>
                <div className="sub-area">
                  <ComposeSection
                    t={t}
                    userData={userData}
                    handleModeChangeParent={handleModeChangeParent}
                    onShowPricing={onShowPricing}
                    defaultMode={mode}
                    onShowReferal={onShowReferal}
                    currentLanguage={currentLanguage}
                  />
                </div>
                <div className="nav-menu">
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link active" onClick={onActiveProfessional}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 240" style={{marginLeft: "2px"}}>
                      <path d="M84.635,20.256c18.383,0,33.286,14.903,33.286,33.286s-14.903,33.286-33.286,33.286S51.349,71.925,51.349,53.542
                        S66.251,20.256,84.635,20.256z M31.002,145.011c0-2.499,1.606-4.194,4.194-4.194s4.194,1.606,4.194,4.194v92.986h91.469v-92.986
                        c0-2.499,1.606-4.194,4.194-4.194c2.499,0,4.194,1.606,4.194,4.194v92.986h29.092V136.623c0-22.934-18.74-41.585-41.585-41.585
                        h-8.388l-24.451,38.015l-2.945-28.467l4.016-9.638H76.96l4.016,9.638l-3.123,28.645L53.401,95.038h-9.816
                        C20.651,95.038,2,113.778,2,136.623v101.375h29.092v-92.986H31.002z M214,76h34.415c3.072,0,5.585-2.513,5.585-5.585V7.927
                        C254,4.903,251.361,2,248.38,2h-94.717C150.548,2,148,4.548,148,7.663v63.002c0,2.947,2.389,5.336,5.336,5.336H188v9h-9.113
                        c0.028,0.02,0.113,0.04,0.113,0.047v5.513c0,0-0.162,0.44-0.113,0.44H222v-6h-8V76z M156,64V11h90v53H156z"/>
                    </svg>
                    <span>{t('app.menu.professionalMode')}</span>
                  </Link>
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveRemoveBG}>
                    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.99988 16.938V19.059L5.05851 20H2.93851L5.99988 16.938ZM22.0015 14.435V16.557L18.5595 20H17.9935L17.9939 18.443L22.0015 14.435ZM8.74988 14H15.2446C16.1628 14 16.9158 14.7071 16.9888 15.6065L16.9946 15.75V20H6.99988V15.75C6.99988 14.8318 7.70699 14.0788 8.60636 14.0058L8.74988 14ZM8.02118 10.4158C8.08088 10.9945 8.26398 11.5367 8.54372 12.0154L1.99951 18.56V16.438L8.02118 10.4158ZM22.0015 9.932V12.055L17.9939 16.065L17.9946 15.75L17.9896 15.5825C17.9623 15.128 17.8246 14.7033 17.6029 14.3348L22.0015 9.932ZM12.0565 4L1.99951 14.06V11.939L9.93551 4H12.0565ZM22.0015 5.432V7.555L16.3346 13.2245C16.0672 13.1089 15.7779 13.0346 15.4746 13.0095L15.2446 13L14.6456 13.0005C14.9874 12.6989 15.2772 12.3398 15.4999 11.9384L22.0015 5.432ZM11.9999 7.00046C13.6567 7.00046 14.9999 8.34361 14.9999 10.0005C14.9999 11.6573 13.6567 13.0005 11.9999 13.0005C10.343 13.0005 8.99988 11.6573 8.99988 10.0005C8.99988 8.34361 10.343 7.00046 11.9999 7.00046ZM7.55851 4L1.99951 9.56V7.438L5.43751 4H7.55851ZM21.0565 4L15.9091 9.14895C15.7923 8.61022 15.5669 8.11194 15.2571 7.67816L18.9345 4H21.0565ZM16.5585 4L14.0148 6.54427C13.5362 6.26463 12.9942 6.08157 12.4157 6.02181L14.4365 4H16.5585Z"
                        fill="currentColor"
                        />
                    </svg>
                    <span>{t('app.menu.removeBGMode')}</span>
                  </Link>
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveUpgradeResolution}>
                    <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                      <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    </svg>
                    <span>{t('app.menu.unBluryMode')}</span>
                  </Link>
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveMyPhoto}>
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                    </svg>
                    <span>{t('app.menu.myPhotos')}</span>
                  </Link>
                </div>
                <div className="left-down-menu">
                  {currentLanguage=== 'vi' ? 
                    (<div className="idea-alert" onClick={() => onShowReferal(true)}>
                      <div className="idea-inner">
                        <div className="ref-title">
                          <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                          </svg>
                          <span>{t('app.menu.affiliateTitle')}</span>
                        </div>
                        <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                      </div>
                    </div>)
                    : 
                    (<div className="idea-alert">
                      <a href='https://reok.lemonsqueezy.com/affiliates'>
                      <div className="idea-inner">
                        <div className="ref-title">
                          <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                          </svg>
                          <span>{t('app.menu.affiliateTitle')}</span>
                        </div>
                        <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                      </div>
                      </a>
                    </div>)
                  }
                  <div className="dropdown">
                    <NewAvatarHeader userData={userData} t={t} darkMode={darkMode} onSelectDarkMode={onSelectDarkMode}/>
                  </div>
                </div>
              </>
            </div>
          )
          :
          (
            <div>
              <div className="logo-header">
                <HeaderSticky currentLanguage={currentLanguage} isMobile={isMobile} isActiveMobileMenu={isActiveMobileMenu} openMobileMenu={openMobileMenu} onActiveProfessional={onActiveProfessional}/>
              </div>
              <div className="left-area-wrap" style={isActiveMobileMenu ? {background: "#000000bf", zIndex: 99}: {}}>
                <div className="left-area" style={isActiveMobileMenu? {left: "0"}: {}} ref={navPopupRef}>
                  <div className="sub-area">
                    <ComposeSection
                      t={t}
                      userData={userData}
                      handleModeChangeParent={handleModeChangeParent}
                      onShowPricing={onShowPricing}
                      defaultMode={mode}
                      onShowReferal={onShowReferal}
                      currentLanguage={currentLanguage}
                    />
                  </div>
                  <div className="nav-menu">
                    <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link active" onClick={onActiveProfessional}>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 240" style={{marginLeft: "2px"}}>
                        <path d="M84.635,20.256c18.383,0,33.286,14.903,33.286,33.286s-14.903,33.286-33.286,33.286S51.349,71.925,51.349,53.542
                          S66.251,20.256,84.635,20.256z M31.002,145.011c0-2.499,1.606-4.194,4.194-4.194s4.194,1.606,4.194,4.194v92.986h91.469v-92.986
                          c0-2.499,1.606-4.194,4.194-4.194c2.499,0,4.194,1.606,4.194,4.194v92.986h29.092V136.623c0-22.934-18.74-41.585-41.585-41.585
                          h-8.388l-24.451,38.015l-2.945-28.467l4.016-9.638H76.96l4.016,9.638l-3.123,28.645L53.401,95.038h-9.816
                          C20.651,95.038,2,113.778,2,136.623v101.375h29.092v-92.986H31.002z M214,76h34.415c3.072,0,5.585-2.513,5.585-5.585V7.927
                          C254,4.903,251.361,2,248.38,2h-94.717C150.548,2,148,4.548,148,7.663v63.002c0,2.947,2.389,5.336,5.336,5.336H188v9h-9.113
                          c0.028,0.02,0.113,0.04,0.113,0.047v5.513c0,0-0.162,0.44-0.113,0.44H222v-6h-8V76z M156,64V11h90v53H156z"/>
                      </svg>
                      <span>{t('app.menu.professionalMode')}</span>
                    </Link>
                    <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveRemoveBG}>
                      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99988 16.938V19.059L5.05851 20H2.93851L5.99988 16.938ZM22.0015 14.435V16.557L18.5595 20H17.9935L17.9939 18.443L22.0015 14.435ZM8.74988 14H15.2446C16.1628 14 16.9158 14.7071 16.9888 15.6065L16.9946 15.75V20H6.99988V15.75C6.99988 14.8318 7.70699 14.0788 8.60636 14.0058L8.74988 14ZM8.02118 10.4158C8.08088 10.9945 8.26398 11.5367 8.54372 12.0154L1.99951 18.56V16.438L8.02118 10.4158ZM22.0015 9.932V12.055L17.9939 16.065L17.9946 15.75L17.9896 15.5825C17.9623 15.128 17.8246 14.7033 17.6029 14.3348L22.0015 9.932ZM12.0565 4L1.99951 14.06V11.939L9.93551 4H12.0565ZM22.0015 5.432V7.555L16.3346 13.2245C16.0672 13.1089 15.7779 13.0346 15.4746 13.0095L15.2446 13L14.6456 13.0005C14.9874 12.6989 15.2772 12.3398 15.4999 11.9384L22.0015 5.432ZM11.9999 7.00046C13.6567 7.00046 14.9999 8.34361 14.9999 10.0005C14.9999 11.6573 13.6567 13.0005 11.9999 13.0005C10.343 13.0005 8.99988 11.6573 8.99988 10.0005C8.99988 8.34361 10.343 7.00046 11.9999 7.00046ZM7.55851 4L1.99951 9.56V7.438L5.43751 4H7.55851ZM21.0565 4L15.9091 9.14895C15.7923 8.61022 15.5669 8.11194 15.2571 7.67816L18.9345 4H21.0565ZM16.5585 4L14.0148 6.54427C13.5362 6.26463 12.9942 6.08157 12.4157 6.02181L14.4365 4H16.5585Z"
                          fill="currentColor"
                          />
                      </svg>
                      <span>{t('app.menu.removeBGMode')}</span>
                    </Link>
                    <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveUpgradeResolution}>
                      <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-badge-4k">
                        <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z"/>
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                      </svg>
                      <span>{t('app.menu.unBluryMode')}</span>
                    </Link>
                    <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="nav-link" onClick={onActiveMyPhoto}>
                      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3,11h7a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v7A1,1,0,0,0,3,11ZM4,4H9V9H4ZM22,3a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1ZM20,9H15V4h5ZM2,21a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H3a1,1,0,0,0-1,1Zm2-6H9v5H4Zm18,6V14a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7A1,1,0,0,0,22,21Zm-2-1H15V15h5Z" />
                      </svg>
                      <span>{t('app.menu.myPhotos')}</span>
                    </Link>
                  </div>
                  <div className="left-down-menu">
                    {currentLanguage=== 'vi' ? 
                      (<div className="idea-alert" onClick={() => onShowReferal(true)}>
                        <div className="idea-inner">
                          <div className="ref-title">
                            <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                              <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                            </svg>
                            <span>{t('app.menu.affiliateTitle')}</span>
                          </div>
                          <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                        </div>
                      </div>)
                      : 
                      (<div className="idea-alert">
                        <a href='https://reok.lemonsqueezy.com/affiliates'>
                        <div className="idea-inner">
                          <div className="ref-title">
                            <svg stroke="#ef4444" fill="#ef4444" strokeWidth="0" viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                              <path d="M160 894c0 17.7 14.3 32 32 32h286V550H160v344zm386 32h286c17.7 0 32-14.3 32-32V550H546v376zm334-616H732.4c13.6-21.4 21.6-46.8 21.6-74 0-76.1-61.9-138-138-138-41.4 0-78.7 18.4-104 47.4-25.3-29-62.6-47.4-104-47.4-76.1 0-138 61.9-138 138 0 27.2 7.9 52.6 21.6 74H144c-17.7 0-32 14.3-32 32v140h366V310h68v172h366V342c0-17.7-14.3-32-32-32zm-402-4h-70c-38.6 0-70-31.4-70-70s31.4-70 70-70 70 31.4 70 70v70zm138 0h-70v-70c0-38.6 31.4-70 70-70s70 31.4 70 70-31.4 70-70 70z"/>
                            </svg>
                            <span>{t('app.menu.affiliateTitle')}</span>
                          </div>
                          <div className="ref-des">{t('app.menu.affiliateSub')}</div>
                        </div>
                        </a>
                      </div>)
                    }
                    <div className="dropdown">
                      <NewAvatarHeader userData={userData} t={t} darkMode={darkMode} onSelectDarkMode={onSelectDarkMode}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <div className="right-area" 
            style={!isActiveDeskMenu? {left: "0", width: "100%"}: {transition: "width 0.35s ease", left: "250px", width: "calc(100% - 250px)"}} 
            id="scroll-top"
          >
            {!showMyDesign && !showReferal &&
            <div className="editor-area">
              <div className={`form-area ${darkMode? 'dark-bg dark-border-2' : 'light-bg light-border-2'}`}>
                {showProfessional && (
                  <div>
                    {/* {currentLanguage=== 'vi' &&
                    <div className="idea-alert">
                      <a href="https://noithatai.com/?ref=reok">
                      <div className="idea-inner-interior">
                        <div className="ref-title-interior">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="20"  version="1.0">
                            <defs>
                              <clipPath id="e821bab1d3">
                                <path d="M 375 187.5 C 375 291.054688 291.054688 375 187.5 375 C 83.945312 375 0 291.054688 0 187.5 C 0 83.945312 83.945312 0 187.5 0 C 291.054688 0 375 83.945312 375 187.5 Z M 375 187.5 " clipRule="nonzero"/>
                              </clipPath>
                              <clipPath id="f749ba33a6">
                                <path d="M 375 187.5 C 375 291.054688 291.054688 375 187.5 375 C 83.945312 375 0 291.054688 0 187.5 C 0 83.949219 83.945312 0 187.5 0 C 291.054688 0 375 83.949219 375 187.5 " clipRule="nonzero"/>
                              </clipPath>
                              <clipPath id="14d8dcea73">
                                <path d="M 190.335938 -187.476562 L -187.476562 184.664062 L 184.660156 562.476562 L 562.476562 190.339844 Z M 190.335938 -187.476562 " clipRule="nonzero"/>
                              </clipPath>
                              <linearGradient x1="-0.0019554" gradientTransform="matrix(371.787281, 377.462731, -377.462731, 371.787281, 2.157462, -0.6694)" y1="0" x2="0.998984" gradientUnits="userSpaceOnUse" y2="0" id="17edf9dbc5">
                                <stop stopOpacity="1" stopColor="rgb(0.00305176%, 85.894775%, 87.098694%)" offset="0"/>
                                <stop stopOpacity="1" stopColor="rgb(0.201416%, 85.696411%, 87.098694%)" offset="0.00390625"/>
                                <stop stopOpacity="1" stopColor="rgb(0.39978%, 85.499573%, 87.098694%)" offset="0.0078125"/>
                                <stop stopOpacity="1" stopColor="rgb(0.782776%, 85.115051%, 87.098694%)" offset="0.0117187"/>
                                <stop stopOpacity="1" stopColor="rgb(1.165771%, 84.732056%, 87.098694%)" offset="0.015625"/>
                                <stop stopOpacity="1" stopColor="rgb(1.60675%, 84.291077%, 87.298584%)" offset="0.0195312"/>
                                <stop stopOpacity="1" stopColor="rgb(2.047729%, 83.851624%, 87.5%)" offset="0.0234375"/>
                                <stop stopOpacity="1" stopColor="rgb(2.388%, 83.660889%, 87.5%)" offset="0.0273438"/>
                                <stop stopOpacity="1" stopColor="rgb(2.728271%, 83.470154%, 87.5%)" offset="0.03125"/>
                                <stop stopOpacity="1" stopColor="rgb(3.118896%, 83.081055%, 87.5%)" offset="0.0351563"/>
                                <stop stopOpacity="1" stopColor="rgb(3.509521%, 82.691956%, 87.5%)" offset="0.0390625"/>
                                <stop stopOpacity="1" stopColor="rgb(3.900146%, 82.348633%, 87.5%)" offset="0.0429688"/>
                                <stop stopOpacity="1" stopColor="rgb(4.290771%, 82.006836%, 87.5%)" offset="0.046875"/>
                                <stop stopOpacity="1" stopColor="rgb(4.681396%, 81.616211%, 87.649536%)" offset="0.0507813"/>
                                <stop stopOpacity="1" stopColor="rgb(5.072021%, 81.227112%, 87.799072%)" offset="0.0546875"/>
                                <stop stopOpacity="1" stopColor="rgb(5.462646%, 81.036377%, 87.799072%)" offset="0.0585938"/>
                                <stop stopOpacity="1" stopColor="rgb(5.853271%, 80.845642%, 87.799072%)" offset="0.0625"/>
                                <stop stopOpacity="1" stopColor="rgb(6.29425%, 80.404663%, 87.799072%)" offset="0.0664062"/>
                                <stop stopOpacity="1" stopColor="rgb(6.735229%, 79.963684%, 87.799072%)" offset="0.0703125"/>
                                <stop stopOpacity="1" stopColor="rgb(7.122803%, 79.573059%, 87.998962%)" offset="0.0742188"/>
                                <stop stopOpacity="1" stopColor="rgb(7.511902%, 79.182434%, 88.198853%)" offset="0.078125"/>
                                <stop stopOpacity="1" stopColor="rgb(7.853699%, 78.990173%, 88.198853%)" offset="0.0820312"/>
                                <stop stopOpacity="1" stopColor="rgb(8.197021%, 78.799438%, 88.198853%)" offset="0.0859375"/>
                                <stop stopOpacity="1" stopColor="rgb(8.587646%, 78.408813%, 88.198853%)" offset="0.0898437"/>
                                <stop stopOpacity="1" stopColor="rgb(8.978271%, 78.019714%, 88.198853%)" offset="0.09375"/>
                                <stop stopOpacity="1" stopColor="rgb(9.368896%, 77.67334%, 88.198853%)" offset="0.0976562"/>
                                <stop stopOpacity="1" stopColor="rgb(9.759521%, 77.328491%, 88.198853%)" offset="0.101562"/>
                                <stop stopOpacity="1" stopColor="rgb(10.198975%, 76.913452%, 88.398743%)" offset="0.105469"/>
                                <stop stopOpacity="1" stopColor="rgb(10.639954%, 76.499939%, 88.598633%)" offset="0.109375"/>
                                <stop stopOpacity="1" stopColor="rgb(11.030579%, 76.287842%, 88.598633%)" offset="0.113281"/>
                                <stop stopOpacity="1" stopColor="rgb(11.421204%, 76.077271%, 88.598633%)" offset="0.117188"/>
                                <stop stopOpacity="1" stopColor="rgb(11.811829%, 75.686646%, 88.598633%)" offset="0.121094"/>
                                <stop stopOpacity="1" stopColor="rgb(12.202454%, 75.296021%, 88.598633%)" offset="0.125"/>
                                <stop stopOpacity="1" stopColor="rgb(12.542725%, 74.905396%, 88.598633%)" offset="0.128906"/>
                                <stop stopOpacity="1" stopColor="rgb(12.884521%, 74.514771%, 88.598633%)" offset="0.132812"/>
                                <stop stopOpacity="1" stopColor="rgb(13.275146%, 74.307251%, 88.781738%)" offset="0.136719"/>
                                <stop stopOpacity="1" stopColor="rgb(13.665771%, 74.099731%, 88.964844%)" offset="0.140625"/>
                                <stop stopOpacity="1" stopColor="rgb(14.105225%, 73.675537%, 88.981628%)" offset="0.144531"/>
                                <stop stopOpacity="1" stopColor="rgb(14.546204%, 73.251343%, 88.999939%)" offset="0.148437"/>
                                <stop stopOpacity="1" stopColor="rgb(14.936829%, 72.86377%, 88.999939%)" offset="0.152344"/>
                                <stop stopOpacity="1" stopColor="rgb(15.327454%, 72.477722%, 88.999939%)" offset="0.15625"/>
                                <stop stopOpacity="1" stopColor="rgb(15.718079%, 72.137451%, 88.999939%)" offset="0.160156"/>
                                <stop stopOpacity="1" stopColor="rgb(16.108704%, 71.798706%, 88.999939%)" offset="0.164062"/>
                                <stop stopOpacity="1" stopColor="rgb(16.499329%, 71.603394%, 89.195251%)" offset="0.167969"/>
                                <stop stopOpacity="1" stopColor="rgb(16.889954%, 71.408081%, 89.390564%)" offset="0.171875"/>
                                <stop stopOpacity="1" stopColor="rgb(17.233276%, 71.017456%, 89.395142%)" offset="0.175781"/>
                                <stop stopOpacity="1" stopColor="rgb(17.578125%, 70.626831%, 89.399719%)" offset="0.179687"/>
                                <stop stopOpacity="1" stopColor="rgb(17.965698%, 70.236206%, 89.399719%)" offset="0.183594"/>
                                <stop stopOpacity="1" stopColor="rgb(18.353271%, 69.845581%, 89.399719%)" offset="0.1875"/>
                                <stop stopOpacity="1" stopColor="rgb(18.792725%, 69.604492%, 89.416504%)" offset="0.191406"/>
                                <stop stopOpacity="1" stopColor="rgb(19.233704%, 69.364929%, 89.433289%)" offset="0.195312"/>
                                <stop stopOpacity="1" stopColor="rgb(19.624329%, 68.974304%, 89.616394%)" offset="0.199219"/>
                                <stop stopOpacity="1" stopColor="rgb(20.014954%, 68.583679%, 89.7995%)" offset="0.203125"/>
                                <stop stopOpacity="1" stopColor="rgb(20.405579%, 68.193054%, 89.7995%)" offset="0.207031"/>
                                <stop stopOpacity="1" stopColor="rgb(20.796204%, 67.802429%, 89.7995%)" offset="0.210937"/>
                                <stop stopOpacity="1" stopColor="rgb(21.186829%, 67.460632%, 89.7995%)" offset="0.214844"/>
                                <stop stopOpacity="1" stopColor="rgb(21.577454%, 67.120361%, 89.7995%)" offset="0.21875"/>
                                <stop stopOpacity="1" stopColor="rgb(21.968079%, 66.929626%, 89.7995%)" offset="0.222656"/>
                                <stop stopOpacity="1" stopColor="rgb(22.358704%, 66.740417%, 89.7995%)" offset="0.226562"/>
                                <stop stopOpacity="1" stopColor="rgb(22.749329%, 66.299438%, 89.99939%)" offset="0.230469"/>
                                <stop stopOpacity="1" stopColor="rgb(23.139954%, 65.858459%, 90.19928%)" offset="0.234375"/>
                                <stop stopOpacity="1" stopColor="rgb(23.530579%, 65.467834%, 90.19928%)" offset="0.238281"/>
                                <stop stopOpacity="1" stopColor="rgb(23.921204%, 65.077209%, 90.19928%)" offset="0.242187"/>
                                <stop stopOpacity="1" stopColor="rgb(24.311829%, 64.886475%, 90.19928%)" offset="0.246094"/>
                                <stop stopOpacity="1" stopColor="rgb(24.702454%, 64.69574%, 90.19928%)" offset="0.25"/>
                                <stop stopOpacity="1" stopColor="rgb(25.093079%, 64.305115%, 90.19928%)" offset="0.253906"/>
                                <stop stopOpacity="1" stopColor="rgb(25.483704%, 63.91449%, 90.19928%)" offset="0.257812"/>
                                <stop stopOpacity="1" stopColor="rgb(25.874329%, 63.523865%, 90.39917%)" offset="0.261719"/>
                                <stop stopOpacity="1" stopColor="rgb(26.264954%, 63.13324%, 90.59906%)" offset="0.265625"/>
                                <stop stopOpacity="1" stopColor="rgb(26.504517%, 62.765503%, 90.59906%)" offset="0.269531"/>
                                <stop stopOpacity="1" stopColor="rgb(26.745605%, 62.399292%, 90.59906%)" offset="0.273437"/>
                                <stop stopOpacity="1" stopColor="rgb(27.133179%, 62.185669%, 90.59906%)" offset="0.277344"/>
                                <stop stopOpacity="1" stopColor="rgb(27.520752%, 61.972046%, 90.59906%)" offset="0.28125"/>
                                <stop stopOpacity="1" stopColor="rgb(27.864075%, 61.581421%, 90.603638%)" offset="0.285156"/>
                                <stop stopOpacity="1" stopColor="rgb(28.208923%, 61.190796%, 90.608215%)" offset="0.289062"/>
                                <stop stopOpacity="1" stopColor="rgb(28.598022%, 60.800171%, 90.803528%)" offset="0.292969"/>
                                <stop stopOpacity="1" stopColor="rgb(28.988647%, 60.409546%, 90.99884%)" offset="0.296875"/>
                                <stop stopOpacity="1" stopColor="rgb(29.379272%, 60.203552%, 90.99884%)" offset="0.300781"/>
                                <stop stopOpacity="1" stopColor="rgb(29.769897%, 59.999084%, 90.99884%)" offset="0.304687"/>
                                <stop stopOpacity="1" stopColor="rgb(30.160522%, 59.622192%, 90.99884%)" offset="0.308594"/>
                                <stop stopOpacity="1" stopColor="rgb(30.551147%, 59.246826%, 90.99884%)" offset="0.3125"/>
                                <stop stopOpacity="1" stopColor="rgb(30.992126%, 58.805847%, 91.19873%)" offset="0.316406"/>
                                <stop stopOpacity="1" stopColor="rgb(31.433105%, 58.364868%, 91.398621%)" offset="0.320312"/>
                                <stop stopOpacity="1" stopColor="rgb(31.820679%, 57.981873%, 91.398621%)" offset="0.324219"/>
                                <stop stopOpacity="1" stopColor="rgb(32.209778%, 57.598877%, 91.398621%)" offset="0.328125"/>
                                <stop stopOpacity="1" stopColor="rgb(32.551575%, 57.450867%, 91.398621%)" offset="0.332031"/>
                                <stop stopOpacity="1" stopColor="rgb(32.894897%, 57.302856%, 91.398621%)" offset="0.335937"/>
                                <stop stopOpacity="1" stopColor="rgb(33.285522%, 56.912231%, 91.398621%)" offset="0.339844"/>
                                <stop stopOpacity="1" stopColor="rgb(33.676147%, 56.521606%, 91.398621%)" offset="0.34375"/>
                                <stop stopOpacity="1" stopColor="rgb(34.066772%, 56.130981%, 91.577148%)" offset="0.347656"/>
                                <stop stopOpacity="1" stopColor="rgb(34.457397%, 55.740356%, 91.757202%)" offset="0.351562"/>
                                <stop stopOpacity="1" stopColor="rgb(34.898376%, 55.499268%, 91.778564%)" offset="0.355469"/>
                                <stop stopOpacity="1" stopColor="rgb(35.339355%, 55.259705%, 91.799927%)" offset="0.359375"/>
                                <stop stopOpacity="1" stopColor="rgb(35.72998%, 54.86908%, 91.799927%)" offset="0.363281"/>
                                <stop stopOpacity="1" stopColor="rgb(36.120605%, 54.478455%, 91.799927%)" offset="0.367187"/>
                                <stop stopOpacity="1" stopColor="rgb(36.51123%, 54.08783%, 91.799927%)" offset="0.371094"/>
                                <stop stopOpacity="1" stopColor="rgb(36.901855%, 53.697205%, 91.799927%)" offset="0.375"/>
                                <stop stopOpacity="1" stopColor="rgb(37.243652%, 53.497314%, 91.990662%)" offset="0.378906"/>
                                <stop stopOpacity="1" stopColor="rgb(37.586975%, 53.29895%, 92.182922%)" offset="0.382812"/>
                                <stop stopOpacity="1" stopColor="rgb(37.974548%, 52.915955%, 92.190552%)" offset="0.386719"/>
                                <stop stopOpacity="1" stopColor="rgb(38.363647%, 52.534485%, 92.199707%)" offset="0.390625"/>
                                <stop stopOpacity="1" stopColor="rgb(38.804626%, 52.14386%, 92.199707%)" offset="0.394531"/>
                                <stop stopOpacity="1" stopColor="rgb(39.245605%, 51.753235%, 92.199707%)" offset="0.398438"/>
                                <stop stopOpacity="1" stopColor="rgb(39.63623%, 51.376343%, 92.199707%)" offset="0.402344"/>
                                <stop stopOpacity="1" stopColor="rgb(40.026855%, 50.999451%, 92.199707%)" offset="0.40625"/>
                                <stop stopOpacity="1" stopColor="rgb(40.41748%, 50.794983%, 92.349243%)" offset="0.410156"/>
                                <stop stopOpacity="1" stopColor="rgb(40.808105%, 50.590515%, 92.498779%)" offset="0.414062"/>
                                <stop stopOpacity="1" stopColor="rgb(41.19873%, 50.19989%, 92.498779%)" offset="0.417969"/>
                                <stop stopOpacity="1" stopColor="rgb(41.589355%, 49.809265%, 92.498779%)" offset="0.421875"/>
                                <stop stopOpacity="1" stopColor="rgb(41.97998%, 49.41864%, 92.498779%)" offset="0.425781"/>
                                <stop stopOpacity="1" stopColor="rgb(42.370605%, 49.028015%, 92.498779%)" offset="0.429687"/>
                                <stop stopOpacity="1" stopColor="rgb(42.710876%, 48.812866%, 92.498779%)" offset="0.433594"/>
                                <stop stopOpacity="1" stopColor="rgb(43.051147%, 48.599243%, 92.498779%)" offset="0.4375"/>
                                <stop stopOpacity="1" stopColor="rgb(43.490601%, 48.18573%, 92.698669%)" offset="0.441406"/>
                                <stop stopOpacity="1" stopColor="rgb(43.93158%, 47.773743%, 92.89856%)" offset="0.445312"/>
                                <stop stopOpacity="1" stopColor="rgb(44.322205%, 47.428894%, 92.89856%)" offset="0.449219"/>
                                <stop stopOpacity="1" stopColor="rgb(44.71283%, 47.085571%, 92.89856%)" offset="0.453125"/>
                                <stop stopOpacity="1" stopColor="rgb(45.103455%, 46.694946%, 92.89856%)" offset="0.457031"/>
                                <stop stopOpacity="1" stopColor="rgb(45.49408%, 46.304321%, 92.89856%)" offset="0.460938"/>
                                <stop stopOpacity="1" stopColor="rgb(45.884705%, 46.113586%, 92.89856%)" offset="0.464844"/>
                                <stop stopOpacity="1" stopColor="rgb(46.27533%, 45.922852%, 92.89856%)" offset="0.46875"/>
                                <stop stopOpacity="1" stopColor="rgb(46.665955%, 45.532227%, 93.09845%)" offset="0.472656"/>
                                <stop stopOpacity="1" stopColor="rgb(47.05658%, 45.141602%, 93.299866%)" offset="0.476562"/>
                                <stop stopOpacity="1" stopColor="rgb(47.447205%, 44.700623%, 93.299866%)" offset="0.480469"/>
                                <stop stopOpacity="1" stopColor="rgb(47.83783%, 44.259644%, 93.299866%)" offset="0.484375"/>
                                <stop stopOpacity="1" stopColor="rgb(48.228455%, 44.068909%, 93.299866%)" offset="0.488281"/>
                                <stop stopOpacity="1" stopColor="rgb(48.61908%, 43.8797%, 93.299866%)" offset="0.492187"/>
                                <stop stopOpacity="1" stopColor="rgb(49.009705%, 43.489075%, 93.299866%)" offset="0.496094"/>
                                <stop stopOpacity="1" stopColor="rgb(49.40033%, 43.09845%, 93.301392%)" offset="0.5"/>
                                <stop stopOpacity="1" stopColor="rgb(49.790955%, 42.755127%, 93.499756%)" offset="0.503906"/>
                                <stop stopOpacity="1" stopColor="rgb(50.18158%, 42.411804%, 93.699646%)" offset="0.507812"/>
                                <stop stopOpacity="1" stopColor="rgb(50.572205%, 42.022705%, 93.699646%)" offset="0.511719"/>
                                <stop stopOpacity="1" stopColor="rgb(50.96283%, 41.635132%, 93.699646%)" offset="0.515625"/>
                                <stop stopOpacity="1" stopColor="rgb(51.403809%, 41.394043%, 93.699646%)" offset="0.519531"/>
                                <stop stopOpacity="1" stopColor="rgb(51.844788%, 41.15448%, 93.699646%)" offset="0.523438"/>
                                <stop stopOpacity="1" stopColor="rgb(52.171326%, 40.763855%, 93.711853%)" offset="0.527344"/>
                                <stop stopOpacity="1" stopColor="rgb(52.49939%, 40.37323%, 93.725586%)" offset="0.53125"/>
                                <stop stopOpacity="1" stopColor="rgb(52.702332%, 39.982605%, 93.911743%)" offset="0.535156"/>
                                <stop stopOpacity="1" stopColor="rgb(52.906799%, 39.59198%, 94.099426%)" offset="0.539062"/>
                                <stop stopOpacity="1" stopColor="rgb(53.297424%, 39.395142%, 94.099426%)" offset="0.542969"/>
                                <stop stopOpacity="1" stopColor="rgb(53.688049%, 39.199829%, 94.099426%)" offset="0.546875"/>
                                <stop stopOpacity="1" stopColor="rgb(54.078674%, 38.813782%, 94.099426%)" offset="0.550781"/>
                                <stop stopOpacity="1" stopColor="rgb(54.469299%, 38.42926%, 94.099426%)" offset="0.554688"/>
                                <stop stopOpacity="1" stopColor="rgb(54.859924%, 38.038635%, 94.274902%)" offset="0.558594"/>
                                <stop stopOpacity="1" stopColor="rgb(55.250549%, 37.64801%, 94.450378%)" offset="0.5625"/>
                                <stop stopOpacity="1" stopColor="rgb(55.690002%, 37.272644%, 94.474792%)" offset="0.566406"/>
                                <stop stopOpacity="1" stopColor="rgb(56.130981%, 36.898804%, 94.499207%)" offset="0.570312"/>
                                <stop stopOpacity="1" stopColor="rgb(56.521606%, 36.691284%, 94.499207%)" offset="0.574219"/>
                                <stop stopOpacity="1" stopColor="rgb(56.912231%, 36.485291%, 94.499207%)" offset="0.578125"/>
                                <stop stopOpacity="1" stopColor="rgb(57.254028%, 36.094666%, 94.499207%)" offset="0.582031"/>
                                <stop stopOpacity="1" stopColor="rgb(57.595825%, 35.704041%, 94.499207%)" offset="0.585937"/>
                                <stop stopOpacity="1" stopColor="rgb(57.984924%, 35.313416%, 94.68689%)" offset="0.589844"/>
                                <stop stopOpacity="1" stopColor="rgb(58.375549%, 34.922791%, 94.874573%)" offset="0.59375"/>
                                <stop stopOpacity="1" stopColor="rgb(58.766174%, 34.710693%, 94.88678%)" offset="0.597656"/>
                                <stop stopOpacity="1" stopColor="rgb(59.156799%, 34.498596%, 94.898987%)" offset="0.601562"/>
                                <stop stopOpacity="1" stopColor="rgb(59.596252%, 34.078979%, 94.898987%)" offset="0.605469"/>
                                <stop stopOpacity="1" stopColor="rgb(60.037231%, 33.660889%, 94.898987%)" offset="0.609375"/>
                                <stop stopOpacity="1" stopColor="rgb(60.427856%, 33.270264%, 94.898987%)" offset="0.613281"/>
                                <stop stopOpacity="1" stopColor="rgb(60.818481%, 32.879639%, 94.898987%)" offset="0.617187"/>
                                <stop stopOpacity="1" stopColor="rgb(61.209106%, 32.539368%, 95.098877%)" offset="0.621094"/>
                                <stop stopOpacity="1" stopColor="rgb(61.599731%, 32.199097%, 95.298767%)" offset="0.625"/>
                                <stop stopOpacity="1" stopColor="rgb(61.990356%, 32.008362%, 95.298767%)" offset="0.628906"/>
                                <stop stopOpacity="1" stopColor="rgb(62.380981%, 31.817627%, 95.298767%)" offset="0.632812"/>
                                <stop stopOpacity="1" stopColor="rgb(62.721252%, 31.427002%, 95.298767%)" offset="0.636719"/>
                                <stop stopOpacity="1" stopColor="rgb(63.063049%, 31.036377%, 95.298767%)" offset="0.640625"/>
                                <stop stopOpacity="1" stopColor="rgb(63.502502%, 30.595398%, 95.298767%)" offset="0.644531"/>
                                <stop stopOpacity="1" stopColor="rgb(63.943481%, 30.154419%, 95.298767%)" offset="0.648438"/>
                                <stop stopOpacity="1" stopColor="rgb(64.334106%, 29.963684%, 95.498657%)" offset="0.652344"/>
                                <stop stopOpacity="1" stopColor="rgb(64.724731%, 29.774475%, 95.698547%)" offset="0.65625"/>
                                <stop stopOpacity="1" stopColor="rgb(65.115356%, 29.38385%, 95.698547%)" offset="0.660156"/>
                                <stop stopOpacity="1" stopColor="rgb(65.505981%, 28.993225%, 95.698547%)" offset="0.664062"/>
                                <stop stopOpacity="1" stopColor="rgb(65.896606%, 28.6026%, 95.698547%)" offset="0.667969"/>
                                <stop stopOpacity="1" stopColor="rgb(66.287231%, 28.211975%, 95.698547%)" offset="0.671875"/>
                                <stop stopOpacity="1" stopColor="rgb(66.677856%, 27.867126%, 95.698547%)" offset="0.675781"/>
                                <stop stopOpacity="1" stopColor="rgb(67.068481%, 27.522278%, 95.698547%)" offset="0.679688"/>
                                <stop stopOpacity="1" stopColor="rgb(67.414856%, 27.334595%, 95.898438%)" offset="0.683594"/>
                                <stop stopOpacity="1" stopColor="rgb(67.76123%, 27.148438%, 96.099854%)" offset="0.6875"/>
                                <stop stopOpacity="1" stopColor="rgb(68.196106%, 26.707458%, 96.099854%)" offset="0.691406"/>
                                <stop stopOpacity="1" stopColor="rgb(68.630981%, 26.268005%, 96.099854%)" offset="0.695312"/>
                                <stop stopOpacity="1" stopColor="rgb(69.021606%, 25.87738%, 96.099854%)" offset="0.699219"/>
                                <stop stopOpacity="1" stopColor="rgb(69.412231%, 25.486755%, 96.099854%)" offset="0.703125"/>
                                <stop stopOpacity="1" stopColor="rgb(69.802856%, 25.292969%, 96.099854%)" offset="0.707031"/>
                                <stop stopOpacity="1" stopColor="rgb(70.193481%, 25.099182%, 96.099854%)" offset="0.710937"/>
                                <stop stopOpacity="1" stopColor="rgb(70.584106%, 24.711609%, 96.299744%)" offset="0.714844"/>
                                <stop stopOpacity="1" stopColor="rgb(70.974731%, 24.324036%, 96.499634%)" offset="0.71875"/>
                                <stop stopOpacity="1" stopColor="rgb(71.365356%, 23.933411%, 96.499634%)" offset="0.722656"/>
                                <stop stopOpacity="1" stopColor="rgb(71.755981%, 23.542786%, 96.499634%)" offset="0.726562"/>
                                <stop stopOpacity="1" stopColor="rgb(72.146606%, 23.120117%, 96.499634%)" offset="0.730469"/>
                                <stop stopOpacity="1" stopColor="rgb(72.537231%, 22.698975%, 96.499634%)" offset="0.734375"/>
                                <stop stopOpacity="1" stopColor="rgb(72.927856%, 22.538757%, 96.508789%)" offset="0.738281"/>
                                <stop stopOpacity="1" stopColor="rgb(73.318481%, 22.380066%, 96.517944%)" offset="0.742188"/>
                                <stop stopOpacity="1" stopColor="rgb(73.709106%, 21.989441%, 96.708679%)" offset="0.746094"/>
                                <stop stopOpacity="1" stopColor="rgb(74.099731%, 21.598816%, 96.899414%)" offset="0.75"/>
                                <stop stopOpacity="1" stopColor="rgb(74.490356%, 21.208191%, 96.899414%)" offset="0.753906"/>
                                <stop stopOpacity="1" stopColor="rgb(74.880981%, 20.817566%, 96.899414%)" offset="0.757812"/>
                                <stop stopOpacity="1" stopColor="rgb(75.271606%, 20.608521%, 96.899414%)" offset="0.761719"/>
                                <stop stopOpacity="1" stopColor="rgb(75.662231%, 20.399475%, 96.899414%)" offset="0.765625"/>
                                <stop stopOpacity="1" stopColor="rgb(76.101685%, 19.976807%, 96.920776%)" offset="0.769531"/>
                                <stop stopOpacity="1" stopColor="rgb(76.542664%, 19.555664%, 96.942139%)" offset="0.773438"/>
                                <stop stopOpacity="1" stopColor="rgb(76.930237%, 19.165039%, 97.120667%)" offset="0.777344"/>
                                <stop stopOpacity="1" stopColor="rgb(77.31781%, 18.774414%, 97.299194%)" offset="0.78125"/>
                                <stop stopOpacity="1" stopColor="rgb(77.661133%, 18.386841%, 97.299194%)" offset="0.785156"/>
                                <stop stopOpacity="1" stopColor="rgb(78.004456%, 17.999268%, 97.299194%)" offset="0.789062"/>
                                <stop stopOpacity="1" stopColor="rgb(78.395081%, 17.805481%, 97.299194%)" offset="0.792969"/>
                                <stop stopOpacity="1" stopColor="rgb(78.785706%, 17.611694%, 97.299194%)" offset="0.796875"/>
                                <stop stopOpacity="1" stopColor="rgb(78.97644%, 17.271423%, 97.436523%)" offset="0.800781"/>
                                <stop stopOpacity="1" stopColor="rgb(79.167175%, 16.931152%, 97.575378%)" offset="0.804688"/>
                                <stop stopOpacity="1" stopColor="rgb(79.5578%, 16.540527%, 97.587585%)" offset="0.808594"/>
                                <stop stopOpacity="1" stopColor="rgb(79.948425%, 16.149902%, 97.599792%)" offset="0.8125"/>
                                <stop stopOpacity="1" stopColor="rgb(80.389404%, 15.908813%, 97.599792%)" offset="0.816406"/>
                                <stop stopOpacity="1" stopColor="rgb(80.830383%, 15.667725%, 97.599792%)" offset="0.820312"/>
                                <stop stopOpacity="1" stopColor="rgb(81.221008%, 15.2771%, 97.599792%)" offset="0.824219"/>
                                <stop stopOpacity="1" stopColor="rgb(81.611633%, 14.888%, 97.599792%)" offset="0.828125"/>
                                <stop stopOpacity="1" stopColor="rgb(82.002258%, 14.497375%, 97.795105%)" offset="0.832031"/>
                                <stop stopOpacity="1" stopColor="rgb(82.392883%, 14.10675%, 97.991943%)" offset="0.835937"/>
                                <stop stopOpacity="1" stopColor="rgb(82.733154%, 13.716125%, 97.994995%)" offset="0.839844"/>
                                <stop stopOpacity="1" stopColor="rgb(83.073425%, 13.3255%, 97.999573%)" offset="0.84375"/>
                                <stop stopOpacity="1" stopColor="rgb(83.46405%, 13.134766%, 97.999573%)" offset="0.847656"/>
                                <stop stopOpacity="1" stopColor="rgb(83.854675%, 12.944031%, 97.999573%)" offset="0.851562"/>
                                <stop stopOpacity="1" stopColor="rgb(84.295654%, 12.553406%, 97.999573%)" offset="0.855469"/>
                                <stop stopOpacity="1" stopColor="rgb(84.736633%, 12.162781%, 97.999573%)" offset="0.859375"/>
                                <stop stopOpacity="1" stopColor="rgb(85.127258%, 11.772156%, 98.199463%)" offset="0.863281"/>
                                <stop stopOpacity="1" stopColor="rgb(85.517883%, 11.381531%, 98.399353%)" offset="0.867188"/>
                                <stop stopOpacity="1" stopColor="rgb(85.908508%, 11.18927%, 98.399353%)" offset="0.871094"/>
                                <stop stopOpacity="1" stopColor="rgb(86.299133%, 10.998535%, 98.399353%)" offset="0.875"/>
                                <stop stopOpacity="1" stopColor="rgb(86.688232%, 10.60791%, 98.399353%)" offset="0.878906"/>
                                <stop stopOpacity="1" stopColor="rgb(87.078857%, 10.218811%, 98.399353%)" offset="0.882812"/>
                                <stop stopOpacity="1" stopColor="rgb(87.423706%, 9.828186%, 98.399353%)" offset="0.886719"/>
                                <stop stopOpacity="1" stopColor="rgb(87.770081%, 9.437561%, 98.399353%)" offset="0.890625"/>
                                <stop stopOpacity="1" stopColor="rgb(88.204956%, 9.017944%, 98.599243%)" offset="0.894531"/>
                                <stop stopOpacity="1" stopColor="rgb(88.641357%, 8.599854%, 98.799133%)" offset="0.898438"/>
                                <stop stopOpacity="1" stopColor="rgb(89.031982%, 8.387756%, 98.799133%)" offset="0.902344"/>
                                <stop stopOpacity="1" stopColor="rgb(89.422607%, 8.175659%, 98.799133%)" offset="0.90625"/>
                                <stop stopOpacity="1" stopColor="rgb(89.813232%, 7.833862%, 98.799133%)" offset="0.910156"/>
                                <stop stopOpacity="1" stopColor="rgb(90.203857%, 7.493591%, 98.799133%)" offset="0.914063"/>
                                <stop stopOpacity="1" stopColor="rgb(90.594482%, 7.102966%, 98.799133%)" offset="0.917969"/>
                                <stop stopOpacity="1" stopColor="rgb(90.985107%, 6.712341%, 98.799133%)" offset="0.921875"/>
                                <stop stopOpacity="1" stopColor="rgb(91.375732%, 6.504822%, 98.999023%)" offset="0.925781"/>
                                <stop stopOpacity="1" stopColor="rgb(91.766357%, 6.298828%, 99.198914%)" offset="0.929688"/>
                                <stop stopOpacity="1" stopColor="rgb(92.112732%, 5.924988%, 99.198914%)" offset="0.933594"/>
                                <stop stopOpacity="1" stopColor="rgb(92.460632%, 5.551147%, 99.198914%)" offset="0.9375"/>
                                <stop stopOpacity="1" stopColor="rgb(92.893982%, 5.110168%, 99.198914%)" offset="0.941406"/>
                                <stop stopOpacity="1" stopColor="rgb(93.328857%, 4.669189%, 99.198914%)" offset="0.945312"/>
                                <stop stopOpacity="1" stopColor="rgb(93.719482%, 4.283142%, 99.205017%)" offset="0.949219"/>
                                <stop stopOpacity="1" stopColor="rgb(94.110107%, 3.898621%, 99.211121%)" offset="0.953125"/>
                                <stop stopOpacity="1" stopColor="rgb(94.500732%, 3.701782%, 99.404907%)" offset="0.957031"/>
                                <stop stopOpacity="1" stopColor="rgb(94.891357%, 3.50647%, 99.598694%)" offset="0.960937"/>
                                <stop stopOpacity="1" stopColor="rgb(95.281982%, 3.115845%, 99.598694%)" offset="0.964844"/>
                                <stop stopOpacity="1" stopColor="rgb(95.672607%, 2.72522%, 99.598694%)" offset="0.96875"/>
                                <stop stopOpacity="1" stopColor="rgb(96.063232%, 2.384949%, 99.598694%)" offset="0.972656"/>
                                <stop stopOpacity="1" stopColor="rgb(96.453857%, 2.044678%, 99.598694%)" offset="0.976562"/>
                                <stop stopOpacity="1" stopColor="rgb(96.890259%, 1.803589%, 99.617004%)" offset="0.980469"/>
                                <stop stopOpacity="1" stopColor="rgb(97.32666%, 1.5625%, 99.635315%)" offset="0.984375"/>
                                <stop stopOpacity="1" stopColor="rgb(97.671509%, 1.171875%, 99.816895%)" offset="0.988281"/>
                                <stop stopOpacity="1" stopColor="rgb(98.016357%, 0.782776%, 100%)" offset="0.992188"/>
                                <stop stopOpacity="1" stopColor="rgb(98.406982%, 0.392151%, 100%)" offset="0.996094"/>
                                <stop stopOpacity="1" stopColor="rgb(98.797607%, 0.00152588%, 100%)" offset="1"/>
                              </linearGradient>
                              <clipPath id="0eb7e04366">
                                <path d="M 37.5 67 L 95 67 L 95 141.332031 L 37.5 141.332031 Z M 37.5 67 " clipRule="nonzero"/>
                              </clipPath>
                              <clipPath id="e652d1edac">
                                <path d="M 116 50.582031 L 156.75 50.582031 L 156.75 105 L 116 105 Z M 116 50.582031 " clipRule="nonzero"/>
                              </clipPath>
                            </defs>
                            <g clipPath="url(#e821bab1d3)">
                              <g clipPath="url(#f749ba33a6)">
                                <g clipPath="url(#14d8dcea73)">
                                  <path fill="url(#17edf9dbc5)" d="M 190.339844 -187.476562 L -187.476562 184.660156 L 184.660156 562.476562 L 562.476562 190.339844 Z M 190.339844 -187.476562 " fillRule="nonzero"/>
                                </g>
                              </g>
                            </g>
                            <path fill="#ffffff" d="M 253.167969 192.695312 C 248.574219 192.695312 244.851562 196.417969 244.851562 201.011719 L 244.851562 267.230469 L 211.644531 267.230469 L 211.644531 227.3125 C 211.644531 222.71875 207.921875 218.996094 203.328125 218.996094 L 171.027344 218.996094 C 166.4375 218.996094 162.710938 222.71875 162.710938 227.3125 L 162.710938 267.230469 L 129.507812 267.230469 L 129.507812 201.011719 C 129.507812 196.417969 125.785156 192.695312 121.191406 192.695312 C 116.597656 192.695312 112.875 196.417969 112.875 201.011719 L 112.875 275.546875 C 112.875 280.140625 116.597656 283.863281 121.191406 283.863281 L 171.027344 283.863281 C 175.621094 283.863281 179.347656 280.140625 179.347656 275.546875 L 179.347656 235.628906 L 195.011719 235.628906 L 195.011719 275.546875 C 195.011719 280.140625 198.734375 283.863281 203.328125 283.863281 L 253.167969 283.863281 C 257.757812 283.863281 261.484375 280.140625 261.484375 275.546875 L 261.484375 201.011719 C 261.484375 196.417969 257.757812 192.695312 253.167969 192.695312 " fillOpacity="1" fillRule="nonzero"/>
                            <path fill="#ffffff" d="M 290.066406 190.257812 L 253.386719 153.578125 L 253.386719 123.964844 C 253.386719 119.371094 249.660156 115.648438 245.066406 115.648438 C 240.476562 115.648438 236.753906 119.371094 236.753906 123.964844 L 236.753906 136.941406 L 193.382812 93.570312 C 190.132812 90.324219 184.867188 90.324219 181.621094 93.570312 L 84.9375 190.257812 C 81.6875 193.503906 81.6875 198.769531 84.9375 202.015625 C 88.183594 205.265625 93.449219 205.265625 96.699219 202.015625 L 187.5 111.214844 L 239.164062 162.878906 C 239.179688 162.894531 239.195312 162.910156 239.210938 162.925781 L 278.300781 202.015625 C 279.925781 203.640625 282.054688 204.453125 284.183594 204.453125 C 286.308594 204.453125 288.441406 203.640625 290.066406 202.015625 C 293.3125 198.769531 293.3125 193.503906 290.066406 190.257812 " fillOpacity="1" fillRule="nonzero"/>
                            <g clipPath="url(#0eb7e04366)">
                              <path fill="#ffffff" d="M 92.394531 102.355469 C 71.191406 102.214844 67.40625 99.695312 67.242188 68.453125 C 67.238281 67.664062 66.597656 67.027344 65.8125 67.027344 C 65.023438 67.027344 64.382812 67.667969 64.378906 68.453125 C 64.21875 99.695312 60.429688 102.214844 39.226562 102.355469 C 38.273438 102.363281 37.5 103.136719 37.5 104.078125 C 37.5 105.023438 38.273438 105.796875 39.226562 105.800781 C 60.429688 105.945312 64.21875 108.464844 64.378906 139.703125 C 64.382812 140.492188 65.023438 141.132812 65.808594 141.132812 C 66.597656 141.132812 67.238281 140.492188 67.242188 139.707031 C 67.402344 108.464844 71.191406 105.945312 92.394531 105.804688 C 93.347656 105.796875 94.121094 105.023438 94.121094 104.082031 C 94.121094 103.136719 93.347656 102.363281 92.394531 102.355469 Z M 92.394531 102.355469 " fillOpacity="1" fillRule="nonzero"/>
                            </g>
                            <g clipPath="url(#e652d1edac)">
                              <path fill="#ffffff" d="M 155.757812 76.472656 C 140.324219 76.371094 137.289062 74.359375 137.171875 51.515625 C 137.167969 51.085938 136.820312 50.738281 136.394531 50.738281 C 135.964844 50.738281 135.617188 51.085938 135.617188 51.515625 C 135.5 74.359375 132.464844 76.371094 117.03125 76.472656 C 116.484375 76.476562 116.035156 76.921875 116.035156 77.464844 C 116.035156 78.003906 116.484375 78.449219 117.03125 78.453125 C 132.464844 78.554688 135.5 80.566406 135.617188 103.414062 C 135.617188 103.839844 135.96875 104.1875 136.394531 104.1875 C 136.820312 104.1875 137.167969 103.839844 137.171875 103.414062 C 137.289062 80.566406 140.324219 78.554688 155.757812 78.453125 C 156.304688 78.449219 156.75 78.003906 156.75 77.464844 C 156.75 76.921875 156.304688 76.476562 155.757812 76.472656 Z M 155.757812 76.472656 " fillOpacity="1" fillRule="nonzero"/>
                            </g>
                            <path fill="#ffffff" d="M 115.664062 117.6875 C 114.691406 120.53125 113.035156 122.660156 110.882812 123.816406 C 113.035156 124.96875 114.691406 127.097656 115.664062 129.945312 C 116.636719 127.097656 118.292969 124.96875 120.449219 123.816406 C 118.292969 122.660156 116.636719 120.53125 115.664062 117.6875 Z M 115.664062 117.6875 " fillOpacity="1" fillRule="nonzero"/>
                            <path fill="#ffffff" d="M 87.453125 72.179688 C 88.425781 69.332031 90.082031 67.203125 92.238281 66.050781 C 90.082031 64.894531 88.425781 62.769531 87.453125 59.921875 C 86.480469 62.769531 84.824219 64.898438 82.671875 66.050781 C 84.824219 67.203125 86.484375 69.332031 87.453125 72.179688 Z M 87.453125 72.179688 " fillOpacity="1" fillRule="nonzero"/>
                          </svg>
                          <span>Thiết kế nhà với AI thử ngay 🏡</span>
                        </div>
                      </div>
                      </a>
                    </div>
                    } */}
                    <div className="generate-form">
                      {!isMobile ? (
                      <div className='flex align-items-center'>
                        <span className="cursor-pointer" style={{color: "#888888"}} onClick={hideMenuBar}>
                          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-layout-sidebar-inset">
                            <path d="M21 3a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1 -1.5 1.5H3a1.5 1.5 0 0 1 -1.5 -1.5V4.5a1.5 1.5 0 0 1 1.5 -1.5zM3 1.5a3 3 0 0 0 -3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3 -3V4.5a3 3 0 0 0 -3 -3z"/>
                            <path d="M4.5 6a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1 -1.5 1.5H6a1.5 1.5 0 0 1 -1.5 -1.5z"/>
                          </svg>
                        </span>
                        <h3 className={`mb-0 pl-4 ${darkMode? 'text-white' : 'text-black'}`}>{t('app.menu.professionalMode')} 📸</h3>
                      </div>
                      ) : (
                      <h3 className={`${darkMode? 'text-white' : 'text-black'}`}>{t('app.menu.professionalMode')} 📸</h3>
                      )}
                      <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                        {t('app.professionalMode.title')} 
                      </div>
                      {/* <hr className="section-line"/> */}
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <label className={`${darkMode? 'text-white' : 'text-black'}`}>1. {t('app.professionalMode.selectPhotographer')}</label>
                      <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "4px"}}>
                        {t('app.professionalMode.selectPhotographerSub')}
                      </div>
                      <div className="d-flex flex-column">
                        <div className="upload-title">
                          {photographerSrc && (
                            <div 
                              className="red-color"
                              onClick={removePhotographer} style={{ marginTop: '-25px', cursor: "pointer" }}
                            >
                              <svg
                                style={{pointerEvents: "none"}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                              >
                                <path d="M3 6L5 6 21 6"></path>
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                              </svg>
                            </div>
                          )}
                        </div>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        {!photographerSrc && (
                          <div
                            className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border light-bg-input'}`}
                            onClick={handlePhotographer}
                          >
                            <div className="uploader-title">
                              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                              <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                            </div>
                          </div>
                        )}
                        {photographerSrc && (
                          <div 
                            className={`photographer-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                            onClick={handlePhotographer}
                          >
                            <div className="flex align-items-center">
                              <img src={photographerSrc} alt="Photographer Image" className="photographer-image" />
                              <span 
                                className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                              >
                                {photographerName}
                              </span>
                            </div>
                            <span className="detail-btn">
                              <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                                <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z"/>
                              </svg>
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-5" />
                        {/* <hr className="section-line"/> */}
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        <label className={`${darkMode? 'text-white' : 'text-black'}`}>2. {t('app.professionalMode.requirements')}</label>
                        <div style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "4px"}}>
                          {t('app.professionalMode.guideSub')}
                      </div>
                      {photographerCode === photographerStyle && (
                        <>
                          <div className="photographer-guide">
                            <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('home.uploadTitle')}</label>
                            <div className="sample-selfie-wrapper">
                              <div className="good-sample-selfie-wrapper">
                                <div>{t('app.goodSelfie')}</div>
                                <img src="/images/good-selfie-woman.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851110669131846/1.webp", 'woman')}/>
                                <img src="/images/good-selfie-man.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111092752435/2.webp", 'man')}/>
                              </div>
                              <div className="bad-sample-selfie-wrapper">
                                <div>{t('app.badSelfie')}</div>
                                <img src="/images/bad-selfie-woman.webp" className="sample-image" onClick={(event) => chooseBadSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851112334270524/4.webp", 'woman')}/>
                                <img src="/images/bad-selfie-man.webp" className="sample-image" onClick={(event) => chooseBadSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111700942928/3.webp", 'man')}/>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="upload-title">
                                {imageSrc && (
                                  <div onClick={removeUploadedImage} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                    <svg
                                      style={{pointerEvents: "none"}}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                    >
                                      <path d="M3 6L5 6 21 6"></path>
                                      <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                    </svg>
                                  </div>
                                )}
                              </div>
                              <div className="page-header mb-0 page-header-reset mb-2" />
                              {!imageSrc && (
                                <>
                                <div
                                  className={`uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                  onClick={handleOpenFileUpload}
                                  onDragOver={handleDrag}
                                  onDragEnter={handleDrag}
                                  onDragLeave={handleDrag}
                                  onDrop={handleDrop}
                                >
                                  <div className="uploader-title">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="icon"
                                      role="presentation"
                                    >
                                      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                                      <polyline points="17 8 12 3 7 8" />
                                      <line x1="12" y1="3" x2="12" y2="15" />
                                    </svg>
                                    <span style={{ marginLeft: '4px' }}>
                                      {t('home.uploadPlaceHolder')}
                                    </span>
                                  </div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                  />
                                </div>
                              </>
                              )}
                              {imageSrc && (
                                <>
                                  <div className="uploaded-wrapper">
                                    <div>
                                      <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-4" />
                            <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.genderTitle')}</label>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            <FormControl sx={{ width: '100%'}}>
                              <Select
                                sx={{
                                  border: darkMode? '1px solid #5d5d5d' : '',
                                  borderRadius: "8px",
                                  background: darkMode? '#2a2a2a' : '',
                                  color: darkMode? 'white' : 'black',
                                  "& .MuiSvgIcon-root": {
                                    color: darkMode? 'white' : 'black',
                                  },
                                  fontSize: "14px"
                                }}
                                value={currentGender} 
                                onChange={handleChangeGender} 
                                autoWidth
                              >
                                {genders.map((c, i) => {
                                  return (
                                    <MenuItem key={i} value={c.value}>
                                      {t(`genders.${c.label}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <div className="page-header mb-0 page-header-reset mb-4" />
                            <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.styleTitle')}</label>
                            <div className="d-flex flex-column">
                              <div className="upload-title">
                                {styleSrc && (
                                  <div onClick={removeUploadedStyle} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                    <svg
                                      style={{pointerEvents: "none"}}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                    >
                                      <path d="M3 6L5 6 21 6"></path>
                                      <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                    </svg>
                                  </div>
                                )}
                              </div>
                              <div className="page-header mb-0 page-header-reset mb-2" />
                              {!styleSrc && (
                                <div
                                  className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                  onClick={handleStyleUpload}
                                >
                                  <div className="uploader-title">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                                  </div>
                                </div>
                              )}
                              {styleSrc && (
                                <div className={`photographer-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}                                onClick={handleStyleUpload}>
                                  <div className="flex align-items-center">
                                    <img src={styleSrc} alt="Style Image" className="photographer-image" />
                                    {promptStyle !== "" ? 
                                    <span 
                                      className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                    >{styleName}</span>
                                    :
                                    <span 
                                    className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                    >{t(`styles.${styleCode}`)}</span>
                                    }
                                  </div>
                                  <span className="detail-btn">
                                    <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                                      <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z"/>
                                    </svg>
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-4" />
                            <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.backgroundTitle')}</label>
                            <div className="d-flex flex-column">
                              <div className="upload-title">
                                {backgroundSrc && (
                                  <div onClick={removeUploadedBackground} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                    <svg
                                      style={{pointerEvents: "none"}}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                    >
                                      <path d="M3 6L5 6 21 6"></path>
                                      <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                    </svg>
                                  </div>
                                )}
                              </div>
                              <div className="page-header mb-0 page-header-reset mb-2" />
                              {!backgroundSrc && (
                                <div
                                  className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                  onClick={handleBackgroundUpload}
                                >
                                  <div className="uploader-title">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                                  </div>
                                </div>
                              )}
                              {backgroundSrc && (
                                <div className={`photographer-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}                                onClick={handleBackgroundUpload}>
                                  <div className="flex align-items-center">
                                    <img src={backgroundSrc} alt="Style Image" className="photographer-image" />
                                    {promptBackground !== "" ? 
                                    <span 
                                      className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                    >{backgroundName}</span>
                                    :
                                    <span
                                      className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                    >{t(`backgrounds.${backgroundCode}`)}</span>
                                    }
                                  </div>
                                  <span className="detail-btn">
                                    <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                                      <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z"/>
                                    </svg>
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-4" />
                            <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.quantityTitle')}</label>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            <FormControl sx={{ width: '100%' }}>
                              <Select 
                                sx={{
                                  border: darkMode? '1px solid #5d5d5d' : '',
                                  borderRadius: "8px",
                                  background: darkMode? '#2a2a2a' : '',
                                  color: darkMode? 'white' : 'black',
                                  "& .MuiSvgIcon-root": {
                                    color: darkMode? 'white' : 'black',
                                  },
                                  fontSize: "14px"
                                }}
                                value={currentResult}
                                onChange={handleChangeResult}
                                autoWidth
                              >
                                {results.map((c, i) => {
                                  return (
                                    <MenuItem key={i} value={c.value}>
                                      {t(`results.${c.label}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      )
                      }
                      {photographerCode === photographerPose && (
                        <>
                        <div className="photographer-guide">
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('home.uploadTitle')}</label>
                          <div className="sample-selfie-wrapper">
                            <div className="good-sample-selfie-wrapper">
                              <div>{t('app.goodSelfie')}</div>
                              <img src="/images/good-selfie-woman.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851110669131846/1.webp", 'woman')}/>
                              <img src="/images/good-selfie-man.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111092752435/2.webp", 'man')}/>
                            </div>
                            <div className="bad-sample-selfie-wrapper">
                              <div>{t('app.badSelfie')}</div>
                              <img src="/images/bad-selfie-woman.webp" className="sample-image" onClick={(event) => chooseBadSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851112334270524/4.webp", 'woman')}/>
                              <img src="/images/bad-selfie-man.webp" className="sample-image" onClick={(event) => chooseBadSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111700942928/3.webp", 'man')}/>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="upload-title">
                              {imageSrc && (
                                <div onClick={removeUploadedImage} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                  <svg
                                    style={{pointerEvents: "none"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                  >
                                    <path d="M3 6L5 6 21 6"></path>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            {!imageSrc && (
                              <>
                              <div
                                className={`uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                onClick={handleOpenFileUpload}
                                onDragOver={handleDrag}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDrop={handleDrop}
                              >
                                <div className="uploader-title">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon"
                                    role="presentation"
                                  >
                                    <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                                    <polyline points="17 8 12 3 7 8" />
                                    <line x1="12" y1="3" x2="12" y2="15" />
                                  </svg>
                                  <span style={{ marginLeft: '4px' }}>
                                    {t('home.uploadPlaceHolder')}
                                  </span>
                                </div>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileUpload}
                                  style={{ display: 'none' }}
                                  ref={fileInputRef}
                                />
                              </div>
                            </>
                            )}
                            {imageSrc && (
                              <>
                                <div className="uploaded-wrapper">
                                  <div>
                                    <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="page-header mb-0 page-header-reset mb-4" />
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.genderTitle')}</label>
                          <div className="page-header mb-0 page-header-reset mb-2" />
                          <FormControl sx={{ width: '100%'}}>
                            <Select
                              sx={{
                                border: darkMode? '1px solid #5d5d5d' : '',
                                borderRadius: "8px",
                                background: darkMode? '#2a2a2a' : '',
                                color: darkMode? 'white' : 'black',
                                "& .MuiSvgIcon-root": {
                                  color: darkMode? 'white' : 'black',
                                },
                                fontSize: "14px"
                              }}
                              value={currentGender} 
                              onChange={handleChangeGender} 
                              autoWidth
                            >
                              {genders.map((c, i) => {
                                return (
                                  <MenuItem key={i} value={c.value}>
                                    {t(`genders.${c.label}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <div className="page-header mb-0 page-header-reset mb-4" />
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.styleTitle')}</label>
                          <div className="d-flex flex-column">
                            <div className="upload-title">
                              {styleSrc && (
                                <div onClick={removeUploadedStyle} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                  <svg
                                    style={{pointerEvents: "none"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                  >
                                    <path d="M3 6L5 6 21 6"></path>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            {!styleSrc && (
                              <div
                                className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                onClick={handleStyleUpload}
                              >
                                <div className="uploader-title">
                                  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                                </div>
                              </div>
                            )}
                            {styleSrc && (
                              <div className={`photographer-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}                                onClick={handleStyleUpload}>
                                <div className="flex align-items-center">
                                  <img src={styleSrc} alt="Style Image" className="photographer-image" />
                                  {promptStyle !== "" ? 
                                  <span 
                                    className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                  >{styleName}</span>
                                  :
                                  <span 
                                  className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                  >{t(`styles.${styleCode}`)}</span>
                                  }
                                </div>
                                <span className="detail-btn">
                                  <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z"/>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="page-header mb-0 page-header-reset mb-4" />
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.backgroundTitle')}</label>
                          <div className="d-flex flex-column">
                            <div className="upload-title">
                              {backgroundSrc && (
                                <div onClick={removeUploadedBackground} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                  <svg
                                    style={{pointerEvents: "none"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                  >
                                    <path d="M3 6L5 6 21 6"></path>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            {!backgroundSrc && (
                              <div
                                className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                onClick={handleBackgroundUpload}
                              >
                                <div className="uploader-title">
                                  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  <span style={{ marginLeft: '4px' }}>{t('home.stylePlaceHolder')}</span>
                                </div>
                              </div>
                            )}
                            {backgroundSrc && (
                              <div className={`photographer-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}                                onClick={handleBackgroundUpload}>
                                <div className="flex align-items-center">
                                  <img src={backgroundSrc} alt="Style Image" className="photographer-image" />
                                  {promptBackground !== "" ? 
                                  <span 
                                    className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                  >{backgroundName}</span>
                                  :
                                  <span
                                    className={`photographer-name ${darkMode? 'text-white' : 'text-black'}`}
                                  >{t(`backgrounds.${backgroundCode}`)}</span>
                                  }
                                </div>
                                <span className="detail-btn">
                                  <svg fill="#757575" width="20px" height="20px" viewBox="-2.8 0 12.8 12.8" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m1.6 8.8 2.4 -2.4 -2.4 -2.4 0.8 -0.8 3.2 3.2L2.4 9.6l-0.8 -0.8Z"/>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="page-header mb-0 page-header-reset mb-4" />
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.poseTitle')}</label>
                          <div className="d-flex flex-column">
                            <div className="upload-title">
                              {poseSrc && (
                                <div onClick={removeUploadedPose} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                                  <svg
                                    style={{pointerEvents: "none"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                                  >
                                    <path d="M3 6L5 6 21 6"></path>
                                    <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div className="page-header mb-0 page-header-reset mb-2" />
                            {!poseSrc && (
                              <div
                                className={`pose-uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                onClick={handleOpenPoseUpload}
                              >
                                <div className="uploader-title">
                                  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z" stroke="#8c8c8c" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  <span style={{ marginLeft: '4px' }}>{t('home.uploadPosePlaceHolder')}</span>
                                </div>
                              </div>
                            )}
                            {poseSrc && (
                              <>
                                <div 
                                  className={`uploaded-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                                  onClick={handleOpenPoseUpload}>
                                  <div>
                                    <img src={poseSrc} alt="Uploaded Image" className="uploaded-image" />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="page-header mb-0 page-header-reset mb-4" />
                          <label className={`${darkMode? 'text-white' : 'text-black'}`}>+ {t('app.professionalMode.quantityTitle')}</label>
                          <div className="page-header mb-0 page-header-reset mb-2" />
                          <FormControl sx={{ width: '100%' }}>
                            <Select 
                              sx={{
                                border: darkMode? '1px solid #5d5d5d' : '',
                                borderRadius: "8px",
                                background: darkMode? '#2a2a2a' : '',
                                color: darkMode? 'white' : 'black',
                                "& .MuiSvgIcon-root": {
                                  color: darkMode? 'white' : 'black',
                                },
                                fontSize: "14px"
                              }}
                              value={currentResult}
                              onChange={handleChangeResult}
                              autoWidth
                            >
                              {results.map((c, i) => {
                                return (
                                  <MenuItem key={i} value={c.value}>
                                    {t(`results.${c.label}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </>
                      )
                      }
                    </div>
                    <div 
                      // className="down-action-area"
                      className={`down-action-area ${darkMode? 'dark-bg' : 'light-bg'}`}
                    >
                      <div className="create-imagine-button generate-btn">
                        <Button onClick={onDesignProfessional} type="submit" className="generate-button w-button" loading={isCreating}>
                          <span style={{ marginLeft: '4px' }}>
                            {t('button.professional')}
                            <span> {'['}- <svg style={{marginTop: "-2px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.764 291.764" width="12px"><g><path fill="#EFC75E" fillOpacity="1" d="M145.882,0c80.573,0,145.882,65.319,145.882,145.882s-65.31,145.882-145.882,145.882 S0,226.446,0,145.882S65.31,0,145.882,0z"></path><path fill="#CC9933" fillOpacity="1" d="M145.882,27.353c-65.465,0-118.529,53.065-118.529,118.529s53.065,118.529,118.529,118.529 s118.529-53.065,118.529-118.529S211.347,27.353,145.882,27.353z M145.882,246.176c-55.39,0-100.294-44.904-100.294-100.294 S90.493,45.588,145.882,45.588s100.294,44.904,100.294,100.294S201.281,246.176,145.882,246.176z M158.009,138.269 c-5.452-2.289-25.493-5.452-25.493-14.214c0-6.464,7.495-8.334,11.99-8.334c4.094,0,8.999,1.295,11.589,3.875 c1.641,1.577,2.316,2.726,2.854,4.313c0.684,1.869,1.094,3.875,3.684,3.875h13.357c3.136,0,3.957-0.574,3.957-4.021 c0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-0.821-4.167-3.957-4.167h-10.64c-3.136,0-3.957,1.577-3.957,4.167v11.051 c-14.178,2.726-26.031,11.634-26.031,27.718c0,18.235,12.683,23.979,26.441,28.566c11.589,3.884,23.724,4.021,23.724,12.063 s-5.99,9.765-13.357,9.765c-5.051,0-10.631-1.304-13.366-4.741c-1.769-2.152-2.453-4.021-2.58-5.89 c-0.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136,0-3.957,0.729-3.957,4.021c0,16.366,13.093,26.286,27.262,29.441v11.206 c0,2.58,0.821,4.167,3.957,4.167h10.64c3.136,0,3.957-1.586,3.957-4.167v-10.905c16.084-2.453,27.125-12.209,27.125-29.441 C182.28,145.591,167.829,141.424,158.009,138.269z"></path></g></svg> {credits}{']'}</span>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {showRemoveBG && (
                  <div>
                    <div className="generate-form">
                      {/* <h3>{t('home.uploadTitle')}</h3> */}
                      <h3 className={`${darkMode? 'text-white' : 'text-black'}`}>{t('app.menu.removeBGMode')}</h3>
                      <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                        {t('app.removeBGSub')}
                      </p>
                      <div className="d-flex flex-column">
                        <div className="upload-title">
                          {imageSrc && (
                            <div onClick={removeUploadedImage} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                              <svg
                                style={{pointerEvents: "none"}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                              >
                                <path d="M3 6L5 6 21 6"></path>
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                              </svg>
                            </div>
                          )}
                        </div>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        {!imageSrc && (
                          <>
                          <div
                            className={`uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                            onClick={handleOpenFileUpload}
                            onDragOver={handleDrag}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDrop={handleDrop}
                          >
                            <div className="uploader-title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon"
                                role="presentation"
                              >
                                <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                                <polyline points="17 8 12 3 7 8" />
                                <line x1="12" y1="3" x2="12" y2="15" />
                              </svg>
                              <span style={{ marginLeft: '4px' }}>
                                {t('home.uploadPlaceHolder')}
                              </span>
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileUpload}
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                            />
                          </div>
                        </>
                        )}
                        {imageSrc && (
                          <>
                            <div
                              className={`uploaded-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                            >
                              <div>
                                <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <div className="sample-image-wrapper">
                        <p>{t('app.tryThese')}</p>
                        <img src="/images/good-selfie-woman.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851110669131846/1.webp", 'woman')}/>
                        <img src="/images/good-selfie-man.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111092752435/2.webp", 'man')}/>
                        {/* <img src="https://cdn.discordapp.com/attachments/1105800665105711166/1153973336351723560/showcase.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1153973336351723560/showcase.webp", 'woman')}/> */}
                        {/* <img src="https://cdn.discordapp.com/attachments/1105800665105711166/1153975176392888330/showcase.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1153975176392888330/showcase.webp", 'man')}/> */}
                      </div>
                    </div>
                    <div 
                      // className="down-action-area"
                      className={`down-action-area ${darkMode? 'dark-bg' : 'light-bg'}`}
                    >
                    <div className="create-imagine-button generate-btn">
                      <Button onClick={onRemoveBG} type="submit" className="generate-button w-button" loading={isCreating}>
                        <span style={{ marginLeft: '4px' }}>
                          {t('button.removeBG')}
                          <span> {'['}- <svg style={{marginTop: "-2px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.764 291.764" width="12px"><g><path fill="#EFC75E" fillOpacity="1" d="M145.882,0c80.573,0,145.882,65.319,145.882,145.882s-65.31,145.882-145.882,145.882 S0,226.446,0,145.882S65.31,0,145.882,0z"></path><path fill="#CC9933" fillOpacity="1" d="M145.882,27.353c-65.465,0-118.529,53.065-118.529,118.529s53.065,118.529,118.529,118.529 s118.529-53.065,118.529-118.529S211.347,27.353,145.882,27.353z M145.882,246.176c-55.39,0-100.294-44.904-100.294-100.294 S90.493,45.588,145.882,45.588s100.294,44.904,100.294,100.294S201.281,246.176,145.882,246.176z M158.009,138.269 c-5.452-2.289-25.493-5.452-25.493-14.214c0-6.464,7.495-8.334,11.99-8.334c4.094,0,8.999,1.295,11.589,3.875 c1.641,1.577,2.316,2.726,2.854,4.313c0.684,1.869,1.094,3.875,3.684,3.875h13.357c3.136,0,3.957-0.574,3.957-4.021 c0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-0.821-4.167-3.957-4.167h-10.64c-3.136,0-3.957,1.577-3.957,4.167v11.051 c-14.178,2.726-26.031,11.634-26.031,27.718c0,18.235,12.683,23.979,26.441,28.566c11.589,3.884,23.724,4.021,23.724,12.063 s-5.99,9.765-13.357,9.765c-5.051,0-10.631-1.304-13.366-4.741c-1.769-2.152-2.453-4.021-2.58-5.89 c-0.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136,0-3.957,0.729-3.957,4.021c0,16.366,13.093,26.286,27.262,29.441v11.206 c0,2.58,0.821,4.167,3.957,4.167h10.64c3.136,0,3.957-1.586,3.957-4.167v-10.905c16.084-2.453,27.125-12.209,27.125-29.441 C182.28,145.591,167.829,141.424,158.009,138.269z"></path></g></svg> 2{']'}</span>
                        </span>
                      </Button>
                    </div>
                  </div>
                  </div>
                )}
                {showUpgradeResolution && (
                  <div>
                    <div className="generate-form">
                      <h3 className={`${darkMode? 'text-white' : 'text-black'}`}>{t('app.menu.unBluryMode')}</h3>
                      <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0"}}>
                        {t('app.upscaleMode.title')}
                      </p>
                      <div className="d-flex flex-column">
                        <div className="upload-title">
                          {imageSrc && (
                            <div onClick={removeUploadedImage} style={{ marginTop: '-25px', cursor: "pointer" }} className='red-color'>
                              <svg
                                style={{pointerEvents: "none"}}
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash text-[#9ca3af] hover:text-white transition"
                              >
                                <path d="M3 6L5 6 21 6"></path>
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                              </svg>
                            </div>
                          )}
                        </div>
                        <div className="page-header mb-0 page-header-reset mb-2" />
                        {!imageSrc && (
                          <>
                          <div
                            className={`uploader-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                            onClick={handleOpenFileUpload}
                            onDragOver={handleDrag}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDrop={handleDrop}
                          >
                            <div className="uploader-title">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon"
                                role="presentation"
                              >
                                <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                                <polyline points="17 8 12 3 7 8" />
                                <line x1="12" y1="3" x2="12" y2="15" />
                              </svg>
                              <span style={{ marginLeft: '4px' }}>
                                {t('home.uploadPlaceHolder')}
                              </span>
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileUpload}
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                            />
                          </div>
                        </>
                        )}
                        {imageSrc && (
                          <>
                            <div
                              className={`uploaded-wrapper ${darkMode? 'dark-border dark-bg-input' : 'light-border'}`}
                            >
                              <div>
                                <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="page-header mb-0 page-header-reset mb-4" />
                      <div className="sample-image-wrapper">
                        <p>{t('app.tryThese')}</p>
                        <img src="/images/good-selfie-woman.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851110669131846/1.webp", 'woman')}/>
                        <img src="/images/good-selfie-man.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1198851111092752435/2.webp", 'man')}/>
                        {/* <img src="https://cdn.discordapp.com/attachments/1105800665105711166/1153970701825486878/showcase.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1153970701825486878/showcase.webp", 'woman')}/> */}
                        {/* <img src="https://cdn.discordapp.com/attachments/1105800665105711166/1153974161786544238/showcase.webp" className="sample-image" onClick={(event) => chooseSampleProfessional(event, "https://cdn.discordapp.com/attachments/1105800665105711166/1153974161786544238/showcase.webp", 'man')}/> */}
                      </div>
                    </div>
                    <div 
                      // className="down-action-area"
                      className={`down-action-area ${darkMode? 'dark-bg' : 'light-bg'}`}
                    >
                    <div className="create-imagine-button generate-btn">
                      <Button onClick={onBeauty} type="submit" className="generate-button w-button" loading={isCreating}>
                        <span style={{ marginLeft: '4px' }}>
                          {t('button.upscale')}
                          <span> {'['}- <svg style={{marginTop: "-2px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.764 291.764" width="12px"><g><path fill="#EFC75E" fillOpacity="1" d="M145.882,0c80.573,0,145.882,65.319,145.882,145.882s-65.31,145.882-145.882,145.882 S0,226.446,0,145.882S65.31,0,145.882,0z"></path><path fill="#CC9933" fillOpacity="1" d="M145.882,27.353c-65.465,0-118.529,53.065-118.529,118.529s53.065,118.529,118.529,118.529 s118.529-53.065,118.529-118.529S211.347,27.353,145.882,27.353z M145.882,246.176c-55.39,0-100.294-44.904-100.294-100.294 S90.493,45.588,145.882,45.588s100.294,44.904,100.294,100.294S201.281,246.176,145.882,246.176z M158.009,138.269 c-5.452-2.289-25.493-5.452-25.493-14.214c0-6.464,7.495-8.334,11.99-8.334c4.094,0,8.999,1.295,11.589,3.875 c1.641,1.577,2.316,2.726,2.854,4.313c0.684,1.869,1.094,3.875,3.684,3.875h13.357c3.136,0,3.957-0.574,3.957-4.021 c0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-0.821-4.167-3.957-4.167h-10.64c-3.136,0-3.957,1.577-3.957,4.167v11.051 c-14.178,2.726-26.031,11.634-26.031,27.718c0,18.235,12.683,23.979,26.441,28.566c11.589,3.884,23.724,4.021,23.724,12.063 s-5.99,9.765-13.357,9.765c-5.051,0-10.631-1.304-13.366-4.741c-1.769-2.152-2.453-4.021-2.58-5.89 c-0.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136,0-3.957,0.729-3.957,4.021c0,16.366,13.093,26.286,27.262,29.441v11.206 c0,2.58,0.821,4.167,3.957,4.167h10.64c3.136,0,3.957-1.586,3.957-4.167v-10.905c16.084-2.453,27.125-12.209,27.125-29.441 C182.28,145.591,167.829,141.424,158.009,138.269z"></path></g></svg> 2{']'}</span>
                        </span>
                      </Button>
                    </div>
                  </div>
                  </div>
                )}
              </div>
              <AllShowCase
                userData={userData}
                checkCredits={checkCredits}
                firebase={firebase}
                currentJob={currentJob}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                progress={progress}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                onUpImageResolution={handleFileUploadFromLink}
                onRemoveBG={handleOnRemoveBG}
                handleSelectCollection={handleSelectCollection}
                darkMode={darkMode}
              />
            </div>
            }
            {showMyDesign && (
              <MyDesign
                userData={userData}
                checkCredits={checkCredits}
                firebase={firebase}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                onUpImageResolution={handleFileUploadFromLink}
                onRemoveBG={handleOnRemoveBG}
                darkMode={darkMode}
              />
            )}
            {showReferal && (
              <Referal
                firebase={firebase}
                t={t}
                jobsList={jobsList}
                isCreating={isCreating}
                onGenFill={onGenFill}
                onFetchUser={onFetchUser}
                userData={userData}
              />
            )}
          </div>
        </div>
      )}
      {isShowPricing && currentLanguage === 'vi' && <Pricing t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} onShowPricing={onShowPricing} onFetchUser={onFetchUser} />}
      {isShowPricing && currentLanguage !== 'vi' && <NewPricing t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} onShowPricing={onShowPricing} onFetchUser={onFetchUser} />}
      {isSpinning && <Spinner />}
      {showGenFillEditor && (
        <EditImage
          checkCredits={checkCredits}
          imageUrl={imageUrlEditor}
          onClose={onCloseImageEdit}
          firebase={firebase}
          t={t}
          onGenFill={onGenFill}
          onChangeImageSrc={onChangeImageSrc}
          onFetchUser={onFetchUser}
        />
      )}
      {isShowPose && <Pose t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} onShowPose={onShowPose} onFetchUser={onFetchUser} posesList={currentPoses} uploadedPosesList={uploadedPosesList} handleSelectPose={handleSelectPose} handleSetPoseFile={handleSetPoseFile} onShowPricing={onShowPricing}/>}
      {isShowBackground && <Background t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} onShowBackground={onShowBackground} onFetchUser={onFetchUser} backgroundsList={backgrounds} handleSelectBackground={handleSelectBackground} onShowPricing={onShowPricing} submitPrompt={submitPromptBG} promptBG={promptBackground}/>}
      {isShowStyle && <Style t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} promptStyle={promptStyle} onShowStyle={onShowStyle} onFetchUser={onFetchUser} stylesList={styles} handleSelectStyle={handleSelectStyle} onShowPricing={onShowPricing} submitPrompt={submitPromptStyle}/>}
      {isShowPhotographer && <Photographer photographerInfo={photographerInfo} t={t} currentLanguage={currentLanguage} userData={userData} firebase={firebase} onShowStyle={onShowPhotographer} onFetchUser={onFetchUser} handleSelectPhotographer={handleSelectPhotographer} onShowPricing={onShowPricing}/>}
    </div>
  );
};

export default NewHome;
