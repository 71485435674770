import React, { useEffect, useState } from 'react';
import {
  HomeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

import { Gradient } from "../AdminHeader/Gradient.js";
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';
import LovedBy from '../LovedBy';

const gradient = new Gradient();

const Terms = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const {t} = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    gradient.initGradient("#canvas-gradient");
    window.scrollTo(0, 0);

    return () => {
    };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <main>
        <div className="landing-cover" data-controller="gradient-animation">
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              <h1 className="display-1 fw-semi-bold text-white text-center mb-5 mb-sm-4">{t('terms.title')}</h1>
              <Link
                to={i18n.language === "en" ? "/" : `/${i18n.language}/`}
                className="btn btn-soft-light btn-sm rounded-pill tag text-nowrap"
              >
                <HomeOutlined />{t('button.backHome')}
              </Link>
            </div>
          </div>
          <canvas id="canvas-gradient" data-transition-in />
          <div id="canvas-overlay" />
        </div>
      </main>
      <div className="page-header mb-0 page-header-reset mb-4" />
      <div className="container mb-6">
        <div>
          <ul className="terms">
            <li>
              <h3>{t('tos.one')}</h3>
              <p>
              {t('tos.oneDetail')}
              </p>
            </li>
            <li>
              <h3>{t('tos.two')}</h3>
              <p>
              {t('tos.twoDetail')}
              </p>
            </li>
            <li>
              <h3>{t('tos.three')}</h3>
              <p>
              {t('tos.threeDetail')}
              </p>
            </li>
            {/* <li>
              <h3>{t('tos.four')}</h3>
              <p>
              {t('tos.fourDetail')}
              </p>
            </li> */}
            <li>
              <h3>{t('tos.five')}</h3>
              <p>
              {t('tos.fiveDetail')}
              </p>
            </li>
            <li>
              <h3>{t('tos.six')}</h3>
              <p>
              {t('tos.sixDetail')}
              </p>
            </li>
            <li>
              <h3>{t('tos.seven')}</h3>
              <p>
              {t('tos.sevenDetail')}
              </p>
            </li>
            {/* <li>
              <h3>{t('tos.eight')}</h3>
              <p>
              {t('tos.eightDetail')}
              </p>
            </li> */}
          </ul>
        </div>
      </div>
      <section>
        <div className="relative bg-neutral-black section-bottom-90">
          <div className="flex flex-col align-items-center section-top-90">
            <BeforeAfterTestimonial/>
            <div className="getstarted-btn-wrapper mt-4">
              <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                {t('button.try')}
              </Link>
            </div>
            <div className="text-white mt-4">
              <LovedBy t={t}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
