import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Editing from '../Loading';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};
const APP_URL = process.env.REACT_APP_APP_URL;

const InputForm = ({ onSubmit }) => {
  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    if (text.length > 0 && text.length <= 300) { // Set your desired character limit (e.g., 100 characters)
      onSubmit(text);
    } else {
      alert("Đầu vào trống hoặc sai định dạng hoặc đang vượt quá 100 từ");
    }
  };

  return (
    <>
      <div className="flexbox">
        <div className="chat-box">
          <div className="chat-box-footer">
            {/* <textarea id="chat_input" placeholder="Bạn muốn thay đổi như nào?" autoFocus={false} defaultValue={""} onChange={handleInputChange}/> */}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '27ch' },
              }}
              noValidate
              autoComplete="off"
            >
                <TextField
                  id="outlined-textarea"
                  label="Bạn muốn thay đổi gì?"
                  placeholder="Miêu tả phong cách bạn muốn"
                  maxRows={3}
                  multiline
                  onChange={handleInputChange}
                />
            </Box>
            <button id="send" onClick={handleSubmit}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.6 5.613C7.91 5.466 6.98 4.874 6.484 3.7c-.179-.423-.304-.917-.384-1.5 0-.1-.1-.2-.2-.2s-.2.1-.2.2c-.08.583-.205 1.077-.384 1.5C4.821 4.874 3.891 5.466 2.2 5.613c-.1 0-.2.1-.2.2s.1.2.2.2c2.1.4 3.2 1.187 3.5 3.387 0 .1.1.2.2.2s.2-.1.2-.2c.3-2.2 1.4-2.987 3.5-3.387.1 0 .2-.1.2-.2s-.1-.2-.2-.2ZM19.469 11.865c-4-.8-5.726-2.73-6.526-6.629a.493.493 0 0 0-.474-.371.493.493 0 0 0-.475.376c-.009.006.007-.015 0 0-.8 4-2.625 5.824-6.525 6.624a.5.5 0 0 0 0 1c4 .8 5.717 2.687 6.517 6.587a.493.493 0 0 0 .483.413.493.493 0 0 0 .477-.387c-.005.01.006-.008 0 0 .8-4 2.623-5.813 6.523-6.613a.5.5 0 0 0 0-1ZM21.465 5.8c0-.084-.061-.14-.144-.156l-.056-.013c-1.168-.305-1.876-1.024-2.073-2.108a.153.153 0 0 0-.153-.153v.004c-.084 0-.14.062-.156.144l-.013.056c-.305 1.168-1.024 1.876-2.108 2.073a.153.153 0 0 0-.153.153h.004c0 .084.062.14.145.156l.055.013c1.168.305 1.876 1.024 2.073 2.108 0 .084.069.153.153.153v-.004c.084 0 .14-.062.156-.145l.014-.055c.304-1.168 1.023-1.876 2.107-2.073a.15.15 0 0 0 .15-.153ZM7.919 18.715c-1-.3-1.582-.782-1.782-1.782a.218.218 0 1 0-.436 0c-.3 1-.782 1.582-1.782 1.782a.218.218 0 0 0 0 .436c1 .3 1.582.782 1.782 1.782a.218.218 0 0 0 .436 0c.3-1 .782-1.582 1.782-1.782a.218.218 0 0 0 0-.436Z" fill="#000"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const Lasso = ({imageUrl, firebase, t, onGenFill, onClose, onChangeImageSrc, onFetchUser, checkCredits}) => {
  const canvasRef = useRef(null);
  const isDrawing = useRef(false);
  const start = useRef(null);
  const points = useRef([]);
  const seats = useRef([]);
  const image = useRef(null);
  const [isLineMounted, setIsLineMounted] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const handleMouseDown = (e) => {
      setIsLineMounted(false);
      isDrawing.current = true;
      ClearSelection();
      const canvasRect = canvas.getBoundingClientRect();
      start.current = {
        x: e.clientX - canvasRect.left,
        y: e.clientY - canvasRect.top
      };
      points.current = [];
      points.current.push(start.current);
      RenderSelection();
    };
    
    const handleMouseMove = (e) => {
      if (!isDrawing.current) {
        return;
      }
      const canvasRect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - canvasRect.left;
      const mouseY = e.clientY - canvasRect.top;
      points.current.push({ x: mouseX, y: mouseY });
      Render();
    };    

    const handleMouseUp = (e) => {
      let inputForm = document.querySelector('#inputForm');

      isDrawing.current = false;
      points.current.push(start.current);
      SelectSeats();
      Render();

      if (points.current.length <= 2) {
        setIsLineMounted(false);
        return;
      }

      // show input and summit button here
      setIsLineMounted(true);

      if (window.innerWidth > 700) {
        // Set the input form position based on the start coordinates
        inputForm.style.left = start.current.x + 'px';
        inputForm.style.top = start.current.y + 60 + 'px';
        inputForm.style.width = "25%";
        inputForm.style.position = 'absolute';
      } else {
        inputForm.style.left = '50%';
        inputForm.style.top = '50%';
        inputForm.style.width = "25%";
        inputForm.style.position = 'absolute';
        inputForm.style.transform = 'translate(-50%, -50%)';
      }

      // Prevent the default mouse down behavior
      e.preventDefault();
    };

    const handleTouchStart = (e) => {
      setIsLineMounted(false);
      isDrawing.current = true;
      ClearSelection();
      const canvasRect = canvas.getBoundingClientRect();
      const touch = e.touches[0];

      start.current = {
        x: touch.clientX - canvasRect.left,
        y: touch.clientY - canvasRect.top
      };
      points.current = [];
      points.current.push(start.current);
      RenderSelection();
    };
    
    const handleTouchMove = (e) => {
      if (!isDrawing.current) {
        return;
      }
      const canvasRect = canvas.getBoundingClientRect();
      const touch = e.touches[0];
      const mouseX = touch.clientX - canvasRect.left;
      const mouseY = touch.clientY - canvasRect.top;
      points.current.push({ x: mouseX, y: mouseY });
      Render();
    };
    
    const handleTouchEnd = (e) => {
      let inputForm = document.querySelector('#inputForm');
    
      isDrawing.current = false;
      points.current.push(start.current);
      SelectSeats();
      Render();
    
      if (points.current.length <= 2) {
        setIsLineMounted(false);
        return;
      }
    
      // Show input and submit button here
      setIsLineMounted(true);
    
      if (window.innerWidth > 700) {
        // Set the input form position based on the start coordinates
        inputForm.style.left = start.current.x + 'px';
        inputForm.style.top = start.current.y + 'px';
        inputForm.style.width = '25%';
        inputForm.style.position = 'absolute';
      } else {
        inputForm.style.left = '50%';
        inputForm.style.top = '50%';
        inputForm.style.width = '75%';
        inputForm.style.position = 'absolute';
        inputForm.style.transform = 'translate(-50%, -50%)';
      }
    }

    const ClearSelection = () => {
      for (let index = 0; index < seats.current.length; index++) {
        const seat = seats.current[index];
        seat.selected = false;
      }
    };

    const SelectSeats = () => {
      for (let index = 0; index < seats.current.length; index++) {
        const seat = seats.current[index];
        SetSelection(seat);
      }
    };

    const SetSelection = (seat) => {
      if (points.current.length <= 1) {
        return;
      }
      seat.selected = false;
      let intercessionCount = 0;
      for (let index = 1; index < points.current.length; index++) {
        const start = points.current[index - 1];
        const end = points.current[index];
        const line = { start: start, end: end };

        // Bounding box intersection test

        // ...

        intercessionCount++;
      }

      if (intercessionCount === 0) {
        seat.selected = false;
        return;
      }

      if (intercessionCount & 1) {
        seat.selected = true;
      } else {
        seat.selected = false;
      }
    };

    const Render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image.current, 0, 0, canvas.width, canvas.height); // Draw image with fixed width of 700px
      RenderSelection();
    };

    const RenderSelection = () => {
      if (points.current.length <= 1) {
        return;
      }

      ctx.setLineDash([5, 3]);
      ctx.strokeStyle = 'white';
      ctx.fillStyle = 'rgba(0,0,0,0.0)';
      ctx.lineWidth = 2;
      ctx.beginPath();
      for (let index = 0; index < points.current.length; index++) {
        const point = points.current[index];
        if (index === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      }

      // ctx.lineTo(start.current.x, start.current.y);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
    };

    const Setup = () => {
      image.current = new Image();
      image.current.src = imageUrl;
      image.current.crossOrigin = `Anonymous`;
      image.current.onload = () => {
        const imageWidth = image.current.width;
        const imageHeight = image.current.height;

        const ratio = imageWidth/imageHeight;

        // if (ratio < 1) {
        //   canvas.width = window.innerHeight * ratio;
        //   canvas.height = window.innerHeight;
        // } else if (image.current.width > 700){
        //   canvas.width = 700;
        //   canvas.height = 700/ratio;
        // } else {
        //   const canvasWidth = window.innerWidth;
        //   const canvasHeight = window.innerWidth * ratio;
        //   canvas.width = canvasWidth;
        //   canvas.height = canvasHeight;
        // }

        if ( window.innerWidth > 700) {
          canvas.width = 700;
          canvas.height = 700/ratio;
          if (ratio < 1) {
            canvas.width = window.innerHeight * ratio;
            canvas.height = window.innerHeight;
          }
        } else {
          if (ratio < 1) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerWidth/ratio;
          } else {
            const canvasWidth = window.innerWidth;
            const canvasHeight = window.innerWidth/ratio;
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
          }
        }

        Render();
      };

      for (let x = 0; x < 15; x++) {
        for (let y = 0; y < 15; y++) {
          const positionX = (x * 15) + 200;
          const positionY = (y * 15) + 200;
          seats.current.push({ x: positionX, y: positionY, selected: false });
        }
      }
    };

    Setup();

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    //for mobile
    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouchMove);
    canvas.addEventListener('touchend', handleTouchEnd);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);

      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
    };
  }, [imageUrl]);

  const handleSubmitForm = (text) => {

    //If not enough credits
    if (checkCredits()) return;

    setIsEditing(true);
    // setIsLineMounted(false);

    const canvas = canvasRef.current;
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');

    // Set the dimensions of the temporary canvas to match the original canvas
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;

    // Draw the original image onto the temporary canvas
    tempCtx.drawImage(canvas, 0, 0);

    // Perform other operations on the temporary canvas
    tempCtx.fillStyle = 'black';
    tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    tempCtx.fillStyle = 'white';
    tempCtx.beginPath();

    for (let index = 0; index < points.current.length; index++) {
      const point = points.current[index];
      if (index === 0) {
        tempCtx.moveTo(point.x, point.y);
      } else {
        tempCtx.lineTo(point.x, point.y);
      }
    }

    tempCtx.closePath();
    tempCtx.fill();

    // Export the filled image from the temporary canvas as a Blob
    tempCanvas.toBlob((blob) => {
      const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
      const fileName = `mask-noithatai-up-${randomNumber}.jpg`;

      // Set the type and lastModified properties
      const fileInfo = new File([blob], fileName, {
        type: 'image/jpeg', // Specify the appropriate MIME type for your file
        lastModified: new Date().getTime(), // Set the timestamp to the current time
      });

      firebase.uploadFile(fileInfo)
      .then(async (downloadURL) => {
        const maskUrl = downloadURL;

        if (!maskUrl) return;

          //add to new job
          const jobData = {
            status: 'starting',
            mode: modes.pro,
            uid: firebase.auth.currentUser.uid,
            prompt: text,
            upscaleUrl: "",
            originalUrl: imageUrl,
            maskUrl: maskUrl,
            createdAt: new Date().toISOString(),
          };

          firebase
            .addJob(jobData)
            .then(async result => {
              const uid = result.id; // Retrieve the UID from the id property
              
              //call api to excute the job
              const data = {
                jobId: uid,
                packageName: modes.pro,
                width: canvas.width,
                height: canvas.height
              }

              const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
              };

              const response = await axios.post(`${APP_URL}/gen-fill`, data, { headers })
              const jobId = response.data.id;
      
              while (true) {
                const response = await axios.get(`${APP_URL}/job/${jobId}`, {}, { headers });
      
                if (response.data.status === "finished") {
                  // Result is finished, you can access it using response.data.output or perform further actions
                  let finishedImage = response.data.image;

                  const newImages = {
                    before: imageUrl,
                    after: finishedImage,
                  };
            
                  const newJob = {
                    images: newImages,
                    prompt: text,
                  };
  
                  onChangeImageSrc(finishedImage);
                  onGenFill(newJob);
  
                  setTimeout(() => {
                    setIsEditing(false);

                    firebase.updateJob(uid, {upscaleUrl: finishedImage});
                    onFetchUser();
                  }, 1000);
                  return;
                } else if (response.data.status === "failed") {
                  // Job failed, handle the failure case
                  message.error(t('message.error.busy'));
                  setIsEditing(false);
                  return;
                }
                // If the job is not yet finished, wait for some time before making the next request
                await new Promise((resolve) => setTimeout(resolve, 2000));
              }
            })
            .catch(err => {
              message.error(err.message);
              setIsEditing(false);
            });
      })
      .catch((error) => {
        message.error(t('message.error.busy'));
        setIsEditing(false);
        // Handle the error
      });

    }, 'image/jpeg');
  };

  return (
  <div>
    <canvas className="canvas-container" ref={canvasRef} />
    <div id="inputForm">
      {isLineMounted && (
        <InputForm onSubmit={handleSubmitForm} />
      )}
    </div>
    { isEditing && <Editing title="AI đang thiết kế"/> }
  </div>
)};

export default Lasso;
