import React from 'react';

const styleList = [
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F1.webp?alt=media&token=26235d66-6991-455f-aaf4-980fe00d6380",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F2.webp?alt=media&token=8285e52e-3a00-4d63-97c8-18d7d5b67818",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F3.webp?alt=media&token=2480eecf-3184-491d-b246-52647011429a",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F4.webp?alt=media&token=01a5dcb7-73c7-4b6a-aefb-f9b17eefa3ff",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F5.webp?alt=media&token=4c8a56b8-7987-44a5-ac72-6b2b3c76db0e",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F21.webp?alt=media&token=1bbbf094-73f7-47e3-83f7-8eef4b5f8560",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F7.webp?alt=media&token=8904284f-3c95-497d-ba1e-c307c1def557",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F8.webp?alt=media&token=4a1fd521-27cf-4d23-8f64-0d298c1f1349",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F9.webp?alt=media&token=e9f8bf0b-e586-4a1f-abd3-02a944d0ddae",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F10.webp?alt=media&token=5154a971-615b-4f93-9c14-94787b0fc6c2",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F11.webp?alt=media&token=a620443b-9e45-4620-8166-92d30bdcafee",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F12.webp?alt=media&token=9d6c3b43-a620-4507-8391-1363bcc624e4",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F13.webp?alt=media&token=8f3f9399-c0e8-45db-b4d4-cdd693cbd890",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F14.webp?alt=media&token=d73737c4-5d5e-4a40-aa8e-e73e9844de5b",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F15.webp?alt=media&token=2f5af1d4-d30b-4192-a302-e2161996371c",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F16.webp?alt=media&token=65c4082f-68fd-4086-ae5f-0c5b20c244d4",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F17.webp?alt=media&token=3de69132-1466-46d5-8d39-4c53c1a502e4",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F18.webp?alt=media&token=91dc60a8-4793-433d-a0d8-7a11ae588350",
    title: ""
  },
  {
    before: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F19.webp?alt=media&token=b834b152-6bce-4376-a01d-32e4e61162c5",
    after: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/generatedStyle%2F20.webp?alt=media&token=9c4afbea-1aa8-4bdf-82a2-2f0ea56c0e95",
    title: ""
  },
]

const BeforeAfterTestimonial = ({ images}) => {

  return (
    <div className="relative">
      <div className="slider-in group relative grid slider-in-motions">
        <div className="relative mx-auto grid h-full w-full max-w-[2200px] py-1.5 grid-cols-2">
          <div className="relative col-span-1 overflow-hidden">
            <div className="space-y-8 md:space-y-[85px]">
              <div className="relative mx-auto max-w-[2200px] overflow-hidden">
                <div className="infinite-scroll-16 -mt-4 flex max-h-[780px] min-w-[3400px] overflow-hidden lg:min-w-[4100px]">
                  <div className="slider-running-wrapper">
                    {styleList.map((c, i) => {
                        return (
                          <div className="relative" key={i}>
                            <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                              <div className="rnftg-item__img-wrapper_landing">
                                <div className="result-box">
                                  <img
                                    className="banner-video"
                                    alt="reok"
                                    src={c.before}
                                  ></img>
                                </div>
                              </div>
                            </article>
                          </div>                          
                        )
                    })}
                  </div>
                  <div className="slider-running-wrapper">
                    {styleList.map((c, i) => {
                        return (
                          <div className="relative" key={i}>
                            <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                              <div className="rnftg-item__img-wrapper_landing">
                                <div className="result-box">
                                  <img
                                    className="banner-video"
                                    alt="reok"
                                    src={c.before}
                                  ></img>
                                </div>
                              </div>
                            </article>
                          </div>                          
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute left-1/2 top-0 z-10 h-full w-3 -translate-x-1/2 rounded-sd border-2 transition-opacity md:w-3.5"
            style={{
              borderRadius: "10px",
              background: "#fff",
              borderWidth: "2px",
              border: "2px solid rgb(255 202 230)"
            }}
          />
          <div className="relative col-span-1 overflow-hidden">
            <div className="space-y-8 md:space-y-[85px]">
              <div className="relative mx-auto max-w-[2200px] overflow-hidden">
                <div className="slider-in-right infinite-scroll-16 -mt-4 flex max-h-[780px] min-w-[3400px] overflow-hidden lg:min-w-[4100px]">
                  <div className="slider-running-wrapper-right">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  alt="reok"
                                  src={c.after}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>                          
                      )
                    })}
                  </div>
                  <div className="slider-running-wrapper-right">
                    {styleList.map((c, i) => {
                      return (
                        <div className="relative" key={i}>
                          <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-transition rnftg-duration-300">
                            <div className="rnftg-item__img-wrapper_landing">
                              <div className="result-box">
                                <img
                                  className="banner-video"
                                  alt="reok"
                                  src={c.after}
                                ></img>
                              </div>
                            </div>
                          </article>
                        </div>                          
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
};

export default BeforeAfterTestimonial;
