import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import * as routes from '../constants/routes';
import i18n from '../i18n';

const AuthRoute = ({ user }) => {
  return (!user) ?
    <>
      <Outlet />
    </> : <Navigate to={i18n.language === "en" ? "/app" : `/${i18n.language}/app`} replace />;
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AuthRoute);
