import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import vi from './vi';
import id from './id';
import jp from './jp';
import zh from './zh';
import ko from './ko';

// Initialize i18n with the options object
i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for React
    },
    resources: {
      en: { translation: en },
      vi: { translation: vi },
      id: { translation: id },
      jp: { translation: jp },
      zh: { translation: zh },
      ko: { translation: ko }
    }
  });

export default i18n;
