import React, { useState, useEffect } from 'react';
import { Col, Row, message } from 'antd';
import axios from 'axios';
import {
  FormGroup,
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Select,
  InputLabel,
  FormHelperText,
  Button,
} from '@mui/material';
import Spinner from '../Spinner';

const APP_URL = process.env.REACT_APP_APP_URL;

const AgencyForm = ({ image, onClose, firebase, t, productsList, userData }) => {
  const [loading, setLoading] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [currentProvince, setCurrentProvince] = useState('');
  const [currentProvinceLabel, setCurrentProvinceLabel] = useState('');
  const [currentDistrict, setCurrentDistrict] = useState('');
  const [currentDistrictLabel, setCurrentDistrictLabel] = useState('');
  const [currentWard, setCurrentWard] = useState('');
  const [currentWardLabel, setCurrentWardLabel] = useState('');
  const [constructionChecked, setConstructionChecked] = useState(false);
  const [buyFurnitureChecked, setBuyFurnitureChecked] = useState(false);
  const [buyDecorItemsChecked, setBuyDecorItemsChecked] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [formError, setFormError] = useState({
    demandChecked: false,
    firstName: false,
    email: false,
    phoneNumber: false,
    detailAddress: false,
    currentProvince: false,
    currentDistrict: false,
    currentWard: false,
  });

  let countPoint = 15;
  const [count, setCount] = useState(countPoint); // Timer

  useEffect(async () => {
    if (userData.firstName !== (null || undefined)) {
      setFirstName(userData.firstName);
    }
    if (userData.lastName !== (null || undefined)) {
      setLastName(userData.lastName);
    }
    if (userData.email !== (null || undefined)) {
      setEmail(userData.email);
    }
    if (userData.phoneNumber !== (null || undefined)) {
      setPhoneNumber(userData.phoneNumber);
    }
    if (provinces.length <= 0) {
      const headers = {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
      };

      const result = await axios.get(`${APP_URL}/child-division-list`, {}, { headers });
      const convertedData = result.data.data.divisions.map(item => ({
        value: item.id,
        label: item.division_name,
      }));

      setProvinces(convertedData);
    }

    return () => {};
  }, []);

  function handleChangeFirstName(event) {
    setFormError(prevData => ({
      ...prevData,
      firstName: false,
    }));
    setFirstName(event.target.value);
  }

  function handleChangeLastName(event) {
    setLastName(event.target.value);
  }

  function handleChangeEmail(event) {
    setFormError(prevData => ({
      ...prevData,
      email: false,
    }));
    setEmail(event.target.value);
  }

  function handleChangePhoneNumber(event) {
    setFormError(prevData => ({
      ...prevData,
      phoneNumber: false,
    }));
    setPhoneNumber(event.target.value);
  }

  function handleChangeDetailAddress(event) {
    setFormError(prevData => ({
      ...prevData,
      detailAddress: false,
    }));
    setDetailAddress(event.target.value);
  }

  function isValidPhoneNumber(phoneNumber) {
    console.log(phoneNumber[0])
    if (phoneNumber[0] === '0' || phoneNumber[0] === '8') {
      return true;
    }
    return false;
}

  function handleSubmit(e) {
    e.preventDefault();
    if (
      (!constructionChecked && !buyFurnitureChecked && !buyDecorItemsChecked) ||
      firstName === '' ||
      firstName === null ||
      email === '' ||
      email === null ||
      phoneNumber === '' ||
      phoneNumber === null ||
      detailAddress === '' ||
      detailAddress === null ||
      currentProvince === '' ||
      currentProvince === null ||
      currentDistrict === '' ||
      currentDistrict === null ||
      currentWard === '' ||
      currentWard === null
    ) {
      setFormError({
        demandChecked: !constructionChecked && !buyFurnitureChecked && !buyDecorItemsChecked ? true : false,
        firstName: firstName === '' || firstName === null ? true : false,
        email: email === '' || email === null ? true : false,
        phoneNumber: phoneNumber === '' || phoneNumber === null || !isValidPhoneNumber(phoneNumber) ? true : false,
        detailAddress: detailAddress === '' || detailAddress === null ? true : false,
        currentProvince: currentProvince === '' || currentProvince === null ? true : false,
        currentDistrict: currentDistrict === '' || currentDistrict === null ? true : false,
        currentWard: currentWard === '' || currentWard === null ? true : false,
      });
      return;
    }

    setLoading(true);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const data = {
      "construction": constructionChecked,
      "furniture": buyFurnitureChecked,
      "decorItems": buyDecorItemsChecked,
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "phoneNumber": phoneNumber,
      "currentProvince": currentProvinceLabel,
      "currentDistrict": currentDistrictLabel,
      "currentWard": currentWardLabel,
      "detailAddress": detailAddress,
      "uid": firebase.auth.currentUser.uid,
      "imageUrl": image
    };

    axios.post(`${APP_URL}/request-info`, { data: data },{ headers })
    .then(response => {
      if (response.data.status === "reach-limit") {
        alert("Vui lòng hoàn thành các thiết kế của bạn trước khi tạo yêu cầu mới");
        onClose();
        return;
      }
      // Handle the response data
      setShowApproved(true);
      setLoading(false);
    })
    .catch(error => {
      message.error(t('message.error.busy'));
      setLoading(false);
    });

    const timer = setInterval(() => {
      if (countPoint > 0) {
        countPoint --;
        setCount(countPoint);
      } else {
        onClose(true);
        // Perform any action needed when the timer is done
        clearInterval(timer); // Clear the interval
      }
    }, 1000);
  }

  async function handleChangeProvince(event) {
    setFormError(prevData => ({
      ...prevData,
      currentProvince: false,
    }));
    setCurrentProvince(event.target.value);
    // Find the selected province label
    const selectedLabel = provinces.find((province) => province.value === event.target.value)?.label || '';
    setCurrentProvinceLabel(selectedLabel);

    const headers = {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const result = await axios.get(`${APP_URL}/child-division-list?division_id=${event.target.value}`, {}, { headers });
    const convertedData = result.data.data.divisions.map(item => ({
      value: item.id,
      label: item.division_name,
    }));

    setDistricts(convertedData);
    setWards([]);
    setCurrentDistrict('');
    setCurrentWard('');
  }

  async function handleChangeDistrict(event) {
    setFormError(prevData => ({
      ...prevData,
      currentDistrict: false,
    }));
    setCurrentDistrict(event.target.value);
    const selectedLabel = districts.find((district) => district.value === event.target.value)?.label || '';
    setCurrentDistrictLabel(selectedLabel);

    const headers = {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${firebase.auth.currentUser.accessToken}`,
    };

    const result = await axios.get(`${APP_URL}/child-division-list?division_id=${event.target.value}`, {}, { headers });
    const convertedData = result.data.data.divisions.map(item => ({
      value: item.id,
      label: item.division_name,
    }));

    setWards(convertedData);
    setCurrentWard('');
  }

  function handleChangeWard(event) {
    setFormError(prevData => ({
      ...prevData,
      currentWard: false,
    }));
    setCurrentWard(event.target.value);
    const selectedLabel = wards.find((ward) => ward.value === event.target.value)?.label || '';
    setCurrentWardLabel(selectedLabel);
  }

  function onChangeConstruction() {
    setFormError(prevData => ({
      ...prevData,
      demandChecked: false,
    }));
    setConstructionChecked(!constructionChecked);
  }

  function onChangeBuyFurniture() {
    setFormError(prevData => ({
      ...prevData,
      demandChecked: false,
    }));
    setBuyFurnitureChecked(!buyFurnitureChecked);
  }

  function onChangeBuyDecorItems() {
    setFormError(prevData => ({
      ...prevData,
      demandChecked: false,
    }));
    setBuyDecorItemsChecked(!buyDecorItemsChecked);
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: '5000',
          left: '0',
          top: '0',
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          touchAction: 'none',
          overflow: 'hidden',
        }}
      >
        <Row gutter={12} justify="center" align="middle">
          <Col md={24} xs={24} sm={24} lg={10} xl={10}>
            <div className="search-image-area">
              <img src={image} className="image-modal" />
            </div>
          </Col>
          <Col md={24} xs={24} sm={24} lg={14} xl={14}>
            <div className="search-products-area">
              <button className="close-modal-shop-btn" onClick={onClose}>
                <svg width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                  <path
                    stroke="#444"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 12 7 7m5 5 5 5m-5-5 5-5m-5 5-5 5"
                  />
                </svg>
              </button>
              <h1 style={{ marginBottom: '10px' }}>Tư vấn và thi công thiết kế này</h1>
              <p style={{ color: '#8c8c8c', fontSize: '14px', marginTop: "0", marginBottom: "1rem"}}>
                Bạn vui lòng kiểm tra chính xác thông tin trước khi kết nối tới đơn vị thi công nhé
              </p>
              {!showApproved ? (
                <div className="agency-container">
                  <div className="login-form-container">
                    <form onSubmit={handleSubmit}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <span style={{ color: 'red', marginRight: '2px' }}>*</span>Bạn đang có nhu cầu về?
                        </FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                          <FormControlLabel
                            checked={constructionChecked}
                            control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'black' } }} />}
                            label="Xây dựng"
                            onChange={onChangeConstruction}
                          />
                          <FormControlLabel
                            checked={buyFurnitureChecked}
                            control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'black' } }} />}
                            label="Nội thất"
                            onChange={onChangeBuyFurniture}
                          />
                          <FormControlLabel
                            checked={buyDecorItemsChecked}
                            control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'black' } }} />}
                            label="Vật tư"
                            onChange={onChangeBuyDecorItems}
                          />
                        </RadioGroup>
                        {formError.demandChecked && (
                            <FormHelperText error style={{ marginTop: '-10px' }}>
                              Vui lòng chọn nhu cầu của bạn
                            </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <span style={{ color: 'red', marginRight: '2px' }}>*</span>Thông tin liên lạc
                        </FormLabel>
                        <div className="two-field-box mt-2">
                          <div>
                            <TextField
                              id="outlined-basic"
                              label="Tên"
                              variant="outlined"
                              onChange={handleChangeFirstName}
                              value={firstName}
                              error={formError.firstName}
                              helpertext={formError.firstName ? 'Vui lòng nhập tên của bạn' : ''}
                            />
                          </div>
                          <div className="ml-8">
                            <TextField id="outlined-basic" label="Họ" variant="outlined" onChange={handleChangeLastName} value={lastName} />
                          </div>
                        </div>
                        <TextField
                          className="mt-2"
                          id="outlined-basic"
                          sx={{ width: '100%' }}
                          label="Địa chỉ email"
                          variant="outlined"
                          onChange={handleChangeEmail}
                          value={email}
                          type="email"
                          error={formError.email}
                          helpertext={formError.email ? 'Vui lòng nhập địa chỉ email' : ''}
                        />
                        <TextField
                          className="mt-2"
                          id="outlined-basic"
                          sx={{ width: '100%' }}
                          label="Số điện thoại"
                          variant="outlined"
                          type="number"
                          onChange={handleChangePhoneNumber}
                          value={phoneNumber}
                          error={formError.phoneNumber}
                          helpertext={formError.phoneNumber ? 'Vui lòng nhập số điện thoại' : ''}
                        />
                        <FormLabel id="demo-row-radio-buttons-group-label" className="mt-4">
                          <span style={{ color: 'red', marginRight: '2px' }}>*</span>Địa chỉ thi công
                        </FormLabel>
                        <FormControl fullWidth className="mt-2">
                          <InputLabel id="demo-simple-select-label">Tỉnh/TP</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentProvince}
                            label="Tỉnh/TP"
                            onChange={handleChangeProvince}
                            MenuProps={{
                              style: { zIndex: 9999 },
                            }}
                            error={formError.currentProvince}
                            helpertext={formError.currentProvince ? 'Vui lòng chọn Tỉnh/TP' : ''}
                          >
                            {provinces.map((c, i) => {
                              return (
                                <MenuItem key={i} value={parseInt(c.value)}>
                                  {c.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth className="mt-2" disabled={districts.length <= 0}>
                          <InputLabel id="demo-simple-select-label">Quận/Huyện</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentDistrict}
                            label="Quận/Huyện"
                            onChange={handleChangeDistrict}
                            MenuProps={{
                              style: { zIndex: 9999 },
                            }}
                            error={formError.currentDistrict}
                            helpertext={formError.currentDistrict ? 'Vui lòng chọn Quận/Huyện' : ''}
                          >
                            {districts.map((c, i) => {
                              return (
                                <MenuItem key={i} value={parseInt(c.value)}>
                                  {c.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth className="mt-2" disabled={wards.length <= 0}>
                          <InputLabel id="demo-simple-select-label">Phường/Xã</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentWard}
                            label="Phường/Xã"
                            onChange={handleChangeWard}
                            MenuProps={{
                              style: { zIndex: 9999 },
                            }}
                            error={formError.currentWard}
                            helpertext={formError.currentWard ? 'Vui lòng chọn Phường/Xã' : ''}
                          >
                            {wards.map((c, i) => {
                              return (
                                <MenuItem key={i} value={parseInt(c.value)}>
                                  {c.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          className="mt-2"
                          id="outlined-basic"
                          sx={{ width: '100%' }}
                          label="Địa chỉ cụ thể"
                          variant="outlined"
                          type="address"
                          onChange={handleChangeDetailAddress}
                          value={detailAddress}
                          error={formError.detailAddress}
                          helpertext={formError.detailAddress ? 'Vui lòng nhập số nhà, tên đường' : ''}
                        />
                        <div className="create-imagine-button generate-btn">
                          <Button type="submit" className="generate-button w-button" style={{textTransform: "unset"}}>
                            Xác nhận yêu cầu
                          </Button>
                        </div>
                      </FormControl>
                    </form>
                  </div>
                </div>
              ) : (
              <div id="master-wrap">
                <div id="logo-box">
                  <div className="animated fast fadeInUp">
                    <div className="email-agency-icon" />
                    <h1>Cảm ơn bạn</h1>
                  </div>
                  <div className="notice animated fadeInUp">
                    <p className="agency-received">
                      Chúng tôi đã nhận được yêu cầu thiết kế. Đơn vị thi công sẽ liên hệ tới bạn!
                    </p>
                    <a className="back-btn animation" onClick={() => onClose(true)}>
                      ← Trở về
                    </a>
                  </div>
                  <div className="footer animated slow fadeInUp">
                  <p id="timer">
                    {count > 0
                      ? `Trở về trang chủ sau ${count} giây.` // Timer Message
                      : `Đang chuyển hướng...`}
                  </p>
                    <p className="copyright">© Noithatai.com</p>
                  </div>
                </div>
                {/* /#logo-box */}
              </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {loading && <Spinner/>}
    </>
  );
};

export default AgencyForm;
