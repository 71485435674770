import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const Footer = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <footer className="section-top-80 relative bg-neutral-black lg:pb-[60px]">
      <div className="section">
        <div className="mx-auto grid max-w-[1320px] gap-20 bg-neutral-dark-grey py-10 px-[15px] md:grid-cols-3 md:gap-10 lg:rounded-[20px] lg:p-[60px]">
          <div className="md:col-span-2">
            <div className="grid grid-cols-2 gap-6 md:col-span-2 md:flex md:justify-between">
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('landing.headshotsMenu')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <a href={currentLanguage === 'en' ? '/businesses-headshots' : `/${currentLanguage}/businesses-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('businessHeadshots.title')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/dating-photos' : `/${currentLanguage}/dating-photos`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('datingPhotos.title')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/teacher-headshots' : `/${currentLanguage}/teacher-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('teacherHeadshots.menuTitle')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/student-headshots' : `/${currentLanguage}/student-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('studentHeadshots.menuTitle')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/doctor-headshots' : `/${currentLanguage}/doctor-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('docterHeadshots.menuTitle')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/lawyer-headshots' : `/${currentLanguage}/lawyer-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('lawyerHeadshots.menuTitle')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/real-estate-headshots' : `/${currentLanguage}/real-estate-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('realeastateHeadshots.menuTitle')}
                  </a>
                  <a href={currentLanguage === 'en' ? '/salesperson-headshots' : `/${currentLanguage}/salesperson-headshots`} className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('salespersonHeadshots.menuTitle')}
                  </a>
                </div>
              </div>
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.page')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                {
                  currentLanguage == 'en' &&
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    href='https://blog.reok.pro/'
                  >
                    {t('footer.blog')}
                  </a>
                }
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" to="/app">
                    {t('footer.app')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white"
                    to={currentLanguage === 'en' ? '/signin' : `/${currentLanguage}/signin`}
                  >
                    {t('footer.signin')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                  >
                    {t('footer.signup')}
                  </Link>
                  {/* <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    href={currentLanguage === 'en' ? '/how-to-get-ai-yearbook-for-free' : `/${currentLanguage}/how-to-get-ai-yearbook-for-free`}
                  >
                    {t('footer.yearbook')}
                  </a> */}
                  {currentLanguage !== 'vi' &&
                    <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" href="https://reok.lemonsqueezy.com/affiliates">
                      {t('footer.affiliate')}
                    </a>
                  }
                </div>
              </div>
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.tos')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                  >
                    {t('footer.terms')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                  >
                    {t('footer.privacy')}
                  </Link>
                  <Link className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" 
                    to={currentLanguage === 'en' ? '/refund' : `/${currentLanguage}/refund`}
                  >
                    {t('refund.title')}
                  </Link>
                </div>
              </div>
              <div>
                <h4 className="t-15 !font-body font-medium text-white">{t('footer.other')}</h4>
                <div className="mt-4 flex flex-col gap-3 md:mt-[30px] md:gap-4">
                  <a href="mailto:hello@reok.pro?subject=Request partners" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.partner')}
                  </a>
                  <a href="mailto:hello@reok.pro?subject=Report bugs" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.reportBug')}
                  </a>
                  <a href="mailto:hello@reok.pro?subject=Request features" target="_blank" className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white">
                    {t('footer.require')}
                  </a>
                  <a className="t-16-footer font-body tracking-[0.02em] text-white/80 transition-colors hover:text-white" href="#" onClick={() => {window.HubSpotConversations.widget.open();}}>
                    {t('footer.chatNow')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between text-white md:order-first">
            <div>
              <Link to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`} aria-label="Reok Logo">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" viewBox="0 0 3600 1015.5" height="44" version="1.0">
                  <defs>
                    <clipPath id="a">
                      <path d="M.887 0h1015v1015H.887Zm0 0"/>
                    </clipPath>
                  </defs>
                  <path d="M1703.836 361.342c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469H1928.88v-90.469h361.874v-90.468H1928.88v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V361.342c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V270.874h-90.469v452.343h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469L3238.42 497.045l135.703-135.703h90.469Zm0 0" fill="#f0f"/>
                  <path d="M1772.704 377.242c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469H1410.83v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V377.242c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V286.774h-90.469v452.343h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#0ff"/>
                  <path d="M1738.27 369.292c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V369.292c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V278.824h-90.469v452.343h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#fff"/>
                  <g transform="translate(3576.56 723.217)" fill="#f0f"/>
                  <g clipPath="url(#a)">
                    <path fill="#fff" d="M1015.617 507.277c0 280.254-227.199 507.453-507.453 507.453C227.906 1014.73.707 787.531.707 507.277.727 227.023 227.907-.18 508.164-.18c280.254.02 507.453 227.203 507.453 507.457"/>
                  </g>
                  <path fill="#fff" d="M956.121 507.277c0 247.375-200.582 447.961-447.957 447.961-247.379 0-447.96-200.586-447.96-447.96 0-247.423 200.581-447.962 447.96-447.962 247.375 0 447.957 200.54 447.957 447.961"/>
                  <path d="m370.875 466.746 230.871-397.59c-30.195-6.441-61.465-9.84-93.582-9.84-115.86 0-221.387 43.973-300.922 116.13l163.633 291.3M179.371 203.09C105.43 282.984 60.203 389.852 60.203 507.277c0 33.414 3.711 65.98 10.645 97.293l332.816-.222L179.371 203.09M642.363 79.758 482.305 342.21h442.414C875.246 217.449 771.375 120.199 642.363 79.758M619.95 381.531l230.51 414.68c65.895-78.016 105.661-178.844 105.661-288.934 0-41.289-5.637-81.238-16.058-119.214-189.043-.313-320.114-6.532-320.114-6.532M508.164 955.238c121.047 0 230.91-48.047 311.566-126.105l-160.457-290.32s-141.582 246.93-216.691 411.636a452.445 452.445 0 0 0 65.582 4.79m-110.269-13.692 163.054-291.3-480.617-9.755c46.031 147.844 166.496 262.809 317.563 301.055"/>
                </svg>
              </Link>
              <div className="t-14 mt-4 max-w-[290px] font-body text-white/80">
                <p>{t('footer.title')}</p>
                {/* <LanguageSwitcher/> */}
              </div>
              <div className="mt-10 text-neutral-light-grey">
                <nav className="flex gap-3">
                  <a
                    target="_blank"
                    className="svg-icon link-dark"
                    href="https://www.tiktok.com/@reokpro"
                    aria-label="Reok Tiktok"
                  >
                    <svg fill="#000000" width={21} height={21} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                      <path d="m 4.9520184,12.936803 c -1.12784,-0.2039 -2.19411,-0.9875 -2.66789,-1.9606 -0.32895,-0.6757 -0.45541,-1.3901003 -0.37418,-2.1137003 0.15054,-1.3412 0.84482,-2.4395 1.92406,-3.0439 0.56899,-0.3186 1.38421,-0.4769 1.991,-0.3867 l 0.35091,0.052 9e-5,1.0725 9e-5,1.0725 -0.332,-0.014 c -0.79998,-0.033 -1.39595,0.3096 -1.70379,0.9784 -0.1473,0.32 -0.18461,0.8887 -0.081,1.2351 0.12773,0.4273003 0.50542,0.8132003 0.96145,0.9825003 0.15535,0.058 0.32344,0.08 0.61152,0.079 0.35862,-4e-4 0.42448,-0.013 0.67663,-0.1323 0.36505,-0.1726 0.63141,-0.4231 0.78797,-0.7411 0.10147,-0.2061003 0.13414,-0.3430003 0.16587,-0.6951003 0.0217,-0.2412 0.0401,-2.2122 0.0409,-4.38 l 10e-4,-3.94149998 0.68371,0 c 0.37605,0 0.8277,0.012 1.00368,0.027 l 0.31995,0.027 0,0.1584 c 0,0.3813 0.22299,1.1127 0.45156,1.4812 0.0571,0.092 0.2564996,0.3178 0.4431796,0.5018 0.36068,0.3555 0.66494,0.5352 1.13352,0.6692 0.138,0.04 0.28359,0.089 0.32353,0.109 0.0399,0.02 0.11522,0.038 0.16728,0.038 0.0521,4e-4 0.13701,0.012 0.18876,0.026 l 0.0941,0.025 0,0.9948 0,0.9948 -0.17773,-0.019 c -0.9611,-0.1037 -1.72925,-0.3601 -2.3423096,-0.782 -0.30468,-0.2096 -0.33102,-0.222 -0.30218,-0.1422 0.0104,0.029 0.003,1.1249 -0.0164,2.436 -0.0336,2.2728 -0.0396,2.3992 -0.12781,2.7173003 -0.33904,1.2222 -1.09994,2.1297 -2.10183,2.5068 -0.6126,0.2306 -1.39679,0.2932 -2.09405,0.1671 z" />
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://twitter.com/ReokPro">
                    <svg width="18" height="18.014" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18.014">
                      <path d="M10.714 7.628 17.415 0h-1.588l-5.822 6.623L5.36 0H0l7.029 10.017L0 18.014h1.588l6.144 -6.995 4.908 6.995H18M2.161 1.173h2.439l11.227 15.728h-2.439" fill="fff"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://www.facebook.com/profile.php?id=61555339043629">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.175 -0.05 0.6 0.6" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-facebook">
                      <path d="M0.051 0.097v0.069H0.001v0.084h0.05v0.25H0.154V0.249h0.069s0.007 -0.04 0.01 -0.084H0.155V0.108c0 -0.009 0.011 -0.02 0.022 -0.02h0.056V0H0.157c-0.108 0 -0.106 0.084 -0.106 0.097z"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://www.youtube.com/@reokpro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.055 -0.138 0.66 0.66" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-youtube">
                      <path d="M0.435 0H0.114C0.051 0 0 0.051 0 0.113v0.159c0 0.062 0.051 0.113 0.114 0.113h0.321c0.063 0 0.114 -0.05 0.114 -0.113V0.113c0 -0.062 -0.051 -0.113 -0.114 -0.113zM0.358 0.2 0.208 0.271a0.006 0.006 0 0 1 -0.009 -0.005V0.12a0.006 0.006 0 0 1 0.009 -0.005l0.15 0.075a0.006 0.006 0 0 1 0 0.011z"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://www.linkedin.com/company/reok">
                    <svg fill="#000000" width="22px" height="22px" viewBox="0 0 0.66 0.66" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                      <path d="M0.563 0.055H0.097a0.04 0.04 0 0 0 -0.04 0.039v0.471A0.04 0.04 0 0 0 0.097 0.605h0.466a0.04 0.04 0 0 0 0.04 -0.039V0.094A0.04 0.04 0 0 0 0.563 0.055ZM0.222 0.515h-0.083v-0.247h0.083ZM0.181 0.233a0.043 0.043 0 1 1 0 -0.086 0.043 0.043 0 1 1 0 0.086Zm0.339 0.282h-0.083v-0.133c0 -0.033 -0.012 -0.055 -0.042 -0.055A0.045 0.045 0 0 0 0.353 0.357a0.055 0.055 0 0 0 -0.003 0.02v0.138h-0.083v-0.247h0.083V0.302a0.083 0.083 0 0 1 0.075 -0.041c0.055 0 0.095 0.035 0.095 0.112Z"/>
                    </svg>
                  </a>
                  <a target="_blank" rel="noopener" className="svg-icon link-dark" href="https://www.instagram.com/reokpro">
                    <svg fill="#000000" width="22px" height="22px" viewBox="-0.05 -0.05 0.6 0.6" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-instagram">
                      <path d="M0.35 0h-0.202A0.149 0.149 0 0 0 0 0.149v0.202a0.149 0.149 0 0 0 0.149 0.149h0.202a0.149 0.149 0 0 0 0.149 -0.149v-0.202A0.149 0.149 0 0 0 0.35 0zm0.099 0.35a0.099 0.099 0 0 1 -0.099 0.099h-0.202a0.099 0.099 0 0 1 -0.098 -0.099v-0.202a0.099 0.099 0 0 1 0.099 -0.098h0.202a0.099 0.099 0 0 1 0.098 0.099v0.202z"/>
                      <path d="M0.25 0.12A0.129 0.129 0 0 0 0.121 0.25a0.129 0.129 0 0 0 0.129 0.129 0.129 0.129 0 0 0 0.129 -0.129A0.129 0.129 0 0 0 0.25 0.121zm0 0.208a0.079 0.079 0 1 1 0 -0.158 0.079 0.079 0 0 1 0 0.158z"/>
                      <path cx="15.156" cy="4.858" r="1.237" d="M0.41 0.121A0.031 0.031 0 0 1 0.379 0.152A0.031 0.031 0 0 1 0.348 0.121A0.031 0.031 0 0 1 0.41 0.121z"/>
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
            <p className="t-14 mt-6 text-neutral-light-grey">
              Reok Pro © 2024
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
