import React, { useState, useEffect } from 'react';
import Header from './Header';
import Lasso from '../Lasso';

const EditImage = ({ imageUrl, onClose, firebase, t, onGenFill, onChangeImageSrc, onFetchUser, checkCredits}) => {
  
  const [rotationDeg, setRotationDeg] = useState(0);
  
  const toggleRotate = (event) => {
    event.preventDefault();
  
    if (rotationDeg === 360) setRotationDeg(90);
    else setRotationDeg(rotationDeg + 90);
  };

  return (
      <div>
        <div className="react_modal_images__modal_container"
          style={{
            position: 'fixed',
            zIndex: '5000',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            touchAction: 'none',
            overflow: 'hidden'
          }}>
          <div className="react_modal_images__modal_content" style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Lasso checkCredits={checkCredits} imageUrl={imageUrl} firebase={firebase} t={t} onGenFill={onGenFill} onClose={onClose} onChangeImageSrc={onChangeImageSrc} onFetchUser={onFetchUser}/>
          </div>
          <Header
            toggleRotate={toggleRotate}
            onClose={onClose}
            enableRotate={false}
          />
        </div>
      </div>
  );
};

export default EditImage;
