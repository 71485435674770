import React, { useState, useRef, useEffect } from 'react';
import Header from './Header';
import Image from './Image';
import BeforeAfter from '../BeforeAfter';

const CompareImage = ({ images, onClose}) => {
  const popupRef = useRef(null);
  const [rotationDeg, setRotationDeg] = useState(0);
  const [move, setMove] = React.useState({
    x: 0,
    y: 0
  });

  useEffect(() => {

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupRef]);

  const toggleRotate = (event) => {
    event.preventDefault();
  
    if (rotationDeg === 360) setRotationDeg(90);
    else setRotationDeg(rotationDeg + 90);
  };

  return (
      <div>
        <div className="react_modal_images__modal_container"
          style={{
            position: 'fixed',
            zIndex: '5000',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            touchAction: 'none',
            overflow: 'hidden'
          }}>
          <div className="react_modal_images__modal_content" style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          ref={popupRef}
          >
            <BeforeAfter
              images={images}
            />
          </div>
          <Header
            toggleRotate={toggleRotate}
            onClose={onClose}
            enableRotate={false}
          />
        </div>
      </div>
  );
};

export default CompareImage;
