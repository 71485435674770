import React, { useState } from 'react';
import { Col, Row } from 'antd';

const ShopFurniture = ({ image, onClose, firebase, t, productsList }) => {

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '5000',
        left: '0',
        top: '0',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        touchAction: 'none',
        overflow: 'hidden',
      }}
    >
      <Row gutter={12} justify="center" align="middle">
        <Col md={24} xs={24} sm={24} lg={10} xl={10}>
          <div className="search-image-area">
            <img src={image} className="image-modal" />
          </div>
        </Col>
        <Col md={24} xs={24} sm={24} lg={14} xl={14}>
          <div className="search-products-area">
            <button className="close-modal-shop-btn" onClick={onClose}>
            <svg width={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
              <path stroke="#444" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12 7 7m5 5 5 5m-5-5 5-5m-5 5-5 5"/>
            </svg>
            </button>
            <h1>Product matching</h1>
            <div className="products-search-list">
            {productsList.length > 0 &&
                productsList.map(product => (
              <div className="your-product" data-price={product.price}>
                <a
                  href={product.link}
                  target="_blank"
                >
                  <div className="thumbnail">
                    <img src={product.thumbnail} />
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 512 512" viewBox="0 0 512 512">
                        <g>
                          <path id="_88" d="m324.5 357.5v-134.7l-151.7 151.7-35.4-35.4 151.7-151.7h-134.6v-50h220v220h-50z" />
                        </g>
                      </svg>
                      {product.source}
                    </span>
                  </div>
                  <div className="price">
                    {product.price}
                  </div>
                  <div className="name">{product.title}</div>
                  <div className="vendor">
                    <img src={product.source_icon} />
                    <span>{product.source}</span>
                  </div>
                </a>
              </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ShopFurniture;
