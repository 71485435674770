import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import ShowCaseSlide from '../ShowCaseSlide';

const RECAPTCHA = process.env.REACT_APP_RECAPTCHA;

function SignIn({ email, password, handleChange, handleSubmit, handleSubmitWithGoogle, error }) {
  const [currentLanguage, setCurrentLanguage] = useState('');

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="sign_in-wrapper">
      <ShowCaseSlide/>
      <div className="sign_in-container">
        <div className="sign_in-logo">
          <Link to={currentLanguage === 'en' ? '/' : `/${currentLanguage}/`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="214" viewBox="0 0 3600 1015.5" height="44" version="1.0">
                <defs>
                  <clipPath id="a">
                    <path d="M.887 0h1015v1015H.887Zm0 0"/>
                  </clipPath>
                </defs>
                <path d="M1703.836 361.342c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469H1928.88v-90.469h361.874v-90.468H1928.88v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V361.342c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V270.874h-90.469v452.343h90.469V542.279h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469L3238.42 497.045l135.703-135.703h90.469Zm0 0" fill="#f0f"/>
                <path d="M1772.704 377.242c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469H1410.83v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V377.242c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V286.774h-90.469v452.343h90.469V558.179h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#0ff"/>
                <path d="M1738.27 369.292c0-49.758-40.71-90.469-90.468-90.469h-361.875v452.344h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-90.469-90.469h90.47c49.757 0 90.468-40.71 90.468-90.468Zm-90.468 0v90.469h-271.406v-90.469Zm677.386-90.469h-361.873c-49.758 0-90.469 40.711-90.469 90.469v271.406c-.001 49.759 40.71 90.469 90.468 90.469h361.874v-90.469h-361.874v-90.469h361.874v-90.468h-361.874v-90.469h361.874Zm496.45 452.344c50.324 0 90.469-41.277 90.469-90.469V369.292c0-49.758-40.711-90.469-90.469-90.469h-271.406c-49.758 0-90.469 40.711-90.469 90.469v271.406c0 49.759 40.711 90.469 90.469 90.469Zm-271.406-361.875h271.406v271.406h-271.406Zm948.792-90.469h-108.561c-12.437 0-23.746 4.524-32.793 13.57l-167.368 167.369h-53.152V278.824h-90.469v452.343h90.469V550.229h53.152l167.367 167.368c9.047 9.047 20.356 13.57 32.793 13.57h108.563v-90.469h-90.469l-135.702-135.703 135.703-135.703h90.469Zm0 0" fill="#fff"/>
                <g transform="translate(3576.56 723.217)" fill="#f0f"/>
                <g clipPath="url(#a)">
                  <path fill="#fff" d="M1015.617 507.277c0 280.254-227.199 507.453-507.453 507.453C227.906 1014.73.707 787.531.707 507.277.727 227.023 227.907-.18 508.164-.18c280.254.02 507.453 227.203 507.453 507.457"/>
                </g>
                <path fill="#fff" d="M956.121 507.277c0 247.375-200.582 447.961-447.957 447.961-247.379 0-447.96-200.586-447.96-447.96 0-247.423 200.581-447.962 447.96-447.962 247.375 0 447.957 200.54 447.957 447.961"/>
                <path d="m370.875 466.746 230.871-397.59c-30.195-6.441-61.465-9.84-93.582-9.84-115.86 0-221.387 43.973-300.922 116.13l163.633 291.3M179.371 203.09C105.43 282.984 60.203 389.852 60.203 507.277c0 33.414 3.711 65.98 10.645 97.293l332.816-.222L179.371 203.09M642.363 79.758 482.305 342.21h442.414C875.246 217.449 771.375 120.199 642.363 79.758M619.95 381.531l230.51 414.68c65.895-78.016 105.661-178.844 105.661-288.934 0-41.289-5.637-81.238-16.058-119.214-189.043-.313-320.114-6.532-320.114-6.532M508.164 955.238c121.047 0 230.91-48.047 311.566-126.105l-160.457-290.32s-141.582 246.93-216.691 411.636a452.445 452.445 0 0 0 65.582 4.79m-110.269-13.692 163.054-291.3-480.617-9.755c46.031 147.844 166.496 262.809 317.563 301.055"/>
              </svg>
          </Link>
        </div>
        <div className="login-placeholder pt-5 pb-5">
          <div className="login-form-container">
            <div>
              <h1 className="auth-panel-title">{t('signin.title')}</h1>
              <a href="#" className="link-block-6 w-inline-block signin-button" onClick={(e) => handleSubmitWithGoogle(e, t)}>
                <div>
                  <img src="fonts/6373e8bb67056287b1380aa8_image%206.svg" loading="lazy" alt="" className="signin-button"/>
                </div>
                <p className="body-1-semi-bold ml-12 signin-button">{t('button.googleSignin')}</p>
              </a>
            </div>
            {/* <div class="g-recaptcha flex justify-content-center mt-2" data-sitekey={RECAPTCHA}/> */}
            <div className="sign-with-box mt-2">
              <div className="sign-with-divider" />
              <p className='pl-2 pr-2'>{t('signin.another')}</p>
              <div className="sign-with-divider" />
            </div>
            <div className="mt-2">
              <form id="email-form" name="email-form" data-name="Email Form" onSubmit={(e) => handleSubmit(e, t)}>
                <div className="_2nd-form">
                  <div className="input-filed">
                    <img src="fonts/6370ac5739109b636f03e5a5_Envelope.svg" loading="lazy" alt="" />
                    <input
                      type="email"
                      className="text-field-4 w-input"
                      maxLength={256}
                      name="email"
                      data-name="Field"
                      placeholder={t('signin.emailPlaceHolder')}
                      id="email"
                      value={email}
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-filed mt-2">
                    <img src="fonts/6373ec51bb8f6f07a17fe482_Frame%20%2837%29.svg" loading="lazy" alt="" />
                    <input
                      type="password"
                      className="text-field-4 w-input"
                      maxLength={256}
                      name="password"
                      data-name="Field 2"
                      placeholder={t('signin.passwordPlaceHolder')}
                      id="password"
                      value={password}
                      required
                      onChange={handleChange}
                    />
                  </div>
                  {error && <p style={{ marginTop: '15px', color: 'red' }}>{error.message}</p>}
                  <button type="submit" data-wait="Please wait..." className="submit-button-3 w-button">
                    {t('button.emailSignin')}
                  </button>
                  <p className="agree-service mt-4">
                    {t('tos.agree')} 
                    <Link
                      className="agree-service"
                      to={currentLanguage === 'en' ? '/tos' : `/${currentLanguage}/tos`}
                    >
                      {t('footer.terms')}
                    </Link>
                    {' '}&{' '}
                  <Link
                    className="agree-service"
                    to={currentLanguage === 'en' ? '/privacy' : `/${currentLanguage}/privacy`}
                  >
                    {t('footer.privacy')}
                  </Link>
                  </p>
                  <div className="forget-password-box">
                    <Link
                      to={currentLanguage === 'en' ? '/forget-password' : `/${currentLanguage}/forget-password`}
                      className="link-block-7"
                    >
                      <p>{t('signin.forgetPassword')}</p>
                    </Link>
                  </div>
                  <div className="sign-up-link-box">
                    <p className="paragraph-46">
                      {t('signin.noAccount')}
                      <Link
                        to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`}
                        className="link"
                      >
                        {t('signup.title')}
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default SignIn;
