const zh = {
    Basic: "基础",
    Pro: "专业",
    month: "月",
    year: "年",
    button: {
        getStarted: '开始',
        try: '开始免费试用',
        viewall: '查看全部',
        basic: '基础',
        pro: '专业',
        generate: '生成设计',
        backHome: '返回首页',
        googleSignin: 'Google',
        googleSignup: 'Google',
        emailSignin: '使用电子邮件登录',
        emailSignup: '使用电子邮件注册',
        submit: '提交',
        choose: '选择',
        download: '下载这个模板',
        upgrade: '购买更多积分',
        subscribe: '购买',
        upgradePro: '升级',
        confirmPayment: '确认支付',
        tryThisFeature: '立即尝试此功能',
        removeBG: '开始',
        professional: '开始',
        upscale: '开始',
        ok: '好的',
        cancel: '取消',
        select: '选择',
        selectToStart: '选择开始',
        uploadPose: '上传姿势',
        uploadingPose: '正在上传您的姿势',
        upgradeToUpload: '升级以上传',
        unlock: "升级",
        englishOnly: "仅英语版本",
        contactUs: "在此联系我们",
        forEnterprise: "企业专属",
        tryThis: "尝试"
    },
    header: {
        nav: {
            signIn: '登录',
            signUp: '注册',
            signOut: '登出',
            supportTitle: '支持',
            supportContact: '联系我们',
            myFlowers: '我的头像'
        }
    },
    landing: {
        title: '专业头像照片 📸',
        subTitle: "使用我们的AI摄影师在几秒钟而不是几小时内即时生成精致的商务头像。轻松节省时间和金钱。",
        input: '告诉我你想创建的花的类型？',
        inside: '室内',
        outside: '室外',
        loveBy: '已经创建了125,620多个AI头像',
        respectTitle: '我们尊重您的隐私',
        respectSub1: '不需要FaceID或多张照片。只需使用一张自拍，就像您平常在Facebook或Instagram上发布的一样。',
        respectSub2: '您的数据在Google平台上是完全安全和安全的，具有很高的安全性。',
        howItWorks: '它是如何运作的',
        howItWorksSub: '拍摄或上传 -> 选择风格和背景 -> 选择或上传您的姿势 -> 收到您的头像！',
        unBlurryTitle: '照片放大器',
        unBlurrySub: '在社交媒体平台上发布之前去除模糊，增强和改善照片颜色，如Facebook，Instagram，Tiktok等。',
        useThisFeature: '立即创建您的头像',
        anotherFeatureTitle: '卓越功能',
        anotherFeatureSub: '免费，快速且准确。尝试以下功能。',
        feature1: '去除背景',
        feature1Sub: '准确去除任何背景。',
        feature2: '室内设计',
        feature2Sub: '与genhomeai.com一起进行室内设计，室外设计和素描。',
        feature3: '更改背景（即将推出）',
        feature3Sub: '使用REOK的AI为任何对象更改背景。',
        hotFeatureTitle: '我们的客户说什么',
        hotFeatureSub: '这些是经过许可分享的AI生成头像示例。',
        pricingTitle: '价格',
        pricingSub: '1张专业照片=10积分或更少',
        step1: '仅上传1张自拍',
        step1Sub: '选择您的风格，背景和姿势。',
        step2: '我们的AI在几秒钟内开始工作',
        step2Sub: '我们的尖端技术快速创建您的头像。',
        step3: '收到您的头像',
        step3Sub: '下载您的头像。',
        trialTitle: "免费试用",
        trialSubTitle: "#1 AI头像生成器 📸"
    },
    businessHeadshots: {
    title: '商业头像照片',
    subTitle: "用商业头像照片解锁终极团队品牌体验！"
    },
    datingPhotos: {
    title: 'AI约会照片',
    subTitle: "让您的约会成功率飙升：释放AI的力量，改变您的个人资料！"
    },
    studentHeadshots: {
    title: '学生头像照片',
    subTitle: "秒变专业AI头像，获取更多工作机会"
    },
    teacherHeadshots: {
    title: '教师头像照片',
    subTitle: "解锁终极专业优势：教师，用革命性的AI头像生成器在几分钟内改变您的形象！"
    },
    docterHeadshots: {
    title: '医生头像照片',
    subTitle: "用AI增强头像解锁个人品牌的未来，适用于医疗专业人士！"
    },
    lawyerHeadshots: {
    title: '律师头像照片',
    subTitle: "解锁第一印象的力量：为律师量身定制的革命性AI头像！"
    },
    realeastateHeadshots: {
    title: '房地产头像照片',
    subTitle: "提升您的房地产成功，用引人注目的AI生成头像提升您的营销！"
    },
    salespersonHeadshots: {
    title: '销售人员头像照片',
    subTitle: "解锁销售业绩飙升的秘密，用我们革命性的AI头像改变您的第一印象！"
    },
    yearbook: {
        title: 'AI年鉴摄影 📸',
        subTitle: "不使用许多自拍图像。使用我们的AI摄影师在几秒钟内仅使用单张自拍即可获得90年代的年鉴照片。节省10倍的时间。",
        input: '告诉我你想创建的花的类型？',
        inside: '室内',
        outside: '室外',
        loveBy: '已经创建了125,620多个AI头像',
        respectTitle: '我们尊重您的隐私',
        respectSub1: '我们不使用FaceID，也不需要很多照片。只需使用一个自拍，就像您经常在Facebook和Instagram上发布的那样。',
        respectSub2: '您的数据在Google平台上是完全安全和安全的，具有很高的安全性。',
        howItWorks: 'AI年鉴的运作方式',
        howItWorksSub: '您拍摄一张自拍或使用清晰的身份证照片，选择您的风格和姿势，几秒钟内完成头像！',
        unBlurryTitle: '照片放大器',
        unBlurrySub: '在社交媒体平台上发布之前去除模糊，增强和改善照片颜色，如Facebook，Instagram，Tiktok等。',
        useThisFeature: '立即创建您的头像',
        anotherFeatureTitle: '卓越功能',
        anotherFeatureSub: '免费，快速且准确。尝试以下功能。',
        feature1: '去除背景',
        feature1Sub: '准确去除任何背景。',
        feature2: '室内设计',
        feature2Sub: '与genhomeai.com一起进行室内设计，室外设计和素描。',
        feature3: '更改背景（即将推出）',
        feature3Sub: '使用REOK的AI为任何对象更改背景。',
        hotFeatureTitle: '我们的AI年鉴照片',
        hotFeatureSub: '这些是由AI生成的头像。',
        pricingTitle: '价格',
        pricingSub: '1张专业照片=10积分或更少',
    },
    showcase: {
        title: '示范花卉',
        status: {
            pending: '处理中',
            upscaling: '升级中',
            choosing: '选择中',
            finished: '完成'
        },
        mode: {
            pro: '专业',
            basic: '基础'
        }
    },
    home: {
        title: '您的设计',
        hotColletion: "热门收藏",
        credits: '剩余设计',
        uploadTitle: '上传您的自拍',
        uploadPlaceHolder: '拖放、点击选择或拍照（最大10MB）',
        stylePlaceHolder: '选择',
        uploadPosePlaceHolder: '选择',
        uploadBGPlaceHolder: '选择',
        roomTitle: '选择房间类型（29）',
        themeTitle: '选择房间风格（34）',
        pro: {
            title: '所有类型的花朵的创造力',
            input: '描述您的花朵...',
        },
        basic: {
            title: '与专业版无限的创意',
            chooseFlowerTitle: '花朵',
            flowers: '客厅',
            styles: '风格',
            decoration: '装饰',
            events: '事件',
            backgrounds: '背景'
        },
    },
    profile: {
        title: '我的头像',
        before: '之前 -',
        after: '之后 -'
    },
    terms: {
        title: '条款和条件',
        content: ''
    },
    privacy: {
        title: '隐私政策',
        one: '您的隐私对我们非常重要。reok.pro的隐私政策尊重您在我们的网站reok.pro以及我们拥有和运营的其他网站上可能收集到的任何信息的隐私。',
        two: '我们仅在真正需要时才会请求个人信息，以向您提供服务。我们通过公正合法的手段，以您的知识和同意收集它。我们还会告诉您我们为什么收集它以及将如何使用它。',
        three: '我们仅在提供您请求的服务所需的时间内保留已收集的信息。我们将保护您的数据，以商业上可接受的手段防止丢失和盗窃，以及未经授权的访问、披露、复制、使用或修改。',
        four: '除非法律要求，否则我们不会公开或与第三方分享任何可以识别个人身份的信息。',
        five: '我们的网站可能链接到我们不操作的外部网站。请注意，我们无法控制这些网站的内容和实践，因此对于它们各自的隐私政策，我们不能接受责任。',
        six: '您可以自由拒绝提供我们要求的个人信息，但请理解我们可能无法为您提供某些所需的服务。',
        seven: '您对我们网站的持续使用将被视为对我们在隐私和个人信息方面的做法的接受。如果您对我们处理用户数据和个人信息的方式有任何疑问，请随时与我们联系。',
        eight: '本政策自2023年9月15日起生效。'
    },
    footer: {
        title: 'REOK Studio：LinkedIn、社交媒体、简历、演艺的AI头像。几秒钟内产生结果，只需一张自拍。',
        terms: '条款',
        privacy: '隐私政策',
        app: '应用',
        signin: '登录',
        signup: '注册',
        email: '电子邮件',
        chatNow: '立即聊天',
        partner: '合作伙伴',
        reportBug: '报告错误',
        require: '提交请求',
        page: '页面',
        tos: '法律',
        contact: '联系',
        other: '其他',
        affiliate: '联盟伙伴',
        yearbook: 'AI年鉴',
        blog: '博客',
        pricing: '价格',
        howItWorks: '它是如何工作的',
        businesses: "企业",
        dating: "约会"
    },
    signin: {
        title: '登录',
        another: '或使用电子邮件',
        emailPlaceHolder: '电子邮件地址',
        passwordPlaceHolder: '密码',
        rememberTitle: '下次记住',
        forgetPassword: '忘记密码？',
        noAccount: '没有账户？'
    },
    signup: {
        title: '注册',
        another: '或使用电子邮件',
        firstNamePlaceHolder: '名字',
        lastNamePlaceHolder: '姓氏',
        emailPlaceHolder: '电子邮件地址',
        passwordPlaceHolder: '密码',
        rememberTitle: '下次记住',
        forgetPassword: '忘记密码？',
        gotAccount: '已经有账户了？'
    },
    forgetPassword: {
        title: '找回密码',
        content: '我们将向您发送密码找回电子邮件',
        emailPlaceHolder: '请输入您的电子邮件',
        sendBtn: '发送',
        backSignin: '返回登录'
    },
    pricing: {
        yearlySubscription: '1000设计',
        monthlySubscription: '100设计',
        saveDesk: '节省10%',
        saveMobile: '购买1000设计时节省10%',
        popular: '82%选择此计划',
        free: {
            title: '免费积分',
            price: '0$',
            trialTitle: '注册码：请登录',
            joinTitle: '在此处粘贴您的代码',
            one: '获得20个免费积分',
            two: '包括所有100积分功能',
            affiliateTitle: '联盟计划',
            shareTitle: '与朋友分享👇',
            loginToShare: '请登录以分享'
        },
        basic: {
            title: '100积分',
            price: '7$',
            duration: '/终身',
            recommend: '个人使用',
            one: '20张专业照片',
            two: '去除背景',
            three: '提升至4K',
            four: '下载所有照片',
            five: '无限云存储',
            six: '照片商业使用',
            seven: '访问所有新功能',
            subscribed: '已购买'
        },
        pro: {
            title: '1000积分',
            subTitle: '(节省30%)',
            price: '$49',
            duration: '/终身',
            recommend: '意见领袖、企业、照片工作室',
            one: '200张专业照片',
            two: '包含所有100积分功能',
            three: '节省您的金钱',
            subscribed: '已购买'
        },
        credit: {
            title: '1头像=5积分。一次性付款。'
        },
        first: {
            title: '入门版 - 200积分',
            price: '$19',
            priceDetail: '$ 0.47 / 头像',
            duration: '/终身',
            recommend: '1头像 = 5积分',
            one: '40张渲染头像',
            two: '去除背景',
            three: '提升到4K',
            four: '下载所有照片',
            five: '无限云存储',
            six: '商业使用照片',
            seven: '几秒钟内拍摄头像',
            eight: '任何风格和背景',
            nine: '上传您的姿势',
            ten: '无需培训时间'
        },
        second: {
            title: '专业版 - 2500积分',
            subTitle: '(省60%)',
            price: '$99',
            priceDetail: '$ 0.19 / 头像',
            duration: '/终身',
            recommend: '影响者、企业、照片工作室',
            five: '$19计划中的所有内容，省60%',
            two: '500张渲染头像',
            three: '美丽的头像无限制',
            one: '购买一次，永久使用，不订阅',
            six: '无需前往工作室',
            four: '为其他多达500人渲染',
            subscribed: '已购买'
        },
        third: {
            title: '专业摄影师',
            subTitle: '(最佳价值)',
            price: '$49',
            priceDetail: '$ 4.9 / 头像',
            duration: '/终身',
            recommend: '影响者、企业、照片工作室',
            one: '请求任何风格',
            two: '10张高级头像',
            three: '由专业摄影师拍摄',
            four: '一天内交付头像',
            five: '高质量头像',
            subscribed: '已购买'
        },
        four: {
            title: '企业解决方案',
            subTitle: '(联系我们)',
            price: '$0',
            priceDetail: '$ 0 / 头像',
            duration: '/终身',
            recommend: '影响者、企业、照片工作室',
            one: '高质量头像',
            two: '公司或远程团队',
            three: '节省时间和金钱',
            four: '几秒钟内拍摄头像',
            five: '无传统摄影',
            subscribed: '已购买',
        },
        currency: 'đ',
        perMonth: '/月',
        safePayment: '安全支付',
        qr: '或使用QR码'
    },
    milestoneMessage: {
        first: '更幸福的客户拥有',
        second: '创建的设计'
    },
    tos: {
        agree: '新账户需遵守我们的',
        one: '1. 介紹',
        oneDetail: '使用reok.pro，您同意並受這些條款和條件約束。',
        two: '2. 合同條款',
        twoDetail: '本協議自您首次使用reok.pro應用程序的日期起生效。',
        three: '3. 功能',
        threeDetail: '只有在訂閱特定付費計劃之後，您才能使用所有功能。有關可用功能的詳細信息可以在價格頁面找到。',
        four: '4. 退款政策',
        fourDetail: '我們允許在購買後的14天內對失敗的交易進行退款。請聯繫支援部門申請退款。',
        five: '5. 產品使用',
        fiveDetail: '使用reok.pro，您同意通過與您的Google帳戶關聯的電子郵件或您用於註冊帳戶的電子郵件地址接收重要的產品更新。您可以隨時通過單擊每封電子郵件底部的“取消訂閱”鏈接來取消訂閱這些產品更新。我們僅發送重要的產品更新。',
        six: '6. 免責聲明',
        sixDetail: '我們不保證reok.pro將滿足您的要求，也不保證其運作將不中斷或無錯誤。本協議未明示的所有保證或條件（包括但不限於利潤損失、數據損失或損壞、業務中斷或任何其他性質的任何其他金錢損失、損壞、費用或開支）均在法律允許的最大程度內被排除和放棄。本協議不影響您作為消費者可能擁有的任何法定權利。',
        seven: '7. 保固和法律限制',
        sevenDetail: 'reok.pro不對軟件的質量、特定用途的適用性或其他特性或性能提供任何保證、擔保或其他陳述。除非法律禁止排除，否則reok.pro將不對您因本協議或您使用reok.pro而引起或與之有關的任何利潤損失或任何間接、特殊、附帶或間接損害承擔責任（無論如何產生，包括過失），除非法律禁止排除。',
        eight: '8. 一般條款和法律',
        eightDetail: '您承認，由於使用這些服務，您與reok.pro之間不存在合資企業、合作夥伴關係、僱傭關係或代理關係。您同意不將自己視為reok.pro的代表、代理或員工。您同意，如果我們採取防止或解決我們平台濫用的行動，我們對您不承擔責任。您承認，為了讓您使用reok.pro，我們已經依賴您同意受本協議條款約束的協議。',
    },
    refund: {
        moneyBack: '退款保证',
        title: '退款政策',
        one: '我们的目标是为所有客户提供高质量的服务和成果。我们理解在某些情况下，成果可能不符合您的期望。',
        two: '我们保证您的满意。如果您对使用 Reok.pro 生成的照片不满意，我们将很乐意为您提供全额退款。另外，在某些情况下，我们可能会重新拍摄您的照片以更好地满足您的期望。',
        three: '根据本政策，我们将审核并回复退款请求，时间为 1-3 个工作日。如果您的退款获批准，资金应该会在 5-10 个工作日内退回您的卡上。退款将以原购买时使用的原始付款方式进行处理；我们无法退款至其他帐户。',
        four: '我们只能处理购买后 30 天内提交且使用少于 20 个积分的退款请求。',
        five: '要申请退款，请发送电子邮件至 hello@reok.pro 或使用我们网站上提供的聊天功能。在与我们联系退款时，请包含您下订单时使用的电子邮件地址。',
        six: '感谢您选择 Reok.pro 为您的头像服务。',
    },    
    faq: {
        title: '常見問題',
        q1: '為什麼應該使用 REOK 來拍專業照片？',
        answer1: '為了節省您的時間和金錢。我們使用強大的人工智慧技術創建了一個在線工作室。無需等待、化妝或擺姿勢拍照。它能夠幫助您快速創建美麗、專業和時尚的照片，用於工作或在Facebook、Instagram、Tiktok等社交媒體平台上發布。',
        q2: 'REOK 是如何工作的？',
        answer2: '只需自拍或從相冊中選擇一張清晰的面部照片，然後選擇您想要創建的照片數量和性別。REOK 的人工智慧將快速而準確地處理您的請求。',
        q3: '我的個人信息和設計數據是否安全？',
        answer3: '絕對安全，我們直接與 Google 平台合作。保護您的隱私對我們來說非常重要。REOK 承諾採取必要措施來保護您的個人信息和設計數據的安全。我們有強大的安全協議來確保您的數據得到安全保護，並僅用於提供我們的服務。詳細信息請參閱我們網站上的隱私政策。',
        q4: '我的設計會在系統中保存多久？',
        answer4: '只要我們的服務仍然運行，您的設計將永久存儲在雲端上。',
        q5: '拍一張工作或商業專業照片需要多少費用？',
        answer5: '拍一張專業照片僅需 10 個積分。這比前往照相館拍攝要便宜 10 倍。',
        q6: '刪除背景需要多少積分？',
        answer6: '刪除背景僅需 2 個積分一次。',
        q7: '對圖像進行去模糊需要多少積分？',
        answer7: '對圖像進行去模糊僅需 2 個積分一次。',
        q8: '設計家居、室內和室外是否需要費用？',
        answer8: '是的。genhomeai.com 是我們的應用之一，您將根據每個設計會話收費。',
        q9: '如果我不滿意，我可以退款嗎？',
        answer9: '非常抱歉，我们很抱歉地说不支持退款。对于运行的高价值AI服务，我们目前不提供退款支持，您应考虑在使用我们的免费信用后购买信用。',    
    },   
    "message": {
        "success": {
            "redering": "您的专业照片正在渲染，请稍候片刻",
            "rederingDone": "AI已完成设计的创建",
            "activated": "激活成功！享受创作美丽而令人满意的设计！",
            "removingBG": "正在移除您的背景",
            "upscale": "提升分辨率",
            "resetPassword": "我们刚刚发送了一封电子邮件来重置您的密码。请查收！",
            "signIn": "我们刚刚发送了一封电子邮件来激活您的帐户。请查收！",
            "reviewing": "我们已收到您的请求。将在一天内进行审核",
            "remindReviewing": "此照片正在审核中以索取新的积分",
            "removed": "成功删除照片",
            useTheTemplate: "使用模板，请上传您的自拍照"
        },
        "error": {
            "selectPhotographer": "请选择您的AI摄影师",
            "uploadPhoto": "请上传您的自拍照",
            "chooseRoom": "请选择房间类型",
            "chooseTheme": "请选择房间风格",
            "fileOverBasic": "请上传小于5MB的文件，或升级到专业版以上传更大的文件",
            "fileOverPro": "请上传小于10MB的文件",
            "wrongFile": "无效的文件，请尝试另一个文件",
            "wrongFace": "请不要把自拍照拍得太近，请尝试其他照片！",
            "busy": "连接中断，请重试",
            "gender": "请选择您的性别",
            "age": "请选择您的年龄",
            "style": "请选择您的风格",
            "numberOfResult": "请选择头像的数量",
            "outOfCredits": "您的积分已用完，请购买更多积分！",
            "userNotFound": "未找到此帐户。请正确输入您的帐户！",
            "wrongPassword": "密码错误！如果您忘记了密码，请使用忘记密码。",
            "accountExist": "帐户已存在。请尝试从此帐户登录",
            "tiktok": "出于Google用户安全原因，请使用Chrome或Safari浏览器。访问reok.pro进行访问。",
            "reviewing": "由于正在审核中，您无法删除此照片",
            "background": "请选择您的背景",
            "pose": "请选择您的姿势",
            "signUpReCaptcha": "请在注册前完成reCAPTCHA。",
            "emptyBG": "您的背景为空，请输入！",
            "tooShortBG": "您的背景应长于2个单词。",
            "tooLongBG": "您的背景太长了。",
            "badWordsBG": "您的背景包含不适当的词语。请尝试其他",
            "emptyStyle": "您的风格为空，请输入！",
            "tooShortStyle": "您的风格应长于2个单词。",
            "tooLongStyle": "您的风格太长了。",
            "badWordsStyle": "您的风格包含不适当的词语。请尝试其他",
        },
        "warning": {
            "runOutBasicCredits": "您已使用完基本计划的所有积分，请购买更多积分",
            "runOutProCredits": "请购买更多积分以使用 ^^",
            "selectPose": "请选择您的性别，然后选择姿势"
        },
        "prompt": {
            "professional": "专业照片",
            "removeBG": "去除背景",
            "upscale": "提升分辨率",
            "rendering": "AI渲染",
            "faceNotFound": "您的脸离得太近，请尝试其他自拍照！",
            "inReview": "审核中",
            "styles": {
                "business": "专业商务照片",
                "realEstate": "专业房地产照片",
                "carShowroom": "专业汽车销售照片",
                "doctor": "专业医生照片",
                "yearbook": "专业90年代年鉴照片",
                "wedding": "专业婚礼照片",
                "halloween": "万圣节照片"
            },
        },
        "maintain": {
            "backsoon": "我们正在维护此功能，很快将恢复。在此期间，请使用其他功能。谢谢！ 💗💗💗"
        }
    },
    "app": {
        "menu": {
            "creditTitle": "您的积分",
            "affiliateTitle": "联盟计划",
            "affiliateSub": "为您推荐的所有客户的付款获得25%的佣金！",
            "professionalMode": "我的工作室",
            "removeBGMode": "去除BG",
            "unBluryMode": "提升",
            "myPhotos": "我的头像",
        },
        "tryThese": "没有图像？尝试这些：",
        "goodSelfie": "好的自拍",
        "badSelfie": "糟糕的自拍",
        "trick": "使用提升来获得更好的质量",
        "removeBGSub": "使用AI快速、简便、准确地去除背景。",
        "professionalMode": {
            "title": "使用AI创建专业头像",
            "refundTitle": "我们的AI正在学习以获得出色的结果 💓",
            "creditTitle": "1张照片=10积分",
            "genderTitle": "选择性别",
            "ageTitle": "选择年龄",
            "styleTitle": "选择/自定义风格",
            "quantityTitle": "头像数量",
            "poseTitle": "选择/上传姿势",
            "backgroundTitle": "选择背景",
            "skipPose": "跳过以保留原始姿势",
            "defaultPose": "默认",
            "uploadedPose": "已上传",
            "defaultBackground": "背景",
            "defaultStyle": "风格",
            "headshotsTab": "头像",
            "selectPhotographer": "选择您的AI摄影师",
            "selectPhotographerSub": "请选择您喜欢的摄影师",
            "requirements": "遵循他的要求",
            "guideSub": "请逐步操作 👇"
        },
        "upscaleMode": {
            "title": "使用AI快速准确地去除模糊并增强您的照片。",
        }
    },
    "myDesign": {
        "title": "我的头像",
        "scrollTitle": "滚动查看所有设计。",
    },
    "imageComponent": {
        "compare": "比较",
        "download": "下载",
        "removeBG": "去除背景",
        "upscale": "提升",
        "errorPhoto": "更新糟糕照片"
    },
    "results": {
        "one": "1个头像",
        "four": "4个头像",
    },
    "genders": {
        "man": "男性",
        "woman": "女性"
    },
    "styles": {
        hoco_black: 'Hoco 黑色',
        date: '日期',
        hoco_pink: 'Hoco 粉色',
        sweet: '甜美',
        elite: '精英',
        luxury: '奢华',
        rich: '富裕',
        jean: '牛仔裤',
        tank_and_jeans: '背心和牛仔裤',
        athleisure: '运动休闲',
        bordo: '酒红色',
        cinematic: '电影风格',
        disney: '迪士尼',
        digital_art: '数字艺术',
        fantasy_art: '奇幻艺术',
        neonpunk: '霓虹朋克',
        comic_book: '漫画书',
        lowpoly: '低多边形',
        line_art: '线描艺术',
        "business": "商务",
        "realEstate": "房地产",
        "carShowroom": "汽车销售员",
        "doctor": "医生",
        "yearbook": "90年代年鉴",
        "wedding": "婚礼",
        "halloween": "万圣节",
        "gray_suit": "灰色西装",
        "white_suit": "白色西装",
        "black_suit": "黑色西装",
        "pink_suit": "粉色西装",
        "blue_suit": "蓝色西装",
        "white_shirt": "白色衬衫",
        "black_shirt": "黑色衬衫",
        "gray_shirt": "灰色衬衫",
        "blue_shirt": "蓝色衬衫",
        "pink_shirt": "粉色衬衫",
        "dating": "约会",
        "other": "其他",
        "custom": {
            "title": "风格和衣服",
            "btn": "开始定制",
            "dialogTitle": "您的风格和衣服",
            "dialogSubTitle": "告诉我们您的风格和衣服，我们的AI摄影师将尽力帮助您呈现出来！",
            "upgrade": "升级此功能",
            "example": "经典西装，不带领带，取决于您想要的..."
        }
    },
    "backgrounds": {
        "coffeeShop": "咖啡店",
        "office": "办公室",
        "cityStreet": "城市街道",
        "factory": "工厂",
        "garden": "花园",
        "carShowroom": "汽车展厅",
        "bookShelf": "书架",
        "hotel": "酒店",
        "goldenGate": "金门大桥",
        "snow": "雪",
        "realtor": "房地产经纪人",
        "brick": "砖",
        "custom": {
            "title": "背景",
            "btn": "开始定制",
            "dialogTitle": "您的背景",
            "dialogSubTitle": "告诉我们您的背景，我们的AI摄影师将尽力帮助您呈现出来！",
            "upgrade": "升级此功能",
            "example": "咖啡店，取决于您想要的位置..."
        }
    },
    "removeTitle": "移除此照片",
    "removeSub": "您确定要删除此照片吗？此照片将被永久删除。此操作无法撤消。",
    "renewTitle": "更新此照片",
    "renewSub": "我们将审核您的请求。如果此照片有问题，它将被永久删除。您将获得10个新积分。",
    "reasonTitle": "告诉我们为什么要更新它？",
    "reasonEx": "这太不正式了...",
    "photographers": {
        "sam": {
            "name": "Sam，👋 你好，顾客",
            "title": "我擅长风格",
            "intro": "我能做什么？",
            "intro1": "几秒钟内所有风格和心情",
            "intro2": "头像（Linkedin，🔥Tinder，Instagram，Facebook，X，Tiktok，Youtube，...）",
            "intro3": "商务照片",
            "intro4": "约会照片",
            "intro5": "艺术和有趣的照片"
        },
        "david": {
            "name": "David，👋 你好，顾客",
            "title": "我擅长姿势",
            "intro": "我能做什么？",
            "intro1": "几秒钟内所有姿势和风格",
            "intro2": "商务照片",
            "intro3": "约会照片",
            "intro4": "婚礼照片",
            "intro5": "全身照片"
        }
    }    
}

export default zh;