import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import AdminHeaderContainer from '../containers/AdminHeaderContainer';

const AnyRoute = ({ user }) =>
  <>
    <AdminHeaderContainer userData={user}/>
    <Outlet userData={user}/>
    <Footer/>
  </>

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AnyRoute);
