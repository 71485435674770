import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import * as routes from '../constants/routes';
import { theme } from '../constants/theme';
import styled from 'styled-components/macro'
import AdminHeaderContainer from '../containers/AdminHeaderContainer';
import i18n from '../i18n';

const AdminPrivateRoute = ({ user, component: Component }) => {

  return (
    user ? 
    <>
      {/* <AdminHeaderContainer userData={user}/> */}
      <Outlet/>
      {/* <Footer/> */}
    </> : <Navigate to={i18n.language === "en" ? "/signin" : `/${i18n.language}/signin`} replace />
  )
};

const mapStateToProps = state => ({
  user: state.user
});

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex: 1;
  main {
    flex: 1;
    .heading {
      background: linear-gradient(
        to right,
        ${theme.crcoCottonCandyBlue},
        ${theme.crcoCottonCandyPink}
      );
      padding: 10px 20px;
      text-align: center;
      z-index: 1;
      h1 {
        font-size: 1.2rem;
        margin: 0;
      }
      @media only screen and (min-width: ${theme.breakpointTablet}) {
        padding: 20px 30px;
        h1 {
          font-size: 1.4rem;
        }
      }
      @media only screen and (min-width: ${theme.breakpointDesktop}) {
        padding: 30px 40px;
        h1 {
          font-size: 1.6rem;
        }
      }
    }
  }
  .loading {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    margin: auto;
  }
  .beta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto 20px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    p {
      margin: 20px 0 40px 0;
    }
    @media only screen and (min-width: ${theme.breakpointTablet}) {
      margin: auto;
    }
  }
`

export default connect(mapStateToProps)(AdminPrivateRoute);
