import React, { Component } from 'react';
import { connect } from 'react-redux';
import withBackend from '../../backend/withBackend';
import { compose } from 'redux';
import { setUser } from '../../actions';
import Home from '../../components/Home';
import LandingPage from '../../components/LandingPage';

class LandingPageContainer extends Component {
  render() {
    return <LandingPage />;
  }
}

export default LandingPageContainer