const jp = {
    Basic: "基本",
    Pro: "プロ",
    month: "月",
    year: "年",
    button: {
        getStarted: 'はじめる',
        try: '無料トライアルを開始',
        viewall: 'すべて表示',
        basic: '基本',
        pro: 'プロ',
        generate: 'デザインを生成',
        backHome: 'ホームに戻る',
        googleSignin: 'Google',
        googleSignup: 'Google',
        emailSignin: 'メールでサインイン',
        emailSignup: 'メールでサインアップ',
        submit: '送信',
        choose: '選択',
        download: 'このテンプレートをダウンロード',
        upgrade: 'もっとクレジットを購入',
        subscribe: '購入する',
        upgradePro: 'アップグレード',
        confirmPayment: '支払いを確認',
        tryThisFeature: '今すぐ試してみる',
        removeBG: '開始',
        professional: '開始',
        upscale: '開始',
        ok: 'OK',
        cancel: 'キャンセル',
        select: '選択',
        selectToStart: '開始するには選択',
        uploadPose: 'ポーズをアップロード',
        uploadingPose: 'ポーズをアップロード中',
        upgradeToUpload: 'アップロードするにはアップグレード',
        unlock: "アップグレード",
        englishOnly: "英語版",
        contactUs: "こちらからお問い合わせください",
        forEnterprise: "企業向け",
        tryThis: "試す"
    },
    header: {
        nav: {
            signIn: 'サインイン',
            signUp: 'サインアップ',
            signOut: 'サインアウト',
            supportTitle: 'サポート',
            supportContact: 'お問い合わせ',
            myFlowers: '私のヘッドショット'
        }
    },
    landing: {
        title: 'プロのヘッドショット 📸',
        subTitle: "AIフォトグラファーで瞬時に洗練されたビジネスヘッドショットを数秒で生成します。効率的に時間とお金を節約しましょう。",
        input: '作成したい花の種類を教えてください？',
        inside: '室内',
        outside: '屋外',
        loveBy: 'すでに125,620件以上のAIヘッドショットが作成されています',
        respectTitle: 'プライバシーを尊重します',
        respectSub1: 'FaceIDや複数の写真は必要ありません。通常のFacebookやInstagramの投稿と同じくらいの単一のセルフィーを使用してください。',
        respectSub2: 'データはGoogleプラットフォームで完全に安全でセキュアです。',
        howItWorks: '使い方',
        howItWorksSub: '写真を撮るかアップロード -> スタイルと背景を選択 -> ポーズを選択またはアップロード -> ヘッドショットを受け取る！',
        unBlurryTitle: '写真アップスケーラー',
        unBlurrySub: '投稿前にFacebook、Instagram、Tiktokなどのソーシャルメディアプラットフォームでのブラーの除去、向上、および写真の色の向上。',
        useThisFeature: '今すぐ作成',
        anotherFeatureTitle: '優れた機能',
        anotherFeatureSub: '無料で高速かつ正確。以下の機能を試してみてください。',
        feature1: '背景の除去',
        feature1Sub: '正確に任意の背景を除去します。',
        feature2: 'インテリアデザイン',
        feature2Sub: 'genhomeai.comでのインテリアデザイン、エクステリアデザイン、およびスケッチ。',
        feature3: '背景の変更（近日公開予定）',
        feature3Sub: 'REOKのAIで任意のオブジェクトの背景を変更します。',
        hotFeatureTitle: 'お客様の声',
        hotFeatureSub: 'これらは彼らの許可を得て共有されたAI生成のヘッドショットの例です。',
        pricingTitle: '価格',
        pricingSub: '1枚のプロの写真 = 10クレジット以下',
        step1: '1つだけのセルフィーをアップロード',
        step1Sub: 'スタイル、背景、およびポーズを選択してください。',
        step2: 'AIが数秒で作業開始',
        step2Sub: '最先端の技術でヘッドショットを迅速に作成します。',
        step3: 'ヘッドショットを受け取る',
        step3Sub: 'ヘッドショットをダウンロードします。',
        trialTitle: "無料トライアル",
        trialSubTitle: "#1 AIヘッドショット生成器 📸",
        headshotsMenu: "ヘッドショット"
    },
    businessHeadshots: {
        title: 'ビジネス用ヘッドショット',
        subTitle: "ビジネス用ヘッドショットで究極のチームブランディング体験を解放しよう！",
        menuTitle: "ビジネス",
    },
    datingPhotos: {
        title: 'AIを使ったデート写真',
        subTitle: "デート成功を加速させよう：プロフィールを変えるAIの力を解放しよう！",
        menuTitle: "デート"
    },
    studentHeadshots: {
        title: '学生用ヘッドショット',
        subTitle: "秒でプロフェッショナルなAIヘッドショットで仕事をもらおう",
        menuTitle: "学生用ヘッドショット"
    },
    teacherHeadshots: {
        title: '教師用ヘッドショット',
        subTitle: "究極のプロフェッショナルエッジを解放しよう：教師よ、革命的なAIヘッドショット生成器でイメージを数分で変えよう！",
        menuTitle: "教師用ヘッドショット"
    },
    docterHeadshots: {
        title: '医師用ヘッドショット',
        subTitle: "AI強化ヘッドショットで個人ブランディングの未来を解放しよう！",
        menuTitle: "医師用ヘッドショット"
    },
    lawyerHeadshots: {
        title: '弁護士用ヘッドショット',
        subTitle: "第一印象の力を解放しよう：弁護士向けに特別に設計された革命的なAIヘッドショット！",
        menuTitle: "弁護士用ヘッドショット"
    },
    realeastateHeadshots: {
        title: '不動産用ヘッドショット',
        subTitle: "不動産の成功を促進し、印象的なAI生成ヘッドショットでマーケティングを向上させよう！",
        menuTitle: "不動産用ヘッドショット"
    },
    salespersonHeadshots: {
        title: 'セールスパーソン用ヘッドショット',
        subTitle: "売上を急上昇させる秘訣を解放し、革命的なAIヘッドショットでファーストインプレッションを変えよう！",
        menuTitle: "セールスパーソン用ヘッドショット"
    },
    yearbook: {
        title: 'AI年鑑写真撮影 📸',
        subTitle: "多くのセルフィー画像を使用しません。単一のセルフィーで90年代の年鑑写真を取得し、数秒で時間の10倍を節約します。",
        input: '作成したい花の種類を教えてください？',
        inside: '室内',
        outside: '屋外',
        loveBy: 'すでに125,620件以上のAIヘッドショットが作成されています',
        respectTitle: 'プライバシーを尊重します',
        respectSub1: 'FaceIDは使用せず、多くの写真も必要ありません。FacebookやInstagramでよく投稿するようなセルフィーを使用してください。',
        respectSub2: 'データはGoogleプラットフォームで完全に安全でセキュアです。',
        howItWorks: 'AI年鑑の動作方法',
        howItWorksSub: 'セルフィーを撮影するか、はっきりしたID写真を使用し、スタイルとポーズを選択して数秒でヘッドショットを撮影します。',
        unBlurryTitle: '写真アップスケーラー',
        unBlurrySub: '投稿前にFacebook、Instagram、Tiktokなどのソーシャルメディアプラットフォームでのブラーの除去、向上、および写真の色の向上。',
        useThisFeature: '今すぐ作成',
        anotherFeatureTitle: '優れた機能',
        anotherFeatureSub: '無料で高速かつ正確。以下の機能を試してみてください。',
        feature1: '背景の除去',
        feature1Sub: '正確に任意の背景を除去します。',
        feature2: 'インテリアデザイン',
        feature2Sub: 'genhomeai.comでのインテリアデザイン、エクステリアデザイン、およびスケッチ。',
        feature3: '背景の変更（近日公開予定）',
        feature3Sub: 'REOKのAIで任意のオブジェクトの背景を変更します。',
        hotFeatureTitle: '私たちのAI年鑑写真',
        hotFeatureSub: 'これらはAIによって生成されたヘッドショットです。',
        pricingTitle: '価格',
        pricingSub: '1枚のプロの写真 = 10クレジット以下',
    },
    showcase: {
        title: 'サンプル花',
        status: {
            pending: '処理中',
            upscaling: 'アップスケーリング中',
            choosing: '選択中',
            finished: '完了'
        },
        mode: {
            pro: 'プロ',
            basic: '基本'
        }
    },
    home: {
        title: 'あなたのデザイン',
        hotColletion: "ホットコレクション",
        credits: '残りのデザイン',
        uploadTitle: 'セルフィーをアップロード',
        uploadPlaceHolder: 'ドラッグアンドドロップ、タップして選択、または写真を撮る（最大10MB）',
        stylePlaceHolder: '選択',
        uploadPosePlaceHolder: '選択',
        uploadBGPlaceHolder: '選択',
        roomTitle: '部屋の種類を選択（29）',
        themeTitle: '部屋のスタイルを選択（34）',
        pro: {
            title: 'あらゆる種類の花の創造性',
            input: 'あなたの花を説明してください...',
        },
        basic: {
            title: 'プロと無制限のクリエイティビティ',
            chooseFlowerTitle: '花',
            flowers: 'リビングルーム',
            styles: 'スタイル',
            decoration: '装飾',
            events: 'イベント',
            backgrounds: '背景'
        },
    },
    profile: {
        title: '私のヘッドショット',
        before: 'Before -',
        after: 'After -'
    },
    terms: {
        title: '利用規約',
        content: ''
    },
    privacy: {
        title: 'プライバシーポリシー',
        one: 'あなたのプライバシーは私たちにとって非常に重要です。reok.proのプライバシーポリシーは、当社のウェブサイトreok.proおよび他の当社が所有および運営するウェブサイトから収集する可能性のある情報に関して、あなたのプライバシーを尊重します。',
        two: '本当にそれが必要である場合にのみ、私たちは個人情報を要求します。私たちは、公正かつ合法な手段でそれを収集し、あなたの知識と同意を得ています。また、なぜそれを収集してどのように使用されるかをお知らせします。',
        three: '私たちは要求されたサービスを提供するために必要な期間だけ情報を保持します。保存されているデータは、損失や盗難、および許可なくアクセス、開示、コピー、使用、または変更されないように、商業的に許容可能な手段で保護します。',
        four: '法律で要求される場合を除き、個人を特定できる情報は一般に公開せず、第三者と共有しません。',
        five: '当社のウェブサイトは、当社が運営していない外部サイトにリンクする場合があります。これらのサイトの内容と慣行については当社が制御しておらず、それらのプライバシーポリシーに対する責任または責任を受け入れることはできません。',
        six: 'お好みのサービスの一部を提供できない可能性があることを理解した上で、個人情報の提供を拒否することができます。',
        seven: '当社のウェブサイトの継続的な利用は、プライバシーと個人情報に関する当社の実践を受け入れたものと見なされます。ユーザーデータと個人情報の取り扱いに関する質問がある場合は、お気軽にお問い合わせください。',
        eight: 'このポリシーは2023年9月15日をもって有効です。'
    },
    refund: {
        moneyBack: '返金保証',
        title: '返金ポリシー',
        one: '私たちの目標は、すべてのお客様に高品質のサービスと成果を提供することです。成果物がお客様の期待に添わない場合があることを理解しています。',
        two: 'お客様の満足を保証します。Reok.proを使用して生成された写真にご不満がある場合、私たちは喜んで全額返金いたします。また、一部の場合には、写真をやり直してお客様の期待により適合するようにすることがあります。',
        three: 'このポリシーに基づいて返金の申請を審査し、1〜3営業日以内に回答いたします。返金が承認された場合、資金は5〜10営業日以内にカードに戻るはずです。返金は、購入時に使用した元の支払い方法に戻されます。異なるアカウントに返金することはできません。',
        four: '購入日から30日以内に提出されたリクエストと、20クレジット以下の使用が条件です。',
        five: '返金を申請するには、hello@reok.proまでメールでお問い合わせいただくか、ウェブサイトで利用可能なチャット機能をご利用ください。返金に関するお問い合わせの際には、注文時に使用したメールアドレスを含めてください。',
        six: 'ご利用いただき、Reok.proをお選びいただきありがとうございます。',
    },    
    footer: {
        title: 'REOK Studio: LinkedIn、ソーシャルメディア、履歴書、演技用のAIヘッドショット。一撃で結果が出ます。',
        terms: '利用規約',
        privacy: 'プライバシーポリシー',
        app: 'アプリ',
        signin: 'サインイン',
        signup: 'サインアップ',
        email: 'Eメール',
        chatNow: '今すぐチャット',
        partner: 'パートナー',
        reportBug: 'バグを報告',
        require: 'リクエストの送信',
        page: 'ページ',
        tos: '法的事項',
        contact: 'お問い合わせ',
        other: 'その他',
        affiliate: 'アフィリエイト',
        yearbook: 'AI年鑑',
        blog: 'ブログ',
        pricing: '価格',
        howItWorks: '使い方',
        businesses: "ビジネス",
        dating: "デート"
    },
    signin: {
        title: 'サインイン',
        another: 'またはEメールで',
        emailPlaceHolder: 'Eメールアドレス',
        passwordPlaceHolder: 'パスワード',
        rememberTitle: '次回のために覚えておく',
        forgetPassword: 'パスワードをお忘れですか？',
        noAccount: 'アカウントをお持ちでないですか？'
    },
    signup: {
        title: 'サインアップ',
        another: 'またはEメールで',
        firstNamePlaceHolder: 'ファーストネーム',
        lastNamePlaceHolder: 'ラストネーム',
        emailPlaceHolder: 'Eメールアドレス',
        passwordPlaceHolder: 'パスワード',
        rememberTitle: '次回のために覚えておく',
        forgetPassword: 'パスワードをお忘れですか？',
        gotAccount: 'すでにアカウントをお持ちですか？'
    },
    forgetPassword: {
        title: 'パスワードの取得',
        content: 'パスワードの取得メールをお送りします',
        emailPlaceHolder: 'メールアドレスを入力してください',
        sendBtn: '送信',
        backSignin: 'サインインに戻る'
    },
    pricing: {
        yearlySubscription: '1000デザイン',
        monthlySubscription: '100デザイン',
        saveDesk: '10%保存',
        saveMobile: '1000デザインの購入時に10%保存',
        popular: '82%がこのプランを選択',
        free: {
            title: '無料クレジット',
            price: '0$',
            trialTitle: '登録コード：サインインしてください',
            joinTitle: 'こちらにコードを貼り付けてください',
            one: '20の無料クレジットをゲット',
            two: 'すべての100クレジットの機能が含まれます',
            affiliateTitle: 'アフィリエイトプログラム',
            shareTitle: '友達と共有👇',
            loginToShare: '共有するにはサインインしてください'
        },
        basic: {
            title: '100クレジット',
            price: '7$',
            duration: '/生涯',
            recommend: '個人向け',
            one: '20のプロフェッショナルな写真',
            two: '背景を削除',
            three: '4Kにアップスケール',
            four: 'すべての写真をダウンロード',
            five: '無制限のクラウドストレージ',
            six: '写真の商用利用',
            seven: 'すべての新機能へのアクセス',
            subscribed: '購入済み'
        },
        pro: {
            title: '1000クレジット',
            subTitle: '(30%オフ)',
            price: '$49',
            duration: '/生涯',
            recommend: 'インフルエンサー、ビジネス、フォトスタジオ向け',
            one: '200のプロフェッショナルな写真',
            two: 'すべての100クレジットの機能が含まれます',
            three: 'お金を節約',
            subscribed: '購入済み'
        },
        credit: {
            title: '1ヘッドショット = 5クレジット。一度の支払い。'
        },
        first: {
            title: 'スターター - 200 クレジット',
            price: '$19',
            priceDetail: '$ 0.47 / 1枚の写真',
            duration: '/終生',
            recommend: '1枚の写真 = 5 クレジット',
            one: '40 枚の生成された写真',
            two: '背景の除去',
            three: '4K へのアップスケール',
            four: 'すべての写真をダウンロード',
            five: '無制限のクラウドストレージ',
            six: '写真の商業利用',
            seven: '数秒で写真を撮る',
            eight: '任意のスタイルと背景',
            nine: 'あなたのポーズをアップロード',
            ten: 'トレーニング時間なし'
        },
        second: {
            title: 'プロ - 2500 クレジット',
            subTitle: '(60%オフ)',
            price: '$99',
            priceDetail: '$ 0.19 / 1枚の写真',
            duration: '/終生',
            recommend: 'インフルエンサー、ビジネス、写真スタジオ',
            five: '$19プランのすべてと60%オフ',
            two: '500 枚の生成された写真',
            three: '制限なしの美しい写真',
            one: '購入して一生使用する、購読ではありません',
            six: 'スタジオに行かずに',
            four: '別の最大500人までの写真を生成',
            subscribed: '購入済み'
        },
        third: {
            title: 'プロのフォトグラファー',
            subTitle: '(ベストバリュー)',
            price: '$49',
            priceDetail: '$ 4.9 / 1枚の写真',
            duration: '/終生',
            recommend: 'インフルエンサー、ビジネス、写真スタジオ',
            one: 'どんなスタイルでもリクエスト',
            two: '10枚のプレミアム写真',
            three: 'プロのフォトグラファーによる',
            four: '1日での写真の配信',
            five: '高品質の写真',
            subscribed: '購入済み'
        },
        four: {
            title: 'エンタープライズソリューション',
            subTitle: '(お問い合わせください)',
            price: '$0',
            priceDetail: '$ 0 / 1枚の写真',
            duration: '/終生',
            recommend: 'インフルエンサー、ビジネス、写真スタジオ',
            one: '高品質の写真',
            two: '企業またはリモートチーム',
            three: '時間とお金を節約',
            four: '数秒で写真を撮る',
            five: '伝統的な写真撮影なし',
            subscribed: '購入済み',
        },
        currency: 'đ',
        perMonth: '/月',
        safePayment: '安全な支払い',
        qr: 'またはQRコードを使用'
    },
    milestoneMessage: {
        first: 'より幸せな顧客と',
        second: '作成されたデザイン'
    },
    "tos": {
        "agree": '新規アカウントは当社の',
        "one": "1. はじめに",
        "oneDetail": "reok.proを使用することで、これらの利用規約に同意し、拘束されます。",
        "two": "2. 利用規約への同意",
        "twoDetail": "この契約は、reok.proアプリを初めて使用した日から有効です。",
        "three": "3. 機能",
        "threeDetail": "特定の有料プランに加入した後、すべての機能を使用できます。利用可能な機能の詳細は価格ページで確認できます。",
        "four": "4. 返金ポリシー",
        "fourDetail": "購入後14日以内の取引エラーに対して返金を許可しています。返金をリクエストするにはサポートに連絡してください。",
        "five": "5. 製品の利用",
        "fiveDetail": "reok.proを使用することで、Googleアカウントに関連付けられたメールアドレスまたはアカウント登録時に使用したメールアドレスを介して、reok.proから重要な製品のアップデートを受け取ることに同意します。いつでもメールの下部にある「購読解除」リンクをクリックして、これらの製品のアップデートを受信しないように選択できます。重要な製品のアップデートのみを送信します。",
        "six": "6. 免責事項",
        "sixDetail": "reok.proがお客様の要件を満たすか、その運用が中断されないことを保証するものではありません。本契約に明示的に規定されていない保証または条件（利益の損失、データの損失または破損、業務の中断、その他のどんな性質の損害、コスト、または経費であっても、法律で許可される最大限度まで、除外および否認されます。この契約は、お客様が消費者として持っているかもしれない法定の権利に影響を与えません。",
        "seven": "7. 保証および法的制約",
        "sevenDetail": "reok.proは、ソフトウェアの品質、特定の目的への適合性、その他のソフトウェアの特性やパフォーマンスに関して、保証、保証、またはその他の表明を行いません。reok.proは、法律において除外できない限り、本契約またはreok.proの利用に関連するいかなる利益の損失または結果的、特別、付随的、または間接的な損害に対しても、お客様に対して責任を負いません（いかなる原因でも、過失を含む）。ただし、法律において責任を排除できない場合を除きます。",
        "eight": "8. 一般的な条件と法律",
        "eightDetail": "お客様とreok.proとの間で共同事業、パートナーシップ、雇用、または代理関係が存在しないことを認識します。お客様は、reok.proの代表、エージェント、または従業員としての出自を示すことはないことに同意します。reok.proのプラットフォームの誤用を防止または対処するために行うアクションに対して、reok.proはお客様に対して責任を負わないことに同意します。reok.proの使用を提供する際、お客様が本契約の条件に拘束されることに同意したことを認識します。"
    },
    "faq": {
        "title": "よくある質問",
        "q1": "なぜREOKを使用してプロの写真を撮る必要がありますか？",
        "answer1": "お時間とお金を節約するためです。強力なAIテクノロジーを使用したオンラインスタジオを作成しました。待つ必要はなく、メイクも不要で、写真のポーズも必要ありません。仕事やFacebook、Instagram、Tiktokなどのソーシャルメディアプラットフォームに投稿するために、迅速に美しい、プロフェッショナルでスタイリッシュな写真を作成するのに役立ちます。",
        "q2": "REOKはどのように機能しますか？",
        "answer2": "自撮りを撮るか、自分の顔がはっきり映るギャラリーから写真を選択し、性別と作成したい写真の数を選択するだけです。REOKのAIがリクエストを迅速かつ正確に処理します。",
        "q3": "私の個人情報とデザインデータは安全ですか？",
        "answer3": "もちろん、直接Googleのプラットフォームと連携しています。あなたのプライバシーを守ることは非常に重要です。REOKは、あなたの個人情報とデザインデータを保護するために必要な措置を講じることを約束します。私たちは、データが安全に保管され、サービス提供の目的以外に使用されないようにするための強力なセキュリティプロトコルを導入しています。詳細については、ウェブサイトのプライバシーポリシーをご参照ください。",
        "q4": "デザインはどのくらいの期間保存されますか？",
        "answer4": "サービスが稼働している限り、デザインはクラウド上に永久に保存されます。",
        "q5": "仕事やビジネス用にプロの写真を撮るのにいくらかかりますか？",
        "answer5": "プロの写真1枚につきわずか10クレジットかかります。これはスタジオで写真撮影に行くよりも10倍も安いです。",
        "q6": "背景を削除するのにいくつのクレジットがかかりますか？",
        "answer6": "背景を1回削除するのにわずか2クレジットかかります。",
        "q7": "画像をぼかし解除するのにいくつのクレジットがかかりますか？",
        "answer7": "画像をぼかし解除するのにわずか2クレジットかかります。",
        "q8": "家や室内、室外のデザインには料金がかかりますか？",
        "answer8": "はい。genhomeai.comは当社のアプリケーションの1つであり、各デザインセッションに対して料金がかかります。",
        "q9": "満足しない場合、返金できますか？",
        "answer9": '残念ながら、AIを稼働させる高額なものに対する返金はサポートしていません。無料クレジットを使用した後、クレジットの購入を検討してください。',
    },
    message: {
        success: {
            redering: 'あなたのプロの写真はレンダリング中です、しばらくお待ちください',
            rederingDone: 'AIがデザイン作成を終了しました',
            activated: 'アクティベーションが成功しました！美しい満足のいくデザインを作成してお楽しみください！',
            removingBG: '背景を削除中',
            upscale: '解像度をアップスケール中',
            resetPassword: 'パスワードのリセット用のメールを送信しました。確認してください！',
            signIn: 'アカウントをアクティブにするためのメールを送信しました。確認してください！',
            reviewing: 'リクエストを受け取りました。1日でレビューされます',
            remindReviewing: 'この写真は新しいクレジットを請求するためにレビュー中です',
            removed: '写真を正常に削除しました',
            useTheTemplate: "テンプレートを使用して、自撮り写真をアップロードしてください"
        },
        error: {
            selectPhotographer: 'AIフォトグラファーを選択してください',
            uploadPhoto: 'セルフィーをアップロードしてください',
            chooseRoom: '部屋の種類を選択してください',
            chooseTheme: '部屋のスタイルを選択してください',
            fileOverBasic: '5MB未満のファイルをアップロードするか、Proにアップグレードして大きなファイルをアップロードしてください',
            fileOverPro: '10MB未満のファイルをアップロードしてください',
            wrongFile: '無効なファイルです。別のファイルを試してください',
            wrongFace: 'セルフィーを近すぎずに撮影してください。別のものをお試しください！',
            busy: '接続が中断されました。もう一度お試しください',
            gender: '性別を選択してください',
            age: '年齢を選択してください',
            style: 'スタイルを選択してください',
            numberOfResult: 'ヘッドショットの数を選択してください',
            outOfCredits: 'クレジットが切れています。もっとクレジットを購入してください！',
            userNotFound: 'このアカウントは見つかりません。アカウントを正しく入力してください！',
            wrongPassword: 'パスワードが違います！パスワードを覚えていない場合は、パスワードをリセットしてください。',
            accountExist: 'アカウントはすでに存在します。このアカウントからログインしてみてください',
            tiktok: 'Googleユーザーセキュリティの理由で、ChromeまたはSafariブラウザを使用してください。reok.proを訪れてアクセスしてください。',
            reviewing: 'この写真はレビュー中のため、削除できません',
            background: '背景を選択してください',
            pose: 'ポーズを選択してください',
            signUpReCaptcha: 'サインアップ前にreCAPTCHAを完了してください。',
            emptyBG: '背景が空です、入力してください！',
            tooShortBG: '背景は2単語以上でなければなりません。',
            tooLongBG: '背景が長すぎます。',
            badWordsBG: '背景に不適切な言葉が含まれています。別のものを試してください',
            emptyStyle: 'スタイルが空です、入力してください！',
            tooShortStyle: 'スタイルは2単語以上でなければなりません。',
            tooLongStyle: 'スタイルが長すぎます。',
            badWordsStyle: 'スタイルに不適切な言葉が含まれています。別のものを試してください',
        },
        warning: {
            runOutBasicCredits: 'Basicプランのクレジットをすべて使用しました、もっとクレジットを購入してください',
            runOutProCredits: '使用するにはもっとクレジットを購入してください ^^',
            selectPose: 'ポーズを選択する前に性別を選択してください'
        },
        prompt: {
            professional: 'プロの写真',
            removeBG: '背景を削除',
            upscale: '解像度をアップスケール',
            rendering: 'AIレンダリング',
            faceNotFound: '顔が近すぎます、別のセルフィーをお試しください！',
            inReview: '審査中',
            styles: {
                business: 'プロのビジネス写真',
                realEstate: 'プロの不動産業者写真',
                carShowroom: 'プロのカーセールス写真',
                doctor: 'プロの医師写真',
                yearbook: 'プロの90年代の卒業アルバム写真',
                wedding: 'プロのウェディング写真',
                halloween: 'ハロウィン写真'
            },
        },
        maintain: {
            backsoon: 'この機能はメンテナンス中で、まもなく戻ります。その間、他の機能をご利用ください。ありがとうございます！ 💗💗💗'
        }
    },    
    app: {
        menu: {
            creditTitle: 'あなたのクレジット',
            affiliateTitle: 'アフィリエイトプログラム',
            affiliateSub: '私たちに紹介したすべてのお客様の支払いに対して25％の手数料を受け取ります！',
            professionalMode: '私のスタジオ',
            removeBGMode: '背景を削除',
            unBluryMode: 'アップスケール',
            myPhotos: '私のヘッドショット',
        },
        tryThese: '画像なし？これを試してみてください：',
        goodSelfie: '良いセルフィー',
        badSelfie: '悪いセルフィー',
        trick: '良い品質を得るためにアップスケールを使用してください',
        removeBGSub: 'AIを使用して背景を迅速かつ正確に削除します。',
        professionalMode: {
            title: 'AIを使用してプロのヘッドショットを作成する',
            refundTitle: '私たちのAIは素晴らしい結果を学んでいます 💓',
            creditTitle: '1枚の写真 = 10クレジット',
            genderTitle: '性別を選択',
            ageTitle: '年齢を選択',
            styleTitle: 'スタイルを選択/カスタマイズ',
            quantityTitle: '結果の数',
            poseTitle: 'ポーズを選択/アップロード',
            backgroundTitle: '背景を選択',
            skipPose: '元のポーズを保持するためにスキップ',
            defaultPose: 'デフォルト',
            uploadedPose: 'アップロード済み',
            defaultBackground: '背景',
            defaultStyle: 'スタイル',
            headshotsTab: 'ヘッドショット',
            selectPhotographer: 'AIフォトグラファーを選択',
            selectPhotographerSub: '好きなフォトグラファーを選んでください',
            requirements: '彼の要件に従ってください',
            guideSub: 'ステップバイステップで行ってください 👇'
        },
        upscaleMode: {
            title: 'アップスケールを使用して写真のぼかしを素早く正確に取り除きます。',
        }
    },
    myDesign: {
        title: '私のヘッドショット',
        scrollTitle: 'すべてのデザインを見るにはスクロールしてください。',
    },
    imageComponent: {
        compare: '比較',
        download: 'ダウンロード',
        removeBG: '背景を削除',
        upscale: 'アップスケール',
        errorPhoto: '不良写真を更新'
    },
    results: {
        one: '1ヘッドショット',
        four: '4ヘッドショット',
    },
    genders: {
        man: '男性',
        woman: '女性'
    },
    styles: {
        hoco_black: 'Hoco ブラック',
        date: '日付',
        hoco_pink: 'Hoco ピンク',
        sweet: '甘い',
        elite: 'エリート',
        luxury: '高級',
        rich: '豊か',
        jean: 'ジーンズ',
        tank_and_jeans: 'タンクトップ＆ジーンズ',
        athleisure: 'アスレジャー',
        bordo: 'ボルドー',
        cinematic: 'シネマティック',
        disney: 'ディズニー',
        digital_art: 'デジタルアート',
        fantasy_art: 'ファンタジーアート',
        neonpunk: 'ネオンパンク',
        comic_book: 'コミックブック',
        lowpoly: 'ローポリ',
        line_art: '線画',
        business: 'ビジネス',
        realEstate: '不動産業者',
        carShowroom: 'カーセールス',
        doctor: '医師',
        yearbook: '90年代の卒業アルバム',
        wedding: 'ウェディング',
        halloween: "ハロウィン",
        gray_suit: "灰色のスーツ",
        white_suit: "白いスーツ",
        black_suit: "黒いスーツ",
        pink_suit: "ピンクのスーツ",
        blue_suit: "青いスーツ",
        white_shirt: "白いシャツ",
        black_shirt: "黒いシャツ",
        gray_shirt: "灰色のシャツ",
        blue_shirt: "青いシャツ",
        pink_shirt: "ピンクのシャツ",
        dating: "デート",
        other: "その他",
        custom: {
            title: 'スタイルと服',
            btn: 'カスタムを開始',
            dialogTitle: 'あなたのスタイルと服',
            dialogSubTitle: 'あなたのスタイルと服を教えてください。AIフォトグラファーがお手伝いします！',
            upgrade: 'この機能をアップグレード',
            example: 'ネクタイのないクラシックなスーツ、あなたが望むものによります...',
        }
    },
    backgrounds: {
        coffeeShop: "カフェ",
        office: "オフィス",
        cityStreet: "市街地の通り",
        factory: "工場",
        garden: "庭",
        carShowroom: "カーショールーム",
        bookShelf: "本棚",
        hotel: "ホテル",
        goldenGate: "ゴールデンゲート",
        snow: "雪",
        realtor: "不動産業者",
        brick: "レンガ",
        custom: {
            title: '背景',
            btn: 'カスタムを開始',
            dialogTitle: 'あなたの背景',
            dialogSubTitle: 'あなたの背景を教えてください。AIフォトグラファーがお手伝いします！',
            upgrade: 'この機能をアップグレード',
            example: 'コーヒーショップ、あなたが望む場所によります...',
        },
    },
    removeTitle: 'この写真を削除する',
    removeSub: 'この写真を削除してもよろしいですか？この操作は元に戻すことができません。',
    renewTitle: 'この写真を更新',
    renewSub: 'リクエストを確認します。この写真に問題がある場合、それは永久に削除されます。新しいクレジットが10枚追加されます。',
    reasonTitle: 'なぜこれを更新したいのか教えてください',
    reasonEx: 'それは非公式だった...',
    photographers: {
        sam: {
            name: "サム、👋 こんにちはお客様",
            title: "私はスタイルが得意です",
            intro: "私ができることは？",
            intro1: "数秒で任意のスタイルとムード",
            intro2: "アバター（Linkedin、🔥Tinder、Instagram、Facebook、X、Tiktok、Youtube、...）",
            intro3: "ビジネス写真",
            intro4: "デート写真",
            intro5: "アート＆面白い写真"
        },
        david: {
            name: "デビッド、👋 こんにちはお客様",
            title: "私はポーズが得意です",
            intro: "私ができることは？",
            intro1: "数秒で任意のポーズとスタイル",
            intro2: "ビジネス写真",
            intro3: "デート写真",
            intro4: "ウェディング写真",
            intro5: "全身写真"
        }
    }
};

export default jp;