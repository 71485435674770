import React from 'react';
import { withFirebase } from '../services/firebase';
// import api from '../services/nodejs';

const withEngine = Component => props => {
    return (
        <Component {...props} engine={props.firebase} />
    );
}

const withBackend = (Component) => {
  const engine = withFirebase(withEngine(Component));
//   const ComponentWithNodeJs = withNodeJs(ComponentWithFirebase);

  return engine;
};

// export { withBackend };
export default withBackend;