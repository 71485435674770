import React, { Component } from 'react';
import { connect } from 'react-redux';
import withBackend from '../../backend/withBackend';
import { compose } from 'redux';
import { setUser } from '../../actions';
import Home from '../../components/Home';
import NewHome from '../../components/NewHome';

class HomeContainer extends Component {
  render() {
    const { setUser, firebase } = this.props;
    return <NewHome  firebase={firebase} setUser={setUser} />;
  }
}

export default compose(
  connect(null, { setUser }),
  withBackend
)(HomeContainer);