import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '../General/Avatar'
import SignOutContainer from '../../containers/SignOutContainer';
import {
  HomeOutlined,
  CustomerServiceOutlined,
  LoginOutlined
} from '@ant-design/icons';
import QuickLanguageSwitcher from '../QuickLanguageSwitcher';

const NewAvatarHeader = ({ userData, t, darkMode, onSelectDarkMode }) => {

  const dropdownRef = useRef(null);
  const dropdownDesktopRef = useRef(null);

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = (event) => {
      if (dropdownDesktopRef.current && !dropdownDesktopRef.current.contains(event.target)) {
        dropdownDesktopRef.current.classList.remove('newshow');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function onOpenTogglerDesktop(e) {
    e.stopPropagation();
    dropdownDesktopRef.current.classList.toggle('newshow');
  }

  function showSupport() {
    window.HubSpotConversations.widget.open();
  }

  function hideDropDown () {
    dropdownDesktopRef.current.classList.remove('newshow');
  }

  return (
    <nav className="navbar navbar-main navbar-height navbar-expand-lg theme-transparent">
      <div className="gap-3">
        <div id="nav" className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            {!userData?.emailVerified &&
              <Link
                to="/signin"
                className="btn btn-outline-light btn-sm rounded-pill text-nowrap"
                style={{}}
                type="default"
              >
                {t('button.getStarted')}
              </Link>}
            <div className="dropdown">
              <button
                className="navbar-dropdown-account-wrapper"
                id="accountNavbarDropdown"
                onClick={onOpenTogglerDesktop}
              >
                {userData?.emailVerified ? (
                  <>
                    <Avatar
                      size={40}
                      initials={`${userData?.firstName?.charAt(0).toUpperCase() ?? ""} ${userData?.lastName?.charAt(0).toUpperCase() ?? ""}`}
                    />
                    <span className='avatar-name'>{`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}</span>
                  </>
                ) : (
                  <span className="avatar flex-shrink-0 avatar-base avatar-circle">
                    <div className="avatar-initials bg-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        className="svg-icon rounded-0"
                      >
                        <g clipPath="url(#4f25a6d6acd8dd3fbe3e)">
                          <path
                            d="M8 0C3.58849 0 0 3.58849 0 8C0 12.4115 3.58849 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58849 12.4115 0 8 0ZM8 2.85712C9.57119 2.85712 10.8571 4.14313 10.8571 5.71431C10.8571 7.2855 9.57119 8.57144 8 8.57144C6.42881 8.57144 5.14286 7.2855 5.14286 5.71431C5.14286 4.14313 6.42881 2.85712 8 2.85712V2.85712ZM5.71429 9.71431H10.2857C11.8459 9.71431 13.1199 10.9781 13.1406 12.5335C11.8852 13.9566 10.052 14.8571 8 14.8571C5.94803 14.8571 4.11482 13.9566 2.85937 12.5335C2.88006 10.9781 4.15412 9.71431 5.71429 9.71431V9.71431Z"
                            fill="#495057"
                          />
                        </g>
                        <defs>
                          <clipPath id="4f25a6d6acd8dd3fbe3e">
                            <rect width={16} height={16} fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </span>
                )}
                <svg xmlns="http://www.w3.org/2000/svg" height={20} viewBox="0 0 18 18" fill="currentColor" className="relative w-6 h-6 text-gray-500 group-hover:text-gray-600"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
              {userData ? (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <div className="dropdown-item fw-semi-bold">
                    <SignOutContainer t={t} />
                  </div>
                  <div className="new-dropdown-divider order-5" />
                  {/* <div className="dropdown-item fw-semi-bold">
                    {t('header.nav.supportTitle')}
                  </div> */}
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={showSupport} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                  aria-labelledby="accountNavbarDropdown"
                  ref={dropdownDesktopRef}
                >
                  <Link className="dropdown-item fw-semi-bold" to="/signin">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signIn')}</span>
                  </Link>
                  <div className="new-dropdown-divider order-5" />
                  <a className="dropdown-item fw-semi-bold" href="/signup">
                    <LoginOutlined /><span style={{ marginLeft: "5px" }}>{t('header.nav.signUp')}</span>
                  </a>
                  <div className="new-dropdown-divider order-5" />
                  {/* <div className="dropdown-item fw-semi-bold">{t('header.nav.supportTitle')}</div> */}
                  <div className="order-5" onClick={hideDropDown}>
                    <a className="dropdown-item" onClick={showSupport} style={{ cursor: "pointer" }}>
                      <CustomerServiceOutlined />
                      <span style={{ marginLeft: "5px" }}>{t('header.nav.supportContact')}</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
            <div className="screen-mode-btn cursor-pointer" onClick={onSelectDarkMode}>
              {darkMode ? (
                <svg fill="#fff" width="18px" height="18px" viewBox="0 0 1.2 1.2" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>moon-solid</title>
                  <path d="M0.73 0.668a0.302 0.302 0 0 1 -0.158 -0.557A0.343 0.343 0 0 0 0.487 0.1a0.35 0.35 0 0 0 0 0.7 0.346 0.346 0 0 0 0.273 -0.134 0.3 0.3 0 0 1 -0.03 0.002"/>
                  <path x="0" y="0" width="36" height="36" fillOpacity="0" d="M0 0H0.9V0.9H0V0z"/>
                </svg>
              ):(
                <svg width="18px" height="18px" viewBox="0 0 0.8 0.8" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_429_11039)">
                    <path cx="12" cy="12" r="4" stroke="#fff" strokeWidth="0.0625" strokeLinejoin="round" d="M0.4 0.3A0.1 0.1 0 0 1 0.3 0.4A0.1 0.1 0 0 1 0.2 0.3A0.1 0.1 0 0 1 0.4 0.3z"/>
                    <path d="M0.5 0.3h0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="M0.075 0.3h0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="M0.3 0.5v0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="M0.3 0.075v0.025" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="m0.441 0.441 0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="m0.141 0.141 0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="m0.159 0.441 -0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                    <path d="m0.459 0.141 -0.018 0.018" stroke="#fff" strokeWidth="0.0625" strokeLinecap="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_429_11039">
                      <path width="24" height="24" fill="#fff" d="M0 0H0.6V0.6H0V0z"/>
                    </clipPath>
                  </defs>
                </svg>
              )}

            </div>
            <div className="pl-2">
              <QuickLanguageSwitcher/>
            </div>
          </div>
      </div>
    </nav>
  )
}

export default NewAvatarHeader
