import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, that page doesn’t exist!</p>
    </div>
  );
};

export default NotFound;
