import React, { useEffect } from 'react';
import Spinner from '../Spinner';

function Affiliate() {
  useEffect(() => {
    // Redirect to the external affiliate link when the component mounts
    window.location.href = 'https://reok.lemonsqueezy.com/affiliates';
  }, []);

  return (
    <Spinner></Spinner>
  );
}

export default Affiliate;
