const ko = {
  Basic: "기본",
  Pro: "프로",
  month: "월",
  year: "년",
  button: {
      getStarted: '시작하기',
      try: '무료 트라이얼 시작',
      viewall: '모두 보기',
      basic: '기본',
      pro: '프로',
      generate: '디자인 생성',
      backHome: '홈으로 돌아가기',
      googleSignin: 'Google',
      googleSignup: 'Google',
      emailSignin: '이메일로 로그인',
      emailSignup: '이메일로 가입',
      submit: '제출',
      choose: '선택',
      download: '이 템플릿 다운로드',
      upgrade: '더 많은 크레딧 구매',
      subscribe: '구매하기',
      upgradePro: '업그레이드',
      confirmPayment: '결제 확인',
      tryThisFeature: '지금 이 기능 시도',
      removeBG: '시작',
      professional: '시작',
      upscale: '시작',
      ok: '확인',
      cancel: '취소',
      select: '선택',
      selectToStart: '선택하여 시작',
      uploadPose: '포즈 업로드',
      uploadingPose: '포즈 업로드 중',
      upgradeToUpload: '업로드하려면 업그레이드',
      unlock: "업그레이드",
      englishOnly: "영어 버전",
      contactUs: "여기에서 문의하세요",
      forEnterprise: "기업용",
      tryThis: "시도"
  },
  header: {
      nav: {
          signIn: '로그인',
          signUp: '가입하기',
          signOut: '로그아웃',
          supportTitle: '지원',
          supportContact: '문의하기',
          myFlowers: '내 헤드샷'
      }
  },
  landing: {
    title: '전문적인 헤드샷 📸',
    subTitle: "AI 포토그래퍼를 사용하여 몇 초만에 정제된 비즈니스 헤드샷을 즉시 생성하세요. 손쉽게 시간과 비용을 절약하세요.",
    input: '만들고 싶은 꽃 종류를 말해주세요?',
    inside: '실내',
    outside: '야외',
    loveBy: '이미 125,620개 이상의 AI 헤드샷이 생성되었습니다',
    respectTitle: '당신의 개인 정보를 존중합니다',
    respectSub1: 'FaceID나 여러 사진은 필요하지 않습니다. 페이스북이나 인스타그램 게시물처럼 일반적인 셀피 하나만 사용하면 됩니다.',
    respectSub2: '귀하의 데이터는 Google 플랫폼에서 완전히 안전하고 안전합니다.',
    howItWorks: '사용 방법',
    howItWorksSub: '촬영 또는 업로드 -> 스타일과 배경 선택 -> 포즈 선택 또는 업로드 -> 헤드샷 받기!',
    unBlurryTitle: '사진 업스케일러',
    unBlurrySub: '소셜 미디어 플랫폼에 게시하기 전에 페이스북, 인스타그램, 틱톡과 같은 플랫폼에서 블러 제거, 향상 및 사진 색상 개선.',
    useThisFeature: '지금 만들기',
    anotherFeatureTitle: '특출난 기능',
    anotherFeatureSub: '무료, 빠르고 정확합니다. 아래의 기능을 시도해보세요.',
    feature1: '배경 제거',
    feature1Sub: '정확하게 어떤 배경이든 제거합니다.',
    feature2: '인테리어 디자인',
    feature2Sub: 'genhomeai.com에서의 인테리어 디자인, 익스테리어 디자인 및 스케치.',
    feature3: '배경 변경 (곧 출시 예정)',
    feature3Sub: 'REOK의 AI로 모든 물체의 배경 변경.',
    hotFeatureTitle: '고객 평가',
    hotFeatureSub: '이들은 고객 허락을 받아 공유된 AI 생성 헤드샷 예시입니다.',
    pricingTitle: '가격',
    pricingSub: '1장의 전문 사진 = 10 크레딧 이하',
    step1: '셀피 1장만 업로드',
    step1Sub: '스타일, 배경 및 포즈를 선택하세요.',
    step2: '우리의 AI가 몇 초 안에 작업 시작',
    step2Sub: '최첨단 기술로 빠르게 헤드샷을 생성합니다.',
    step3: '헤드샷 받기',
    step3Sub: '헤드샷을 다운로드하세요.',
    trialTitle: "무료 체험",
    trialSubTitle: "#1 AI 헤드샷 생성기 📸",
    headshotsMenu: "헤드샷"
  },
    businessHeadshots: {
        title: '비즈니스 헤드샷',
        subTitle: "비즈니스 헤드샷으로 궁극적인 팀 브랜딩 경험을 언락하세요!",
        menuTitle: "비즈니스",
    },
    datingPhotos: {
        title: 'AI를 활용한 데이트 사진',
        subTitle: "데이트 성공을 폭발시키세요: 프로필을 변화시키는 AI의 힘을 발휘하세요!",
        menuTitle: "데이트"
    },
    studentHeadshots: {
        title: '학생 헤드샷',
        subTitle: "초 단위로 더 많은 일을 얻으세요. 프로페셔널한 AI 헤드샷으로",
        menuTitle: "학생 헤드샷"
    },
    teacherHeadshots: {
        title: '교사 헤드샷',
        subTitle: "최고의 프로페셔널 엣지를 경험하세요: 교사여러분, 혁신적인 AI 헤드샷 생성기로 몇 분 안에 이미지를 변환하세요!",
        menuTitle: "교사 헤드샷"
    },
    docterHeadshots: {
        title: '의사 헤드샷',
        subTitle: "AI 향상 헤드샷으로 개인 브랜딩의 미래를 경험하세요! 의료 전문가들을 위한",
        menuTitle: "의사 헤드샷"
    },
    lawyerHeadshots: {
        title: '변호사 헤드샷',
        subTitle: "첫인상의 힘을 경험하세요: 변호사들을 위한 혁신적인 AI 헤드샷!",
        menuTitle: "변호사 헤드샷"
    },
    realeastateHeadshots: {
        title: '부동산 헤드샷',
        subTitle: "부동산 성공을 촉진하고 인상적인 AI 생성 헤드샷으로 마케팅을 향상시키세요!",
        menuTitle: "부동산 헤드샷"
    },
    salespersonHeadshots: {
        title: '영업사원 헤드샷',
        subTitle: "판매를 폭발시키는 비밀을 경험하세요. 혁신적인 AI 헤드샷으로 첫인상을 바꾸세요!",
        menuTitle: "영업사원 헤드샷"
    },
  yearbook: {
      title: 'AI 연감 사진 촬영 📸',
      subTitle: "많은 셀피 이미지를 사용하지 않습니다. 단일 셀피로 90년대 연감 사진을 AI 포토그래퍼로 몇 초 만에 얻으세요. 시간의 10배를 절약하세요.",
      input: '만들고 싶은 꽃 종류를 말해주세요?',
      inside: '실내',
      outside: '야외',
      loveBy: '이미 125,620개 이상의 AI 헤드샷이 생성되었습니다',
      respectTitle: '당신의 개인 정보를 존중합니다',
      respectSub1: 'FaceID를 사용하지 않으며 수많은 사진이 필요하지 않습니다. 페이스북이나 인스타그램에 자주 올리는 셀피를 그대로 사용하세요.',
      respectSub2: '귀하의 데이터는 Google 플랫폼에서 완전히 안전하고 안전합니다.',
      howItWorks: 'AI 연감의 작동 방식',
      howItWorksSub: '셀피를 찍거나 명확한 ID 사진을 사용하고 스타일 및 포즈를 선택하여 몇 초만에 헤드샷을 찍습니다!',
      unBlurryTitle: '사진 업스케일러',
      unBlurrySub: '소셜 미디어 플랫폼에 게시하기 전에 페이스북, 인스타그램, 틱톡과 같은 플랫폼에서 블러 제거, 향상 및 사진 색상 개선.',
      useThisFeature: '지금 만들기',
      anotherFeatureTitle: '특출난 기능',
      anotherFeatureSub: '무료, 빠르고 정확합니다. 아래의 기능을 시도해보세요.',
      feature1: '배경 제거',
      feature1Sub: '정확하게 어떤 배경이든 제거합니다.',
      feature2: '인테리어 디자인',
      feature2Sub: 'genhomeai.com에서의 인테리어 디자인, 익스테리어 디자인 및 스케치.',
      feature3: '배경 변경 (곧 출시 예정)',
      feature3Sub: 'REOK의 AI로 모든 물체의 배경 변경.',
      hotFeatureTitle: '우리의 AI 연감 사진',
      hotFeatureSub: '이들은 AI에 의해 생성된 헤드샷입니다.',
      pricingTitle: '가격',
      pricingSub: '1장의 전문 사진 = 10 크레딧 이하',
  },
  showcase: {
      title: '샘플 꽃',
      status: {
          pending: '처리 중',
          upscaling: '업스케일링 중',
          choosing: '선택 중',
          finished: '완료'
      },
      mode: {
          pro: '프로',
          basic: '기본'
      }
  },
  home: {
      title: '당신의 디자인',
      hotColletion: "인기 컬렉션",
      credits: '남은 디자인',
      uploadTitle: '셀피를 업로드하세요',
      uploadPlaceHolder: '드래그 앤 드롭, 선택하려면 탭 또는 사진 찍기 (최대 10MB)',
      stylePlaceHolder: '선택',
      uploadPosePlaceHolder: '선택',
      uploadBGPlaceHolder: '선택',
      roomTitle: '방 유형 선택 (29)',
      themeTitle: '방 스타일 선택 (34)',
      pro: {
          title: '모든 종류의 꽃에 대한 창의성',
          input: '꽃을 설명해주세요...',
      },
      basic: {
          title: '프로와 무제한의 창의성',
          chooseFlowerTitle: '꽃',
          flowers: '거실',
          styles: '스타일',
          decoration: '장식',
          events: '이벤트',
          backgrounds: '배경'
      },
  },
  profile: {
      title: '내 헤드샷',
      before: '이전 -',
      after: '이후 -'
  },
  terms: {
      title: '이용 약관',
      content: ''
  },
  privacy: {
      title: '개인 정보 정책',
      one: '귀하의 개인 정보는 저희에게 매우 중요합니다. reok.pro의 개인 정보 정책은 당사의 웹 사이트 reok.pro 및 기타 소유 및 운영하는 웹 사이트에서 귀하로부터 수집할 수 있는 정보에 관한 귀하의 개인 정보를 존중합니다.',
      two: '우리는 정말로 서비스를 제공하기 위해 그것이 필요한 경우에만 개인 정보를 요청합니다. 우리는 공정하고 합법적인 방법으로 귀하의 동의와 지식을 가지고 그것을 수집합니다. 또한 왜 그것을 수집하고 어떻게 사용될 것인지 알려 드립니다.',
      three: '우리는 귀하가 요청한 서비스를 제공하기 위해 필요한 기간 동안만 수집 된 정보를 보존합니다. 저장된 데이터는 상업적으로 허용 가능한 수단으로 손실 및 도난, 무단 액세스, 공개, 복사, 사용 또는 수정을 방지하기 위해 보호됩니다.',
      four: '법률에 의해 요구되지 않는 한 개인을 식별 할 수있는 정보는 공개하지 않으며 제3자와 공유하지 않습니다.',
      five: '당사의 웹 사이트는 당사가 운영하지 않는 외부 사이트에 연결할 수 있습니다. 이러한 사이트의 콘텐츠 및 실천 방법에 대한 통제권이 없으므로 그들의 개인 정보 취급 방침에 대한 책임이나 책임을 수용 할 수 없음을 알려드립니다.',
      six: '원하는 서비스 중 일부를 제공 할 수없는 경우가 있으므로 개인 정보 제공을 거부할 수 있습니다.',
      seven: '당사 웹 사이트의 계속적인 사용은 개인 정보 및 개인 정보에 대한 당사의 실천을 수락으로 간주됩니다. 사용자 데이터 및 개인 정보를 처리하는 방법에 관한 궁금한 점이 있으면 언제든지 문의하십시오.',
      eight: '이 정책은 2023년 9월 15일부터 유효합니다.'
  },
  footer: {
      title: 'REOK Studio: LinkedIn, 소셜 미디어, 이력서, 연기용 AI 헤드샷. 몇 초만에 결과물이 나옵니다.',
      terms: '이용 약관',
      privacy: '개인 정보 정책',
      app: '앱',
      signin: '로그인',
      signup: '가입',
      email: '이메일',
      chatNow: '지금 채팅',
      partner: '파트너',
      reportBug: '버그 신고',
      require: '요청 제출',
      page: '페이지',
      tos: '법률',
      contact: '문의',
      other: '기타',
      affiliate: '제휴사',
      yearbook: 'AI 연감',
      blog: '블로그',
      pricing: '가격',
      howItWorks: '작동 방식',
      businesses: "비즈니스",
      dating: "데이트"
  },
  signin: {
      title: '로그인',
      another: '또는 이메일로',
      emailPlaceHolder: '이메일 주소',
      passwordPlaceHolder: '비밀번호',
      rememberTitle: '다음 번에 기억하기',
      forgetPassword: '비밀번호를 잊으셨나요?',
      noAccount: '계정이 없으신가요?'
  },
  signup: {
      title: '가입',
      another: '또는 이메일로',
      firstNamePlaceHolder: '이름',
      lastNamePlaceHolder: '성',
      emailPlaceHolder: '이메일 주소',
      passwordPlaceHolder: '비밀번호',
      rememberTitle: '다음 번에 기억하기',
      forgetPassword: '비밀번호를 잊으셨나요?',
      gotAccount: '이미 계정이 있으신가요?'
  },
  forgetPassword: {
      title: '비밀번호 찾기',
      content: '비밀번호 검색 이메일을 보내드립니다',
      emailPlaceHolder: '이메일을 입력하세요',
      sendBtn: '보내기',
      backSignin: '로그인으로 돌아가기'
  },
  pricing: {
      yearlySubscription: '1000 디자인',
      monthlySubscription: '100 디자인',
      saveDesk: '10% 저장',
      saveMobile: '1000 디자인 구매 시 10% 저장',
      popular: '82%가이 플랜 선택',
      free: {
          title: '무료 크레딧',
          price: '0$',
          trialTitle: '등록 코드 : 로그인하세요',
          joinTitle: '여기에 코드를 붙여 넣으세요',
          one: '무료 크레딧 20 개 받기',
          two: '모든 100 크레딧 기능 포함',
          affiliateTitle: '제휴 프로그램',
          shareTitle: '친구들과 공유👇',
          loginToShare: '공유하려면 로그인하십시오'
      },
      basic: {
          title: '100 크레딧',
          price: '7$',
          duration: '/평생',
          recommend: '개인용',
          one: '20 프로페셔널 사진',
          two: '배경 제거',
          three: '4K로 업스케일',
          four: '모든 사진 다운로드',
          five: '무제한 클라우드 스토리지',
          six: '사진 상업적 이용',
          seven: '모든 새로운 기능에 액세스',
          subscribed: '구입 됨'
      },
      pro: {
          title: '1000 크레딧',
          subTitle: '(30% 할인)',
          price: '$49',
          duration: '/평생',
          recommend: '인플루언서, 비즈니스, 포토 스튜디오',
          one: '200 프로페셔널 사진',
          two: '모든 100 크레딧 기능 포함',
          three: '돈 절약',
          subscribed: '구입 됨'
      },
      credit: {
          title: '1 헤드샷 = 5 크레딧. 일회성 결제.'
      },
      first: {
        title: '스타터 - 200 크레딧',
        price: '$19',
        priceDetail: '$ 0.47 / 셀카',
        duration: '/평생',
        recommend: '1 셀카 = 5 크레딧',
        one: '40장의 생성된 셀카',
        two: '배경 제거',
        three: '4K로 업스케일링',
        four: '모든 사진 다운로드',
        five: '무제한 클라우드 저장소',
        six: '사진 상업적 이용',
        seven: '몇 초만에 셀카 찍기',
        eight: '원하는 스타일 및 배경',
        nine: '자신의 포즈 업로드',
        ten: '훈련 시간 없음'
    },
    second: {
        title: '프로 - 2500 크레딧',
        subTitle: '(60% 할인)',
        price: '$99',
        priceDetail: '$ 0.19 / 셀카',
        duration: '/평생',
        recommend: '인플루언서, 비즈니스, 사진 스튜디오',
        five: '$19 플랜의 모든 기능 및 60% 할인',
        two: '500장의 생성된 셀카',
        three: '제한 없이 아름다운 셀카',
        one: '구매 후 영구 사용, 구독 아님',
        six: '스튜디오에 가지 않고도',
        four: '최대 500명까지의 다른 사람을 위한 셀카 생성',
        subscribed: '구매 완료'
    },
    third: {
        title: '프로 카메라맨',
        subTitle: '(최고의 가치)',
        price: '$49',
        priceDetail: '$ 4.9 / 셀카',
        duration: '/평생',
        recommend: '인플루언서, 비즈니스, 사진 스튜디오',
        one: '어떤 스타일이든 요청',
        two: '프리미엄 셀카 10장',
        three: '전문 카메라맨에 의한',
        four: '하루에 셀카 배송',
        five: '고품질 셀카',
        subscribed: '구매 완료'
    },
    four: {
        title: '기업 솔루션',
        subTitle: '(문의)',
        price: '$0',
        priceDetail: '$ 0 / 셀카',
        duration: '/평생',
        recommend: '인플루언서, 비즈니스, 사진 스튜디오',
        one: '고품질 셀카',
        two: '회사 또는 원격 팀',
        three: '시간과 비용 절약',
        four: '몇 초 만에 셀카 찍기',
        five: '전통적인 사진 촬영 없이',
        subscribed: '구매 완료',
    },
      currency: 'đ',
      perMonth: '/월',
      safePayment: '안전한 결제',
      qr: '또는 QR 코드 사용'
    },
    milestoneMessage: {
        first: '더 행복한 고객과 함께',
        second: '만들어진 디자인'
    },
    "tos": {
        agree: '새로운 계정은 당사의',
        "one": "1. 소개",
        "oneDetail": "reok.pro를 사용함으로써 귀하는 이용 약관에 동의하며 그에 따라 구속됩니다.",
        "two": "2. 약관 동의",
        "twoDetail": "이 계약은 reok.pro 앱을 처음 사용한 날부터 효력이 발생합니다.",
        "three": "3. 기능",
        "threeDetail": "특정 유료 플랜을 구독한 후에 모든 기능을 사용할 수 있습니다. 사용 가능한 기능에 대한 자세한 내용은 가격 페이지에서 확인할 수 있습니다.",
        "four": "4. 환불 정책",
        "fourDetail": "실패한 거래에 대한 환불을 14일 이내로 지원합니다. 환불을 요청하려면 지원팀에 문의하십시오.",
        "five": "5. 제품 이용",
        "fiveDetail": "reok.pro를 사용함으로써 귀하는 Google 계정 또는 귀하의 계정 가입 이메일과 관련된 이메일을 통해 중요한 제품 업데이트를 수신하는 것에 동의합니다. 각 이메일 하단의 '구독 취소' 링크를 클릭하여 언제든지 이러한 제품 업데이트 수신을 거부할 수 있습니다. 우리는 중요한 제품 업데이트만 보냅니다.",
        "six": "6. 면책 조항",
        "sixDetail": "reok.pro가 귀하의 요구 사항을 충족시키거나 그 운영이 중단되거나 오류가 없을 것을 보증하지 않습니다. 이 계약에서 명시되지 않은 모든 보증 또는 조건(예: 이익 손실, 데이터 손실 또는 훼손, 사업 중단 또는 어떠한 성격의 어떠한 손실, 피해, 비용 또는 경비도 포함하지 않음)은 법률이 허용하는 한도 내에서 제외되고 부인됩니다. 이 계약은 소비자로서 귀하의 법적 권리에 영향을 미치지 않습니다.",
        "seven": "7. 보증 및 법적 제한",
        "sevenDetail": "reok.pro는 소프트웨어의 품질, 특정 목적에 대한 적합성 또는 기타 성격 또는 성능에 대한 어떠한 보증, 보장 또는 다른 표현도 하지 않습니다. reok.pro는 법률에 따라 책임을 지울 수 없을 때를 제외하고 귀하에게 이용 약관 또는 reok.pro 사용과 관련하여 발생하는 이익 손실 또는 이로 인한 간접적인, 특수, 결과적 또는 인과 관계의 손해에 대해 어떠한 책임도 지지 않습니다.",
        "eight": "8. 일반 조항 및 법률",
        "eightDetail": "귀하는 reok.pro를 사용함으로써 귀하와 reok.pro 간에 합작법인, 제휴, 고용 또는 대리인 관계가 존재하지 않음을 인정합니다. 귀하는 자신을 reok.pro의 대표, 에이전트 또는 직원으로 포장하거나 내세우지 않을 것을 동의합니다. 귀하는 reok.pro 플랫폼의 오용을 방지하거나 처리하기 위해 우리가 취하는 조치에 대한 어떠한 책임도 reok.pro에게 묻지 않을 것을 동의합니다. 귀하는 reok.pro의 사용을 제공하기 위한 목적으로 귀하의 동의를 얻기 위해 필요한 조치를 취했다고 인정합니다.",
    },
    refund: {
      moneyBack: '환불 보장',
      title: '환불 정책',
      one: '우리의 목표는 모든 고객에게 고품질의 서비스와 결과물을 제공하는 것입니다. 결과물이 고객의 기대에 부합하지 않을 수 있는 상황을 이해합니다.',
      two: '당신의 만족을 보장합니다. Reok.pro를 사용하여 생성된 사진에 만족하지 않는 경우, 우리는 기꺼이 전액 환불해 드립니다. 또한 경우에 따라 사진을 다시 촬영하여 고객의 기대에 더욱 부응시킬 수도 있습니다.',
      three: '이 정책에 따라 환불 요청을 검토하고 회신하며, 최대 1-3 영업일 내에 회신합니다. 환불이 승인되면 자금은 5-10 영업일 내에 카드에 반환될 것입니다. 환불은 구매 시 사용한 원래 결제 방법으로 처리됩니다. 다른 계정으로 환불할 수 없습니다.',
      four: '구매 후 30일 이내에 제출된 환불 요청과 20 크레딧 이하의 사용이 필요합니다.',
      five: '환불을 신청하려면 hello@reok.pro로 이메일을 보내시거나 웹 사이트에서 제공되는 채팅 기능을 사용하십시오. 환불에 관한 문의시 주문시 사용한 이메일 주소를 포함해 주시기 바랍니다.',
      six: '헤드샷에 대해 Reok.pro를 선택해 주셔서 감사합니다.',
  },  
    "faq": {
        "title": "자주 묻는 질문",
        "q1": "왜 전문 사진 촬영을 위해 REOK을 사용해야 하나요?",
        "answer1": "시간과 비용을 절약하기 위해서입니다. 우리는 강력한 AI 기술을 사용하여 온라인 스튜디오를 만들었습니다. 기다릴 필요 없이 메이크업을 하거나 사진 촬영을 위해 포즈를 취할 필요가 없습니다. 이것은 작업용이거나 Facebook, Instagram, Tiktok 등의 소셜 미디어 플랫폼에 게시할 아름다운 프로페셔널하고 스타일리시한 사진을 빠르게 만들어줍니다.",
        "q2": "REOK는 어떻게 작동하나요?",
        "answer2": "셀카를 찍거나 얼굴이 명확하게 나와 있는 갤러리 사진을 선택한 후 성별과 생성할 사진 수를 선택하세요. REOK의 AI가 귀하의 요청을 신속하고 정확하게 처리합니다.",
        "q3": "내 개인 정보와 디자인 데이터는 안전한가요?",
        "answer3": "절대로 안전합니다. 우리는 Google의 플랫폼과 직접 작업하고 있습니다. 귀하의 개인 정보를 보호하는 것은 저희에게 매우 중요합니다. REOK는 귀하의 개인 정보와 디자인 데이터를 안전하게 보호하고 서비스 제공 목적으로만 사용하기 위한 필요한 조치를 취하기로 헌신합니다. 자세한 정보는 웹사이트의 개인 정보 정책을 참조하세요.",
        "q4": "내 디자인은 얼마나 오래 보관됩니까?",
        "answer4": "귀하의 디자인은 우리 서비스가 계속 운영되는 한 영구적으로 클라우드에 저장됩니다.",
        "q5": "작업 또는 비즈니스용 프로 사진 촬영 비용은 얼마인가요?",
        "answer5": "프로 사진 1장당 10 크레딧이 듭니다. 이것은 스튜디오로 가서 사진 촬영하는 것보다 10배 저렴합니다.",
        "q6": "배경을 제거하는 데 얼마나 많은 크레딧이 들까요?",
        "answer6": "배경 제거 비용은 1회당 2 크레딧이 듭니다.",
        "q7": "이미지의 흐림을 제거하는 데 얼마나 많은 크레딧이 들까요?",
        "answer7": "이미지의 흐림을 제거하려면 1회당 2 크레딧이 듭니다.",
        "q8": "집, 실내 및 실외 디자인에 요금이 부과되나요?",
        "answer8": "네. genhomeai.com은 저희 응용 프로그램 중 하나이며 각 디자인 세션에 대한 요금이 부과됩니다.",
        "q9": "만족하지 못할 경우 환불을 받을 수 있나요?",
        "answer9": '죄송합니다만 아니라고 말씀드려야 합니다. 현재 운영 중인 고가의 AI 서비스에 대한 환불은 지원하지 않습니다. 무료 크레딧을 사용한 후 크레딧 구매를 고려해 보실 것을 권장합니다.',
    },
    "message": {
        "success": {
            "redering": "전문적인 사진을 렌더링 중입니다. 잠시 기다려주세요",
            "rederingDone": "AI가 디자인 작업을 완료했습니다",
            "activated": "활성화 성공! 아름답고 만족스러운 디자인을 즐기세요!",
            "removingBG": "배경 제거 중",
            "upscale": "해상도 향상 중",
            "resetPassword": "비밀번호 재설정 이메일을 보냈습니다. 확인해주세요!",
            "signIn": "계정을 활성화하기 위한 이메일을 전송했습니다. 확인해주세요!",
            "reviewing": "요청을 받았습니다. 하루 안에 검토될 예정입니다",
            "remindReviewing": "이 사진은 신규 크레딧을 청구하기 위해 검토 중입니다",
            "removed": "사진이 성공적으로 제거되었습니다",
            useTheTemplate: "템플릿을 사용하여 셀카를 업로드하세요"
        },
        "error": {
            "selectPhotographer": "AI 포토그래퍼를 선택하세요",
            "uploadPhoto": "셀피를 업로드하세요",
            "chooseRoom": "방 유형을 선택하세요",
            "chooseTheme": "방 스타일을 선택하세요",
            "fileOverBasic": "5MB보다 작은 파일을 업로드하거나 Pro로 업그레이드하여 더 큰 파일을 업로드하세요",
            "fileOverPro": "10MB보다 작은 파일을 업로드하세요",
            "wrongFile": "잘못된 파일입니다. 다른 파일을 시도하세요",
            "wrongFace": "셀피를 너무 가까이 찍지 마세요. 다른 시도하세요!",
            "busy": "연결이 끊겼습니다. 다시 시도하세요",
            "gender": "성별을 선택하세요",
            "age": "나이를 선택하세요",
            "style": "스타일을 선택하세요",
            "numberOfResult": "헤드샷 수를 선택하세요",
            "outOfCredits": "크레딧이 부족합니다. 더 많은 크레딧을 구매하세요!",
            "userNotFound": "계정을 찾을 수 없습니다. 계정을 올바르게 입력하세요!",
            "wrongPassword": "잘못된 비밀번호입니다! 비밀번호를 기억하지 못하는 경우 비밀번호를 재설정하세요.",
            "accountExist": "계정이 이미 있습니다. 대신 해당 계정에서 로그인하세요",
            "tiktok": "Google 사용자 보안상의 이유로 Chrome이나 Safari 브라우저를 사용하세요. reok.pro에서 액세스하세요.",
            "reviewing": "이 사진은 검토 중이므로 제거할 수 없습니다",
            "background": "배경을 선택하세요",
            "pose": "자세를 선택하세요",
            "signUpReCaptcha": "가입하기 전에 reCAPTCHA를 완료하세요.",
            "emptyBG": "배경이 비어 있습니다. 입력하세요!",
            "tooShortBG": "배경은 2단어보다 길어야 합니다.",
            "tooLongBG": "배경이 너무 깁니다.",
            "badWordsBG": "배경에 부적절한 단어가 포함되어 있습니다. 다른 것을 시도하세요",
            "emptyStyle": "스타일이 비어 있습니다. 입력하세요!",
            "tooShortStyle": "스타일은 2단어보다 길어야 합니다.",
            "tooLongStyle": "스타일이 너무 깁니다.",
            "badWordsStyle": "스타일에 부적절한 단어가 포함되어 있습니다. 다른 것을 시도하세요"
        },
        "warning": {
            "runOutBasicCredits": "기본 요금제의 크레딧을 모두 사용했습니다. 추가 크레딧을 구매하세요",
            "runOutProCredits": "사용하려면 더 많은 크레딧을 구매하세요 ^^",
            "selectPose": "자세 선택 전에 성별을 선택하세요"
        },
        "prompt": {
            "professional": "전문적인 사진",
            "removeBG": "배경 제거",
            "upscale": "해상도 향상",
            "rendering": "AI 렌더링",
            "faceNotFound": "얼굴이 너무 가까워 다른 셀피를 시도하세요!",
            "inReview": "검토 중",
            "styles": {
                "business": "전문 비즈니스 사진",
                "realEstate": "전문 부동산 중개인 사진",
                "carShowroom": "전문 자동차 판매자 사진",
                "doctor": "전문 의사 사진",
                "yearbook": "전문 90년대 년갑 사진",
                "wedding": "전문 웨딩 사진",
                "halloween": "할로윈 사진"
            }
        },
        "maintain": {
            "backsoon": "이 기능은 유지 보수 중이며 곧 돌아올 예정입니다. 그 동안 다른 기능을 사용하세요. 감사합니다! 💗💗💗"
        }
    },
    "app": {
        "menu": {
            "creditTitle": "귀하의 크레딧",
            "affiliateTitle": "제휴 프로그램",
            "affiliateSub": "우리를 추천해주는 모든 고객에게 결제 금액의 25% 커미션을 드립니다!",
            "professionalMode": "내 스튜디오",
            "removeBGMode": "배경 제거",
            "unBluryMode": "해상도 향상",
            "myPhotos": "내 헤드샷"
        },
        "tryThese": "이미지 없나요? 다음을 시도하세요:",
        "goodSelfie": "좋은 셀피",
        "badSelfie": "나쁜 셀피",
        "trick": "더 나은 품질을 위해 해상도를 향상시키려면 Upscale을 사용하세요",
        "removeBGSub": "배경 제거가 AI로 빠르고 간단하며 정확해집니다.",
        "professionalMode": {
            "title": "AI로 전문 헤드샷을 만들어보세요",
            "refundTitle": "우리의 AI는 훌륭한 결과를 위해 학습 중입니다 💓",
            "creditTitle": "1 사진 = 10 크레딧",
            "genderTitle": "성별 선택",
            "ageTitle": "나이 선택",
            "styleTitle": "스타일 선택/사용자 정의",
            "quantityTitle": "결과물 수",
            "poseTitle": "자세 선택/업로드",
            "backgroundTitle": "배경 선택",
            "skipPose": "기존 자세를 유지하려면 건너뛰세요",
            "defaultPose": "기본",
            "uploadedPose": "업로드됨",
            "defaultBackground": "배경",
            "defaultStyle": "스타일",
            "headshotsTab": "헤드샷",
            "selectPhotographer": "AI 포토그래퍼 선택",
            "selectPhotographerSub": "마음에 드는 포토그래퍼를 선택하세요",
            "requirements": "그의 요구 사항을 따르세요",
            "guideSub": "단계별로 진행하세요 👇"
        },
        "upscaleMode": {
            "title": "AI로 빠르게 흐릿한 부분을 제거하고 사진을 향상시킵니다."
        }
    },
    "myDesign": {
        "title": "내 헤드샷",
        "scrollTitle": "모든 디자인을 보려면 스크롤하세요."
    },
    "imageComponent": {
        "compare": "비교",
        "download": "다운로드",
        "removeBG": "배경 제거",
        "upscale": "해상도 향상",
        "errorPhoto": "문제 있는 사진 갱신"
    },
    "results": {
        "one": "1 헤드샷",
        "four": "4 헤드샷"
    },
    "genders": {
        "man": "남성",
        "woman": "여성"
    },
    "styles": {
      hoco_black: '호코 블랙',
      date: '날짜',
      hoco_pink: '호코 핑크',
      sweet: '달콤한',
      elite: '엘리트',
      luxury: '고급',
      rich: '부유한',
      jean: '청바지',
      tank_and_jeans: '탱크 탑 & 청바지',
      athleisure: '애슬레저',
      bordo: '보르도',
      cinematic: '시네마틱',
      disney: '디즈니',
      digital_art: '디지털 아트',
      fantasy_art: '판타지 아트',
      neonpunk: '네온펑크',
      comic_book: '만화책',
      lowpoly: '로우 폴리',
      line_art: '선 그림',
      "business": "비즈니스",
      "realEstate": "부동산 중개인",
      "carShowroom": "자동차 판매자",
      "doctor": "의사",
      "yearbook": "90년대 년갑",
      "wedding": "웨딩",
      "halloween": "할로윈",
      "gray_suit": "회색 정장",
      "white_suit": "흰색 정장",
      "black_suit": "검은색 정장",
      "pink_suit": "분홍색 정장",
      "blue_suit": "파란색 정장",
      "white_shirt": "흰색 셔츠",
      "black_shirt": "검은색 셔츠",
      "gray_shirt": "회색 셔츠",
      "blue_shirt": "파란색 셔츠",
      "pink_shirt": "분홍색 셔츠",
      "dating": "데이트",
      "other": "기타",
      "custom": {
          "title": "스타일 및 옷",
          "btn": "맞춤 시작",
          "dialogTitle": "귀하의 스타일과 옷",
          "dialogSubTitle": "귀하의 스타일과 옷을 알려주세요. AI 포토그래퍼가 도움을 드리겠습니다!",
          "upgrade": "이 기능 업그레이드",
          "example": "넥타이 없는 클래식한 정장, 당신이 원하는 대로..."
      }
    },
    "backgrounds": {
        "coffeeShop": "커피숍",
        "office": "사무실",
        "cityStreet": "도시 거리",
        "factory": "공장",
        "garden": "정원",
        "carShowroom": "자동차 전시장",
        "bookShelf": "책장",
        "hotel": "호텔",
        "goldenGate": "골든 게이트",
        "snow": "눈",
        "realtor": "부동산 중개인",
        "brick": "벽돌",
        "custom": {
            "title": "배경",
            "btn": "맞춤 시작",
            "dialogTitle": "귀하의 배경",
            "dialogSubTitle": "귀하의 배경을 알려주세요. AI 포토그래퍼가 도움을 드리겠습니다!",
            "upgrade": "이 기능 업그레이드",
            "example": "커피숍, 원하는 위치에 따라..."
        }
    },
    "removeTitle": "이 사진 제거",
    "removeSub": "이 사진을 제거하시겠습니까? 이 사진은 영구적으로 제거됩니다. 이 작업은 되돌릴 수 없습니다.",
    "renewTitle": "이 사진 갱신",
    "renewSub": "귀하의 요청을 검토할 것입니다. 이 사진에 문제가 있다면 영구적으로 제거됩니다. 10개의 새 크레딧을 받게 됩니다.",
    "reasonTitle": "왜 갱신하려고 하시나요?",
    "reasonEx": "비형식적이었습니다...",
    "photographers": {
        "sam": {
            "name": "샘, 👋 안녕하세요 고객님",
            "title": "나는 스타일에 능숙해",
            "intro": "무엇을 할 수 있나요?",
            "intro1": "몇 초 안에 모든 스타일과 분위기",
            "intro2": "아바타(Linkedin, 🔥Tinder, Instagram, Facebook, X, Tiktok, Youtube,...)",
            "intro3": "비즈니스 사진",
            "intro4": "데이트 사진",
            "intro5": "아트 및 재미있는 사진"
        },
        "david": {
            "name": "데이비드, 👋 안녕하세요 고객님",
            "title": "나는 포즈에 능숙해",
            "intro": "무엇을 할 수 있나요?",
            "intro1": "몇 초 안에 모든 포즈와 스타일",
            "intro2": "비즈니스 사진",
            "intro3": "데이트 사진",
            "intro4": "웨딩 사진",
            "intro5": "풀 바디 사진"
        }
    }
};

export default ko;