const vi = {
    Basic: "Cơ Bản",
    Pro: "Pro",
    month: "tháng",
    year: "năm",
    "button": {
        "getStarted": "Bắt đầu",
        // "try": "Dùng Thử MIỄN PHÍ",
        "try": "Bắt Đầu Thiết Kế",
        "viewall": "Xem Tất Cả",
        "basic": "Cơ Bản",
        "pro": "Chuyên Nghiệp",
        "generate": "Tạo Thiết Kế",
        "backHome": "Quay Lại Trang Chủ",
        "googleSignin": "Google",
        "googleSignup": "Google",
        "emailSignin": "Đăng Nhập bằng Email",
        "emailSignup": "Đăng Ký bằng Email",
        "submit": "Gửi",
        "choose": "Chọn",
        "download": "Tải Xuống Mẫu Này",
        "upgrade": "Mua thêm xu",
        "subscribe": "Mua ngay",
        "upgradePro": "Nâng cấp",
        "confirmPayment": "Xác Nhận Thanh Toán",
        "tryThisFeature": "Thử Nghiệm Ngay",
        "removeBG": "Bắt Đầu",
        "professional": "Bắt Đầu",
        "upscale": "Bắt Đầu",
        "ok": "Đồng ý",
        "cancel": "Hủy",
        "select": "Chọn",
        "selectToStart": "Chọn để Bắt Đầu",
        "uploadPose": "Tải Lên Pose",
        "uploadingPose": "Đang Tải lên Pose của bạn",
        "upgradeToUpload": "Nâng cấp để tải lên",
        "unlock": "Nâng cấp",
        "englishOnly": "English Only",
        contactUs: "Liên hệ chúng tôi",
        forEnterprise: "Cho Doanh nghiệp",
        tryThis: "Thử"
    },
    header: {
        nav: {
            signIn: 'Đăng nhập',
            signUp: 'Đăng ký',
            signOut: 'Đăng xuất',
            supportTitle: 'Hỗ trợ',
            supportContact: 'Liên hệ ngay',
            myFlowers: 'Thiết kế của tôi'
        }
    },
    landing: {
        title: 'REOK chụp là thích mê 📸',
        subTitle: 'Tự chụp ảnh đi làm, kinh doanh chuyên nghiệp với công nghệ AI. Tiết kiệm x10 thời gian và tài chính của bạn.',
        input: 'Vui lòng cho tôi biết kiểu hoa muốn tạo?',
        inside: 'Không gian trong nhà',
        outside: 'Không gian khác',
        loveBy: 'Được yêu thích bởi 22,743 khách hàng.',
        respectTitle: 'Tôn trọng quyền riêng tư của bạn',
        respectSub1: 'Chúng tôi không dùng FaceID cũng như yêu cầu thật nhiều hình ảnh để thực hiện. Chỉ dùng 1 ảnh Selfie giống như bạn thường xuyên đăng Facebook và Instagram.',
        respectSub2: 'Dữ liệu của bạn hoàn toàn an toàn và bảo mật trên nền tảng của Google.',
        howItWorks: 'REOK sử dụng thế nào?',
        "howItWorksSub": "Bạn tự selfie hoặc tải lên một bức ảnh -> Chọn phong cách và nền của bạn -> Chọn hoặc tải lên dáng bạn muốn -> Nhận kết quả của bạn trong vài giây chứ không phải vài giờ!",
        unBlurryTitle: 'Xoá mờ làm nét ảnh',
        unBlurrySub: 'Xoá mờ, làm nét, cải thiện màu sắc của hình ảnh trước khi đăng trên các nền tảng mạng xã hội như Facebook, Instagram, Tiktok.',
        useThisFeature: 'Sử dụng tính năng',
        anotherFeatureTitle: 'Các tính năng nổi bật khác',
        anotherFeatureSub: 'Miễn phí, nhanh chóng và chính xác. Hãy thử các tính năng dưới đây.',
        feature1: 'Xoá phông nền',
        feature1Sub: 'Xoá phông mọi vật thể chuyên nghiệp và chính xác với AI của REOK',
        feature2: 'Thiết Kế Nhà',
        feature2Sub: 'Thiết kế nội thất, ngoại thất, vẽ sketch với AI của noithatai.com',
        feature3: 'Thay Đổi Phông Nền',
        feature3Sub: 'Thay đổi mọi phông nền cho mọi vật thể với AI của REOK',
        "hotFeatureTitle": "Khách hàng hài lòng về chúng tôi",
        "hotFeatureSub": "Đây là các ảnh đại diện được tạo bằng trí tuệ nhân tạo, được chia sẻ với sự cho phép của họ.",
        pricingTitle: 'Bảng giá REOK',
        pricingSub: 'Mua Xu để sử dụng các tính năng.',
        step1: 'Tải lên chỉ 1 bức ảnh tự sướng',
        step1Sub: 'Chọn kiểu, phông nền và dáng chụp của bạn',
        step2: 'AI thiết kế ảnh trong vài giây',
        step2Sub: 'AI nhanh chóng tạo ra bức ảnh chân dung của bạn',
        step3: 'Nhận ảnh của bạn',
        step3Sub: 'Tải xuống những bức ảnh chân dung của bạn',
        trialTitle: "MIỄN PHÍ",
        trialSubTitle: "#1 Trình tạo ảnh đại diện AI 📸",
        headshotsMenu: "Ảnh chân dung"
    },
    businessHeadshots: {
        title: 'Ảnh chân dung Doanh nghiệp',
        subTitle: "Mở khóa Trải nghiệm Thương hiệu Đội nhóm Cuối cùng với Ảnh chân dung Doanh nghiệp!",
        menuTitle: "Doanh nghiệp",
    },
    datingPhotos: {
        title: 'Ảnh hẹn hò với AI',
        subTitle: "Nâng cao Thành công Hẹn hò của Bạn: Thả lỏng Sức mạnh của Trí tuệ Nhân tạo để Chuyển đổi Hồ sơ của Bạn!",
        menuTitle: "Hẹn hò"
    },
    studentHeadshots: {
        title: 'Ảnh chân dung Sinh viên',
        subTitle: "Nhận nhiều công việc hơn với ảnh chân dung AI chuyên nghiệp của chúng tôi chỉ trong vài giây",
        menuTitle: "Ảnh chân dung Sinh viên"
    },
    teacherHeadshots: {
        title: 'Ảnh chân dung Giáo viên',
        subTitle: "Mở khóa Lợi thế Chuyên nghiệp Cuối cùng: Giáo viên, Biến hình ảnh của Bạn trong vài phút với Trình tạo ảnh chân dung AI Cách mạng!",
        menuTitle: "Ảnh chân dung Giáo viên"
    },
    docterHeadshots: {
        title: 'Ảnh chân dung Bác sĩ',
        subTitle: "Mở khóa Tương lai của Thương hiệu Cá nhân với Ảnh chân dung được Cải thiện bởi Trí tuệ Nhân tạo cho các Chuyên gia Y tế!",
        menuTitle: "Ảnh chân dung Bác sĩ"
    },
    lawyerHeadshots: {
        title: 'Ảnh chân dung Luật sư',
        subTitle: "Mở khóa Sức mạnh của Ấn tượng Đầu tiên: Ảnh chân dung AI Cách mạng được Thiết kế riêng cho Luật sư!",
        menuTitle: "Ảnh chân dung Luật sư"
    },
    realeastateHeadshots: {
        title: 'Ảnh chân dung Bất động sản',
        subTitle: "Nâng cao Thành công Bất động sản của Bạn Nâng cao Tiếp thị của Bạn với Ảnh chân dung Tạo ra bởi AI Nổi bật!",
        menuTitle: "Ảnh chân dung Bất động sản"
    },
    salespersonHeadshots: {
        title: 'Ảnh chân dung Nhân viên bán hàng',
        subTitle: "Mở khóa Bí mật để Bắn phá Doanh số của Bạn Biến Đổi Ấn tượng Đầu tiên của Bạn với Trình tạo ảnh chân dung AI Cách mạng của chúng tôi!",
        menuTitle: "Ảnh chân dung Nhân viên bán hàng"
    },
    yearbook: {
        title: 'Yearbook ảnh thập niên 90 📸',
        subTitle: 'Chụp ảnh thập niên 90 chuyên nghiệp với công nghệ AI. Tiết kiệm x10 thời gian và tài chính của bạn.',
        input: 'Vui lòng cho tôi biết kiểu hoa muốn tạo?',
        inside: 'Không gian trong nhà',
        outside: 'Không gian khác',
        loveBy: 'Được yêu thích bởi 22,743 khách hàng.',
        respectTitle: 'Tôn trọng quyền riêng tư của bạn',
        respectSub1: 'Chúng tôi không dùng FaceID cũng như yêu cầu thật nhiều hình ảnh để thực hiện. Chỉ dùng 1 ảnh Selfie giống như bạn thường xuyên đăng Facebook và Instagram.',
        respectSub2: 'Dữ liệu của bạn hoàn toàn an toàn và bảo mật trên nền tảng của Google.',
        howItWorks: 'Cách AI Yearbook Hoạt Động',
        "howItWorksSub": "Bạn tự selfie hoặc tải lên một bức ảnh -> Chọn phong cách và nền của bạn -> Chọn hoặc tải lên dáng bạn muốn -> Nhận kết quả của bạn trong vài giây chứ không phải vài giờ!",
        unBlurryTitle: 'Xoá mờ làm nét ảnh',
        unBlurrySub: 'Xoá mờ, làm nét, cải thiện màu sắc của hình ảnh trước khi đăng trên các nền tảng mạng xã hội như Facebook, Instagram, Tiktok.',
        useThisFeature: 'Sử dụng tính năng',
        anotherFeatureTitle: 'Các tính năng nổi bật khác',
        anotherFeatureSub: 'Miễn phí, nhanh chóng và chính xác. Hãy thử các tính năng dưới đây.',
        feature1: 'Xoá phông nền',
        feature1Sub: 'Xoá phông mọi vật thể chuyên nghiệp và chính xác với AI của REOK',
        feature2: 'Thiết Kế Nhà',
        feature2Sub: 'Thiết kế nội thất, ngoại thất, vẽ sketch với AI của noithatai.com',
        feature3: 'Thay Đổi Phông Nền',
        feature3Sub: 'Thay đổi mọi phông nền cho mọi vật thể với AI của REOK',
        hotFeatureTitle: 'Các thiết kế nổi bật',
        hotFeatureSub: 'Lưu ý đây không phải hình thật, hoàn toàn là AI của REOK tạo ra từ hình gốc.',
        pricingTitle: 'Bảng giá REOK',
        pricingSub: 'Mua Xu để sử dụng các tính năng.',
        yearbook: 'AI Yearbook thập niên 90'
    },
    showcase: {
        title: 'Hoa Mẫu',
        status: {
            pending: 'đang tạo',
            upscaling: 'đang nâng cấp',
            choosing: 'đang chọn',
            finished: 'hoàn thành'
        },
        mode: {
            pro: 'pro',
            basic: 'Cơ Bản'
        }
    },
    home: {
        title: 'Thiết kế của tôi',
        hotColletion: "Nổi bật",
        credits: 'lượt thiết kế',
        uploadTitle: 'Tải lên hình ảnh của bạn',
        uploadPlaceHolder: 'Thả hình ảnh, chạm để chọn, hoặc chụp ảnh (tối đa 10MB)',
        uploadPosePlaceHolder: 'Chọn',
        stylePlaceHolder: 'Chọn',
        uploadBGPlaceHolder: "Chọn phông nền của bạn",
        roomTitle: 'Chọn loại phòng (29)',
        themeTitle: 'Chọn phong cách (34)',
        pro: {
            title: 'Sáng Tạo Với Mọi Loại Hoa',
            input: 'Miêu tả hoa của bạn...',
        },
        basic: {
            title: 'Sáng Tạo Không Giới Hạn Với Pro',
            chooseFlowerTitle: 'Hoa',
            flowers: 'Phòng khách',
            styles: 'Kiểu',
            decoration: 'Trang trí',
            events: 'Sự kiện',
            backgrounds: 'Nền'
        },
    },
    profile: {
        title: 'Thiết Kế Của Tôi',
        before: 'Trước -',
        after: 'Sau -'
    },
    terms: {
        title: 'Điều Khoản Và Điều Kiện',
        content: ''
    },
    privacy: {
        title: 'Chính Sách Bảo Mật',
        one: 'Quyền riêng tư của bạn rất quan trọng với chúng tôi. Chính sách của reok.pro là tôn trọng quyền riêng tư của bạn đối với bất kỳ thông tin nào chúng tôi có thể thu thập từ bạn trên trang web của chúng tôi, reok.pro và các trang web khác mà chúng tôi sở hữu và vận hành.',
        two: 'Chúng tôi chỉ yêu cầu thông tin cá nhân khi chúng tôi thực sự cần nó để cung cấp dịch vụ cho bạn. Chúng tôi thu thập nó bằng các phương tiện công bằng và hợp pháp, với sự hiểu biết và đồng ý của bạn. Chúng tôi cũng cho bạn biết lý do tại sao chúng tôi thu thập nó và nó sẽ được sử dụng như thế nào.',
        three: 'Chúng tôi chỉ lưu giữ thông tin đã thu thập trong thời gian cần thiết để cung cấp cho bạn dịch vụ mà bạn yêu cầu. Dữ liệu nào chúng tôi lưu trữ, chúng tôi sẽ bảo vệ bằng các biện pháp được chấp nhận về mặt thương mại để ngăn ngừa mất mát và trộm cắp, cũng như truy cập, tiết lộ, sao chép, sử dụng hoặc sửa đổi trái phép.',
        four: 'Chúng tôi không chia sẻ bất kỳ thông tin nhận dạng cá nhân nào một cách công khai hoặc với bên thứ ba, trừ khi luật pháp yêu cầu.',
        five: 'Trang web của chúng tôi có thể liên kết đến các trang bên ngoài không do chúng tôi điều hành. Xin lưu ý rằng chúng tôi không kiểm soát nội dung và thực tiễn của các trang web này và không thể chịu trách nhiệm hoặc trách nhiệm pháp lý đối với các chính sách bảo mật tương ứng của họ.',
        six: 'Bạn có thể từ chối yêu cầu của chúng tôi về thông tin cá nhân của bạn, với sự hiểu biết rằng chúng tôi có thể không cung cấp cho bạn một số dịch vụ mong muốn của bạn.',
        seven: 'Việc bạn tiếp tục sử dụng trang web của chúng tôi sẽ được coi là chấp nhận các thông lệ của chúng tôi về quyền riêng tư và thông tin cá nhân. Nếu bạn có bất kỳ câu hỏi nào về cách chúng tôi xử lý dữ liệu người dùng và thông tin cá nhân, vui lòng liên hệ với chúng tôi.',
        eight: 'Chính sách này có hiệu lực kể từ ngày 15 tháng 9 năm 2023.'
    },
    footer: {
        title: 'REOK chuyên về chụp ảnh đi làm, kinh doanh chuyên nghiệp chỉ từ 1 ảnh Selfie trong vài giây với công nghệ AI.',
        terms: 'Điều Khoản',
        privacy: 'Chính Sách Bảo Mật',
        app: 'Ứng dụng',
        signin: 'Đăng nhập',
        signup: 'Đăng kí',
        email: 'Email',
        chatNow: 'Chat ngay',
        partner: 'Hợp tác',
        reportBug: 'Báo lỗi',
        require: 'Gửi yêu cầu',
        page: 'Trang',
        tos: 'Pháp lý',
        contact: 'Liên hệ',
        other: 'Khác',
        affiliate: 'Affiliates',
        yearbook: 'AI Yearbook',
        blog: 'Blog',
        pricing: 'Bảng giá',
        howItWorks: 'Cách hoạt động',
        businesses: "Doanh nghiệp",
        dating: "Hẹn hò"
    },
    tos: {
        agree: 'Tài khoản mới phải tuân thủ ',
        one: '1. Giới thiệu',
        oneDetail: 'Bằng cách sử dụng reok.pro, bạn xác nhận việc chấp nhận và đồng ý bị ràng buộc bởi các điều khoản và điều kiện này.',
        two: '2. Thỏa thuận với các Điều khoản và Điều kiện',
        twoDetail: 'Thỏa thuận này có hiệu lực kể từ ngày bạn sử dụng ứng dụng reok.pro lần đầu tiên.',
        three: '3. Các tính năng',
        threeDetail: 'Bạn sẽ có thể sử dụng tất cả các tính năng sau khi đăng ký một gói trả phí cụ thể. Chi tiết về các tính năng có sẵn trên trang giá cả.',
        four: '4. Chính sách hoàn tiền',
        fourDetail: 'Chúng tôi cho phép hoàn lại tiền cho các giao dịch bị thất bại trong vòng 14 ngày kể từ ngày mua. Liên hệ với bộ phận hỗ trợ để yêu cầu hoàn lại tiền.',
        five: '4. Sử dụng sản phẩm',
        fiveDetail: 'Bằng cách sử dụng reok.pro, bạn đồng ý nhận các bản cập nhật sản phẩm quan trọng từ reok.pro qua email được liên kết với tài khoản Google của bạn hoặc email bạn đã sử dụng để đăng ký tài khoản của mình. Bạn có thể chọn không nhận các bản cập nhật sản phẩm này bất kỳ lúc nào bằng cách nhấp vào liên kết "Hủy đăng ký" ở cuối mỗi email. Chúng tôi chỉ gửi các bản cập nhật sản phẩm quan trọng.',
        six: '5. Miễn trừ trách nhiệm',
        sixDetail: 'Không đảm bảo rằng reok.pro sẽ đáp ứng yêu cầu của bạn hoặc hoạt động của nó sẽ không bị gián đoạn hoặc không có lỗi. Tất cả các bảo đảm hoặc điều kiện rõ ràng và ngụ ý không được nêu trong Thỏa thuận này (bao gồm nhưng không giới hạn, mất lợi nhuận, mất hoặc hỏng dữ liệu, gián đoạn kinh doanh hoặc mất hợp đồng), cho đến khi loại trừ hoặc từ chối trách nhiệm đó được cho phép theo luật hiện hành, được loại trừ và từ chối rõ ràng. Thỏa thuận này không ảnh hưởng đến các quyền theo luật định của bạn.',
        seven: '6. Bảo hành và Giới hạn trách nhiệm pháp lý',
        sevenDetail: 'reok.pro không đưa ra bất kỳ bảo hành, đảm bảo hoặc điều khoản nào khác về chất lượng, tính phù hợp với mục đích hoặc mặt khác của phần mềm. reok.pro sẽ không chịu trách nhiệm với bạn vì lý do của bất kỳ tuyên bố nào (trừ khi gian lận), hoặc bất kỳ bảo hành, điều kiện hoặc điều khoản ngụ ý nào, hoặc bất kỳ nghĩa vụ nào theo luật thông thường, đối với bất kỳ tổn thất lợi nhuận hoặc bất kỳ tổn thất gián tiếp, đặc biệt hoặc do hậu quả nào, thiệt hại, chi phí, phí tổn hoặc các khiếu nại khác (dù là do sơ suất của reok.pro hay sơ suất của nhân viên hoặc đại lý của reok.pro hoặc nguyên nhân khác) phát sinh từ hoặc liên quan đến việc cung cấp bất kỳ hàng hóa hoặc dịch vụ nào của reok.pro. reok.pro sẽ không chịu trách nhiệm hoặc bị coi là vi phạm hợp đồng do bất kỳ sự chậm trễ nào trong việc thực hiện hoặc không thực hiện bất kỳ nghĩa vụ nào của mình nếu sự chậm trễ hoặc không thực hiện được là do bất kỳ nguyên nhân nào nằm ngoài tầm kiểm soát hợp lý của reok.pro. Bất kể các điều khoản trái ngược trong Thỏa thuận này, trong trường hợp reok.pro được coi là chịu trách nhiệm pháp lý với bạn do vi phạm Thỏa thuận này, bạn đồng ý rằng trách nhiệm pháp lý của reok.pro được giới hạn ở số tiền bạn thực sự trả cho các dịch vụ hoặc phần mềm của mình, số tiền này được tính toán dựa trên khoản này. Theo đây, bạn giải phóng reok.pro khỏi bất kỳ và tất cả các nghĩa vụ, trách nhiệm pháp lý và khiếu nại vượt quá giới hạn này.',
        eight: '7. Điều khoản chung và luật',
        eightDetail: 'Bạn thừa nhận rằng không có mối quan hệ liên doanh, hợp tác, việc làm hoặc đại lý nào tồn tại giữa bạn và reok.pro do bạn sử dụng các dịch vụ này. Bạn đồng ý không thể hiện mình là đại diện, đại lý hoặc nhân viên của reok.pro. Bạn đồng ý rằng reok.pro sẽ không chịu trách nhiệm pháp lý về bất kỳ đại diện, hành động hoặc thiếu sót nào trong hành động của bạn.'
    },
    faq: {
        title: 'Các câu hỏi thường gặp',
        q1: 'Tại sao phải sử dụng REOK để chụp hình?',
        answer1: 'Để tiết kiệm thời gian và tài chính cho bạn. Chúng tôi đã tạo ra 1 studio online sử dụng công nghệ AI mạnh mẽ của chúng tôi. Không cần ngồi đợi hay makeup hay phải tạo dáng chụp, giúp bạn nhanh chóng tạo ra những bức hình đẹp, chuyên nghiệp và lịch sự, sử dụng cho công việc hoặc đăng lên các nền tảng mạng xã hội như Facebook, Instagram, Tiktok.',
        q2: 'REOK hoạt động như thế nào?',
        answer2: 'Chỉ cần chụp Selfie 1 ảnh hoặc chọn 1 ảnh từ thư viện, sau đó chọn giới tính và số lượng ảnh muốn tạo ra, AI của REOK sẽ xử lý yêu cầu của bạn nhanh chóng và chính xác.',
        q3: 'Thông tin cá nhân và dữ liệu thiết kế của tôi có được bảo mật không?',
        answer3: 'Chắc chắn là có, chúng tôi làm việc trực tiếp với nền tảng của Google. Bảo vệ sự riêng tư của bạn là vô cùng quan trọng đối với chúng tôi. REOK cam kết thực hiện các biện pháp cần thiết để bảo mật thông tin cá nhân và dữ liệu thiết kế của bạn. Chúng tôi có sẵn các giao thức bảo mật mạnh mẽ để đảm bảo rằng dữ liệu của bạn được bảo mật và chỉ được sử dụng cho mục đích cung cấp dịch vụ của chúng tôi. Để biết thông tin chi tiết, vui lòng tham khảo chính sách bảo mật trên trang web của chúng tôi.',
        q4: 'Các thiết kế của tôi được lưu trữ trong bao lâu?',
        answer4: 'Thiết kế của bạn sẽ được chúng tôi lưu trữ trên Cloud vĩnh viễn khi dịch vụ của chúng tôi vẫn còn hoạt động.',
        q5: 'Chụp 1 tấm ảnh đi làm hoặc kinh doanh tốn bao nhiêu xu?',
        answer5: 'Chỉ tốn 10 xu cho 1 tấm ảnh chuyên nghiệp, sang trọng và lịch thiệp. Rẻ x100 lần so với đi chụp Studio.',
        q6: 'Xoá phông nền tốn bao nhiêu xu?',
        answer6: 'Chỉ tốn 2 xu cho 1 lần xoá phông nền.',
        q7: 'Làm nét tốn bao nhiêu xu?',
        answer7: 'Chỉ tốn 2 xu cho 1 lần làm nét.',
        q8: 'Thiết kế nhà, nội thất ngoại thất có tốn phí không?',
        answer8: 'Có. noithatai.com là 1 ứng dụng trong hệ sinh thái của chúng tôi, bạn sẽ trả chi phí cho mỗi lượt thiết kế.',
        q9: 'Không vừa ý có được hoàn tiền không?',
        answer9: 'Chúng tôi rất tiếc phải nói Không. Đối với những dự án sử dụng trí tuệ nhân tạo chi phí vận hành tốn kém, hiện tại chúng tôi không hỗ trợ hoàn tiền, bạn nên xem xét mua Xu sau khi sử dụng Xu miễn phí từ chúng tôi.',
    },
    signin: {
        title: 'Đăng nhập',
        another: 'Hoặc với Email',
        emailPlaceHolder: 'Địa chỉ Email',
        passwordPlaceHolder: 'Mật Khẩu',
        rememberTitle: 'Nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        noAccount: 'Chưa tạo tài khoản?'
    },
    signup: {
        title: 'Đăng ký',
        another: 'Hoặc với Email',
        firstNamePlaceHolder: 'Tên',
        lastNamePlaceHolder: 'Họ',
        emailPlaceHolder: 'Địa chỉ Email',
        passwordPlaceHolder: 'Mật Khẩu',
        rememberTitle: 'Nhớ cho lần sau',
        forgetPassword: 'Quên mật khẩu?',
        gotAccount: 'Bạn đã có tài khoản?'
    },
    forgetPassword: {
        title: 'Lấy lại mật khẩu',
        content: 'Chúng tôi sẽ gửi email lấy lại mật khẩu cho bạn ngay',
        emailPlaceHolder: 'Vui lòng nhập Email',
        sendBtn: 'Gửi',
        backSignin: 'Trở về đăng nhập'
    },
    pricing: {
        yearlySubscription: '1000 lượt',
        monthlySubscription: '100 lượt',
        saveDesk: 'Giảm 10%',
        saveMobile: 'Giảm 10% khi mua 1000 lượt',
        popular: 'Phổ biến nhất',
        free: {
            title: 'Miễn phí',
            price: '0k',
            trialTitle: 'Mã đăng ký: Đăng nhập để lấy mã',
            joinTitle: 'Bạn gửi mã ở đây nhé',
            one: '20 Xu dùng thử',
            two: 'Gồm tất cả trong gói 100 Xu',
            affiliateTitle: 'Nhận thưởng',
            shareTitle: 'Chia sẻ ngay tới bạn bè👇',
            loginToShare: 'Vui lòng đăng nhập để chia sẻ'
        },
        basic: {
            title: '100 Xu',
            price: '99k',
            duration: '/trọn đời',
            recommend: 'Phù hợp với Cá nhân',
            one: '20 thiết kế ảnh đi làm, kinh doanh',
            two: 'Xoá phông nền',
            three: 'Làm nét ảnh',
            four: 'Tải xuống mọi thiết kế',
            five: 'Lưu trữ không giới hạn trên Cloud',
            six: 'Sử dụng hình ảnh thương mại',
            seven: 'Truy cập mọi tính năng mới',
            subscribed: 'Đã mua gói này',
        },
        pro: {
            title: '1000 Xu',
            subTitle: '(tiết kiệm 30%)',
            price: '699k',
            duration: '/trọn đời',
            recommend: 'Influencers, Doanh nghiệp, Tiệm ảnh',
            one: '200 thiết kế ảnh đi làm, kinh doanh',
            two: 'Gồm tất cả trong gói 100 Xu',
            three: 'Tiết kiệm chi phí hơn',
            subscribed: 'Đã mua gói này',
        },
        currency: 'đ',
        perMonth: '/tháng',
        safePayment: 'Thanh toán bảo mật',
        qr: 'Hoặc sử dụng mã QR'
    },
    bill: {
        tittle: 'Thanh toán của bạn',
        name: 'Gói: ',
        credits: 'Số lượt thiết kế: ',
        duration: 'Thời hạn: ',
        price: 'Giá: ',
        discount: 'Giảm ',
        total: 'Tổng cộng: ',
        payment: {
            title: 'Phương thức thanh toán',
            internalATM: 'Chuyển khoản ngân hàng',
            momo: 'Ví MoMo',
            zalopay: 'Ví ZaloPay',
            vnpay: 'Ví VNPAY'
        },
        transfer: {
            internalATM: {
            title: 'Chuyển khoản',
            account: 'STK: 0441000810601',
            name: 'Chủ TK: PHUNG THANH AN',
            content: 'Nội dung: '
            },
            momo: {
                title: 'Chuyển Momo',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            },
            zalopay: {
                title: 'Chuyển ZaloPay',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            },
            vnpay: {
                title: 'Chuyển VNPAY',
                account: 'SĐT: 0973898494',
                name: 'Tên: PHUNG THANH AN',
                content: 'Nội dung: '
            }
        },
        notes: {
            title: 'LƯU Ý',
            one: 'Vui lòng kiểm tra chính xác THÔNG TIN và NỘI DUNG trước khi chuyển khoản',
            oneDetail: 'Chúng tôi sẽ không chịu trách nhiệm nếu bạn thực hiện sai giao dịch',
            two: 'Vui lòng liên hệ với chúng tôi nếu bạn gặp khó khăn trong quá trình thanh toán.',
            twoDetail: 'Chat với chúng tôi',
        },
        afterPayment: {
            thankyouMess: 'Cảm ơn Bạn!',
            checking: 'Chúng tôi đang kiểm tra giao dịch vui lòng chờ trong giây lát 💓'
        }
    },
    "milestoneMessage": {
        "first": "Khách hàng hạnh phúc với",
        "second": "thiết kế đã tạo ra"
    },
    "message": {
        "success": {
            "redering": "Đang khởi tạo thiết kế",
            "rederingDone": "AI đã hoàn thành thiết kế",
            "activated": "Wohoo! Kích hoạt thành công.",
            "removingBG": "Đang loại bỏ nền của bạn",
            "upscale": "Nâng độ phân giải",
            "resetPassword": "Chúng tôi vừa gửi một email để đặt lại mật khẩu của bạn. Vui lòng kiểm tra!",
            "signIn": "Chúng tôi vừa gửi một email để kích hoạt tài khoản của bạn. Vui lòng kiểm tra!",
            "reviewing": "Chúng tôi đã nhận được yêu cầu của bạn. Nó sẽ được xem xét trong một ngày",
            "remindReviewing": "Ảnh này đang được xem xét để yêu cầu điểm mới",
            "removed": "Xóa ảnh thành công",
            useTheTemplate: "Sử dụng mẫu, chỉ cần tải lên bức ảnh tự sướng của bạn"
        },
        "error": {
            "selectPhotographer": "Vui lòng chọn nhiếp ảnh gia AI của bạn",
            "uploadPhoto": "Vui lòng tải lên ảnh tự sướng của bạn",
            "chooseRoom": "Vui lòng chọn loại phòng",
            "chooseTheme": "Vui lòng chọn phong cách phòng",
            "fileOverBasic": "Vui lòng tải lên một tệp nhỏ hơn 5MB hoặc nâng cấp lên Pro để tải lên các tệp lớn hơn",
            "fileOverPro": "Vui lòng tải lên một tệp nhỏ hơn 10MB",
            "wrongFile": "Tệp không hợp lệ, vui lòng thử một tệp khác",
            "wrongFace": "Vui lòng không chụp ảnh tự sướng quá gần, vui lòng thử ảnh khác!",
            "busy": "Kết nối bị gián đoạn, vui lòng thử lại",
            "gender": "Vui lòng chọn giới tính của bạn",
            "age": "Vui lòng chọn độ tuổi của bạn",
            "style": "Vui lòng chọn phong cách của bạn",
            "numberOfResult": "Vui lòng chọn số lượng ảnh đại diện",
            "outOfCredits": "Bạn đã hết điểm, vui lòng mua thêm điểm!",
            "userNotFound": "Không tìm thấy tài khoản này. Vui lòng nhập đúng tài khoản của bạn!",
            "wrongPassword": "Sai mật khẩu! Nếu bạn không nhớ mật khẩu, hãy sử dụng Quên Mật Khẩu.",
            "accountExist": "Tài khoản đã tồn tại. Hãy thử đăng nhập từ tài khoản này thay vì đó",
            "tiktok": "Vì lý do an ninh của người dùng Google, vui lòng sử dụng trình duyệt Chrome hoặc Safari. Truy cập reok.pro để truy cập.",
            "reviewing": "Bạn không thể xóa ảnh này vì nó đang được xem xét",
            "background": "Vui lòng chọn nền của bạn",
            "pose": "Vui lòng chọn dáng chụp của bạn",
            "signUpReCaptcha": "Vui lòng hoàn thành reCAPTCHA trước khi đăng ký.",
            "emptyBG": "Nền của bạn đang trống, hãy nhập vào!",
            "tooShortBG": "Nền của bạn phải dài hơn 2 từ",
            "tooLongBG": "Nền của bạn quá dài",
            "badWordsBG": "Nền của bạn chứa từ không thích hợp. Hãy thử một cái khác",
            "emptyStyle": "Phong cách của bạn đang trống, hãy nhập vào!",
            "tooShortStyle": "Phong cách của bạn phải dài hơn 2 từ",
            "tooLongStyle": "Phong cách của bạn quá dài",
            "badWordsStyle": "Phong cách của bạn chứa từ không thích hợp. Hãy thử một cái khác"
        },
        "warning": {
            "runOutBasicCredits": "Bạn đã sử dụng hết điểm trong kế hoạch Cơ Bản, vui lòng mua thêm điểm",
            "runOutProCredits": "Vui lòng mua thêm điểm để sử dụng ^^",
            "selectPose": "Vui lòng chọn Giới Tính trước khi chọn Pose"
        },
        "prompt": {
            "professional": "Ảnh chuyên nghiệp",
            "removeBG": "Loại bỏ nền",
            "upscale": "Nâng độ phân giải",
            "rendering": "AI đang tạo thiết kế",
            "faceNotFound": "Khuôn mặt của bạn quá gần, hãy thử ảnh khác!",
            "inReview": "Đang xem xét",
            "styles": {
                "business": "Ảnh chuyên nghiệp về kinh doanh",
                "realEstate": "Ảnh chuyên nghiệp về môi giới bất động sản",
                "carShowroom": "Ảnh chuyên nghiệp về người bán ô tô",
                "doctor": "Ảnh chuyên nghiệp về bác sĩ",
                "yearbook": "Ảnh chuyên nghiệp trong Sổ năm 90",
                "wedding": "Ảnh chuyên nghiệp về đám cưới",
                "halloween": "Ảnh Halloween"
            },
        },
        "maintain": {
            "backsoon": "Chúng tôi đang bảo trì tính năng này và nó sẽ trở lại sớm. Trong lúc đó, hãy sử dụng các tính năng khác. Cảm ơn bạn! 💗💗💗"
        }
    },
    "app": {
        "menu": {
            "creditTitle": "Xu của bạn",
            "affiliateTitle": "Phần thưởng",
            "affiliateSub": "Nhận 20 xu cho tất cả khách hàng bạn giới thiệu cho chúng tôi!",
            "professionalMode": "Studio Của Tôi",
            "removeBGMode": "Loại Bỏ Nền",
            "unBluryMode": "Nâng Cấp Ảnh",
            "myPhotos": "Ảnh Của Tôi",
        },
        "tryThese": "Không có ảnh? Thử những cái này:",
        "goodSelfie": "Ảnh chụp đúng",
        "badSelfie": "Ảnh chụp sai",
        "trick": "Sử dụng Nâng cấp để có chất lượng tốt hơn",
        "removeBGSub": "Loại bỏ nền trở nên nhanh chóng, đơn giản và chính xác với trí tuệ nhân tạo.",
        "professionalMode": {
            "title": "Tạo ảnh chuyên nghiệp với trí tuệ nhân tạo",
            "refundTitle": "AI của chúng tôi đang học để có kết quả tuyệt vời 💓",
            "creditTitle": "1 ảnh = 10 điểm",
            "genderTitle": "Chọn giới tính",
            "ageTitle": "Chọn độ tuổi của bạn",
            "styleTitle": "Chọn/phong cách tùy chỉnh",
            "quantityTitle": "Số lượng kết quả",
            "poseTitle": "Chọn/tải lên dáng chụp",
            "backgroundTitle": "Chọn nền",
            "skipPose": "Bỏ qua để giữ nguyên dáng chụp ban đầu của bạn",
            "defaultPose": "Mặc định",
            "uploadedPose": "Đã tải lên",
            "defaultBackground": "Nền",
            "defaultStyle": "Phong cách",
            "headshotsTab": "Ảnh Đại Diện",
            "selectPhotographer": "Chọn nhiếp ảnh gia AI của bạn",
            "selectPhotographerSub": "Vui lòng chọn một nhiếp ảnh gia mà bạn thích",
            "requirements": "Yêu cầu của nhiếp ảnh gia",
            "guideSub": "Vui lòng thực hiện theo từng bước 👇"
        },
        "upscaleMode": {
            "title": "Nhanh chóng và chính xác loại bỏ độ mờ và cải thiện ảnh của bạn với trí tuệ nhân tạo.",
        }
    },
    "myDesign": {
        "title": "Ảnh Của Tôi",
        "scrollTitle": "Cuộn để xem tất cả các thiết kế của bạn.",
    },
    "imageComponent": {
        "compare": "So Sánh",
        "download": "Tải Xuống",
        "removeBG": "Loại Bỏ Nền",
        "upscale": "Nâng Cấp",
        "errorPhoto": "Làm mới Ảnh Tệ"
    },
    "results": {
        "one": "1 ảnh",
        "four": "4 ảnh",
    },
    "genders": {
        "man": "Nam",
        "woman": "Nữ"
    },
    "styles": {
        hoco_black: 'Hoco Đen',
        date: 'Hẹn Hò',
        hoco_pink: 'Hoco Hồng',
        sweet: 'Ngọt',
        elite: 'Ưu tú',
        luxury: 'Sang trọng',
        rich: 'Giàu có',
        jean: 'Quần jean',
        tank_and_jeans: 'Áo tank và quần jean',
        athleisure: 'Thể thao',
        bordo: 'Màu bordo',
        cinematic: 'Điện ảnh',
        disney: 'Disney',
        digital_art: 'Nghệ thuật số',
        fantasy_art: 'Trong mơ',
        neonpunk: 'Neonpunk',
        comic_book: 'Truyện tranh',
        lowpoly: 'Lowpoly',
        line_art: 'Nghệ thuật đường nét',
        "business": "Kinh doanh",
        "realEstate": "Bất động sản",
        "carShowroom": "Showroom ô tô",
        "doctor": "Bác sĩ",
        "yearbook": "Yearbook",
        "wedding": "Đám cưới",
        "halloween": "Halloween",
        "gray_suit": "Vest Xám",
        "white_suit": "Vest Trắng",
        "black_suit": "Vest Đen",
        "pink_suit": "Vest Hồng",
        "blue_suit": "Vest Xanh",
        "white_shirt": "Sơ Mi Trắng",
        "black_shirt": "Sơ Mi Đen",
        "gray_shirt": "Sơ Mi Xám",
        "blue_shirt": "Sơ Mi Xanh",
        "pink_shirt": "Sơ Mi Hồng",
        "dating": "Hẹn hò",
        "other": "Khác",
        "custom": {
            "title": "Phong Cách Tuỳ Chỉnh",
            "btn": "Tùy chỉnh",
            "dialogTitle": "Phong cách và quần áo của bạn",
            "dialogSubTitle": "Hãy nói cho chúng tôi biết phong cách và quần áo của bạn, nhiếp ảnh gia AI của chúng tôi sẽ cố gắng giúp bạn xuất hiện với nó!",
            "upgrade": "Nâng cấp tính năng này",
            "example": "một bộ vest cổ điển không cần cà vạt, tùy thuộc vào ý bạn muốn...",
        }
    },
    "backgrounds": {
        "coffeeShop": "Quán Cà Phê",
        "office": "Văn Phòng",
        "cityStreet": "Đường Phố",
        "factory": "Nhà Máy",
        "garden": "Khu Vườn",
        "carShowroom": "Showroom Xe",
        "bookShelf": "Kệ Sách",
        "hotel": "Khách Sạn",
        "goldenGate": " Cầu Golden Gate",
        "snow": "Tuyết",
        "realtor": "Bất Động Sản",
        "brick": "Tường Gạch",
        "custom": {
            "title": "Nền Tuỳ Chỉnh",
            "btn": "Tùy chỉnh",
            "dialogTitle": "Nền của bạn",
            "dialogSubTitle": "Hãy nói cho chúng tôi biết nền của bạn, nhiếp ảnh gia AI của chúng tôi sẽ cố gắng giúp bạn xuất hiện với nó!",
            "upgrade": "Nâng cấp tính năng này",
            "example": "một quán cà phê, tùy thuộc vào nơi bạn muốn...",
        },
    },
    "removeTitle": "Đang Xóa Ảnh Này",
    "removeSub": "Bạn có chắc chắn muốn xóa ảnh này không? Ảnh này sẽ bị xóa vĩnh viễn. Hành động này không thể hoàn tác.",
    "renewTitle": "Làm mới Ảnh Này",
    "renewSub": "Chúng tôi sẽ xem xét yêu cầu của bạn. Nếu ảnh này gặp vấn đề, nó sẽ bị xóa vĩnh viễn. Bạn sẽ nhận được 10 điểm mới.",
    "reasonTitle": "Nói cho chúng tôi biết tại sao bạn muốn làm mới nó?",
    "reasonEx": "Nó quá không chính thức...",
    "photographers": {
        "sam": {
            "name": "Sam, 👋 Xin chào",
            "title": "Tôi chuyên về Phong cách",
            "intro": "Tôi có thể làm gì?",
            "intro1": "Bất kỳ Phong cách và Tâm trạng nào trong vài giây",
            "intro2": "Hình đại diện (Linkedin, Tinder, Instagram, Facebook, X, Tiktok, Youtube,...)",
            "intro3": "Ảnh kinh doanh",
            "intro4": "Ảnh hẹn hò",
            "intro5": "Ảnh Nghệ thuật & Hài hước"
        },
        "david": {
            "name": "David, 👋 Xin chào",
            "title": "Tôi chuyên về Dáng chụp",
            "intro": "Tôi có thể làm gì?",
            "intro1": "Bất kỳ dáng chụp và Phong cách nào trong vài giây",
            "intro2": "Ảnh kinh doanh",
            "intro3": "Ảnh hẹn hò",
            "intro4": "Ảnh đám cưới",
            "intro5": "Ảnh toàn thân"
        }
    }
};

export default vi;  