import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import PricingLandingPage from '../PricingLandingPage';
import Testimonial from '../Testimonial';
import LovedBy from '../LovedBy';
import BeforeAfterTestimonial from '../BeforeAfterTestimonial';

const upgradeResolution = {
  before: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/upscaler%2Fbefore.webp?alt=media&token=a8099783-29fc-49f5-b949-3585a28c2ed7',
  after: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/upscaler%2Fafter.webp?alt=media&token=123a368d-1452-481a-a3df-5adf594650b7',
};

const LandingBusinessesHeadshot = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);

  const urls = [
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F1.webp?alt=media&token=f34a08f5-4fad-4985-86a5-688cf88c6224',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F2.webp?alt=media&token=5c72d196-c579-42d8-9aac-ee371e645700',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/3.webp?alt=media&token=6c3b46e7-e516-4394-9fdb-3ab928c251be',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/4.webp?alt=media&token=d75ead71-9b18-4dd8-aad7-5a9a76c61c84',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F3.webp?alt=media&token=dead1e95-35d9-477f-8bbc-323ad0b48c9a',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F5.webp?alt=media&token=4bd9ccc6-e967-47c5-abf5-359a576a11f2',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F6.webp?alt=media&token=85b265a9-51d7-4d61-89b2-df4daa02f1b1',
    // 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F8.webp?alt=media&token=ee14f7ef-849e-4ee9-b0f2-850744b1d7d4'
  ];

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('affCode', '');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
      // You can perform further actions with the referral code here
    } else {
      localStorage.setItem('redirect', "");
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setImageIndex((prevIndex) => (prevIndex + 1) % urls.length);
  //   }, 3000);

  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId)
  // }, []);

  return (
    <>
      <main style={{ background: '#000' }}>
        <img src="/images/night-background.webp" alt="reok" className="absolute inset-0 object-cover object-bottom w-full h-full" />
        <div className="landing-cover-header" data-controller="gradient-animation">
          <Row gutter={12}>
            <Col lg={12} md={24} xs={24} className="landing-order-second">
              <div className="banner-block-copy-0-2-82 banner-block-copy-d123-0-2-395">
                <div className="title-landing-wrapper">
                  <h1 className="text-root-0-2-141 text-root-d10-0-2-401 banner-block-copyTitle-0-2-83 banner-block-copyTitle-d124-0-2-396">
                    <span>
                      <h1 className="display-title-landing fw-bold text-white">{t('businessHeadshots.title')}</h1>
                    </span>
                  </h1>
                  <div className="quick-sign-up-mobile mt-4">
                    <div className="upgrade-btn-wrapper">
                      <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button">
                        {t('button.try')}
                      </Link>
                    </div>
                  </div>
                  <div className="description-root-0-2-148 description-root-d27-0-2-402" style={{maxWidth: "490px"}}>
                    <span style={{ color: '#adb5bd' }}>
                      {t('businessHeadshots.subTitle')}
                    </span>
                  </div>
                  <div className="relative mx-auto mt-6 max-w-lg space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="flex -space-x-3 overflow-hidden">
                        <img
                          src="/images/customer-1.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-2.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-3.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-4.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                          width={40}
                          height={40}
                        />{' '}
                        <img
                          style={{ marginLeft: '-12px' }}
                          src="/images/customer-5.webp"
                          alt=""
                          className="inline-block h-10 w-10 rounded-full border-[3px] border-white"
                          width={40}
                          height={40}
                        />
                      </div>{' '}
                      <div className="flex" style={{ color: '#facc15' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>{' '}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>
                    </div>{' '}
                    <p className="text-sm text-gray-500">{t('landing.loveBy')}</p>
                  </div>
                  <div className="banner-block-ctaHolder-d126-0-2-398  mt-4">
                    <div className="upgrade-btn-wrapper">
                      <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button">
                        {t('button.try')}
                      </Link>
                    </div>
                    <span className="text-gray-500">OR</span>
                    <div className="upgrade-btn-wrapper">
                      <a className="enterprise-button" href='https://share.hsforms.com/1_jRJFYMUQMSBTV61q7Y2Pwq3ize' target='_'>
                        {t('button.forEnterprise')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={24} xs={24} className="landing-order-first">
              <div className="banner-block-mediaWrapper">
                <div className=" media-root-0-2-161 media-root-d6-0-2-411 banner-block-media-0-2-88 banner-block-media-d128-0-2-400">
                  <div data-testid="image-root" className="base-image-root-0-2-361 base-image-root-d2-0-2-412">
                    <div className="before-after-landing-wrapper">
                      <div className="video-slider">
                        <img
                          loading='lazy'
                          className="banner-video-landing"
                          alt="reok"
                          width={367}
                          height={313}
                          src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landingpage%2F1.webp?alt=media&token=f34a08f5-4fad-4985-86a5-688cf88c6224"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="flex items-center justify-center text-center relative">
            <span className="text-gray-500">as seen on</span>
            <img className="tiktok-seen-on" loading="lazy" alt="reok" src="images/tiktok.webp" />
            <img className="facebook-seen-on" loading="lazy" alt="reok" src="images/facebook.svg" />
            <img className="instagram-seen-on" loading="lazy" alt="reok" src="images/instagram.webp" />
            <img className="linkedin-seen-on" loading="lazy" alt="reok" src="images/linkedin.png" />
            <img className="x-seen-on" loading="lazy" alt="reok" src="images/x.webp" />
            <img className="reddit-seen-on" loading="lazy" alt="reok" src="images/reddit.webp" />
          </div>
          {/* <div className="flex items-center justify-center text-center relative">
            <MoneyBackGuarantee/>
          </div> */}
        </div>
      </main>
      {/* <div className="mb-3" /> */}
      <main>
        {/* How it works */}
        <section
          id="5section1"
          className="relative overflow-hidden bg-black pb-1"
          data-layouts="simple_cards"
        >
          <BeforeAfterTestimonial/>
        </section>
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-90 section-bottom-90"
          data-layouts="simple_cards"
        >
          <div className="space-y-8 md:space-y-[85px]">
            {/* <div className="container w-full">
              <h2 className="mx-auto relative z-[10] t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center">
                <span className="custom-title">{t('landing.respectTitle')}</span> 🔐
              </h2>
              <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  {t('landing.respectSub1')}
                </p>
              </div>
            </div> */}
            <div className="container">
              <div className="w-full" id="how-it-works">
                <h2 className="mx-auto relative z-[10] t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center custom-title">
                  {t('landing.howItWorks')}
                </h2>
                <div
                className="mx-auto font-body relative z-10  t-20-l text-neutral-dark-grey/80  text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                <p>
                  {t('landing.respectSub1')}
                </p>
              </div>
              </div>
              
              {/* How it works steps */}
              <div className="space-y-8">
                <ul className="cards">
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-red-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-purple-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-pink-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">1</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step1')}</h2>
                        <p className="card_text text-align-center text-neutral-dark-grey/80">{t('landing.step1Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-gray-800 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-gray-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-black-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">2</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step2')}</h2>
                        <p className="card_text text-align-center text-neutral-dark-grey/80">{t('landing.step2Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content">
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-white-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-yellow-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-yellow-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">3</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step3')}</h2>
                        <p className="card_text text-align-center text-neutral-dark-grey/80">{t('landing.step3Sub')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
                <div className="grid gap-5 sm:grid-cols-2 sm:gap-4 lg:gap-10">
                <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-aliceblue">
                  <div className="rnftg-item__img-wrapper_landing">
                    <div>
                      <img
                        className="banner-video"
                        alt="reok"
                        src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/results%2Fmanshowcase1.webp?alt=media&token=6824ac4d-0d53-49b1-b7bc-fbe19f8dafe5"
                      ></img>
                    </div>
                  </div>
                </article>
                <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300 bg-light-purple">
                  <div className="rnftg-item__img-wrapper_landing">
                    <div>
                      <img
                        className="banner-video"
                        alt="reok"
                        src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/results%2Fwoman-showcase.webp?alt=media&token=26253631-2f1f-4d91-bd09-71203c63a324"
                      ></img>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>

        {/* Upscaler section */}
        <section
          id="5section2"
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container space-y-8">
            <Row gutter={12}>
              <Col lg={12} md={24} xs={24} className="landing-order-second flex items-center">
                <div className="w-full">
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    <svg
                      width="80px"
                      height="80px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      className="bi bi-badge-4k"
                    >
                      <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                    </svg>
                  </h2>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.unBlurryTitle')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p>
                      {t('landing.unBlurrySub')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                        {t('button.try')}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} md={24} xs={24} className="landing-order-second">
                <div className="flex flex-col justify-between">
                  <BeforeAfter images={upgradeResolution} />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* Remove background section */}
        <section
          id="5section2"
          className="relative overflow-hidden bg-black section-top-90 section-bottom-120"
          data-layouts="text_card,featured_cards"
        >
          <div className="container space-y-8">
            <Row gutter={12}>
              <Col lg={12} md={24} xs={24} className="landing-order-second">
                <div className="flex flex-col justify-between">
                  <div className="swiper">
                    <div className="before-after-wrapper">
                      <div id="before-after-slider">
                        <div className="card">
                          <div className="card_image">
                            <video
                              className="banner-video "
                              autoPlay
                              loop
                              style={{
                                backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/rm-background.webp?alt=media&token=ee87e03d-d0c9-49d6-966b-05c773f73aae")',
                              }}
                              muted
                              playsInline
                              data-wf-ignore="true"
                              dataobjectfit="cover"
                            >
                              
                              <source src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/rm-background.mp4?alt=media&token=d255d8d4-3ea7-47f9-afec-98aa24f2a8a1" data-wf-ignore="true" />
                            </video>
                          </div>
                        </div>     
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} md={24} xs={24} className="landing-order-first flex items-center">
                <div className="w-full">
                    <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    <svg width="80px" height="80px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.99988 16.938V19.059L5.05851 20H2.93851L5.99988 16.938ZM22.0015 14.435V16.557L18.5595 20H17.9935L17.9939 18.443L22.0015 14.435ZM8.74988 14H15.2446C16.1628 14 16.9158 14.7071 16.9888 15.6065L16.9946 15.75V20H6.99988V15.75C6.99988 14.8318 7.70699 14.0788 8.60636 14.0058L8.74988 14ZM8.02118 10.4158C8.08088 10.9945 8.26398 11.5367 8.54372 12.0154L1.99951 18.56V16.438L8.02118 10.4158ZM22.0015 9.932V12.055L17.9939 16.065L17.9946 15.75L17.9896 15.5825C17.9623 15.128 17.8246 14.7033 17.6029 14.3348L22.0015 9.932ZM12.0565 4L1.99951 14.06V11.939L9.93551 4H12.0565ZM22.0015 5.432V7.555L16.3346 13.2245C16.0672 13.1089 15.7779 13.0346 15.4746 13.0095L15.2446 13L14.6456 13.0005C14.9874 12.6989 15.2772 12.3398 15.4999 11.9384L22.0015 5.432ZM11.9999 7.00046C13.6567 7.00046 14.9999 8.34361 14.9999 10.0005C14.9999 11.6573 13.6567 13.0005 11.9999 13.0005C10.343 13.0005 8.99988 11.6573 8.99988 10.0005C8.99988 8.34361 10.343 7.00046 11.9999 7.00046ZM7.55851 4L1.99951 9.56V7.438L5.43751 4H7.55851ZM21.0565 4L15.9091 9.14895C15.7923 8.61022 15.5669 8.11194 15.2571 7.67816L18.9345 4H21.0565ZM16.5585 4L14.0148 6.54427C13.5362 6.26463 12.9942 6.08157 12.4157 6.02181L14.4365 4H16.5585Z"
                        fill="currentColor"
                        />
                      </svg>
                  </h2>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.feature1')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p>
                    {t('landing.feature1Sub')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                    <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                      {t('button.try')}
                    </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="section-top-90" style={{color: "#fff"}}>
            <LovedBy t={t}/>
          </div>
        </section>

        {/* Show case section */}
        <section
          id="5section1"
          className="relative overflow-hidden bg-white section-top-none section-top-90 section-bottom-90"
          data-layouts="simple_cards"
          // style={{background:"radial-gradient(circle at 30.7747% 100%, rgba(255, 124, 196, 0.055) 0%, 8.7%, rgba(255, 124, 196, 0) 29%), radial-gradient(circle at 79.2497% 45.1172%, rgba(248, 244, 235, 0.3) 0%, 17.5%, rgba(248, 244, 235, 0) 35%), radial-gradient(circle at 100% 0%, rgb(255 235 81 / 21%) 0%, 10.2%, rgba(255, 124, 81, 0) 34%), radial-gradient(circle at 48.7874% 46.8652%, rgba(248, 244, 235, 0.3) 0%, 32.5%, rgba(248, 244, 235, 0) 65%), radial-gradient(circle at 62.6107% 0%, rgba(248, 244, 235, 0.1) 0%, 47%, rgba(248, 244, 235, 0) 100%), radial-gradient(circle at 36.0107% 3.36914%, rgba(86, 108, 236, 0.16) 0%, 11.73%, rgba(86, 108, 236, 0) 23%), radial-gradient(circle at 8.77279% 0%, rgba(255, 211, 244, 0.235) 0%, 18.87%, rgba(255, 211, 244, 0) 37%), radial-gradient(circle at 48.9014% 49.5215%, rgba(248, 244, 235, 0.3) 0%, 100%, rgba(248, 244, 235, 0) 100%)"}}
        >
          <Testimonial t={t}/>
        </section>
        {/* Pricing section */}
        <section className="bg-neutral-black section-top-90 section-bottom-90">
          <div>
            <PricingLandingPage t={t} currentLanguage={currentLanguage}/>
          </div>
        </section>

        {/* FAQ section */}
        <section>
          <div className="relative bg-neutral-black section-bottom-90">
            <div className="container">
              <FAQ t={t}/>
            </div>
            <div className="flex flex-col align-items-center section-top-90">
                {/* <BeforeAfterTestimonial/> */}
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-5 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                🔐 {t('landing.respectTitle')}
              </h2>
              <div
                className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                style={{ maxWidth: '722px' }}
              >
                <p>
                {t('landing.respectSub1')}
                </p>
              </div>
              <div className="container rnftg-h-full rnftg-w-full">
                <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                  <div className="rnftg-flex rnftg-flex-col pd-2">
                    <div className="rnftg-grid rnftg-gap-custom rnftg-grid-flow-row rnftg-grid-cols-3 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F1.webp?alt=media&token=c31607b8-909c-4d8f-b0f0-efe0cb200499"
                            ></img>
                          </div>
                        </div>
                      </article>
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F3.webp?alt=media&token=52023a02-29fb-4c9c-a533-8c1667bac5c5"
                            ></img>
                          </div>
                        </div>
                      </article>
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F6.webp?alt=media&token=9c455fb6-5ddf-4d89-876e-570718e4c448"
                            ></img>
                          </div>
                        </div>
                      </article>
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F5.webp?alt=media&token=063fe45d-9a07-437a-b2c4-2f45dedb03df"
                            ></img>
                          </div>
                        </div>
                      </article>
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F4.webp?alt=media&token=26d896a5-6616-49f7-a815-4e728cdf57ff"
                            ></img>
                          </div>
                        </div>
                      </article>
                      <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                        <div className="rnftg-item__img-wrapper_landing">
                          <div className="result">
                            <img
                              className="banner-video"
                              alt="reok"
                              src="https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/showcase%2F2.webp?alt=media&token=6bc31fac-347b-4a79-a49e-a3bb614284a6"
                            ></img>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <div className="getstarted-btn-wrapper mt-4">
                <Link to={currentLanguage === 'en' ? '/signup' : `/${currentLanguage}/signup`} className="upgrade-button mt-4">
                  {t('button.try')}
                </Link>
              </div>
              <div className="mt-4" style={{color: "#fff"}}>
                <LovedBy t={t}/>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default LandingBusinessesHeadshot;
