import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';

const countries = [
  {
    label: 'English',
    lang: 'en',
    flag: '🇺🇸',
  },
  {
    label: 'Tiếng Việt',
    lang: 'vi',
    flag: '🇻🇳',
  },
  {
    label: 'Indonesia',
    lang: 'id',
    flag: '🇮🇩'
  },
  {
    label: '日本語',
    lang: 'jp',
    flag: '🇯🇵',
  },
  {
    label: '繁體中文',
    lang: 'zh',
    flag: '🇨🇳',
  },
  {
    label: '한국어',
    lang: 'ko',
    flag: '🇰🇷',
  }
];

const QuickLanguageSwitcher = () => {
  const [selected, setSelected] = useState(0);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.classList.remove('show');
        // Your code to handle closing the popup goes here
      }
    };

    document.addEventListener('click', handleClickOutside);

    const currentLanguage = i18n.language;
    const index = countries.findIndex((country) => country.lang === currentLanguage);
    setSelected(index);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [i18n.language]);

  const handleLanguageChange = (index) => {
    setSelected(index);
    dropdownRef.current.classList.remove('show');
    localStorage.setItem('mainLang', countries[index].lang);
  };

  function onOpenToggler(e) {
    e.stopPropagation();
    dropdownRef.current.classList.toggle('show');
  }

  function handleMouseEnter() {
    if (!dropdownRef.current.classList.contains('show')) {
      dropdownRef.current.classList.toggle('show');
    }
  }

  return (
    <div className="screen-mode-btn cursor-pointer" style={{paddingRight: "5px"}} onClick={onOpenToggler}>
      <span>{countries[selected].flag}</span>
      <div className="lang-drop-down text-gray-700 shadow-md rounded text-sm absolute mb-20 bottom-0 right-0 min-w-full" ref={dropdownRef}>
        <div className="overflow-auto rounded w-full relative z-10 dark-bg">
          <ul className="list-reset">
            {countries.map((item, index) => (
              <li key={index}>
                <a
                  href={item.lang === 'en' ? "/app" : `/${item.lang}/app` }
                  className={`px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100 ${index === selected ? 'font-bold' : ''}`}
                  onClick={() => handleLanguageChange(index)}
                >
                  <span className="inline-block pr-2">{item.flag}</span>
                  <span className="inline-block text-white">{item.label}</span>
                  {index === selected && (
                    <span className="ml-auto">
                      <i className="mdi mdi-check"></i>
                    </span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuickLanguageSwitcher;

