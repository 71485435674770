import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Button
} from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NewSkeleton from '../NewSkeleton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '65%',
    backgroundColor: '#3cff9a',
  },
});

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Background = ({ userData, firebase, onShowBackground, t, backgroundsList, uploadedbackgroundsList, handleSelectBackground, mode, onShowPricing, submitPrompt, currentLanguage, promptBG }) => {
  const popupRef = useRef(null);
  const [showSub, setShowSub] = useState(true);
  const [currentPoses, setCurrentPoses] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [error, setError] = useState('');
  const [prompt, setPrompt] = useState('');
  const [value, setValue] = useState(0);
  const [forbiddenWords, setForbiddenWords] = useState([]);

  useEffect(() => {
    if (backgroundsList !== undefined ) {
      setCurrentPoses(backgroundsList)
    }

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowBackground(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onShowBackground]);

  useEffect(() => {
    if (promptBG !== '') {
      setPrompt(promptBG);
    }
  }, [promptBG]);

  useEffect(() => {
    // Read bad words from environment variable
    const badWordsString = process.env.REACT_APP_BAD_WORDS || 'boobs,nipple,bikini,underwear,sex,porn,nsfw,nude,fuck,Blood,Bloodbath,Crucifixion,Bloody,Flesh,Bruises,Car crash,Corpse,Crucified,Cutting,Decapitate,Infested,Gruesome,Kill,Infected,Sadist,Slaughter,Teratoma,Tryphophobia,Wound,Cronenberg,Khorne,Cannibal,Cannibalism,Visceral,Guts,Bloodshot,Gory,Killing,Surgery,Vivisection,Massacre,Hemoglobin,Suicide,Female Body Parts,ahegao,pinup,ballgag,Playboy,Bimbo,pleasure,bodily fluids,pleasures,boudoir,rule34,brothel,seducing,dominatrix,seductive,erotic seductive,fuck,sensual,Hardcore,sexy,Hentai,Shag,horny,shibari (bondage in Japanese),incest,Smut,jav,succubus,Jerk off king at pic,thot,kinbaku (bondage in Japanese),transparent,legs spread,twerk,making love,voluptuous,naughty,wincest,orgy,Sultry,XXX,Bondage,Bdsm,Dog collar,Slavegirl,Transparent and Translucent,Arse,Labia,Ass,Mammaries,Human centipede,Badonkers,Minge (Slang for vag),Massive chests,Big Ass,Mommy Milker (milker or mommy is fine),Booba,Nipple,Booty,Oppai (Japanese word for breasts),Bosom,Organs,Breasts,Ovaries,Busty,Penis,Clunge (British slang for vagina),Phallus,Crotch,sexy female,Dick (as in Moby-Dick),Skimpy,Girth,Thick,Honkers,Vagina,Hooters,Veiny,Knob,no clothes,Speedo,au naturale,no shirt,bare chest,nude,barely dressed,bra,risqué,clear,scantily,clad,cleavage,stripped,full frontal unclothed,invisible clothes,wearing nothing,lingerie with no shirt,naked,without clothes on,negligee,zero clothes,nude,Taboo,Fascist,Nazi,Prophet Mohammed,Slave,Coon,Honkey,Arrested,Jail,Handcuffs,Drugs,Cocaine,Heroin,Meth,Crack,Torture,Disturbing,Farts, Fart,Poop,Warts,Xi Jinping,Shit,Pleasure,Errect,Big Black,Brown pudding,Bunghole,Vomit,Voluptuous,Seductive,Sperm,Hot,Sexy,Sensored,Censored,Silenced,Deepfake,Inappropriate,Pus,Waifu,mp5,Succubus,1488,Surgery';
    const badWordsArray = badWordsString.split(',');
    setForbiddenWords(badWordsArray);
  }, []);

  function onSelectPose(e, background) {
    e.preventDefault();
    handleSelectBackground(background);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const onOpenCustomStyle = () => {
    setIsPrompt(true);
  }

  const handleInputChange = (event) => {
    if (prompt === '') {
      setError('');
    }
    setPrompt(event.target.value);
  };

  const handleClosePrompt = () => setIsPrompt(false);

  const handleSubmitPromptStyle = () => {

    if (prompt === '') {
      setError(t('message.error.emptyBG'));
      return;
    }

    const words = prompt.trim().split(/\s+/); // Split the prompt into words
    const wordCount = words.length;

    if (wordCount < 2) {
      setError(t('message.error.tooShortBG'));
      return;
    }

    if (wordCount > 8) {
      setError(t('message.error.tooLongBG'));
      return;
    }

    if (containsBadWord(prompt)) {
      setError(t('message.error.badWordsBG'));
      return;
    }

    const background = {
      imageUrl: "/images/custom-style.webp",
      name: prompt
    }
    handleSelectBackground(background);
    submitPrompt(prompt);
  };

  const containsBadWord = (text) => {
    const words = text.trim().split(/\s+/).map((word) => word.toLowerCase());
    return forbiddenWords.some((word) => words.includes(word.toLowerCase()));
  };

  return (
    <>
      <div className="pose-showbox">
        <section id="pose-container" ref={popupRef}>
          {showSub && (
            <>
              <div>
                <Box>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                  >
                    <StyledTab label={t('app.professionalMode.defaultBackground')} />
                  </StyledTabs>
                  <Box sx={{ p: 1 }} />
                </Box>
                <CustomTabPanel value={value} index={0}>
                <div className="rnftg-h-full rnftg-w-full">
                  <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                    <div className="rnftg-flex rnftg-flex-col pd-2">
                      <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-4 xl:rnftg-grid-cols-6">
                        <div className="pose-item">
                          <div className="pose-line-border">
                            <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                              <div className="rnftg-item__img-wrapper">
                                <div className="pose-col">
                                  {!imageLoaded && (
                                      <NewSkeleton/>
                                    )
                                  }
                                  <img
                                    className={`new-image-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
                                    alt="reok"
                                    src="/images/custom-style.webp"
                                  ></img>
                                  <div className="item-label">{t('backgrounds.custom.title')}</div>
                                </div>
                              </div>
                            </div>
                            <div className="select-pose-imagine-button generate-btn">
                              {currentLanguage === 'en' ?
                              <Button className="generate-button w-button" onClick={onOpenCustomStyle}>
                                <span style={{ marginLeft: '4px', textTransform:'none' }}>{t('backgrounds.custom.btn')}</span>
                              </Button>
                                :
                                <Button className="generate-button w-button">
                                <div className="x-price-card--hero-text" style={{ marginLeft: '4px', textTransform:'none', fontSize: '0.875rem' }}>
                                  {t('button.englishOnly')}
                                </div>
                              </Button>
                              }
                            </div>
                          </div>
                        </div>
                        {currentPoses.map((c, i) => {
                          return (
                            <div className="pose-item" key={i}>
                              <div className="pose-line-border">
                                <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                                  <div className="rnftg-item__img-wrapper">
                                    <div className="pose-col">
                                      {!imageLoaded && (
                                        <NewSkeleton/>
                                        )
                                      }
                                      <img
                                        className={`new-image-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
                                        alt="reok"
                                        src={c.imageUrl}
                                        onLoad={handleImageLoad}
                                      ></img>
                                      <div className="item-label">{t(`backgrounds.${c.code}`)}</div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="text-align-center text-white mt-2" style={{fontSize: "14px"}}>
                                  {t(`backgrounds.${c.code}`)}
                                </div> */}
                                <div className="select-pose-imagine-button generate-btn">
                                  {userData.trial && i !== 0 ? (
                                    <Button className="generate-button w-button" onClick={onShowPricing}>
                                      <div className="x-price-card--hero-text" style={{ marginLeft: '4px', textTransform:'none', fontSize: '0.875rem' }} onClick={() => onShowPricing(true)}>
                                        {t('button.unlock')}
                                        <svg version="1.1" fill="rgb(251, 190, 40)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 51.2 51.2" xmlSpace="preserve" style={{ marginLeft: "4px", marginTop: "2px"}}>
                                          <g>
                                            <path className="st0" d="M51.2 15.247c0 -2.147 -1.742 -3.888 -3.889 -3.888 -2.148 0 -3.891 1.741 -3.891 3.888 0 1.05 0.417 2.002 1.094 2.7 -2.645 5.478 -7.702 7.391 -11.62 5.659 -3.491 -1.544 -4.778 -5.956 -5.214 -9.375 1.423 -0.748 2.394 -2.239 2.394 -3.959C30.073 7.802 28.072 5.8 25.6 5.8c-2.47 0 -4.473 2.002 -4.473 4.472 0 1.72 0.97 3.211 2.394 3.959 -0.436 3.419 -1.723 7.831 -5.214 9.375 -3.919 1.731 -8.975 -0.181 -11.62 -5.659 0.677 -0.698 1.094 -1.65 1.094 -2.7 0 -2.147 -1.742 -3.888 -3.889 -3.888C1.742 11.359 0 13.1 0 15.247c0 1.978 1.478 3.608 3.388 3.855l4.483 16.408h35.459l4.483 -16.408C49.723 18.855 51.2 17.225 51.2 15.247z"/>
                                            <path className="st0" d="M45.502 42.506c0 1.598 -1.295 2.894 -2.895 2.894H8.594C6.995 45.4 5.7 44.105 5.7 42.506v-0.241c0 -1.6 1.295 -2.895 2.894 -2.895h34.013c1.6 0 2.895 1.295 2.895 2.895v0.241z"/>
                                          </g>
                                        </svg>
                                      </div>
                                    </Button>
                                  ):(
                                    <Button className="generate-button w-button" onClick={(e) => onSelectPose(e, c)}>
                                      <span style={{ marginLeft: '4px', textTransform:'none' }}>{t('button.select')}</span>
                                    </Button>
                                  )}
                                </div>
                              </div>
                          </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                </CustomTabPanel>
              </div>
            </>
          )}
        </section>
      </div>
      <Dialog
        open={isPrompt}
        onClose={handleClosePrompt}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{fontWeight: "700"}}>
          {t('backgrounds.custom.dialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="mb-4">
              <span id="parent-modal-description">
                {t('backgrounds.custom.dialogSubTitle')}
              </span>
              {userData.trial && (
                <div className="x-price-card--hero-text" style={{ marginLeft: '4px', textTransform:'none', fontSize: '0.875rem' }} onClick={() => onShowPricing(true)}>
                  {t('backgrounds.custom.upgrade')}
                  <svg version="1.1" fill="rgb(251, 190, 40)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 51.2 51.2" xmlSpace="preserve" style={{ marginLeft: "4px", marginTop: "2px"}}>
                    <g>
                      <path className="st0" d="M51.2 15.247c0 -2.147 -1.742 -3.888 -3.889 -3.888 -2.148 0 -3.891 1.741 -3.891 3.888 0 1.05 0.417 2.002 1.094 2.7 -2.645 5.478 -7.702 7.391 -11.62 5.659 -3.491 -1.544 -4.778 -5.956 -5.214 -9.375 1.423 -0.748 2.394 -2.239 2.394 -3.959C30.073 7.802 28.072 5.8 25.6 5.8c-2.47 0 -4.473 2.002 -4.473 4.472 0 1.72 0.97 3.211 2.394 3.959 -0.436 3.419 -1.723 7.831 -5.214 9.375 -3.919 1.731 -8.975 -0.181 -11.62 -5.659 0.677 -0.698 1.094 -1.65 1.094 -2.7 0 -2.147 -1.742 -3.888 -3.889 -3.888C1.742 11.359 0 13.1 0 15.247c0 1.978 1.478 3.608 3.388 3.855l4.483 16.408h35.459l4.483 -16.408C49.723 18.855 51.2 17.225 51.2 15.247z"/>
                      <path className="st0" d="M45.502 42.506c0 1.598 -1.295 2.894 -2.895 2.894H8.594C6.995 45.4 5.7 44.105 5.7 42.506v-0.241c0 -1.6 1.295 -2.895 2.894 -2.895h34.013c1.6 0 2.895 1.295 2.895 2.895v0.241z"/>
                    </g>
                  </svg>
                </div>
              )}
            </div>
            <TextField
              error={ error!== '' }
              id="outlined-error-helper-text"
              // label={error=== '' ? "" : "Error"}
              helperText={error}
              disabled={userData.trial}
              value={prompt}
              autoFocus={false}
              sx={{ width: '100%'}}
              maxRows={3}
              minRows={3}
              multiline
              onChange={handleInputChange}
              placeholder={t('backgrounds.custom.example')}
              inputProps={{style: {fontSize: 14}}} // font size of input text
              InputLabelProps={{style: {fontSize: 14}}} // font size of input label
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClosePrompt} variant="outlined" color="error">
            Cancel
          </Button>
          <Button variant="outlined" color="success" onClick={handleSubmitPromptStyle} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Background;
