// import React from 'react';

// const LoaderLineMask = () => {

//   return (
//     <div className="line-mask">
//       <div className="loader-wrapper">
//       <p/><p/><p/><p/>
//       </div>
//     </div>
//   );
// };

// export default LoaderLineMask;
import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="inherit">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function LoaderLineMask({status}) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    if (status !== undefined && typeof(status) === 'number') {
      setProgress(status);
    } else {
      setProgress(0);
    }
  }, [status]);

  return (
    <div className="line-mask">
      <div className="circular-progress">
        <CircularProgressWithLabel value={status} color="inherit"/>
      </div>
    </div>
  );
}

