import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

export const Avatar = ({ size = 50, url = false, initials = '', color = '#46a5c8' }) => {
  const [validUrl, setValidUrl] = useState(false)

  useEffect(() => {
    if (url) {
      fetch(url, { method: 'HEAD' })
        .then(res => {
          if (res.ok) {
            setValidUrl(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [url])

  return (
    <Wrapper
      style={{
        backgroundImage: `url(${validUrl && url})`,
        backgroundColor: color,
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        fontSize: `${size / 3}px`,
        fontWeight: 'bold',
        paddingTop: `${!validUrl && '2px'}`,
      }}>
      {!validUrl && initials}
    </Wrapper>
  )
}

const Wrapper = styled.span`
  font-family: 'Campton-Medium', Verdana, sans-serif;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: grid;
  place-items: center;
`
