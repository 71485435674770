import React, { useEffect, useState, useRef } from 'react';
import { Button, message } from 'antd';
import MUIButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import NewSkeleton from '../NewSkeleton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ImageResult = ({
  t,
  job,
  firebase,
  userData,
  onCompareImage,
  onDownloadImage,
  onFindAgency,
  onEditImage,
  onRemoveBG,
  isDownloading,
  onUpImageResolution,
  onRemoveFromList,
  hideRenew
}) => {

  const [loveColor, setLoveColor] = useState('#fff');
  const [isLoved, setIsLoved] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openRenew, setOpenRenew] = useState(false);
  const [isInReview, setIsInReview] = useState(false);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState(false);
  const [isDestroyed, setIsDestroyed] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  // const imageRef = useRef(null);

  useEffect(() => {
    if (job.status === "nsfw") {
      const nsfwText = "NSFW Detected, we removed it - ";
      if (!job.prompt.includes(nsfwText)) {
        // If the substring is not already present, add the prefix
        job.prompt = nsfwText + job.prompt;
      }
    }
    if (job.isLoved !== undefined && job.isLoved) {
      setIsLoved(true);
      setLoveColor('#e8594b');
    }

    if (job.isInReview !== undefined && job.isInReview) {
      setIsInReview(true);
    }

    // setTimeout(() => {
    //   try {
    //     const image = new Image();
    //     image.onload = () => {
    //       imageRef.current.src = job.upscaleUrl;
    //       setImageLoaded(true);
    //     };
    //     image.onerror = () => {
    //       setImageLoaded(false);
    //       // Handle error, show message, etc.
    //       console.error("Error loading image");
    //     };
    //     image.src = job.upscaleUrl;
    //   } catch (error) {
    //     setImageLoaded(false);
    //     // Handle other errors
    //     console.error(error);
    //   }
    // }, 2500);

  }, [job]);
  
  function onLove(job) {

    if (isLoved) {
      if (loveColor === "#fff") {
        setLoveColor('#e8594b');
      } else {
        setLoveColor("#fff");
      }
      return;
    }

    firebase.updateJob(job.id, {isLoved: true})
    .then(result => {
      setLoveColor('#e8594b');
      setIsLoved(true);
      job.isLoved = true;
    })
    .catch(err => {
      message.error(err.message);
      console.log(err);
    });
  }

  function onConfirmRemove(e, job) {
    e.preventDefault();

    firebase.updateJob(job.id, {isDeleted: true})
    .then(result => {
      setIsDestroyed(true);
      message.success(t('message.success.removed'));
    })
    .catch(err => {
      message.error(err.message);
      console.log(err);
    });

    setOpenRemove(false);
  }

  function onConfirmRenew(e) {
    e.preventDefault();

    if (text === "") {
      setTextError(true);
      return;
    }

    if (isInReview) {
      message.success(t('message.success.remindReviewing'));
      setOpenRenew(false);
      return;
    } else {
      const data = {
        email: userData.email,
        uid: job.uid,
        jobId: job.id,
        reason: text,
        originalUrl: job.originalUrl,
        upscaleUrl: job.upscaleUrl,
        createdAt: new Date().toISOString(),
        isDeleted: false,
        isApproved: false
      }

      firebase
      .addRenewPhoto(data)
      .then(result => {
        setIsInReview(true);
        message.success(t('message.success.reviewing'));
      })
      .catch(err => {
        message.error(err.message);
        console.log(err);
      });

      firebase.updateJob(job.id, {isInReview: true})
      .then(result => {
        job.isInReview = true;
      })
      .catch(err => {
        message.error(err.message);
        console.log(err);
      });
    }

    setOpenRenew(false);
  }

  const onRemove = () => {
    if (isInReview) {
      message.error(t('message.error.reviewing'));
      return;
    }
    setOpenRemove(true);
  };

  const onRenew = () => {
    setTextError(false);
    setOpenRenew(true);
  }

  const onCloseRemove = () => {
    setOpenRemove(false);
  };

  const onCloseRenew = () => {
    setOpenRenew(false);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
    {!isDestroyed ?
    <article className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
      <div className="rnftg-item__img-wrapper">
        <div className="result">
          {!imageLoaded && (
            <NewSkeleton/>
            )
          }
          <img
            className={`rnftg-w-full rnftg-h-full rnftg-object-cover rnftg-cursor-pointer rnftg-rounded-t-2xl result-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
            alt="reok"
            width={400}
            height={600}
            src={job.upscaleUrl}
            onLoad={handleImageLoad}
          ></img>
          {
            isInReview === true ? (
              <div className="prompt-label">{t('message.prompt.inReview')}</div>
            )
            :
            (<div className="prompt-label">{job.prompt}</div>)
          }
          
          <div className="action-1">
            <Button
              className="image_action download_image"
              onClick={() => onLove(job)}
              type="submit"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#e8594b">
                <path
                  d="M20.808 11.079C19.829 16.132 12 20.5 12 20.5s-7.829-4.368-8.808-9.421C2.227 6.1 5.066 3.5 8 3.5a4.444 4.444 0 0 1 4 2 4.444 4.444 0 0 1 4-2c2.934 0 5.773 2.6 4.808 7.579Z"
                  fill={loveColor}
                />
              </svg>
            </Button>
            {/* <span>{t('imageComponent.compare')}</span> */}
          </div>
          <div className="action-1-right">
            <Button
              className="image_action download_image"
              onClick={() => onRemove(job)}
              type="submit"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                  fill="#fff"
                />
              </svg>
            </Button>
          </div>
          <div className="action-2">
            <Button
              className="image_action download_image"
              onClick={() => onCompareImage({ before: job.originalUrl, after: job.upscaleUrl })}
              type="submit"
            >
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240" fillRule="nonzero">
                <g
                  fill="#ffffff"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth={1}
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit={10}
                  strokeDashoffset={0}
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: 'normal' }}
                >
                  <g transform="scale(5.12,5.12)">
                    <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z" />
                  </g>
                </g>
              </svg>
            </Button>
            <span>{t('imageComponent.compare')}</span>
          </div>
          <div className="action-3">
            <Button
              className="image_action download_image"
              onClick={() => onDownloadImage(job.upscaleUrl)}
              type="submit"
              loading={isDownloading}
            >
              <svg fill="#000000" viewBox="-2 -2 18 20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
                <path d="M8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z" />
              </svg>
            </Button>
            <span>{t('imageComponent.download')}</span>
          </div>
          <div className="action-4">
            <Button onClick={e => onRemoveBG(e, job.upscaleUrl)} className="image_action open_shop" type="button">
              <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.99988 16.938V19.059L5.05851 20H2.93851L5.99988 16.938ZM22.0015 14.435V16.557L18.5595 20H17.9935L17.9939 18.443L22.0015 14.435ZM8.74988 14H15.2446C16.1628 14 16.9158 14.7071 16.9888 15.6065L16.9946 15.75V20H6.99988V15.75C6.99988 14.8318 7.70699 14.0788 8.60636 14.0058L8.74988 14ZM8.02118 10.4158C8.08088 10.9945 8.26398 11.5367 8.54372 12.0154L1.99951 18.56V16.438L8.02118 10.4158ZM22.0015 9.932V12.055L17.9939 16.065L17.9946 15.75L17.9896 15.5825C17.9623 15.128 17.8246 14.7033 17.6029 14.3348L22.0015 9.932ZM12.0565 4L1.99951 14.06V11.939L9.93551 4H12.0565ZM22.0015 5.432V7.555L16.3346 13.2245C16.0672 13.1089 15.7779 13.0346 15.4746 13.0095L15.2446 13L14.6456 13.0005C14.9874 12.6989 15.2772 12.3398 15.4999 11.9384L22.0015 5.432ZM11.9999 7.00046C13.6567 7.00046 14.9999 8.34361 14.9999 10.0005C14.9999 11.6573 13.6567 13.0005 11.9999 13.0005C10.343 13.0005 8.99988 11.6573 8.99988 10.0005C8.99988 8.34361 10.343 7.00046 11.9999 7.00046ZM7.55851 4L1.99951 9.56V7.438L5.43751 4H7.55851ZM21.0565 4L15.9091 9.14895C15.7923 8.61022 15.5669 8.11194 15.2571 7.67816L18.9345 4H21.0565ZM16.5585 4L14.0148 6.54427C13.5362 6.26463 12.9942 6.08157 12.4157 6.02181L14.4365 4H16.5585Z"
                  fill="#fff"
                />
              </svg>
            </Button>
            <span>{t('imageComponent.removeBG')}</span>
          </div>
          <div className="action-5">
            <Button onClick={e => onUpImageResolution(e, job.upscaleUrl)} className="image_action open_shop" type="button">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-badge-4k"
              >
                <path d="M4.807 5.001C4.021 6.298 3.203 7.6 2.5 8.917v.971h2.905V11h1.112V9.888h.733V8.93h-.733V5.001h-1.71zm-1.23 3.93v-.032a46.781 46.781 0 0 1 1.766-3.001h.062V8.93H3.577zm9.831-3.93h-1.306L9.835 7.687h-.057V5H8.59v6h1.187V9.075l.615-.699L12.072 11H13.5l-2.232-3.415 2.14-2.584z" />
                <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
              </svg>
            </Button>
            <span>{t('imageComponent.upscale')}</span>
          </div>
          {/* {!hideRenew &&
          <div className="action-6">
            <Button onClick={() => onRenew(job.upscaleUrl)} className="image_action open_shop" type="button">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z" />
                <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 0 0 .054 1.968A1.984 1.984 0 0 0 4.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 0 0 .054-1.968L13.768 4.2zM4.661 19 12 5.137 19.344 19H4.661z" />
              </svg>
            </Button>
            <span>{t('imageComponent.errorPhoto')}</span>
          </div>
          } */}
          <div className="blur-image" />
        </div>
      </div>
      {openRemove &&
        <div>
          <Dialog
            open={openRemove}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseRemove}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t('removeTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {t('removeSub')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MUIButton onClick={onCloseRemove}>{t('button.cancel')}</MUIButton>
              <MUIButton onClick={(e) => onConfirmRemove(e, job)} color="error">{t('button.ok')}</MUIButton>
            </DialogActions>
          </Dialog>
        </div>
      }
      {openRenew &&
        <div>
          <Dialog
            open={openRenew}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCloseRenew}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t('renewTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {t('renewSub')}
              </DialogContentText>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': {mt: 4,width: '100%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                    <TextField
                      id="outlined-textarea"
                      label={t('reasonTitle')}
                      placeholder={t('reasonEx')}
                      maxRows={3}
                      multiline
                      onChange={handleInputChange}
                      error={textError}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
              <MUIButton onClick={onCloseRenew}>{t('button.cancel')}</MUIButton>
              <MUIButton onClick={(e) => onConfirmRenew(e)} color="error">{t('button.ok')}</MUIButton>
            </DialogActions>
          </Dialog>
        </div>
      }
    </article>
    : null
    }
    </>
  );
};

export default ImageResult;
