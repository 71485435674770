import React, { useRef } from 'react';
import Spinner from '../Spinner';

const Image = ({ id, className, src, style, contextMenu, handleChooseImage, choosingImage, enableDownload, flowerData, onDownloadImage, t }) => {

    const imageRef = useRef(null);
    const buttonLayerRef = useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [choosing, setChoosing] = React.useState(true);

    const handleOnLoad = () => {
        setLoading(true);

        // Get the image element and the button layer element
        const image = imageRef.current;
        const buttonLayer = buttonLayerRef.current;

        // Set the initial size of the button layer based on the image size
        const imageSize = image.getBoundingClientRect();
        buttonLayer.style.width = `${imageSize.width}px`;
        buttonLayer.style.height = `${imageSize.height}px`;

        // Add an event listener for the "resize" event on the window object
        const handleResize = () => {
            // Get the new image size and update the button layer size accordingly
            const newImageSize = image.getBoundingClientRect();
            buttonLayer.style.width = `${newImageSize.width}px`;
            buttonLayer.style.height = `${newImageSize.height}px`;
        };
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    };

    const handleOnContextMenu = (event) => {
        if (!contextMenu) event.preventDefault();
    };

    return (
        <div>
            {(!loading) && <Spinner />}
            <img loading="lazy" id={id} ref={imageRef} className={className} src={src} style={style} onLoad={handleOnLoad} onContextMenu={handleOnContextMenu} />
            {enableDownload &&
                <div class="button-layer" ref={buttonLayerRef}>
                    <button onClick={() => { onDownloadImage(src) }} class="download-button-pro">
                        <img src="fonts/download.svg" style={{ width: "40px" }} />
                        <div className="download-title">{t('button.download')}</div>
                    </button>
                </div>
            }
        </div>
    );
};

export default Image;
