import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import * as routes from '../constants/routes';
import i18n from '../i18n';
import Footer from '../components/Footer';
import AdsHeaderContainer from '../containers/AdsHeaderContainer';

const AdsRoute = ({ user }) => {
  return (!user) ?
    <>
      <AdsHeaderContainer userData={user}/>
      <Outlet userData={user}/>
      <Footer/>
    </> : <Navigate to={i18n.language === "en" ? "/app" : `/${i18n.language}/app`} replace />;
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdsRoute);
