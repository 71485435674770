import React from 'react';
import { ZoomInIcon, ZoomOutIcon, DownloadIcon, CloseIcon, RotateIcon } from './Icons';
import { react_modal_images__caption, react_modal_images__header, react_modal_images__icon_menu, react_modal_images__icon_menuA } from './styles';

const Header = ({ image, alt, zoomed, toggleZoom, toggleRotate, onClose, enableDownload, enableZoom, enableRotate }) => {
    const handleDownloadClick = (href) => async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(href, {
                "method": "GET",
                "headers": {},
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                "Access-Control-Allow-Headers": "X-Requested-With,content-type",
                "Access-Control-Allow-Credentials": true,
                // mode: 'no-cors'
            });
            const blob = response.blob();

            let tmpAnchor = document.createElement('a');
            tmpAnchor.setAttribute('download', href.split('/').pop());
            tmpAnchor.href = URL.createObjectURL(blob);
            tmpAnchor.click();

        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <div className="react_modal_images__header" style={react_modal_images__header}>
            <span className="react_modal_images__icon_menu" style={react_modal_images__icon_menu}>
                {enableDownload && (
                    <a
                        href={image}
                        onClick={handleDownloadClick(image)}
                        download
                        // onClick={Download.crossOriginDownload(image)}
                        style={react_modal_images__icon_menuA}
                    >
                        <DownloadIcon />
                    </a>
                )}
                {enableZoom && (
                    <a onClick={toggleZoom} style={react_modal_images__icon_menuA}>
                        {zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
                    </a>
                )}
                {enableRotate && (
                    <a onClick={toggleRotate} style={react_modal_images__icon_menuA}>
                        <RotateIcon />
                    </a>
                )}
                <a onClick={onClose} style={react_modal_images__icon_menuA}>
                    <CloseIcon />
                </a>
            </span>
            {alt && (
                <span className="react_modal_images__caption" style={react_modal_images__caption}>
                    {alt}
                </span>
            )}
        </div>
    );
};

export default Header;
