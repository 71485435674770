import React, { useState } from 'react';
import Header from './Header';
import Image from './Image';

const ProgressBar = ({ flowerData, large, alt, onClose, hideDownloadButton, hideRotateButton, imageBackgroundColor = 'black', handleChooseImage, choosingImage, onDownloadImage, t }) => {
  
  const [rotationDeg, setRotationDeg] = useState(0);
  const [move, setMove] = React.useState({
    x: 0,
    y: 0
  });

  
  const toggleRotate = (event) => {
    event.preventDefault();
  
    if (rotationDeg === 360) setRotationDeg(90);
    else setRotationDeg(rotationDeg + 90);
  };

  return (
      <div>
        <div className="react_modal_images__modal_container"
          style={{
            position: 'fixed',
            zIndex: '5000',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            touchAction: 'none',
            overflow: 'hidden'
          }}>
          <div className="react_modal_images__modal_content" style={{
            position: 'relative',
            height: '100%',
            width: '100%'
          }}>
            <Image
              id="react-modal-image-img"
              className="react_modal_images__large_img"
              src={large}
              choosingImage={choosingImage}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '50%',
                left: '50%',
                overflow: 'hidden',
                maxWidth: '90%',
                maxHeight: '90%',
                transform: `translate3d(-50%, -50%, 0) translate3d(${move.x}px, ${move.y}px, 0) rotate(${rotationDeg}deg)`,
                WebkitTransform: `translate3d(-50%, -50%, 0) translate3d(${move.x}px, ${move.y}px, 0) rotate(${rotationDeg}deg)`,
                msTransform: `translate3d(-50%, -50%, 0) translate3d(${move.x}px, ${move.y}px, 0) rotate(${rotationDeg}deg)`,
                backgroundColor: `${imageBackgroundColor}`
              }}
              handleChooseImage={handleChooseImage}
              flowerData={flowerData}
              enableDownload={!hideDownloadButton}
              onDownloadImage={onDownloadImage}
              t={t}
            />
          </div>
          <Header
            image={large}
            alt={alt}
            toggleRotate={toggleRotate}
            onClose={onClose}
            // enableDownload={!hideDownloadButton}
            enableRotate={!hideRotateButton}
          />
        </div>
      </div>
  );
};

export default ProgressBar;
