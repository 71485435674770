import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRoutes from '../../routes';
import RightClickWrapper from '../../components/RightClickWrapper';

const App = () => {
  return (
    <Router>
      <RightClickWrapper>
        <AppRoutes />
      </RightClickWrapper>
    </Router>
  );
};

export default App;
