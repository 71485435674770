import React, { Component } from 'react';

class RightClickWrapper extends Component {
  constructor(props) {
    super(props);

    // Initialize state to track whether right-click is allowed
    this.state = {
      allowRightClick: true,
    };
  }

  // Function to prevent right-click
  handleContextMenu = (e) => {
    if (!this.state.allowRightClick) {
      e.preventDefault();
    }

    // Disable right-click
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      )
        return false;
    };
  };

  // Toggle right-click functionality
  toggleRightClick = () => {
    this.setState((prevState) => ({
      allowRightClick: !prevState.allowRightClick,
    }));
  };

  render() {
    const { children } = this.props;

    return (
      <div onContextMenu={this.handleContextMenu}>
        {React.cloneElement(children, {
          allowRightClick: this.state.allowRightClick,
          toggleRightClick: this.toggleRightClick,
        })}
      </div>
    );
  }
}

export default RightClickWrapper;
