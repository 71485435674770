import React, { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import FAQ from '../FAQ';
import axios from 'axios';
import confetti from 'canvas-confetti';
import {
  TextField,
  Button,
  Snackbar
} from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Spinning from '../Spinning';
import NewSkeleton from '../NewSkeleton';

const modes = {
  pro: 'Pro',
  basic: 'Basic',
};

const MAX_WIDTH = 768;

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '65%',
    backgroundColor: '#3cff9a',
  },
});

const StyledTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const Pose = ({ userData, firebase, onShowPose, onFetchUser, t, currentLanguage, posesList, uploadedPosesList, handleSelectPose, mode, handleSetPoseFile, onShowPricing }) => {
  const popupRef = useRef(null);
  const [showSub, setShowSub] = useState(true);
  const [currentPoses, setCurrentPoses] = useState([]);
  const [currentUploadedPoses, setCurrentUploadedPoses] = useState([]);

  const [value, setValue] = useState(0);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [poseSrc, setPoseSrc] = useState('');
  const [file, setFile] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [width, setWidthInfo] = useState(800);
  const [height, setHeightInfo] = useState(800);
  const [isUploading, setIsUploading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (posesList !== undefined ) {
      setCurrentPoses(posesList)
    }

    if (uploadedPosesList !== undefined ) {
      setCurrentUploadedPoses(uploadedPosesList)
    }

    // Add event listener to close the popup when clicked outside
    const handleClickOutside = event => {
      if (popupRef.current && event.target.contains(popupRef.current)) {
        onShowPose(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onShowPose]);

  function onSelectPose(e, pose, convertPose) {
    e.preventDefault();
    handleSelectPose(pose, convertPose);
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setImageLoaded(false);
  };

  const handleOpenFileUpload = () => {
    if (userData.trial) {
      onShowPricing(true);
      return;
    }

    fileInputRef.current.click();
  };

  const handleDrop = async function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (userData.trial) {
      onShowPricing(true);
      return;
    }

    setIsUploading(true);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];

      if (checkFileSize(file) === false) return;

      if (currentUploadedPoses.length > 100) {
        alert("You reached 100 free uploaded poses. Please upgrade to upload more!");
        setIsUploading(false);
        return;
      }
      
      try {
        const downloadURL = await firebase.uploadPoseFile(file);
      
        if (downloadURL) {
          const imageUrl = downloadURL;
      
          const data = {
            uid: userData.uid,
            imageUrl: imageUrl,
            poseUrl: imageUrl,
            createdAt: new Date().toISOString(),
          };
      
          await firebase
          .addUploadedPoses(data)
          .then(() => {
      
            const convertPose = true;
            handleSetPoseFile(downloadURL, convertPose);
      
            setFile(file);
            const reader = new FileReader();
        
            reader.onload = function () {
              const image = new Image();
        
              image.onload = function () {
                const canvas = document.createElement('canvas');
                const scaleSize = MAX_WIDTH / image.width;
                // canvas.width = MAX_WIDTH;
                // canvas.height = image.height * scaleSize;
                canvas.width = image.width;
                canvas.height = image.height;
                setWidthInfo(canvas.width);
                setHeightInfo(canvas.height);
        
                const ctx = canvas.getContext('2d');
        
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        
                if (file.name === '' || file.name === undefined) {
                  const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
                  file.name = `reok-up-${randomNumber}.jpg`;
                }
        
                canvas.toBlob(blob => {
                  const canvasImage = new File([blob], file.name, {
                    type: file.type,
                    lastModified: file.lastModified,
                  });
                  setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
                  setFile(canvasImage);
        
                  const reader2 = new FileReader();
                  reader2.onloadend = function () {
                    const srcEncoded = reader2.result;
                    setImageSrc(srcEncoded);
                    // handleSetPoseFile(srcEncoded);
                  };
        
                  canvas.remove(); // Remove the canvas element
                  reader2.readAsDataURL(blob);
                }, 'image/jpeg');
        
              setTimeout(() => {
                setIsUploading(false);
                onShowPose(false);
              }, 1000);
              };
        
              image.src = reader.result;
            };
            reader.readAsDataURL(file);
      
          })
          .catch(error => {
            if (error.response.data.error === "not-enough-credits") {
              message.error(t('message.error.outOfCredits'));
              setIsUploading(false);
              return;
            }
            message.error(t('message.error.wrongFace'));
            setIsUploading(false);
            // Handle the error
          });
  
        } else {
          console.error("Error: downloadURL is undefined");
        }
      } catch (error) {
        console.error("Error uploading pose:", error);
      }
    }
  };

  // handle drag events
  const handleDrag = function (e) {

    if (userData.trial) {
      onShowPricing(true);
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      const uploaderWrapper = document.querySelector('.uploader-pose-wrapper');
      uploaderWrapper.style.border = '1px dashed #fff';
      uploaderWrapper.style.color = '#fff';
    } else if (e.type === 'dragleave') {
      const uploaderWrapper = document.querySelector('.uploader-pose-wrapper');
      uploaderWrapper.style.border = '1px dashed rgb(0 0 0 / 20%)';
      uploaderWrapper.style.color = '#8c8c8c';
    }
  };

  const checkFileSize = file => {
    const fileSize = file.size;
    const maxSizeBasic = 10 * 1024 * 1024;
    const maxSizePro = 10 * 1024 * 1024;

    if (mode === modes.pro && fileSize > maxSizePro) {
      message.error(t('message.error.fileOverPro'));
      setIsUploading(false);
      return false;
    }
    if (mode === modes.basic && fileSize > maxSizeBasic) {
      message.error(t('message.error.fileOverPro'));
      setIsUploading(false);
      return false;
    }
    return true;
  };

  const handleFileUpload = async event => {
    if (userData.trial) {
      onShowPricing(true);
      return;
    }

    setIsUploading(true);
    const file = event.target.files[0];

    if (checkFileSize(file) === false) return;

    if (currentUploadedPoses.length > 100) {
      alert("You reached 100 free uploaded poses. Please upgrade to upload more!");
      setIsUploading(false);
      return;
    }
    
    try {
      const downloadURL = await firebase.uploadPoseFile(file);
    
      if (downloadURL) {
        const imageUrl = downloadURL;
    
        const data = {
          uid: userData.uid,
          imageUrl: imageUrl,
          poseUrl: imageUrl,
          createdAt: new Date().toISOString(),
        };
    
        await firebase
        .addUploadedPoses(data)
        .then(() => {
    
          const convertPose = true;
          handleSetPoseFile(downloadURL, convertPose);

          setFile(file);
          const reader = new FileReader();
      
          reader.onload = function () {
            const image = new Image();
      
            image.onload = function () {
              const canvas = document.createElement('canvas');
              const scaleSize = MAX_WIDTH / image.width;
              // canvas.width = MAX_WIDTH;
              // canvas.height = image.height * scaleSize;
              canvas.width = image.width;
              canvas.height = image.height;
              setWidthInfo(canvas.width);
              setHeightInfo(canvas.height);
      
              const ctx = canvas.getContext('2d');
      
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      
              if (file.name === '' || file.name === undefined) {
                const randomNumber = Math.floor(Math.random() * 1000000000); // Generate a random number between 0 and 999999999
                file.name = `reok-up-${randomNumber}.jpg`;
              }
      
              canvas.toBlob(blob => {
                const canvasImage = new File([blob], file.name, {
                  type: file.type,
                  lastModified: file.lastModified,
                });
                setFileInfo(canvasImage); // Set fileInfo as the canvas image with additional properties
                setFile(canvasImage);
      
                const reader2 = new FileReader();
                reader2.onloadend = function () {
                  const srcEncoded = reader2.result;
                  setImageSrc(srcEncoded);
                  // handleSetPoseFile(srcEncoded);
                };
      
                canvas.remove(); // Remove the canvas element
                reader2.readAsDataURL(blob);
              }, 'image/jpeg');
      
            setTimeout(() => {
              setIsUploading(false);
              onShowPose(false);
            }, 1000);
            };
      
            image.src = reader.result;
          };
          reader.readAsDataURL(file);
    
        })
        .catch(error => {
          if (error.response.data.error === "not-enough-credits") {
            message.error(t('message.error.outOfCredits'));
            setIsUploading(false);
            return;
          }
          message.error(t('message.error.wrongFace'));
          setIsUploading(false);
          // Handle the error
        });

      } else {
        console.error("Error: downloadURL is undefined");
      }
    } catch (error) {
      console.error("Error uploading pose:", error);
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className="pose-showbox">
        <section id="pose-container" ref={popupRef}>
          {showSub && (
            <>
              <div>
                <Box>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                  >
                    <StyledTab label={t('app.professionalMode.defaultPose')} />
                    <StyledTab label={t('app.professionalMode.uploadedPose')} />
                  </StyledTabs>
                  <Box sx={{ p: 1 }} />
                </Box>
                <CustomTabPanel value={value} index={0}>
                <div className="rnftg-h-full rnftg-w-full">
                  <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                    <div className="rnftg-flex rnftg-flex-col pd-2">
                      <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                        <div className="upload-pose-item">
                        <div className="pose-col">
                        {!imageSrc && (
                          <div
                            className="uploader-pose-wrapper"
                            onClick={handleOpenFileUpload}
                            onDragOver={handleDrag}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDrop={handleDrop}
                          >
                            <div className="uploader-pose-title">
                              <span>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="icon"
                                  role="presentation"
                                >
                                  <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                                  <polyline points="17 8 12 3 7 8" />
                                  <line x1="12" y1="3" x2="12" y2="15" />
                                </svg>
                                <span style={{marginLeft:"4px"}}>
                                {t('button.uploadPose')}
                                </span>
                                <p style={{marginLeft:"4px"}}>
                                {t('home.uploadPlaceHolder')}
                                </p>
                                {userData.trial && <div className="x-price-card--hero-text" onClick={() => onShowPricing(true)}>
                                  {t('button.upgradeToUpload')}
                                  <svg version="1.1" fill="rgb(251, 190, 40)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 51.2 51.2" xmlSpace="preserve" style={{ marginLeft: "4px", marginTop: "2px"}}>
                                    <g>
                                      <path className="st0" d="M51.2 15.247c0 -2.147 -1.742 -3.888 -3.889 -3.888 -2.148 0 -3.891 1.741 -3.891 3.888 0 1.05 0.417 2.002 1.094 2.7 -2.645 5.478 -7.702 7.391 -11.62 5.659 -3.491 -1.544 -4.778 -5.956 -5.214 -9.375 1.423 -0.748 2.394 -2.239 2.394 -3.959C30.073 7.802 28.072 5.8 25.6 5.8c-2.47 0 -4.473 2.002 -4.473 4.472 0 1.72 0.97 3.211 2.394 3.959 -0.436 3.419 -1.723 7.831 -5.214 9.375 -3.919 1.731 -8.975 -0.181 -11.62 -5.659 0.677 -0.698 1.094 -1.65 1.094 -2.7 0 -2.147 -1.742 -3.888 -3.889 -3.888C1.742 11.359 0 13.1 0 15.247c0 1.978 1.478 3.608 3.388 3.855l4.483 16.408h35.459l4.483 -16.408C49.723 18.855 51.2 17.225 51.2 15.247z"/>
                                      <path className="st0" d="M45.502 42.506c0 1.598 -1.295 2.894 -2.895 2.894H8.594C6.995 45.4 5.7 44.105 5.7 42.506v-0.241c0 -1.6 1.295 -2.895 2.894 -2.895h34.013c1.6 0 2.895 1.295 2.895 2.895v0.241z"/>
                                    </g>
                                  </svg>
                                </div>}
                              </span>
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileUpload}
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                            />
                          </div>
                        )}
                        {imageSrc && (
                          <>
                            <div className="uploaded-wrapper">
                              <div>
                                <img src={imageSrc} alt="Uploaded Image" className="uploaded-image" />
                              </div>
                            </div>
                          </>
                        )}
                        </div>
                      </div>
                        {currentPoses.map((c, i) => {
                          return (
                            <div className="pose-item" key={i}>
                              <div className="pose-line-border">
                                <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                                  <div className="rnftg-item__img-wrapper">
                                    <div className="pose-col">
                                    {!imageLoaded && (
                                        <NewSkeleton/>
                                        )
                                      }
                                      <img
                                        className={`new-image-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
                                        alt="reok"
                                        src={c.imageUrl}
                                        onLoad={handleImageLoad}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="select-pose-imagine-button generate-btn">
                                  {userData.trial && i !== 0 ? (
                                    <Button className="generate-button w-button" onClick={onShowPricing}>
                                      <div className="x-price-card--hero-text" style={{ marginLeft: '4px', textTransform:'none', fontSize: '0.875rem' }} onClick={() => onShowPricing(true)}>
                                        {t('button.unlock')}
                                        <svg version="1.1" fill="rgb(251, 190, 40)" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="14px" viewBox="0 0 51.2 51.2" xmlSpace="preserve" style={{ marginLeft: "4px", marginTop: "2px"}}>
                                          <g>
                                            <path className="st0" d="M51.2 15.247c0 -2.147 -1.742 -3.888 -3.889 -3.888 -2.148 0 -3.891 1.741 -3.891 3.888 0 1.05 0.417 2.002 1.094 2.7 -2.645 5.478 -7.702 7.391 -11.62 5.659 -3.491 -1.544 -4.778 -5.956 -5.214 -9.375 1.423 -0.748 2.394 -2.239 2.394 -3.959C30.073 7.802 28.072 5.8 25.6 5.8c-2.47 0 -4.473 2.002 -4.473 4.472 0 1.72 0.97 3.211 2.394 3.959 -0.436 3.419 -1.723 7.831 -5.214 9.375 -3.919 1.731 -8.975 -0.181 -11.62 -5.659 0.677 -0.698 1.094 -1.65 1.094 -2.7 0 -2.147 -1.742 -3.888 -3.889 -3.888C1.742 11.359 0 13.1 0 15.247c0 1.978 1.478 3.608 3.388 3.855l4.483 16.408h35.459l4.483 -16.408C49.723 18.855 51.2 17.225 51.2 15.247z"/>
                                            <path className="st0" d="M45.502 42.506c0 1.598 -1.295 2.894 -2.895 2.894H8.594C6.995 45.4 5.7 44.105 5.7 42.506v-0.241c0 -1.6 1.295 -2.895 2.894 -2.895h34.013c1.6 0 2.895 1.295 2.895 2.895v0.241z"/>
                                          </g>
                                        </svg>
                                      </div>
                                    </Button>
                                  ):(
                                    <Button className="generate-button w-button" onClick={(e) => onSelectPose(e, c, false)}>
                                      <span style={{ marginLeft: '4px', textTransform:'none' }}>{t('button.select')}</span>
                                    </Button>
                                  )}
                                </div>
                              </div>
                          </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                <div className="rnftg-h-full rnftg-w-full">
                  <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                    <div className="rnftg-flex rnftg-flex-col pd-2">
                      <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                        {currentUploadedPoses.map((c, i) => {
                          return (
                            <div className="pose-item" key={i}>
                              <div className="pose-line-border">
                                <div className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300">
                                  <div className="rnftg-item__img-wrapper">
                                    <div className="pose-col">
                                      {!imageLoaded && (
                                        <NewSkeleton/>
                                        )
                                      }
                                      <img
                                        className={`new-image-wrapper ${imageLoaded ? 'loaded-image' : ''}`}
                                        alt="reok"
                                        src={c.imageUrl}
                                        onLoad={handleImageLoad}
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="select-pose-imagine-button generate-btn">
                                  <Button className="generate-button w-button" onClick={(e) => onSelectPose(e, c, true)}>
                                    <span style={{ marginLeft: '4px' }}>{t('button.select')}</span>
                                  </Button>
                                </div>
                              </div>
                          </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                </CustomTabPanel>
              </div>
            </>
          )}
        </section>
      </div>
      {isUploading && <Spinning text={t('button.uploadingPose')}/>}
    </>
  );
};

export default Pose;
