import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const NewSkeleton = () => {
  return (
    <Skeleton 
      variant="rounded" width="100%" height="100%"
      style={{ position: 'absolute', top: 0, left: 0, background:"rgb(44 44 44 / 94%)" }}
    />
  );
};

export default NewSkeleton;
