import React, { useEffect } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import * as routes from '../constants/routes';

import SignUpContainer from '../containers/SignUpContainer';
import SignInContainer from '../containers/SignInContainer';
import HomeContainer from '../containers/HomeContainer';
import NotFound from '../components/NotFound';
import ForgetPasswordContainer from '../containers/ForgetPasswordContainer';
import SignOutContainer from '../containers/SignOutContainer';
import AuthRoute from './AuthRoute';
import AnyRoute from './AnyRoute';
import PrivateRoute from './PrivateRoute'
import LandingPageContainer from '../containers/LandingPageContainer';
import TermsContainer from '../containers/TermsContainer';
import PrivacyContainer from '../containers/PrivacyContainer';
import AppContainer from '../containers/AppContainer';
import i18n from '../i18n';
import Affiliate from '../components/Affiliate';
import YearbookPageContainer from '../containers/YearbookPageContainer';
import XContainer from '../containers/XContainer';
import AdsLandingPageContainer from '../containers/AdsLandingPageContainer';
import AdsRoute from './AdsRoute';
import RefundPolicy from '../components/RefundPolicy';
import LandingBusinessesHeadshot from '../components/LandingBusinessesHeadshot';
import LandingDatingPhotos from '../components/LandingDatingPhotos';
import LandingDocterHeadshot from '../components/LandingDocterHeadshot';
import LandingTeacherHeadshot from '../components/LandingTeacherHeadshot';
import LandingStudentHeadshot from '../components/LandingStudentHeadshot';
import LandingLawyerHeadshot from '../components/LandingLawyerHeadshot';
import LandingRealestateHeadshot from '../components/LandingRealestateHeadshot';
import LandingSalePersonHeadshot from '../components/LandingSalePersonHeadshot';

const LanguageRoute = () => {
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang === 'lemonsqueezy') {
      setTimeout(() => {
        const mainLang = localStorage.getItem('mainLang');
        if (mainLang !== null) {
          navigate(`/${mainLang}/app`);
        } else {
          navigate(`/app`);
        }
      }, 2000);
    }
    else if (['en', 'vi', 'id', 'jp', 'ko', 'zh'].includes(lang)) {
      i18n.changeLanguage(lang);

      const languageMetaTags = {
        en: {
          title: 'REOK AI Headshot Studio: Create Professional Headshots & Portraits',
          description: 'Create professional and realistic AI headshots and profile pictures using our online AI headshot photographer in seconds not hours.',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625981521346560/reok.webp'
        },
        vi: {
          title: 'Chụp ảnh chuyên nghiệp với AI',
          description: 'Tạo ảnh chuyên nghiệp, cải thiện chất lượng ảnh và nhiều tính năng khác với trí tuệ nhận tạo của chúng tôi.',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625981965938718/reok-vi.webp'
        },
        zh: {
          title: '功能的AI工作室',
          description: '使用我们先进的AI摄影师创建专业照片，增强照片等。',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625983408771103/reok-zh.webp'
        },
        id: {
          title: 'Studio AI untuk Fitur',
          description: 'Buat foto profesional, perbaiki foto, dan banyak lagi dengan fotografer AI canggih kami.',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625982343430164/reok-id.webp'
        },
        jp: {
          title: '機能のためのAIスタジオ',
          description: '高度なAIカメラマンを使用して、プロの写真を作成し、写真を向上させます。',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625982712524830/reok-jp.webp'
        },
        ko: {
          title: '기능을 위한 AI 스튜디오',
          description: '고급 AI 포토그래퍼를 사용하여 전문적인 사진을 만들고 사진을 향상시킵니다.',
          meta: 'https://cdn.discordapp.com/attachments/1105800665105711166/1154625983081627658/reok-ko.webp'
        },
        // Add titles and descriptions for other languages as needed
      };

      const { title, description, meta } = languageMetaTags[lang];
      document.title = title;

      // Update Open Graph (og) meta tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute('content', title);
      }

      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute('content', description);
      }

      const metaUrl = document.querySelector('meta[property="og:image"]');
      if (metaUrl) {
        metaUrl.setAttribute('content', meta);
      }

      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (twitterTitle) {
        twitterTitle.setAttribute('content', title);
      }

      const twitterDescription = document.querySelector('meta[name="twitter:description"]');
      if (twitterDescription) {
        twitterDescription.setAttribute('content', description);
      }

      const twitterUrl = document.querySelector('meta[name="twitter:image"]');
      if (twitterUrl) {
        twitterUrl.setAttribute('content', meta);
      }
    }
  }, [lang, navigate]);

  return (
    <>
    <Routes>
      <Route
        path="/"
        element={<AnyRoute />}
      >
        <Route
          path={routes.LANDING}
          element={<LandingPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.BUSINESSES_HEADSHOT}
          element={<LandingBusinessesHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.DATING_PHOTOS}
          element={<LandingDatingPhotos />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.DOCTOR_HEADSHOTS}
          element={<LandingDocterHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.TEACHER_HEADSHOTS}
          element={<LandingTeacherHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.STUDENT_HEADSHOTS}
          element={<LandingStudentHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.LAWYER_HEADSHOTS}
          element={<LandingLawyerHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.REAL_ESTATE_HEADSHOTS}
          element={<LandingRealestateHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.SALESPERSON_HEADSHOTS}
          element={<LandingSalePersonHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.TERMS_CONDITIONS}
          element={<TermsContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.PRIVACY}
          element={<PrivacyContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
            path={routes.REFUND}
            element={<RefundPolicy />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        <Route
          path={routes.YEARBOOK}
          element={<YearbookPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={<PrivateRoute/>}
      >
        <Route
          path={routes.HOME}
          element={<HomeContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.APP}
          element={<AppContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={<AuthRoute/>}
      >
        <Route
          path={routes.SIGN_IN}
          element={<SignInContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.SIGN_UP}
          element={<SignUpContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.SIGN_OUT}
          element={<SignOutContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.FORGET_PASSWORD}
          element={<ForgetPasswordContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={<AdsRoute/>}
      >
        <Route
          path={routes.AIHEADSHOT}
          element={<AdsLandingPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
    </>
  );
};

const AppRoutes = () => {
  return (
      <Routes>
        <Route
          path="/:lang/*"
          element={<LanguageRoute />}
        />
        <Route
          path="/"
          element={<AnyRoute />}
        >
          <Route
            path={routes.LANDING}
            element={<LandingPageContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.BUSINESSES_HEADSHOT}
            element={<LandingBusinessesHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.DATING_PHOTOS}
            element={<LandingDatingPhotos />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.DOCTOR_HEADSHOTS}
            element={<LandingDocterHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.TEACHER_HEADSHOTS}
            element={<LandingTeacherHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.STUDENT_HEADSHOTS}
            element={<LandingStudentHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.LAWYER_HEADSHOTS}
            element={<LandingLawyerHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.REAL_ESTATE_HEADSHOTS}
            element={<LandingRealestateHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SALESPERSON_HEADSHOTS}
            element={<LandingSalePersonHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.X}
            element={<XContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.AFFILIATE}
            element={<Affiliate />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.TERMS_CONDITIONS}
            element={<TermsContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.PRIVACY}
            element={<PrivacyContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.REFUND}
            element={<RefundPolicy />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
          path={routes.YEARBOOK}
          element={<YearbookPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        </Route>
        <Route
          path="/"
          element={<PrivateRoute/>}
        >
          <Route
            path={routes.HOME}
            element={<HomeContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.APP}
            element={<AppContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<AuthRoute/>}
        >
          <Route
            path={routes.SIGN_IN}
            element={<SignInContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_UP}
            element={<SignUpContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_OUT}
            element={<SignOutContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.FORGET_PASSWORD}
            element={<ForgetPasswordContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<AdsRoute/>}
        >
          <Route
            path={routes.AIHEADSHOT}
            element={<AdsLandingPageContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
  );
};

export default AppRoutes;
